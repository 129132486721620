import { ConfigAPI } from './config-api';
import { isObject } from '../lib/utils';

export type IConfigRoute = {
    url?: string;
    label?: string;
    href?: string;
    subLabel?: string;
};

function parseConfigRoute(route: unknown): undefined | IConfigRoute {
    if (!isObject(route)) return;
    const { url, label } = route;
    return {
        ...(typeof url === 'string' ? { url } : {}),
        ...(typeof label === 'string' ? { label } : {}),
    };
}

function parseConfig(config: unknown): undefined | Record<string, IConfigRoute> {
    if (!isObject(config)) return;
    if (!isObject(config.routes)) return;
    const entries: [string, IConfigRoute][] = Object.entries(config.routes).flatMap(([id, route]) => {
        const parsed = parseConfigRoute(route);
        return parsed ? [[id, parsed]] : [];
    });
    return Object.fromEntries(entries);
}

export function resolve(configs: { organization: unknown }) {
    return parseConfig(configs.organization) ?? {};
}

export async function fetch(overrides?: { organization?: unknown }) {
    const organization = (await overrides?.organization) ?? ConfigAPI.get().then(api => api.organization.get());
    return resolve({ organization });
}
