import _ from 'lodash';
import type { VisualMapComponentOption } from 'echarts/components';
import { EChartsOption, LegendComponentOption, MapSeriesOption, TooltipComponentOption } from 'echarts';
import type { IMetricDefinition } from '../../lib/types';
import { isObject } from '../../lib/utils';
import { buildEchartTooltipListItem } from './chart-echarts.helper';
import { ISeriesData } from './chart-echarts-wrapper.directive';
import { ECHARTS_THEME } from './chart-echarts.config';
import { USA } from './maps';

export interface IMapSeries extends MapSeriesOption {
    data: ISeriesData[];
}

export type ITooltipParams = {
    metric: string;
    color: string;
    name: string;
    value?: string | number;
};

const ECHARTS_TOOLTIP: TooltipComponentOption = {
    trigger: 'item',
    transitionDuration: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    axisPointer: {
        type: 'shadow',
        shadowStyle: {
            color: 'rgba(0,0,0,0.03)',
        },
    },
    textStyle: {
        fontFamily: ECHARTS_THEME.fontFamily,
        fontSize: 12,
    },
};

const ECHARTS_VISUAL_MAP_OPTIONS: VisualMapComponentOption = {
    orient: 'horizontal',
    inRange: {
        color: ['#EEE', '#74DDD0', '#8C7FEA'],
    },
    text: ['High', 'Low'],
    textStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 12,
    },
};

const ECHARTS_LEGEND: LegendComponentOption = {
    show: false,
    height: 0,
    inactiveColor: ECHARTS_THEME.axisLineColor,
    textStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 10,
    },
};

const ECHARTS_DEFAULT_MAP_OPTIONS: echarts.EChartsOption = {
    scaleLimit: {
        min: 1,
        max: 1,
    },
    textStyle: {
        fontFamily: ECHARTS_THEME.fontFamily,
    },
    series: {},
    legend: ECHARTS_LEGEND,
    visualMap: ECHARTS_VISUAL_MAP_OPTIONS,
    tooltip: ECHARTS_TOOLTIP,
    emphasis: {
        label: {
            show: true,
        },
    },
};

const defaultSeriesObj: MapSeriesOption = {
    type: 'map',
    roam: true,
    map: 'USA',
    showLegendSymbol: false,
    itemStyle: {
        borderColor: '#444',
        borderWidth: 0.25,
    },
    emphasis: {
        itemStyle: {
            borderWidth: 1.0,
        },
        label: {
            show: false,
        },
    },
    data: [],
};

type I42MapSeriesRow = { name: string; metric: IMetricDefinition; value: '-' | number };
type I42MapSeriesOptions = Omit<MapSeriesOption, 'data'> & { data: I42MapSeriesRow[] };

export const EchartsMapChartModelInstance = () => [
    '$filter',
    function EchartsMapChartModelMethod($filter: angular.IFilterService) {
        class EchartsMapChartModel {
            chartOptions: EChartsOption;

            constructor(metrics: IMetricDefinition[], data: Record<string, string | number>[] = []) {
                const metric = metrics[0];
                if (!metric) throw new Error('EchartsMapChartModelInstance error; metric not found');

                // FIXME: https://42technologies.atlassian.net/browse/DEV-5394
                let total = 0;

                const rows: I42MapSeriesRow[] = data.flatMap(row => {
                    const valueRaw: unknown = row[metric.field];
                    const value = typeof valueRaw === 'number' && !Number.isNaN(valueRaw) ? valueRaw : null;
                    total += value ?? 0;
                    const property =
                        typeof row.property0 === 'string' &&
                        row.property0.length > 0 &&
                        row.property0.toLowerCase() !== 'n/a'
                            ? row.property0
                            : undefined;
                    const state = property ? USA.getStateFullName(property) : undefined;
                    const name = state ?? property;
                    if (!name) return [];
                    return [{ name, metric, value: value ?? '-' }];
                }, []);

                const serie: I42MapSeriesOptions = {
                    ..._.cloneDeep(_.omit(defaultSeriesObj, 'data')),
                    data: rows,
                };

                const chartOptions = {
                    ..._.cloneDeep(ECHARTS_DEFAULT_MAP_OPTIONS),
                    series: serie,
                };

                chartOptions.visualMap = ((): VisualMapComponentOption => {
                    const { min, max } = getSeriesMaxMinValue(chartOptions.series);
                    const formatter: VisualMapComponentOption['formatter'] = value => $filter('metric')(value, metric);
                    return { ...chartOptions.visualMap, formatter, min, max };
                })();

                chartOptions.tooltip = {
                    ...chartOptions.tooltip,
                    formatter: params => {
                        if (!('name' in params)) return '';
                        const name = params.name;
                        const color = typeof params.color === 'string' ? params.color : null;
                        const value = typeof params.value === 'number' ? params.value : null;
                        const percent = value !== null ? $filter('percent')(value / total) : null;
                        const absolute = value !== null ? $filter('metric')(value, metric) : null;
                        const html = buildEchartTooltipListItem({ color, name, value: absolute, percent });
                        return `<div class='tooltip'>${html}</div>`;
                    },
                };

                this.chartOptions = chartOptions;
            }
        }

        return EchartsMapChartModel;
    },
];

function getSeriesMaxMinValue(serie: { data?: { value?: unknown }[] }) {
    const data = serie.data ?? [];
    const values = data.flatMap(elem => {
        if (!isObject(elem)) return [];
        if (typeof elem.value !== 'number') return [];
        if (Number.isNaN(elem.value)) return [];
        return [elem.value];
    });
    return {
        max: _.max(values) ?? 0,
        min: _.min(values) ?? 0,
    };
}
