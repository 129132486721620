import _ from 'lodash';
import { IQueryColumnFilterValue, IQueryColumnFilterValueOperatorFuzzy, IQueryTableFilterColumns } from '../types';
import { FilterExpressionParser } from './filter-expression-parser';
import { Parse } from './values';

const OPERATOR_MAP = {
    '<': '$lt',
    '>': '$gt',
    '<=': '$lte',
    '=<': '$lte',
    '=>': '$gte',
    '>=': '$gte',
    '==': '$equals',
    '!=': '$ne',
} as const;
const isOperator = (x: string): x is keyof typeof OPERATOR_MAP => {
    return x in OPERATOR_MAP;
};

const numberFilter = (value: unknown): undefined | IQueryColumnFilterValue => {
    const tokens = FilterExpressionParser.money(value);
    if (!tokens) return;
    const operator = isOperator(tokens.operator) ? tokens.operator : null;
    if (!operator) return;
    const key = OPERATOR_MAP[operator];
    if (!key) return;
    return { [key]: tokens.value } as IQueryColumnFilterValue;
};

const stringFilter = (value: unknown): undefined | IQueryColumnFilterValueOperatorFuzzy => {
    const $fuzzy = Parse.String(value);
    return $fuzzy ? { $fuzzy } : undefined;
};

const COMPILERS: Record<string, (value: unknown) => IQueryColumnFilterValue | undefined> = {
    number: numberFilter,
    string: stringFilter,
} as const;

export const FilterExpressionToQueryFilterFactory = (descriptors: Record<string, string>) => {
    const types = Object.keys(COMPILERS);
    const descriptorTypes = _.uniq(_.values(descriptors));
    const unsupportedTypes = _.difference(descriptorTypes, types);

    if (unsupportedTypes.length !== 0) {
        throw new Error(`Parsers types '${unsupportedTypes}' are not supported.`);
    }

    return {
        parse: (properties: Record<string, any>): IQueryTableFilterColumns => {
            return Object.keys(properties).reduce<IQueryTableFilterColumns>((result, property: string) => {
                const key = descriptors[property];
                if (!key) return result;
                const parser = COMPILERS[key];
                if (!parser) return result;
                const value = parser(properties[property]);
                if (value) result[property] = value;
                return result;
            }, {});
        },
    };
};
