import { isPlainObject as _isPlainObject, isObject as _isObject } from 'lodash';
export { omitDeep } from './utils-object-omit-deep';
import type { Primitive, Container } from './utils-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PickObject<T> = T extends Record<string, any>
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Exclude<T, Primitive | unknown[]>
    : Record<string, unknown>;

export const isObject = <T extends Primitive | Container = Primitive | Container>(
    input: unknown,
): input is PickObject<T> => {
    return typeof input === 'object' && input !== null && !Array.isArray(input);
};

const FLATTENED_OBJECT_SEPARATOR = '';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function flatten(obj: any, result: Record<string, unknown> = {}, prefix = '') {
    // Unlikely to happen given our separator... skipping for maybe performance...
    // Object.keys(object).forEach (key) ->
    //     invalid = key.includes(separator)
    //     throw new Error("Key `#{key}` contains separator `#{separator}`") if invalid
    for (const key in obj) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const value: unknown = obj[key];
        if (_isPlainObject(value)) {
            flatten(value, result, `${prefix}${key}${FLATTENED_OBJECT_SEPARATOR}`);
        } else {
            result[`${prefix}${key}`] = value;
        }
    }
    return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hash(obj: any) {
    if (obj === null || obj === undefined) return 'null';
    if (['string', 'number', 'boolean'].includes(typeof obj)) return JSON.stringify(obj);
    if (!_isObject(obj)) throw new Error('Argument must be a string, number, boolean, array or object.');
    obj = flatten(obj, {});
    return JSON.stringify(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Object.keys(obj)
            .sort()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            .reduce((result: unknown[], key: string | number) => result.concat([[key, obj[key]]]), []),
    );
}

/** Pulls out values matching a list of object keys */
export function pickValues<T extends Record<K, unknown>, K extends keyof T>(obj: T): T[K][];
export function pickValues<T extends Record<K, unknown>, K extends keyof T, S>(obj: T, subset: (S | K)[]): T[S & K][];
export function pickValues<T extends Record<K, unknown>, K extends keyof T, S extends symbol | number | string>(
    obj: T,
    subset?: (S | K)[],
) {
    if (!subset) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Object.values(obj);
    }
    return subset.flatMap(key => {
        if (!(key in obj)) return [];
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const value = obj[key as keyof T];
        return [value];
    });
}

// Usage example
const result = pickValues({ a: 'v', b: 2 }, ['a', 'b']);
