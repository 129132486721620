import _ from 'lodash';
import type { IMetricsFunnelNode } from './metrics-funnel';

export interface IAssociatedColumns {
    headerName: string;
    field: string;
    width: number;
    pinned?: boolean;
    cellFilter?: string;
}

export const MetricsFunnelNodeGridViewModelAssociatedColumns = (node: IMetricsFunnelNode, org: string) => {
    const associatedColumns: IAssociatedColumns[] = [];
    let width: number | undefined = 0;
    const selectedDrilldownProperties = node.getDrilldownProperties();

    const result = node.properties.reduce<Record<string, IAssociatedColumns>>((acc, property) => {
        const propertyAssociatedColumens: Record<string, IAssociatedColumns> = {};
        switch (org) {
            case 'joesjeans':
            case 'joesjeans_dtc':
                switch (property.id) {
                    case 'items.class':
                        width = 200;
                        [{ headerName: 'Department', field: 'item_department', width: 120 }].forEach(item => {
                            if (selectedDrilldownProperties.includes(item.field)) {
                                propertyAssociatedColumens[item.field] = item;
                                associatedColumns.push(item);
                            }
                        });
                        break;
                    case 'items.subclass':
                        width = 200;

                        [
                            { headerName: 'Department', field: 'item_department', width: 120 },
                            { headerName: 'Class', field: 'item_class', width: 90 },
                        ].forEach(item => {
                            if (selectedDrilldownProperties.includes(item.field)) {
                                propertyAssociatedColumens[item.field] = item;
                                associatedColumns.push(item);
                            }
                        });
                        break;
                    case 'items.pattern':
                        width = 200;
                        [
                            { headerName: 'Department', field: 'item_department', width: 120 },
                            { headerName: 'Class', field: 'item_class', width: 90 },
                            { headerName: 'Subclass', field: 'item_subclass', width: 100 },
                            { headerName: 'DCS Code', field: 'item_dcs_code', width: 100, pinned: false },
                        ].forEach(item => {
                            associatedColumns.push(item);
                            propertyAssociatedColumens[item.field] = item;
                        });
                        break;
                    case 'items.style':
                        width = 80;
                        [
                            { headerName: 'Department', field: 'item_department', width: 120 },
                            { headerName: 'Class', field: 'item_class', width: 90 },
                            { headerName: 'Subclass', field: 'item_subclass', width: 100 },
                            { headerName: 'DCS Code', field: 'item_dcs_code', width: 100, pinned: false },
                            { headerName: 'Body', field: 'item_pattern', width: 150, pinned: false },
                        ].forEach(item => {
                            propertyAssociatedColumens[item.field] = item;
                            associatedColumns.push(item);
                        });
                        break;
                    case 'items.material':
                        width = 120;
                        [
                            { headerName: 'Department', field: 'item_department', width: 120 },
                            { headerName: 'Class', field: 'item_class', width: 90 },
                            { headerName: 'Subclass', field: 'item_subclass', width: 100 },
                            { headerName: 'DCS Code', field: 'item_dcs_code', width: 100, pinned: false },
                            { headerName: 'Body', field: 'item_pattern', width: 150, pinned: false },
                            { headerName: 'Season', field: 'item_season', width: 125, pinned: false },
                            {
                                headerName: 'MSRP',
                                field: 'item_msrp',
                                width: 125,
                                cellFilter: 'money:0',
                                pinned: false,
                            },
                        ].forEach(item => {
                            propertyAssociatedColumens[item.field] = item;
                            associatedColumns.push(item);
                        });
                        break;
                }
                break;
            case 'rebeccaminkoff':
            case 'rebeccaminkoff_dtc':
                switch (property.id) {
                    case 'items.name':
                        width = 150;
                        [
                            { headerName: 'Division', field: 'item_division_name', width: 150 },
                            { headerName: 'Category', field: 'item_product_category', width: 100 },
                            { headerName: 'Group', field: 'item_product_group', width: 150 },
                            { headerName: 'Season', field: 'item_season', width: 125, pinned: false },
                        ].forEach(item => {
                            propertyAssociatedColumens[item.field] = item;
                            associatedColumns.push(item);
                        });
                        break;
                    case 'items.sku':
                        width = 200;
                        [
                            { headerName: 'Division', field: 'item_division_name', width: 150 },
                            { headerName: 'Category', field: 'item_product_category', width: 100 },
                            { headerName: 'Group', field: 'item_product_group', width: 150 },
                            { headerName: 'Name', field: 'item_name', width: 150 },
                            { headerName: 'Season', field: 'item_season', width: 125, pinned: false },
                            {
                                headerName: 'MSRP',
                                field: 'item_msrp',
                                width: 125,
                                cellFilter: 'money:0',
                                pinned: false,
                            },
                        ].forEach(item => {
                            associatedColumns.push(item);
                            propertyAssociatedColumens[item.field] = item;
                        });
                        break;
                    case 'items.upc':
                        width = 120;
                        [
                            { headerName: 'Division', field: 'item_division_name', width: 150 },
                            { headerName: 'Category', field: 'item_product_category', width: 100 },
                            { headerName: 'Name', field: 'item_name', width: 150 },
                            { headerName: 'Group', field: 'item_product_group', width: 150 },
                            { headerName: 'SKU', field: 'item_sku', width: 150, pinned: false },
                            { headerName: 'Season', field: 'item_season', width: 125, pinned: false },
                            {
                                headerName: 'MSRP',
                                field: 'item_msrp',
                                width: 125,
                                cellFilter: 'money:0',
                                pinned: false,
                            },
                        ].forEach(item => {
                            associatedColumns.push(item);
                            propertyAssociatedColumens[item.field] = item;
                        });
                        break;
                    case 'items.color':
                        width = 80;
                        [
                            { headerName: 'Division', field: 'item_division_name', width: 100 },
                            { headerName: 'Category', field: 'item_category', width: 100 },
                            { headerName: 'Name', field: 'item_name', width: 150 },
                        ].forEach(item => {
                            if (selectedDrilldownProperties.includes(item.field)) {
                                associatedColumns.push(item);
                                propertyAssociatedColumens[item.field] = item;
                            }
                        });
                        break;
                }
                break;
        }

        return _.mergeWith(acc, propertyAssociatedColumens, (objValue, srcValue) => {
            if (objValue && !srcValue) return objValue;
            if (!objValue && srcValue) return srcValue;

            return {
                headerName: objValue.headerName,
                field: objValue.field,
                width: objValue.width >= srcValue.width ? objValue.width : srcValue.width,
                ...((objValue.pinned || srcValue.pinned) !== undefined
                    ? { pinned: objValue.pinned || srcValue.pinned }
                    : {}),
                ...((objValue.cellFilter || srcValue.cellFilter) !== undefined
                    ? { cellFilter: objValue.cellFilter || srcValue.cellFilter }
                    : {}),
            };
        });
    }, {});

    return {
        columns: associatedColumns,
        width,
    };
};
