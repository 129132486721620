import $ from 'jquery'
import { memoize } from 'lodash'


export getScrollbarWidth = memoize ->
    $inner = $('<div style="width: 100%; height:200px;">test</div>')
    $outer = $('<div style="width:200px;height:150px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner)
    inner = $inner[0]
    outer = $outer[0]
    $('body').append(outer)
    width1 = inner.offsetWidth
    $outer.css('overflow', 'scroll')
    width2 = outer.clientWidth
    $outer.remove()
    return (width1 - width2)


export hasScollbar = (element) ->
    $(element).get(0).scrollHeight > $(element).height()


export preventOverscroll = (element) ->
    onScroll = (e) ->
        up = false
        up = e.originalEvent.wheelDelta / -1 < 0 if e.originalEvent?.wheelDelta
        up = e.originalEvent.deltaY < 0          if e.originalEvent?.deltaY
        up = e.originalEvent.detail < 0          if e.originalEvent?.detail

        prevent = ->
            e.stopPropagation()
            e.preventDefault()
            e.returnValue = false
            return false

        if not up and @scrollHeight <= $(this).innerHeight() + @scrollTop + 1
            prevent()
        else if up and 0 >= @scrollTop - 1
            prevent()
        return

    $element = $(element)
    $element.on('DOMMouseScroll mousewheel', onScroll)
    return -> $element.off('DOMMouseScroll mousewheel', onScroll)
