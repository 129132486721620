import _ from 'lodash';
import { IPromise, IRootScopeService } from 'angular';
import type { ColDef as AgGridColumnDef } from '@ag-grid-community/core';
import * as Analytics from '../../lib/analytics';
import * as Utils from '../../lib/utils';
import { AngularInjected } from '../../lib/angular';
import { IMetricsGridConfigViews, IMetricsGridConfigViewsColumns, deserializeViews, isTotalRow } from './metrics-utils';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import {
    IQueryType,
    IQuerySortNulls,
    IQuery,
    IMetricDefinition,
    IQueryWhereTableFilter,
    IQueryTableFilterOperatorAnd,
    IQueryTableFilter,
} from '../../lib/types';
import { IQueryServiceAPI } from '../../modules/services/query-service.types';
import { IQueryMetrics } from '../main-controller';
import { QueryServiceExport } from '../../modules/services/query-service-export';
import { MetricsFunnelNodeGridViewModelAssociatedColumns } from './metrics-funnel-node-grid-view-model-associated-columns';
import { IMetricsFunnelRowData } from './metrics-grid.directive';
import { IAGGridFiltering, normalizedAGGridFiltering } from './metrics-grid-utils';
import { isObject } from '../../lib/utils/utils-object';

export type IMetricsFunnel = InstanceType<typeof MetricsFunnel>;
export type IMetricsFunnelNode = InstanceType<typeof MetricsFunnelNode>;

export interface IMetricsFunnelSort {
    field: string;
    order: 1 | -1;
    nulls?: IQuerySortNulls;
}

export interface IColumnDef extends AgGridColumnDef {
    field: string;
    headerGroup?: string;
    cellFilter?: string | null;
    _cellClass?: string;
    drilldown?: boolean;
    columnViewName?: string;
    category?: string;
}

export interface IMetricsColumnDef extends IColumnDef {
    headerGroup: string;
}

type MetricsFunnelArguments = {
    properties: IPropertyDefinition[];
    metrics: IMetricDefinition[];
    selectedMetrics: IMetricDefinition[] | string[];
    metricsFilteringByProperty?: Record<string, IQueryWhereTableFilter>;
    views?: IMetricsGridConfigViews;
    actions?: IMetricsFunnelActions;
};

export interface IMetricsFunnelActions {
    save: (node: MetricsFunnelNode) => void;
}

export type IMetricsFiltering = IQueryWhereTableFilter;

export type IMetricsFunnelState = {
    properties?: {
        property: string;
        sort?: IMetricsFunnelSort | undefined;
        value: string | number | undefined;
        filtering?: IMetricsFiltering;
    }[];
    metrics?: string[];
    metricsFiltering?: IMetricsFiltering;
    views?: IMetricsGridConfigViews;
};

export type IMetricsFunnelViews = {
    columns: IMetricsGridConfigViewsColumns;
    filters?: IAGGridFiltering;
};

export class MetricsFunnel {
    views: IMetricsFunnelViews;
    metrics: MetricsFunnelMetrics;
    properties: IPropertyDefinition[];
    root: MetricsFunnelNode;
    nodes: MetricsFunnelNode[];
    node: MetricsFunnelNode;
    protected stash: null | MetricsFunnelNode;
    readonly actions: IMetricsFunnelActions;

    constructor({ properties, metrics, selectedMetrics, views, actions }: MetricsFunnelArguments) {
        if (!Array.isArray(metrics)) {
            throw new Error('missing required: metrics');
        }
        if (!Array.isArray(properties)) {
            throw new Error('missing required: properties (not an array)');
        }
        if (properties.length === 0) {
            throw new Error('missing required: properties (empty array)');
        }
        if (!actions?.save) {
            throw new Error('missing required: actions.save');
        }
        this.actions = actions;
        this.views = _.cloneDeep({ columns: views?.columns ?? {} });
        this.metrics = new MetricsFunnelMetrics({
            available: metrics,
            selected: selectedMetrics,
        });
        this.properties = _.cloneDeep(properties);
        this.root = new MetricsFunnelNode({
            properties: this.properties,
        });
        this.nodes = [];
        this.node = this.root;
        this.stash = null;
    }

    public reset() {
        this.root = new MetricsFunnelNode({ properties: this.properties });
        this.metrics.setFiltering();
        this.nodes = [];
        this.node = this.root;
        this.stash = null;
    }

    protected push(parent?: MetricsFunnelNode, child?: MetricsFunnelNode) {
        if (!parent) throw new Error('missing required argument: parent');
        this.node = child ?? this.getNewNode(parent);
        this.nodes = [...this.nodes.slice(0, parent.level), parent];
        this.stash = null;
        return this.node;
    }

    protected getNewNode(parent?: MetricsFunnelNode) {
        if (!parent) return new MetricsFunnelNode({ properties: this.properties });
        return new MetricsFunnelNode({
            sort: parent.getUserSort(),
            level: parent.level + 1,
            parent,
        });
    }

    public removeNode(node: MetricsFunnelNode) {
        this.nodes = [...this.nodes.filter(x => x.id !== node.id)];
    }

    public updateGridConfigColumnWidth(columnsResized: Record<string, number>) {
        Object.keys(columnsResized).forEach(key => (this.views.columns[key] = columnsResized[key]));
        this.actions.save(this.node);
    }

    protected updateMetricsFiltering(columnFilters: IMetricsFiltering) {
        this.node.setFiltering(columnFilters);
    }

    public updateGridColumnFilters(columnFilters: IAGGridFiltering) {
        const metricsFiltering = normalizedAGGridFiltering(columnFilters, this.metrics.availableByField);
        this.updateMetricsFiltering(metricsFiltering);
        this.actions.save(this.node);
    }

    public drilldown(values: Record<string, (string | number)[]>) {
        if (_.isNil(values) || _.isEmpty(values)) return console.warn('value is nil; skipping drilldown:', values);
        this.node.setValue(values);
        this.node = this.push(this.node);
        this.actions.save(this.node);
    }

    public unselectNode() {
        if (!this.stash) return;
        const stash = this.stash;
        this.stash = null;
        if (!this.nodes.find(x => x.id === stash.parent?.id)) {
            let message = `Metrics funnel unselect node error`;

            try {
                message = message + `\n${JSON.stringify(_.omit(this.serialize(), 'metrics'), null, 2)}`;
            } catch (error) {
                console.log(error);
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            Analytics.logError(new Error(message));
            this.reset();
            return;
        }
        this.node = stash;
    }

    public selectNode(node?: MetricsFunnelNode) {
        if (!node) throw new Error('missing required argument: node');
        const selectedNode = this.nodes.find(x => x.id === node.id);
        if (!selectedNode) throw new Error('node not found in drilldown stack');
        if (selectedNode.id === this.node.id) return this.unselectNode();
        const previousSelectedNode = this.nodes.find(x => x.id === this.node.id);
        this.stash = !previousSelectedNode ? this.node : this.stash;
        this.node = node;
    }

    public setProperty(properties: undefined | string | IPropertyDefinition | (IPropertyDefinition | string)[]) {
        if (_.isNil(properties)) throw new Error('missing required argument: property');
        properties = Array.isArray(properties) ? properties : [properties];
        const propertyIds = properties.map(property => (typeof property === 'string' ? property : property.id));
        // const propertyId = Utils.isObject(property) ? property.id : property;
        // if (typeof propertyId !== 'string') throw new Error('missing required argument: property');
        const nodeHasAllProperties = (() => {
            if (this.node.property.length !== propertyIds.length) return false;
            return this.node.property.every(x => propertyIds.includes(x.id));
        })();
        if (nodeHasAllProperties) return this.node;
        const nodes = [...this.nodes.slice(0, this.node.level)];
        const parent = nodes[nodes.length - 1];
        const prev = this.node;
        this.stash = null;
        this.node = this.getNewNode(parent);
        this.node.setProperty(propertyIds);
        this.node.setUserSort(prev.getUserSort());
        this.nodes = nodes;
        this.actions.save(this.node);
        return this.node;
    }

    public updateSort(funnelSort: IMetricsFunnelSort[]) {
        const sort = Array.isArray(funnelSort) && funnelSort.length > 0 ? funnelSort : null;
        [...this.nodes, this.node].forEach(node => node.setUserSort(sort));
        return this.actions.save(this.node);
    }

    public updateColumnSortOrder(columnSortOrder: string[]) {
        this.metrics.reorder(columnSortOrder);
        return this.actions.save(this.node);
    }

    public selectMetrics(metrics: IMetricDefinition[]) {
        this.metrics.select(metrics);
        return this.actions.save(this.node);
    }

    public serialize() {
        const properties = (() => {
            const propertyNode = this.nodes.find(n => this.node.id === n.id);
            if (propertyNode) return this.nodes.map(x => x.serialize());
            return [...this.nodes.map(x => x.serialize()), this.node.serialize()];
        })();

        return {
            properties,
            metrics: this.metrics.selected.map(x => x.field),
            views: _.cloneDeep(this.views),
        };
    }

    static deserialize(props: {
        properties: IPropertyDefinition[];
        metrics: IMetricDefinition[];
        actions: IMetricsFunnelActions;
        state?: unknown | IMetricsFunnelState;
    }): null | MetricsFunnel {
        if (!Utils.isObject(props.state)) return null;
        const metricsFunnel = new MetricsFunnel({
            properties: props.properties,
            metrics: props.metrics,
            selectedMetrics: props.state.metrics ?? props.metrics,
            views: deserializeViews(props.state.views),
            actions: props.actions,
        });
        const nodes = Array.isArray(props.state.properties) ? props.state.properties : [];
        for (const data of nodes) {
            try {
                const parsed = MetricsFunnelNode.deserialize(data);
                if (!parsed) break;
                const node = metricsFunnel.node;
                if (parsed.property.length === 0) {
                    continue;
                }
                node.setProperty(parsed.property);
                if (Array.isArray(parsed.sort) && parsed.sort.length > 0) {
                    node.setUserSort(parsed.sort);
                }
                if (!_.isEmpty(parsed.filtering)) {
                    node.setFiltering(parsed.filtering);
                }
                if (!_.isEmpty(parsed.value)) {
                    metricsFunnel.drilldown(parsed.value);
                }
            } catch (error_) {
                const error = new MetricsFunnelDeserializationError(props.state, error_);
                Analytics.logError(error);
                break;
            }
        }

        return metricsFunnel;
    }
}

export type MetricsFunnelNodeValue = string | number;

class MetricsFunnelDeserializationError extends Utils.CustomError {
    static StringifyState(state: Record<string, unknown>) {
        try {
            return JSON.stringify(_.omit(state, 'metrics'));
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    readonly state: Record<string, unknown>;
    constructor(state: Record<string, unknown>, cause: unknown) {
        let details: null | string = MetricsFunnelDeserializationError.StringifyState(state);
        details = details ? `\n${details}` : '';
        super(`Metrics funnel deserialization error${details}`, { cause });
        this.state = state;
    }
}

export class MetricsFunnelNode {
    readonly parent: undefined | MetricsFunnelNode;
    readonly level: number;
    readonly id: string;
    readonly properties: IPropertyDefinition[];
    readonly propertiesByField: Record<string, IPropertyDefinition>;
    property: IPropertyDefinition[];
    filtering: IMetricsFiltering = {};
    protected sort: null | IMetricsFunnelSort[];
    value: Record<string, MetricsFunnelNodeValue[]>;
    valueLabel: string;
    hasValue: boolean;

    constructor(
        props:
            | undefined
            | {
                  level?: number;
                  parent?: MetricsFunnelNode;
                  properties?: IPropertyDefinition[];
                  metricsFilteringByProperty?: Record<string, IMetricsFiltering>;
                  property?: IPropertyDefinition | IPropertyDefinition[];
                  value?: MetricsFunnelNodeValue | MetricsFunnelNodeValue[] | Record<string, MetricsFunnelNodeValue[]>;
                  sort?: undefined | null | IMetricsFunnelSort[];
              },
    ) {
        const { parent, property, level, sort, value } = props ?? {};
        let properties = props?.properties;
        this.id = Utils.uuid();
        this.parent = parent;
        this.level = level ?? 0;
        this.sort = sort ?? null;

        this.properties = (() => {
            if (!parent && !Array.isArray(properties)) throw new Error('missing required: properties');
            properties ??= [];
            if (!parent) return properties;
            const parentProperties = parent.property.map(x => x.id);
            return parent.properties.filter(p => !parentProperties.includes(p.id));
        })();
        if (this.properties.length === 0) throw new Error('property list is exhausted');

        this.propertiesByField = _.keyBy(this.properties, x => x.id);

        this.property = ((): IPropertyDefinition[] => {
            const selectedProperties = (() => {
                const properties = Array.isArray(property) ? property : [property];
                const p = _.compact(properties.map(x => (typeof x === 'string' ? { id: x } : x)));
                if (p.length > 0) return p;
                if (!parent?.properties) return null;

                // Fetch next property in the list
                const nextPropertyIndex = parent.property
                    .map(x => x.id)
                    .reduce((acc, propertyId) => {
                        const propertyIndex = parent.properties.findIndex(x => x.id === propertyId);
                        if (propertyIndex === -1) return acc;
                        return propertyIndex > acc ? propertyIndex : acc;
                    }, -1);

                if (nextPropertyIndex === -1) return null;
                const nextProperty = this.properties[nextPropertyIndex];
                return nextProperty ? [nextProperty] : null;
            })();

            const nextSelectedProperty = (() => {
                const properties = _.compact((selectedProperties ?? []).map(x => this.propertiesByField[x.id]));
                if (properties.length > 0) return properties;
                const next = this.properties[0];
                return next ? [next] : [];
            })();

            if (nextSelectedProperty.length === 0) throw new Error('Could not resolve property');
            return nextSelectedProperty;
        })();

        this.value = (() => {
            if (Array.isArray(value)) {
                const property = this.property[0];
                return property ? { [property.id]: value } : {};
            } else if (Utils.isObject(value)) {
                return value;
            }
            return {};
        })();

        this.hasValue = !_.isNil(this.value) && Object.keys(this.value).length > 0;
        if (!this.hasValue) {
            this.valueLabel = 'Ø';
        } else {
            this.valueLabel = Object.values(this.value).reduce<string>((acc, value) => {
                const label = acc.length > 0 ? `${acc} • ` : '';
                return `${label}${value.join(' • ')}`;
            }, '');
        }
        this.filtering = (() => {
            const metricsFilteringByProperty = props?.metricsFilteringByProperty ?? {};
            return this.property.reduce<IMetricsFiltering>((result, property) => {
                const propertyId = property.id;
                return { ...result, ...(metricsFilteringByProperty[propertyId] ?? {}) };
            }, {});
        })();
    }

    // NOTE: this is used by MetricsFunnelNodeGridViewModelAssociatedColumns...
    getDrilldownProperties() {
        let current = this.parent;
        let result: string[] = [];
        while (current) {
            const propertiesIds = current.property.map(x => x.id);
            result = result.concat(propertiesIds);
            current = current.parent;
        }
        return result;
    }

    getSort(): null | IMetricsFunnelSort[] {
        return this.getUserSort() ?? this.getPropertySort();
    }

    getUserSort() {
        return _.cloneDeep(this.sort);
    }

    getPropertySort(): null | IMetricsFunnelSort[] {
        if (this.property.length === 0) return null;
        const propertySorts = this.property.reduce<IMetricsFunnelSort[]>((acc, x) => {
            if (Utils.isObject(x) && x.sort) acc.push(_.cloneDeep(x.sort));
            return acc;
        }, []);
        if (propertySorts.length === 0) return null;
        return propertySorts;
    }

    setUserSort(sort: null | IMetricsFunnelSort[]): void {
        sort = Array.isArray(sort) && sort.length > 0 ? sort : null;
        console.log('[metrics] setting sort:', sort);
        this.sort = _.cloneDeep(sort);
    }

    setValue(values: Record<string, (number | string)[]>): void {
        this.value = _.isEmpty(values) ? {} : values;
        this.hasValue = Object.keys(this.value).length > 0;

        if (!this.hasValue) {
            this.valueLabel = 'Ø';
        } else {
            this.valueLabel = Object.values(this.value).reduce<string>((acc, value) => {
                const label = acc.length > 0 ? `${acc} • ` : '';
                return `${label}${value.join(' • ')}`;
            }, '');
        }
    }

    setProperty(property?: IPropertyDefinition[] | string[]) {
        if (_.isNil(property)) throw new Error('missing required: property');
        property = Array.isArray(property) ? property : [property];
        const propertyIds = property.map(x => (typeof x === 'string' ? x : x.id));

        // const propertyId = Utils.isObject(property) ? property.id : property;
        const selectedProperty = _.compact(propertyIds.map(x => this.propertiesByField[x]));
        if (selectedProperty.length === 0) throw new Error(`property not found: ${JSON.stringify(property)}`);
        this.property = selectedProperty;
        this.setValue({});
        // FIXME: revisit this UX...
        if (!this.sort) {
            const sort = this.getPropertySort();
            if (sort) this.setUserSort(this.getPropertySort());
        }
    }

    setFiltering(filtering: IMetricsFiltering) {
        this.filtering = filtering;
    }

    getFiltering(): IMetricsFiltering {
        return _.cloneDeep(this.filtering);
    }

    serialize() {
        return {
            property: this.property.map(x => x.id),
            sort: this.getUserSort(),
            value: this.value,
            filtering: this.filtering,
        };
    }

    static deserialize(state: unknown): null | {
        property: string[];
        sort: null | IMetricsFunnelSort[];
        value: Record<string, MetricsFunnelNodeValue[]>;
        filtering: IMetricsFiltering;
    } {
        if (!isObject(state)) {
            return null;
        }

        const properties = (() => {
            const propertyIds = Array.isArray(state.property) ? state.property : [state.property];
            return propertyIds.flatMap(x => (typeof x === 'string' ? [x] : []));
        })();

        if (properties.length === 0 || !properties[0]) {
            return null;
        }

        const value = ((): Record<string, MetricsFunnelNodeValue[]> => {
            if (state.value === undefined) return {};

            if (isObject(state.value)) {
                return Object.fromEntries(
                    Object.entries(state.value).flatMap(([key, values]) => {
                        if (!Array.isArray(values)) return [];
                        const clean = values.flatMap(v => (typeof v === 'string' || typeof v === 'number' ? [v] : []));
                        return clean.length === 0 ? [] : [[key, clean]];
                    }),
                );
            }

            const rawValues = Array.isArray(state.value) ? state.value : [state.value];
            const values = _.compact(
                rawValues.flatMap(v => (typeof v === 'string' || typeof v === 'number' ? [v] : [])),
            );
            return values.length > 0 ? { [properties[0]]: values } : {};
        })();

        return {
            value,
            property: properties,
            sort: MetricsFunnelNode.deserializeSort(state.sort),
            filtering: isObject(state.filtering) ? state.filtering : {},
        };
    }

    static deserializeSort(sort: unknown): null | IMetricsFunnelSort[] {
        const result = _.compact(
            Array.from(
                (function* (): Generator<IMetricsFunnelSort> {
                    if (Array.isArray(sort)) {
                        for (const sortItem of sort) {
                            const parsed = MetricsFunnelNode.deserializeSortItem(sortItem);
                            if (!parsed) continue;
                            yield parsed;
                        }
                    }
                    if (Utils.isObject(sort)) {
                        const parsed = MetricsFunnelNode.deserializeSortItem(sort);
                        if (parsed) yield parsed;
                    }
                })(),
            ),
        );

        return result.length === 0 ? null : result;
    }

    static deserializeSortItem(sortItem: unknown): null | IMetricsFunnelSort {
        if (!Utils.isObject(sortItem)) return null;
        const field = typeof sortItem.field === 'string' ? sortItem.field : null;
        const order = this.deserializeSortItemOrder(sortItem.order);
        if (field === null) return null;
        if (order === null) return null;
        return { field, order };
    }

    static deserializeSortItemOrder(sortItemOrder: unknown): null | IMetricsFunnelSort['order'] {
        if (typeof sortItemOrder !== 'number') return null;
        if (sortItemOrder === 1) return 1;
        if (sortItemOrder === -1) return -1;
        return null;
    }
}

export class MetricsFunnelMetrics {
    public readonly availableByField: Record<string, IMetricsColumnDef>;
    public readonly available: (IMetricDefinition | IMetricsColumnDef)[];
    public selected: IMetricsColumnDef[] = [];
    public filtering: IMetricsFiltering = {};

    constructor(options: {
        available: (IMetricDefinition | IMetricsColumnDef)[];
        selected?: (string | IMetricDefinition | IMetricsColumnDef)[];
        filtering?: IMetricsFiltering;
    });
    constructor(
        options: {
            available?: (IMetricDefinition | IMetricsColumnDef)[];
            selected?: (string | IMetricDefinition | IMetricsColumnDef)[];
            filtering?: IMetricsFiltering;
        } = {},
    ) {
        const { available, selected } = options;
        if (!Array.isArray(available)) throw new Error('missing required: available');
        this.available = _.cloneDeep(available);
        this.availableByField = _.keyBy(this.available, x => x.field);
        this.select(selected ?? this.available);
        this.filtering = _.cloneDeep(options.filtering ?? {});
    }

    select(metrics: (string | IMetricDefinition | IMetricsColumnDef)[]) {
        this.selected = this.normalizeSelectedMetrics(this.available, metrics);
        const selectedMetricsByField = _.keyBy(this.selected, x => x.field);
        const filtering = _.pickBy(this.filtering, (_, key) => selectedMetricsByField[key]);
        this.filtering = filtering;
    }

    setFiltering(filtering?: IMetricsFiltering) {
        this.filtering = _.cloneDeep(filtering ?? {});
    }

    getFiltering() {
        return _.cloneDeep(this.filtering);
    }

    reorder(columnSortOrder: string[]) {
        this.selected = _.sortBy(this.selected, x => columnSortOrder.indexOf(x.field));
    }

    protected normalizeSelectedMetrics(
        availableMetrics: (IMetricDefinition | IMetricsColumnDef)[],
        metrics: ({ field: string } | string)[],
    ) {
        const selectedMetrics = _.compact(
            metrics.map(metric => this.availableByField[typeof metric === 'string' ? metric : metric.field]),
        );
        const selectedMetricsHeaderGroupOrder = selectedMetrics.reduce<Record<string, number>>((result, metric) => {
            if (!metric.headerGroup) {
                console.warn('[metrics] [selectedMetricsHeaderGroupOrder] missing headerGroup for: ', metric.field);
                return result;
            }
            const metricHeaderGroup = metric.headerGroup;
            result[metricHeaderGroup] = result[metricHeaderGroup] ?? Object.keys(result).length + 1;
            return result;
        }, {});
        const metricHeaderGroupOrder = availableMetrics.reduce<Record<string, number>>((result, metric) => {
            const metricHeaderGroup = metric.headerGroup;
            result[metricHeaderGroup] =
                result[metricHeaderGroup] ??
                selectedMetricsHeaderGroupOrder[metricHeaderGroup] ??
                availableMetrics.length + Object.keys(result).length + 1;
            return result;
        }, {});
        const selectedMetricOrder = selectedMetrics.reduce<Record<string, number>>((result, metric, index) => {
            return { ...result, [metric.field]: index };
        }, {});
        return _.sortBy(selectedMetrics, [
            x => metricHeaderGroupOrder[x.headerGroup],
            x => selectedMetricOrder[x.field],
        ]);
    }
}

export type IMetricsFunnelNodeService = AngularInjected<typeof MetricsFunnelNodeServiceFactory>;
export const MetricsFunnelNodeServiceFactory = () => [
    '$rootScope',
    'QueryServiceAPI',
    'QueryMetrics',
    function MetricsFunnelNodeService(
        $rootScope: IRootScopeService & { organizationId: string },
        QueryServiceAPI: IQueryServiceAPI,
        QueryMetrics: IQueryMetrics,
    ) {
        const toQuery = (props: {
            node: MetricsFunnelNode;
            query: IQuery;
            metricsFiltering?: IMetricsFiltering;
        }): IQuery => {
            const { node, query: rootQuery = {}, metricsFiltering } = props;
            const query = _.cloneDeep(rootQuery);
            delete query.sort;

            query.options = query.options ?? {};
            query.options.properties = node.property.map(x => x.id);
            query.options.associatedProperties ??= getAssociatedProperties(node);
            query.options.sort = (() => {
                let sort = node.getSort();
                if (!sort) return;
                sort = sort.reduce<IMetricsFunnelSort[]>((acc, x) => {
                    return x.field === 'property0'
                        ? acc.concat(node.property.map(property => ({ ...x, field: property.id })))
                        : acc.concat(x);
                }, []);
                console.log('[metrics][query] sort:', sort);
                return sort;
            })();

            if (node.properties.length > 1 && isObject(query.export)) {
                query.export.columnStyle = 'compact';
            }

            const timestamp = query.filters?.transactions?.timestamp;
            query.filters = {
                items: {},
                ...(timestamp ? { transactions: { timestamp } } : {}),
            };

            query.where = _.omitBy(metricsFiltering ?? {}, (_, key) => {
                return /^property\d+/.test(key);
            });

            let current = node.parent;
            if (!current) return query;

            while (current) {
                current.property.forEach(property => {
                    const values = current?.value ?? {};
                    const propertyId = property.id;
                    const propertyValues = values[propertyId] ?? [];

                    if (!Array.isArray(propertyValues)) return;
                    if (propertyValues.length < 1) return;

                    const [table, column] = propertyId.split('.');
                    if (!table || !column) return;

                    let tableFilter: IQueryTableFilter = query.filters?.[table] ?? {};
                    let $and: IQueryTableFilterOperatorAnd['$and'];
                    $and = Array.isArray(tableFilter.$and) ? tableFilter.$and : [];
                    $and = $and.filter(x => !isObject(x) || Object.keys(x)[0] !== column);
                    delete tableFilter.$and;

                    const ids = Object.values(propertyValues).flat();
                    const operator = ids.length > 1 ? '$or' : '$in';
                    $and.push({ [column]: { [operator]: ids } });
                    tableFilter = { ...tableFilter, $and };

                    query.filters = {
                        ...query.filters,
                        [table]: tableFilter,
                    };
                });

                current = current.parent;
            }

            return query;
        };

        const runExport = (props: {
            node: MetricsFunnelNode;
            query: IQuery;
            metricsByField: Record<string, IMetricDefinition & { _cellClass?: string }>;
            type?: IQueryType;
            tabName?: string | undefined;
        }): IPromise<void> => {
            const { node, metricsByField, query: rootQuery = {}, type = 'xlsx' } = props;

            // Remove "percentages" from the filter values - Backend does NOT support them for now
            const metricsFiltering = (() => {
                const metricsFiltering = node.getFiltering();
                return _.pickBy(metricsFiltering, (_, key) => {
                    const metric = metricsByField[key];
                    if (!metric) return false;
                    return !metric.cellFilter?.startsWith('percent');
                });
            })();

            let { tabName } = props;
            const query = toQuery({ node, query: { ...rootQuery, type }, metricsFiltering });
            tabName ??= node.property
                .map(p => p.id)
                .join(',')
                .replace(/\./g, '-');
            const filename = `42-metrics-${tabName}.${type}`;
            return QueryServiceAPI().then(api => {
                const queryId = 'frye__productClassification';
                return api.query[queryId](query).then(result => QueryServiceExport.downloadAs(filename)(result));
            });
        };

        const fetch = (node: MetricsFunnelNode, query: IQuery): IPromise<IMetricsFunnelRowData> => {
            return QueryMetrics.fetch()
                .then((metrics: IMetricDefinition[] | undefined) => {
                    query.options = query.options ?? {};
                    if (Array.isArray(metrics)) query.options.metrics = metrics.map(metric => metric.field);
                    return query;
                })
                .then(query => {
                    return QueryServiceAPI()
                        .then(api => api.query.metricsFunnel(query))
                        .then(data => postprocess(node, data));
                });
        };

        const postprocess = (node: MetricsFunnelNode, data: Record<string, unknown> | Record<string, unknown>[]) => {
            const hasMultipleProperties = node.property.length > 1;
            data = Array.isArray(data) ? data : [data];

            const result = data.reduce<{
                rows: Record<string, unknown>[];
                total: Record<string, unknown>[];
            }>(
                (result, row) => {
                    const collection = isTotalRow(row) ? 'total' : 'rows';
                    result[collection].push(row);
                    return result;
                },
                { rows: [], total: [] },
            );

            if (result.total.length === 0) {
                result.total.push({ property0: '$total' });
            }

            for (const row of result.rows) {
                node.property.forEach((property, index) => {
                    const field = `property${index}`;
                    row[field] = (() => {
                        const value = row[field];
                        if (property.type !== 'numeric') return value;
                        if (typeof value === 'number') return Number.isNaN(value) ? null : parseInt(String(value));
                        if (typeof value !== 'string') return value;
                        const r = parseInt(value);
                        return Number.isNaN(r) ? null : r;
                    })();
                });
            }

            if (result.rows.length === 0) {
                return {
                    rows: [],
                    total: [],
                };
            }

            if (hasMultipleProperties) {
                // Remove other row Totals
                result.total = result.total.slice(0, 1);
            }

            return result;
        };

        const getAssociatedProperties = (node: MetricsFunnelNode) => {
            const { columns } = MetricsFunnelNodeGridViewModelAssociatedColumns(node, $rootScope.organizationId);
            return columns.length > 0 ? _.uniq(columns.map(x => x.field.replace(/^item_/, 'items.'))) : [];
        };

        return {
            toQuery,
            runExport,
            fetch,
            getAssociatedProperties,
        };
    },
];
