import _ from 'lodash'
import hexToRgba from 'hex-to-rgba'
import { ECHARTS_THEME, ECHARTS_THEME_LEGEND } from './chart-echarts.config'
import { buildEchartTooltip } from './chart-echarts.helper'

ECHARTS_DEFAULT_PIE_OPTIONS =
    color: ECHARTS_THEME.colors,
    grid:
        containLabel: true
        top: 0
        bottom: 0
        left: '0%'
        right: '0%'
    textStyle:
        fontFamily: ECHARTS_THEME.fontFamily
    tooltip:
        contain: true
        trigger: 'item',
        backgroundColor: "rgba(0, 0, 0, 0.8)"
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
    legend: {
        type: 'scroll'
        orient: 'horizontal'
        data:[]
        bottom: 0
        left: 0
        right: 0
        ..._.cloneDeep(ECHARTS_THEME_LEGEND)
    }
    series: [
        {
            name: ''
            type: 'pie'
            radius: ['30%', '50%']
            center: ['50%', '45%']
            data: []
            cursor: 'default'
            selectedOffset: 5
            labelLine:
                smooth: false
            itemStyle:
                borderColor: '#fff',
                borderRadius: 5,
                borderWidth: 4
            emphasis:
                normal:
                    borderWidth: 0
                    borderColor: '#fff'
        }
    ]

export EchartsPieChartModelFactory = () -> [
    '$filter',
    ###* @param {import('angular').IFilterService} $filter ###
    ($filter) ->
        return class EchartsPieChartModel
            constructor: (metrics, data = [], options = {}) -> @chartOptions = do ->
                options =
                    showPercentage            : options.showPercentage ? true
                    multiline                 : options.multiline ? true
                    maxNumberOfSlices         : options.maxNumberOfSlices
                    groupSlicesThresholdValue : options.groupSlicesThresholdValue
                    expandedTheme             : options.expandedTheme

                chartOptions = _.cloneDeep(ECHARTS_DEFAULT_PIE_OPTIONS)
                chartOptions.customOptions = options

                buildNegativeDataObject = (dataObj, index) ->
                    color = ECHARTS_THEME.colors[index % ECHARTS_THEME.colors.length]
                    dataObj.label ?= {}
                    dataObj.label.color = color
                    dataObj.labelLine ?= {}
                    dataObj.labelLine.lineStyle ?= {}
                    dataObj.labelLine.lineStyle.color = color
                    dataObj.value = dataObj.value * -1
                    dataObj.isNegative = true
                    dataObj.itemStyle =
                        color: hexToRgba(color, 0.2)
                        borderWidth: 1
                        borderColor: color
                        borderType: 'dashed'
                    dataObj.emphasis =
                        color: hexToRgba(color, 0.2)
                        shadowBlur: 0
                        shadowOffsetX: 0
                        shadowOffsetY: 0
                        itemStyle:
                            borderWidth: 1
                            borderColor: color
                            borderType: 'dashed'
                    return dataObj

                chartOptions.tooltip.formatter = (params) ->
                    return '' if options.expandedTheme and not params.data.groupedSeries
                    params = params[0] if Array.isArray(params)
                    return buildEchartTooltip($filter, params)

                chartOptions.series[0].data = data.flatMap (x, index) ->
                    value = x[metrics[0].field]
                    return [] if typeof value isnt 'number'
                    return [] if value is 0
                    # NOTE: uncomment to test negatives...
                    # value = value * -1 if index is 2
                    result = {metric: metrics[0], name: x['property0'], value}
                    result = buildNegativeDataObject(result, index) if result.value < 0
                    return [result]

                chartOptions.series[0].data.sort (a,b) ->
                    return 1 if a.isNegative and not b.isNegative
                    return -1 if not a.isNegative and b.isNegative
                    valueA = if a.isNegative then a.value * -1 else a.value
                    valueB = if b.isNegative then b.value * -1 else b.value
                    return valueB - valueA

                if options.expandedTheme
                    chartOptions.series[0].label ?= {}
                    chartOptions.series[0].label.show = false
                    chartOptions.series[0].label.position = 'center'
                    chartOptions.series[0].labelLine.show = false
                    chartOptions.series[0].radius = ['60%', '80%']
                    chartOptions.series[0].center = ['50%', '43%']
                    chartOptions.series[0].emphasis ?= {}
                    chartOptions.series[0].emphasis.scale = 10
                    chartOptions.series[0].emphasis.label ?= {}
                    chartOptions.series[0].emphasis.label.show = true
                    chartOptions.series[0].emphasis.label.fontSize = '18'
                    chartOptions.series[0].emphasis.label.fontWeight = 'bold'
                    chartOptions.series[0].emphasis.label.width = 100
                    chartOptions.series[0].emphasis.label.overflow = 'break'

                chartOptions.label ?= {}
                chartOptions.label.color = 'inherit'
                chartOptions.label.formatter = do ->
                    return if not options.showPercentage
                    return (params) ->
                        result = params.name
                        percent = $filter('percent')(params.percent / 100)
                        return "#{result} (#{percent})" if not options.multiline
                        return "#{result}\n#{percent}\n"

                return chartOptions
]
