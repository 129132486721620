import moment from 'moment';
import type { IConfigObj, IQuery } from './types';
import type { QueryResultFrom } from './api/api-query-service';
import { QueryServiceAPI } from './api';
import { ConfigAPI } from './config-api';
import { isObject } from './utils';

export interface DatabaseResolvedTimeSpan {
    start: string;
    end: string;
}

// REVIEW: what does the scheduler do here????
export const DatabaseTimeSpanService = {
    get(
        timespan: QueryResultFrom<'getFullTimeSpan'>[number] | undefined,
        maxTimestamp: string | undefined,
    ): DatabaseResolvedTimeSpan {
        const { min_timestamp, max_timestamp } = timespan ?? {};
        if (!min_timestamp) throw new Error('Could not get min timestamp from query service.');
        if (!max_timestamp) throw new Error('Could not get max timestamp from query service.');
        const min = moment(min_timestamp);
        const max = maxTimestamp === 'yesterday' ? moment().subtract(1, 'days') : moment(max_timestamp);
        const start = min.format('YYYY-MM-DD');
        const end = max.format('YYYY-MM-DD');
        return { start, end };
    },
    async fetch(query: IQuery = {}) {
        const { maxTimestamp, timespanOverride } = await DatabaseTimeSpanConfigService.fetch();
        if (timespanOverride) return this.get(timespanOverride, maxTimestamp);
        const api = await QueryServiceAPI.get();
        const timespan = await api.organizations.doQuery({ queryId: 'getFullTimeSpan', query }).then(x => x[0]);
        return this.get(timespan, maxTimestamp);
    },
};

interface DatabaseTimeSpanConfig {
    maxTimestamp: string | undefined;
    timespanOverride: NonNullable<IConfigObj['timespanOverride']> | undefined;
}

export const DatabaseTimeSpanConfigService = {
    getTimespanOverride(orgConfig: IConfigObj): DatabaseTimeSpanConfig['timespanOverride'] {
        const value: unknown = orgConfig.timespanOverride;
        if (!isObject(value)) return;
        const { min_timestamp, max_timestamp } = value;
        if (typeof min_timestamp !== 'string')
            throw new Error(`Invalid/missing 'config.timespan.min_timestamp' value (${String(min_timestamp)}).`);
        if (typeof max_timestamp !== 'string')
            throw new Error(`Invalid/missing 'config.timespan.max_timestamp' value (${String(max_timestamp)}).`);
        return { min_timestamp, max_timestamp };
    },
    getMaxTimestamp(orgConfig: IConfigObj): DatabaseTimeSpanConfig['maxTimestamp'] {
        // throw an error/warning on usage
        const value: unknown = orgConfig.defaults?.maxTimestamp;
        return typeof value === 'string' ? value.toLowerCase() : undefined;
    },
    get(orgConfig: IConfigObj): DatabaseTimeSpanConfig {
        return {
            maxTimestamp: this.getMaxTimestamp(orgConfig),
            timespanOverride: this.getTimespanOverride(orgConfig),
        };
    },
    async fetch() {
        const config = await ConfigAPI.get().then(api => api.organization.get());
        return this.get(config);
    },
};
