import { retry } from '@42technologies/retry';
import type { DatabaseDescriptors } from './api/api-query-service';
import { QueryServiceAPI } from './api';
import { CachedService } from './model/model-service-cached';
import { isObject, seconds } from '../lib/utils';

export type { DatabaseDescriptors };
export type IDatabaseDescriptorsService = typeof DatabaseDescriptorsService;
export const DatabaseDescriptorsService = CachedService(async () => {
    const api = await QueryServiceAPI.get();
    return await retry(
        async () => {
            const result = await api.organizations.getDescriptors({});
            // NOTE: The checks here are a workaround for null / blank responses coming from the API
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (result === null) throw new Error('invalid db descriptors; is null');
            if (!isObject(result)) throw new Error(`invalid db descriptors; not an object (type=${typeof result})`);
            if (Object.keys(result).length === 0) throw new Error('invalid db descriptors; empty object');
            return result;
        },
        { delay: { max: seconds(3) } },
    );
});
