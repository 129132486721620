interface SortableControllerModel {
    state: null | { order: number; property: string };
    update: (state: { property: string; order: number }) => void;
    toggle: (property: string) => void;
}
interface SortableContainerModel {
    model?: Record<string, number>;
    refreshSort?: () => void;
}
interface SortableContainerScope extends angular.IScope {
    registerSortableProperty: (property: string, element: angular.IRootElementService) => void;
    _sortable: SortableControllerModel;
    sortable?: SortableContainerModel;
}

const module = angular.module('42.directives.sortable-property', []);
export default module;

module.directive('sortableContainer', [
    function SortableContainerDirective(): angular.IDirective<SortableContainerScope> {
        return {
            restrict: 'A',
            controller: function (this: { _sortable: SortableControllerModel }, $scope) {
                this._sortable = {
                    state: null,
                    update: (state: { property: string; order: number }) => {
                        const prev = this._sortable.state;
                        if (prev?.property === state.property && prev?.order === state.order) return;
                        this._sortable.state = { ...state };
                    },
                    toggle: (property: string) => {
                        const propertyHasChanged = this._sortable.state?.property !== property;
                        let order: number;
                        order = this._sortable.state?.order === -1 ? 1 : -1;
                        order = propertyHasChanged ? -1 : order;
                        this._sortable.update({ property, order });
                    },
                };
                $scope._sortable = this._sortable;

                const updateModelFromView = (state: null | undefined | SortableControllerModel['state']) => {
                    if (!$scope.sortable) return;
                    if (!state) return;
                    const { property, order } = state;
                    $scope.sortable.model = { [property]: order };
                    if ($scope.sortable.refreshSort) $scope.sortable.refreshSort();
                };

                const updateViewFromModel = (model: null | SortableContainerModel['model']) => {
                    if (!model) return;
                    const property = Object.keys(model)[0];
                    if (typeof property !== 'string') return;
                    const order = model[property];
                    this._sortable.update({ property, order: order === 1 ? 1 : -1 });
                };

                $scope.$watch('sortable.model', updateViewFromModel);
                $scope.$watch('_sortable.state', updateModelFromView);
            },
        };
    },
]);

module.directive('sortableProperty', [
    function SortablePropertyDirective(): angular.IDirective<SortableContainerScope> {
        return {
            restrict: 'A',
            require: '^sortableContainer',
            link: ($scope, $element, attributes, sortableCtrl) => {
                const SORT_ASCENDING = 'ascending';
                const SORT_DESCENDING = 'descending';

                const view: { property: null | string } = { property: null };

                const onClick = () => {
                    if (!view.property) return;
                    sortableCtrl?._sortable?.toggle(view.property);
                };

                const updateViewFromModel = (state: undefined | SortableControllerModel['state']) => {
                    const selected = state?.property === view.property;
                    let order: string;
                    order = state?.order === 1 ? SORT_ASCENDING : SORT_DESCENDING;
                    order = selected ? order : '';
                    $element[0]?.setAttribute('sort-order', order);
                };

                const updateProperty = (property: undefined | null | string) => {
                    view.property = property ?? null;
                    $element[0]?.classList.add('sortable');
                    $element[0]?.removeEventListener('click', onClick);
                    $element[0]?.addEventListener('click', onClick);
                };

                attributes.$observe('sortableProperty', updateProperty);
                $scope.$watch('_sortable.state', updateViewFromModel);
                $scope.$on('$destroy', () => {
                    $element[0]?.removeEventListener('click', onClick);
                    $element[0]?.removeAttribute('sort-order');
                });
            },
        };
    },
]);
