import _ from 'lodash'

export TablePropertyAvailableTables = {
    employees: "employees"
    customers: "customers"
    accounts: "accounts"
    warehouses: "warehouses"
    items: "items"
    transaction_items: "transaction_items"
    transactions: "transactions"
    demand_items: "demand_items"
    demand: "demand"
    stores: "stores"
    acquisitions: "acquisitions"
    gift_cards: "gift_cards"
    item_timeseries: "item_timeseries"
    order_items: "order_items"
    campaigns: "campaigns"
}

export TablePropertyAvailableTablesKeys = Object.values(TablePropertyAvailableTables)

export default (module) ->

    module.service 'TablePropertyValues', (EmployeePropertyValues, CustomersPropertyValues, ItemPropertyValues, TransactionItemPropertyValues,
        TransactionPropertyValues, StorePropertyValues, AcquisitionPropertyValues, GiftCardsPropertyValues, ItemTimeseriesPropertyValues,
        AccountPropertyValues, WarehousePropertyValues, DemandItemPropertyValues, DemandPropertyValues, OrderItemPropertyValues, CampaignPropertyValues) ->
            queries =
                [TablePropertyAvailableTables.employees]:         EmployeePropertyValues
                [TablePropertyAvailableTables.customers]:         CustomersPropertyValues
                [TablePropertyAvailableTables.accounts]:          AccountPropertyValues
                [TablePropertyAvailableTables.warehouses]:        WarehousePropertyValues
                [TablePropertyAvailableTables.items]:             ItemPropertyValues
                [TablePropertyAvailableTables.transaction_items]: TransactionItemPropertyValues
                [TablePropertyAvailableTables.transactions]:      TransactionPropertyValues
                [TablePropertyAvailableTables.demand_items]:      DemandItemPropertyValues
                [TablePropertyAvailableTables.demand]:            DemandPropertyValues
                [TablePropertyAvailableTables.stores]:            StorePropertyValues
                [TablePropertyAvailableTables.acquisitions]:      AcquisitionPropertyValues
                [TablePropertyAvailableTables.gift_cards]:        GiftCardsPropertyValues
                [TablePropertyAvailableTables.item_timeseries]:   ItemTimeseriesPropertyValues
                [TablePropertyAvailableTables.order_items]:       OrderItemPropertyValues,
                [TablePropertyAvailableTables.campaigns]:         CampaignPropertyValues

            fetch: (table, property, filter) ->
                console.warn('[smart-groups-api] fetch:', {table, property, filter})
                query = queries[table]
                query.fetch(property, filter)

            availableTables: Object.keys(queries)


    module.service 'ItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            sort = do ->
                return value: -1 if property is 'season'
                return count: -1
            query =
                options:{property}
                filters:{items:filter}
                sort: sort
            itemFilters = filter?.$and
            if itemFilters
                query.filters.items.$and = _.filter itemFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterItems(query)


    module.service 'TransactionItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{transaction_items:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterTransactionItems(query)


    module.service 'TransactionPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{transactions:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterTransactions(query)


    module.service 'DemandItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{demand_items:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterDemandItems(query)


    module.service 'DemandPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{demand:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterDemand(query)


    module.service 'OrderItemPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{orders:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterOrderItems(query)


    module.service 'CampaignPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{campaigns:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterCampaigns(query)


    module.service 'AcquisitionPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{acquisitions:filter}
                sort: count: -1

            return QueryServiceAPI().then (api) -> api.query.filterAcquisitions(query)


    module.service 'AccountPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{accounts:filter}
                sort: value: 1
            accountFilters = filter?.$and
            if accountFilters
                query.filters.accounts.$and = _.filter accountFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterAccounts(query)


    module.service 'WarehousePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{warehouses:filter}
                sort: value: 1
            warehouseFilters = filter?.$and
            if warehouseFilters
                query.filters.warehouses.$and = _.filter warehouseFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterWarehouses(query)


    module.service 'ItemTimeseriesPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{item_timeseries:filter}
                sort: value: -1
            QueryServiceAPI().then (api) -> api.query.filterItemTimeseries(query)


    module.service 'EmployeePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{employees:filter}
                sort: count: -1
            QueryServiceAPI().then (api) -> api.query.filterEmployees(query)


    module.service 'CustomersPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{customers:filter}
                sort: count: -1
            customerFilters = filter?.$and
            if customerFilters
                query.filters.customers.$and = _.filter customerFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterCustomers(query)


    module.service 'GiftCardsPropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            if filter and filter.$and
                filter.$and = _.filter filter.$and, (propertyFilter) -> Object.keys(propertyFilter)[0] isnt property

            query =
                options:{property}
                filters:{gift_cards:filter}
                sort: value: 1
            QueryServiceAPI().then (api) -> api.query.filterGiftCards(query)


    module.service 'StorePropertyValues', (QueryServiceAPI) ->
        fetch: (property, filter) ->
            query =
                options:{property}
                filters:{stores:filter}
                sort: {count:-1}

            storeFilters = filter?.$and
            if storeFilters
                query.filters.stores.$and = _.filter storeFilters, (filter) ->
                    return Object.keys(filter)[0] isnt property
            QueryServiceAPI().then (api) -> api.query.filterStores(query)
