import _ from 'lodash';
import { ICardHeaderModel } from './ads-card-header.directive';
import { IAdView } from '../ads.controller';
import { IAdStatsItem, IMetricDefinitionExtended } from '../ads.helper';
import { IMetricDefinition, IQuery } from '../../../lib/types';
import { AdsPlatformConfig, IAdsService } from '../ads.service';
import { IPropertyDefinition } from '../../../lib/config-hierarchy';
import { AngularInjected } from '../../../lib/angular';

interface AdsStatsDirectiveScope extends angular.IScope {
    model: IAdsStats;
    stats: CardOverallStateViewModel[];
}

export interface IAdsStatsOptions {
    title: string;
    platform: string;
    query: IQuery;
    availableMetrics: IMetricDefinition[];
    metrics: IMetricDefinitionExtended[];
    config: AdsPlatformConfig;
    property: IPropertyDefinition;
    data?: IAdStatsItem[] | null;
}

export const AdsStatsFactory = () => [
    'AdsService',
    function AdsStatsFn(AdsService: IAdsService) {
        return class AdsStats {
            isLoading: boolean | null = null;
            isError = false;
            data: IAdStatsItem[] | null = null;
            title: string;
            metrics: IMetricDefinitionExtended[];

            constructor(public options: IAdsStatsOptions) {
                this.metrics = options.metrics;
                this.title = options.title;
                this.isLoading = true;
                this.isError = false;
                if (options.data) {
                    this.data = options.data;
                    this.isLoading = false;
                } else {
                    this.isLoading = true;
                    void this.init(options);
                }
            }

            async init({ availableMetrics, metrics, config, property, query }: IAdsStatsOptions) {
                void AdsService.fetchStats(availableMetrics, metrics, config, property, query)
                    .then(platformStats => {
                        this.data = platformStats;
                        this.isLoading = false;
                        this.isError = false;
                    })
                    .catch(err => {
                        console.warn(err);
                        this.isLoading = false;
                        this.isError = true;
                    });
            }
        };
    },
];
export type IAdsStatsFactory = AngularInjected<typeof AdsStatsFactory>;
export type IAdsStats = InstanceType<IAdsStatsFactory>;

class CardOverallStateViewModel {
    readonly actual: {
        label: null | string;
        value: null | string | number;
    };
    readonly difference: null | {
        value: string | number;
        inverted: boolean;
        direction: null | 'positive' | 'negative';
    };
    readonly comparison: null | {
        label: null | string;
        value: string | number;
    };
    constructor(
        readonly data?: IAdStatsItem, // readonly chart: undefined | null | ISalesChart, // readonly data: undefined | null | Record<string, unknown>,
    ) {
        if (!data) {
            const label = null;
            this.actual = { label, value: null };
            this.difference = null;
            this.comparison = null;
        } else {
            this.actual = {
                label: data.title,
                value: data.value ?? null,
            };

            this.comparison = (() => {
                const title = data.title;
                const headerGroup = data.chart?.headerGroup ?? null;
                const label = headerGroup && title !== headerGroup ? headerGroup : null;
                const value = data.growth_value;
                return _.isNil(value) ? null : { value, label };
            })();

            this.difference = (() => {
                if (_.isNil(this.comparison)) return null;
                const growth = data.growth;
                if (_.isNil(growth)) return null;

                // prettier-ignore
                const direction =
                    growth > 0 ? 'positive' :
                    growth < 0 ? 'negative' :
                    null;

                const inverted = data.inverted ?? false;

                return { value: growth, direction, inverted };
            })();
        }
    }
}

export const AdsStatsDirectiveFactory = () => [
    '$filter',
    '$rootScope',
    function AdsStatsDirective(
        $filter: angular.IFilterService,
        $rootScope: angular.IRootScopeService,
    ): angular.IDirective<AdsStatsDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: `
                <div class="stats marketing-stats" ng-class="{error: model.isError && model.isLoading !== null}">
                    <div class="loadable" ng-class="{loading: model.isLoading}"></div>
                    <div class="marketing-platform-title">{{ model.title }}</div>
                    <div class="message-container">
                        <div class="message">
                            <i class="message-icon icon-attention"></i>
                            <span class="message-summary">Data could not be loaded</span>
                        </div>
                    </div>
                    <ul class="marketing-stats-list">
                        <li ng-repeat="view in stats">
                            <div class="stats-content">
                                <div class="actual-stat">
                                    <div class="title">{{ view.actual.label || '' }}</div>
                                    <div class="value" ng-class='{blank:view.actual.value === null}'>
                                    {{ view.actual.value === null ? '–' : view.actual.value }}
                                    </div>
                                </div>
                                <div class="growth">
                                    <div class="growth-stat"
                                        ng-hide="!view.comparison"
                                        ng-class="{
                                            'percent-invert-color': view.difference.inverted,
                                            'percent-positive': view.difference.direction === 'positive',
                                            'percent-negative': view.difference.direction === 'negative'
                                        }">
                                        <i ng-class="{
                                            'icon-up-open': view.difference.direction === 'positive',
                                            'icon-down-open': view.difference.direction === 'negative'
                                        }"></i>
                                        <div class="growth-stat-description">
                                            <span ng-show="view.difference">{{ view.difference.value | percent:true:false }}</span>
                                            <span>(vs. {{ view.comparison.label || '' }} {{ view.comparison.value }})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            `,
            link: function AdsStatsDirective(scope, $element: angular.IRootElementService) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/non-nullable-type-assertion-style
                const element = $element[0] as HTMLElement;
                scope.stats = (() => {
                    const maxCount = scope.model.metrics.length === 0 ? 12 : scope.model.metrics.length;
                    return _.range(Math.max(1, maxCount)).map(() => new CardOverallStateViewModel());
                })();

                const refresh = () => {
                    if (!scope.model.data) return;
                    const results = scope.model.data.map(item => new CardOverallStateViewModel(item));
                    scope.stats = results;
                };

                const resizeObserver = new ResizeObserver(entries => {
                    for (const el of entries) {
                        const width = el.target.getBoundingClientRect().width;
                        const sizeClass = width >= 920 ? 'large' : width >= 600 ? 'medium' : 'small';
                        const sizeChanged = !el.target.classList.contains(sizeClass);
                        if (!sizeChanged) return;
                        el.target.classList.remove('large', 'medium', 'small');
                        el.target.classList.add(sizeClass);
                    }
                });
                resizeObserver.observe(element);

                scope.$watch('model.data', refresh);

                // REVIEW:
                // This shouldn't be necessary, since we don't query anything in this directive
                // The model in theory changes on query refresh, in the parent controller.
                const unwatchQueryRefresh = $rootScope.$on('query.refresh', refresh);

                scope.$on('$destroy', () => {
                    unwatchQueryRefresh();
                    resizeObserver.disconnect();
                });
            },
        };
    },
];

export const AdsSectionStatsDirectiveFactory = () => [
    function AdsSectionStatsDirective(): angular.IDirective<
        angular.IScope & { views: IAdView[]; model: IAdView[]; header: ICardHeaderModel }
    > {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
            },
            template: `
                <div class="marketing-page-stats-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="marketing-page-stats-block" ng-repeat="view in model">
                        <ads-stats model="view.stats"></ads-stats>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionStatsDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => typeof view.stats !== 'undefined');
                    $scope.header = {
                        title: `Stats ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', () => Boolean($scope.views) && init());
            },
        };
    },
];
