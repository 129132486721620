import _ from 'lodash'
import { FontWidthCalculator } from '../../lib/dom/font-width-calculator'

###*
@typedef {import('../../lib/types').IMetricDefinition} IMetricDefinition
@typedef {import('../../lib/config-hierarchy').IPropertyDefinition} IPropertyDefinition
###

export ITEM_EXPORT_OPTIONS =
    itemColumnsPerPage: 10
    maxPages: 100

fontWidthCalculator = new FontWidthCalculator({font:'6px Helvetica'})


splitColumnDefsByPage = (columnDefs, options) ->
    pinnedColumnDefs = columnDefs.filter (x) -> x.pinned
    itemColumnDefs = columnDefs.filter (x) -> not x.pinned

    # We split up our item columnDefs
    itemColumnDefsSplitByPage = do ->
        result = []
        index  = 0
        while index < itemColumnDefs.length
            pageIndex = Math.floor(index / options.itemColumnsPerPage)
            result[pageIndex] ?= []
            result[pageIndex]?.push itemColumnDefs?[index++]
        return result

    # We add the pinned columns to each page
    result = itemColumnDefsSplitByPage.map (page) -> pinnedColumnDefs.concat(page)
    return result

###*
@param {IMetricDefinition[]} metrics
@param {unknown[]} data
@param {IPropertyDefinition[]} itemsExtraInfo
@param {IPropertyDefinition} itemsGroupBy
@returns {{
    headerGroup: string;
    headerName: string;
    field: string;
    pinned?: boolean;
}[]
}}
###
generateRowDefs = (data, metrics, itemsExtraInfo, itemsGroupBy) ->
    selectedMetrics = do ->
        return [] if data.length is 0
        return metrics
    return [
        {
            headerGroup: ''  # image
            headerName: ''
            field: 'pinned1'
            pinned: true
        }
        {
            headerGroup: itemsGroupBy.label
            headerName: ''
            field: 'pinned2'
            pinned: true
        }
        ...(itemsExtraInfo or []).map((property, index) -> {
            headerGroup: ''
            headerName: property.label or property.column
            field: 'pinned3' + index
            pinned: true
        })
        ...(selectedMetrics.map((metric) -> {
            headerGroup: metric.headerGroup
            headerName:  metric.headerName
            field:       metric.field
            pinned:      false
        }))
    ]

###*
@param { {
    metrics        : IMetricDefinition[];
    data           : unknown[];
    itemsExtraInfo : IPropertyDefinition[];
    itemsGroupBy   : IPropertyDefinition;
    columnDefs     : {pinned?: string | boolean}[];
} } options
@returns {number}
###
export getNumberOfPagesEstimate = ({metrics, data, itemsExtraInfo, itemsGroupBy, columnDefs}) ->
    rowDefs = generateRowDefs(data, metrics, itemsExtraInfo, itemsGroupBy)

    # in the export, normally it has two pinned columns
    # so here the first column is repliocated to the left
    columnDefinitions = do ->
        pinnedColumnDefs = columnDefs.filter (x) -> x.pinned
        itemColumnDefs = columnDefs.filter (x) -> not x.pinned
        return [pinnedColumnDefs[0]].concat(pinnedColumnDefs, itemColumnDefs)
    # these variables are from topItems in the query service.
    # this is an estimate, but it should be pretty close. the inaccuracy is in the fact that we don't actually take wrapping lines into account
    dpi = 72
    margin = Math.floor 0.6 * 0.393701 * dpi
    width  = (11  * dpi) - 2 * margin
    height = (8.5 * dpi) - 2 * margin

    headerHeight = 40
    footerHeight = 30
    tableHeaderHeight = 20
    tableHeaderTopMargin = 10
    mainHeight = height - headerHeight - footerHeight - (tableHeaderHeight + tableHeaderTopMargin)
    imageHeight = 50

    # font height - measured in browser - font-size: 6px - ocuppies max 7px height
    fontHeight = 7
    cellPadding = 4

    valueCellWidth = 60
    cellWidth = 60
    metricNameWidth = width - cellWidth * ITEM_EXPORT_OPTIONS.itemColumnsPerPage - valueCellWidth

    metricLabelWidths = rowDefs.reduce ((result, metric) ->
        result[metric.field] = fontWidthCalculator.getPixelWidth("#{metric.headerGroup} #{metric.headerName}")
        return result
    ), {}

    tableHeight = do ->
        associatedColumnsHeight = itemsExtraInfo.length * 15
        # (image height) + sum[(text width) / (width of the cell-metric div - padding) * (font height) + padding]
        metricCellHeights = rowDefs.map (row) ->
            return Math.ceil(metricLabelWidths[row.field] / (metricNameWidth - cellPadding)) * fontHeight + cellPadding
        return imageHeight + associatedColumnsHeight + _.sumBy(metricCellHeights)

    rowsPerPageEstimate = Math.floor(mainHeight / tableHeight)
    columnDefsSplitByPage = splitColumnDefsByPage(columnDefinitions, { itemColumnsPerPage: ITEM_EXPORT_OPTIONS.itemColumnsPerPage })

    pinnedRowDefs = rowDefs.filter (x) -> x.pinned
    metricRowDefs = rowDefs.filter (x) -> not x.pinned

    # This is used when we split a row into multiple pages if they have too many metrics
    heightAvailablePerPage = mainHeight - imageHeight - 14
    rowDefChunks = do ->
        rowDefChunk = pinnedRowDefs.slice(0)
        rowDefChunks = [rowDefChunk]
        currentHeightOfRows = 0
        metricRowDefs.forEach (rowDef) ->
            if (heightAvailablePerPage - currentHeightOfRows) <= 0
                rowDefChunk = pinnedRowDefs.slice(0)
                rowDefChunks.push(rowDefChunk)
                currentHeightOfRows = 0
            rowHeight = Math.ceil(metricLabelWidths[rowDef.field] / (metricNameWidth - 4)) * 8 + 6
            currentHeightOfRows += rowHeight
            rowDefChunk.push(rowDef)
        return rowDefChunks

    rowsPerPage = Math.max(1, rowsPerPageEstimate)

    result = []
    data.forEach (d, index) ->
        pageIndex = Math.floor(index / rowsPerPage)
        result[pageIndex] ?=
            rowBreak: pageIndex
            data: []
        result[pageIndex].data.push(d)

    rows = do ->
        rows = result.flatMap () -> columnDefsSplitByPage.flatMap () ->
            rowDefChunks.map (_, index) -> {index}
        splitsPerPage = Math.floor(rowsPerPage / data.length)
        return _.chunk(rows, Math.max(1, splitsPerPage))

    return rows.length
