import { z } from 'zod';

export const MetricsPageViewMetaSchema = z.object({
    createdAt: z.number(),
    organizationId: z.string(),
    exportedByUserId: z.string(),
    type: z.literal('tab-metrics'),
    version: z.number(),
});

const MetricFilterValueSchema = z.number().or(z.string());
const MetricFilterOperatorDataSchema = z.record(MetricFilterValueSchema);

export const MetricsPageViewDataSchema = z.object({
    name: z.string(),
    funnelState: z.object({
        properties: z.array(
            z.object({
                property: z.array(z.string()).or(z.string()),
                sort: z
                    .array(
                        z
                            .object({
                                field: z.string(),
                                order: z.number(),
                            })
                            .nullish(),
                    )
                    .nullish(),
                value: z
                    .string()
                    .nullish()
                    .or(z.record(z.array(MetricFilterValueSchema)).nullish()),
                filtering: z
                    .record(z.record(MetricFilterValueSchema.or(MetricFilterOperatorDataSchema)))
                    .nullish()
                    .optional(),
            }),
        ),
        metrics: z.array(z.string()),
        views: z
            .object({
                columns: z.record(z.number()).optional(),
            })
            .optional(),
    }),
});

export const MetricsPageExportViewConfigSchema = z.object({
    meta: MetricsPageViewMetaSchema,
    data: MetricsPageViewDataSchema,
});

export type IMetricsPageExportTabViewConfig = z.infer<typeof MetricsPageExportViewConfigSchema>;
