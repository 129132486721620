import { Parse } from '../../lib/parsers/values';
import { IStorageAPI, StorageAPI } from '../../lib/storage-user-config';

// NOTE: we're persisting the data using NULL, to unset the user selection,
// but the calendar module expects to get undefined when there's no user selection.
// That's why we're converting the null to undefined after reading.
class StorageDatepickerCalendarIdFactory {
    static USER_STORAGE_KEY = 'view.calendar.selectedCalendarId' as const;

    constructor(
        protected StorageAPI: IStorageAPI<null | string, typeof StorageDatepickerCalendarIdFactory['USER_STORAGE_KEY']>,
    ) {}

    get(): Promise<string | undefined> {
        return Promise.resolve()
            .then(() => this.slice())
            .then(api => api.get())
            .then(payload => {
                const calendarId = this.parse(payload);
                return typeof calendarId === 'string' ? calendarId : undefined;
            });
    }

    set(calendarId: string): Promise<void>;
    set(calendarId: unknown): Promise<void> {
        try {
            const payload = this.parse(calendarId);
            return this.slice().then(api => api.put(payload ?? null));
        } catch (error) {
            return Promise.reject(error);
        }
    }

    protected slice() {
        return this.StorageAPI(StorageDatepickerCalendarIdFactory.USER_STORAGE_KEY);
    }

    protected parse(value: unknown): string | undefined {
        return Parse.String(value)?.toLowerCase() ?? undefined;
    }
}

export const StorageDatepickerCalendarId = new StorageDatepickerCalendarIdFactory(StorageAPI);
