export const GROUP_BY_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" version="1.1"
        style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;"
        viewBox="0 0 203 278.75" x="0px" y="0px" fill-rule="evenodd" clip-rule="evenodd">
        <g>
            <path class="fil0"
            d="M101 77c19,0 35,16 35,35 0,19 -16,34 -35,34 -19,0 -34,-15 -34,-34 0,-19 15,-35 34,-35zm0 11c-13,0 -23,11 -23,24 0,13 10,23 23,23 13,0 24,-10 24,-23 0,-13 -11,-24 -24,-24z" />
            <path class="fil0"
            d="M101 0c19,0 35,15 35,34 0,19 -16,35 -35,35 -19,0 -34,-16 -34,-35 0,-19 15,-34 34,-34zm0 11c-13,0 -23,10 -23,23 0,13 10,24 23,24 13,0 24,-11 24,-24 0,-13 -11,-23 -24,-23z" />
            <path class="fil0"
            d="M5 56c9,-17 30,-22 47,-13 16,10 22,31 12,47 -9,17 -30,22 -47,13 -16,-10 -22,-31 -12,-47zm9 5c-6,12 -3,26 9,32 11,7 25,3 32,-8 6,-11 2,-26 -9,-32 -11,-7 -25,-3 -32,8z" />
            <path class="fil0"
            d="M5 168c-10,-17 -4,-38 12,-47 17,-10 38,-4 47,12 10,17 4,38 -12,47 -17,10 -38,4 -47,-12zm9 -6c7,11 21,15 32,9 11,-7 15,-21 9,-32 -7,-12 -21,-15 -32,-9 -12,7 -15,21 -9,32z" />
            <path class="fil0"
            d="M101 223c-19,0 -34,-15 -34,-34 0,-19 15,-34 34,-34 19,0 35,15 35,34 0,19 -16,34 -35,34zm0 -10c13,0 24,-11 24,-24 0,-13 -11,-23 -24,-23 -13,0 -23,10 -23,23 0,13 10,24 23,24z" />
            <path class="fil0"
            d="M198 168c-9,16 -30,22 -47,12 -16,-9 -22,-30 -12,-47 9,-16 30,-22 47,-12 16,9 22,30 12,47zm-9 -6c6,-11 2,-25 -9,-32 -11,-6 -26,-3 -32,9 -6,11 -3,25 9,32 11,6 25,2 32,-9z" />
            <path class="fil0"
            d="M198 56c10,16 4,37 -12,47 -17,9 -38,4 -47,-13 -10,-16 -4,-37 12,-47 17,-9 38,-4 47,13zm-9 5c-7,-11 -21,-15 -32,-8 -12,6 -16,21 -9,32 6,11 21,15 32,8 11,-6 15,-20 9,-32z" />
        </g>
    </svg>
`;
