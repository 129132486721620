const module = angular.module('42.directives.input', []);

const KeyPressDirectiveFactory = (key: string, keyNumber: number, keyDirective: string) => {
    return [
        (): angular.IDirectiveLinkFn =>
            ($scope: angular.IScope, $element: angular.IRootElementService, $attrs: angular.IAttributes) => {
                $element.bind('keydown keypress', event => {
                    if (!(event.key === key || (event.keyCode || event.which) === keyNumber)) {
                        return;
                    }
                    $scope.$apply(() => {
                        $scope.$eval($attrs[keyDirective]);
                        return;
                    });
                    event.preventDefault();
                });
            },
    ];
};

module.directive('ngEsc', KeyPressDirectiveFactory('Escape', 27, 'ngEsc'));
module.directive('ngEnter', KeyPressDirectiveFactory('Enter', 13, 'ngEnter'));
module.directive('ngDoubleClick', [
    () => ($scope: angular.IScope, $element: angular.IRootElementService, $attrs: angular.IAttributes) => {
        $element.bind('dblclick', event => {
            $scope.$apply(() => {
                $scope.$eval($attrs.ngDoubleClick);
                return;
            });
            event.preventDefault();
            return;
        });
    },
]);

module.directive('focusOnNgIf', [
    '$timeout',
    function FocusOnNgIfDirective($timeout: angular.ITimeoutService) {
        return {
            restrict: 'A',
            link: function FocusOnNgIfDirectiveLink(
                $scope: angular.IScope,
                $element: angular.IRootElementService,
                $attr: angular.IAttributes,
            ) {
                if (!$attr.ngIf) {
                    return;
                }
                $scope.$watch($attr.ngIf, newValue => {
                    if (newValue) {
                        $timeout(() => $element[0]?.focus(), 0);
                    }
                });
            },
        };
    },
]);

export default module;
