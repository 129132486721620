import type { IPromise } from 'angular';

interface ExportButtonDirectiveScope<T = unknown> extends angular.IScope {
    onClick: () => IPromise<T>;
    hide: boolean;
    tracker: angular.promisetracker.PromiseTracker;
    text?: unknown;
    disable?: boolean;
    disabledMessage: string;
    exportFn?: () => PromiseLike<void>;
}
export const ButtonExportDirective = () => [
    '$q',
    'promiseTracker',
    function ButtonExportDirective(
        $q: angular.IQService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
    ): angular.IDirective<ExportButtonDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                exportFn: '&onClick',
                text: '=',
                disable: '=',
            },
            replace: true,
            template: `
            <article
                ng-click="onClick()"
                class="button-export"
                promise-tracker="tracker"
                ng-class="{ 'disabled': disable }">
                <i class="icon-export"><span>{{ text || 'Export' }}</span></i>
                <tooltip ng-if="disable" messages="[disabledMessage]"></tooltip>
            </article>
            `,
            link: function ExportButtonLink(scope) {
                scope.disabledMessage = 'Too many pages';
                scope.tracker = promiseTracker();
                scope.hide = false;
                scope.onClick = () => {
                    if (scope.disable) return $q.when(undefined);
                    const { exportFn } = scope;
                    if (!exportFn) throw new Error("Export button's onClick attribute is not defined");
                    const promise = $q.when((async () => await exportFn())());
                    scope.tracker.addPromise(promise);
                    return promise;
                };
            },
        };
    },
];

const buttonExportModule = angular
    .module('42.components.button-export', [])
    .directive('buttonExport', ButtonExportDirective());

export default buttonExportModule;
