import type { ServiceAPI } from './api';
import { ServiceAPIWithOrgConfigOverrideFactory } from './api-with-override';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SchedulingServiceAPICall<T = any, V = any> = (params?: T, httpOptions?: any) => Promise<V>;

export interface SchedulingServiceAPI extends ServiceAPI {
    organizations: {
        GetJob: SchedulingServiceAPICall;
        ListJobs: SchedulingServiceAPICall;
        RunJob: SchedulingServiceAPICall;
        CreateSchedule: SchedulingServiceAPICall;
        UpdateSchedule: SchedulingServiceAPICall;
        RunSchedule: SchedulingServiceAPICall;
        DeleteSchedule: SchedulingServiceAPICall;
        ListSchedules: SchedulingServiceAPICall;
        GetSchedule: SchedulingServiceAPICall;
    };
}

export interface Schedule {
    templateId: string;
    expiresAfter: number;
    expression: string;
    id: string;
    jobId: string;
    label: string;
    namespace: string;
    objectVersion: string;
    schemaVersion: number;
    timezone: string;
    updatedAt: string;
    data: {
        organizationId: string;
        userId: string;
        reportId: string[];
        target: {
            bcc: string;
            body: string;
            filename: string;
            recipients: string[];
        };
    };
}

export const SchedulingServiceAPI = new ServiceAPIWithOrgConfigOverrideFactory<SchedulingServiceAPI>('scheduling');
