import _ from 'lodash';

export interface ITimeGrouping {
    label: string;
    id: string;
    property: string[];
    chartType: string;
    tooltipFormatter: (point?: Record<string, unknown>) => string | null;
    pointFormatter?: (point: Record<string, unknown>) => string;
}

export const TimeGroupings: ITimeGrouping[] = [
    {
        id: 'none',
        label: 'None',
        property: [],
        chartType: 'barchart',
        tooltipFormatter: point => `${point.value0}`,
    },
    {
        id: 'day',
        label: 'Day',
        property: ['calendar.timestamp', 'calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => {
            const year = point.calendar__year;
            const week = Number(point.calendar__week) + 1;
            const day = Number(point.calendar__day_of_week) + 1;
            return `${year} W${week}|${day}`;
        },
        tooltipFormatter: point => {
            return point ? `${point.calendar__timestamp}` : null;
        },
    },
    {
        id: 'week',
        label: 'Week',
        property: ['calendar.week', 'calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => {
            const year = point.calendar__year;
            const week = Number(point.calendar__week) + 1;
            return `${year} W${week}`;
        },
        tooltipFormatter: point => `week ${Number(point.calendar__week) + 1} of ${point.calendar__year}`, // would be nice if we showed "week of YYYY-MM-DD"
    },
    {
        id: 'month',
        label: 'Month',
        property: ['calendar.month', 'calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => {
            const year = point.calendar__year;
            const month = point.calendar__month_label;
            return _.compact([typeof month === 'string' ? _.capitalize(month) : null, year]).join(' ');
        },
        tooltipFormatter: point => {
            const year = point.calendar__year;
            const month = point.calendar__month_label;
            return _.compact([typeof month === 'string' ? _.capitalize(month) : null, year]).join(' ');
        },
    },
    {
        id: 'quarter',
        label: 'Quarter',
        property: ['calendar.quarter', 'calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => `${point.calendar__year} Q${Number(point.calendar__quarter) + 1}`,
        tooltipFormatter: point => `${point.calendar__year} Q${Number(point.calendar__quarter) + 1}`,
    },
    {
        id: 'season',
        label: 'Season',
        property: ['calendar.season', 'calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => `${point.calendar__year} S${Number(point.calendar__season) + 1}`,
        tooltipFormatter: point => `${point.calendar__year} Q${Number(point.calendar__season) + 1}`,
    },
    {
        id: 'year',
        label: 'Year',
        property: ['calendar.year'],
        chartType: 'timeseries',
        pointFormatter: point => `${point.calendar__year}`,
        tooltipFormatter: point => `${point.calendar__year}`,
    },
];
