import { StorageAPI } from '../../lib/storage-user-config';
import { z } from 'zod';

// const ADS_STORAGE_KEY = 'ads.views.v1';
// TODO - remove this key after migration
const ADS_STORAGE_KEY = 'ads';

const AdsStorageSchema = z.record(z.object({ chart: z.array(z.string()) }));
export type IAdsStorage = z.infer<typeof AdsStorageSchema>;
const adsChartStorageSchema = z.record(z.object({ chart: z.array(z.string()) }));
export type IAdsChartStorage = z.infer<typeof adsChartStorageSchema>;

async function getStorage() {
    return StorageAPI<IAdsStorage>(ADS_STORAGE_KEY).then(api => {
        return api.get().then(adsStorage => {
            const parsedSchema = AdsStorageSchema.safeParse(adsStorage);
            return parsedSchema.success ? parsedSchema.data : {};
        });
    });
}

async function saveStorage(storageData: IAdsStorage) {
    return StorageAPI<IAdsStorage>(ADS_STORAGE_KEY).then(api => {
        return api.put(storageData);
    });
}

async function saveChartStorage(platform: string, chartData: string[]) {
    return StorageAPI<IAdsStorage>(ADS_STORAGE_KEY).then(api => {
        return api.get().then(adsStorage => {
            const parsedSchema = adsChartStorageSchema.safeParse(adsStorage);
            const storageData: IAdsChartStorage = parsedSchema.success ? parsedSchema.data : {};

            storageData[platform] = {
                ...(storageData[platform] ? storageData[platform] : {}),
                ...{ chart: chartData },
            };

            return api.put(storageData);
        });
    });
}

export default { getStorage, saveStorage, saveChartStorage };
