export function downloadFromURL({ url, filename }: { url: string | URL; filename?: string }) {
    const link = document.createElement('a');
    link.href = url.toString();
    if (filename) link.download = filename;
    link.dispatchEvent(new MouseEvent('click', { view: window, bubbles: true, cancelable: false }));
    return;
}

export function downloadFromJSON(filename: string, obj: any) {
    const url = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
    return downloadFromURL({ filename, url });
}
