import _ from 'lodash'
import hexToRgba from 'hex-to-rgba'
import { ECHARTS_THEME, ECHARTS_THEME_LEGEND } from './chart-echarts.config'
import { buildEchartTooltip } from './chart-echarts.helper'

ECHARTS_DEFAULT_BAR_OPTIONS =
    color: ECHARTS_THEME.colors,
    grid:
        containLabel: true
        top: 40
        bottom: 10
        left: '1%'
        right: '3%'
    textStyle:
        fontFamily: ECHARTS_THEME.fontFamily
    xAxis:
        type: 'category'
        data: []
        animation: false
        splitLine:
            lineStyle:
                opacity: 1
                width: 1
                type: 'dotted'
                color: [ECHARTS_THEME.axisLineColor]
        axisLine:
            lineStyle:
                width: 1
                type: 'solid'
                color: ECHARTS_THEME.axisLineColor
        axisLabel:
            color: ECHARTS_THEME.axisLabelColor
            rotate: 45
            width: 80
            overflow: 'break'
            fontSize: 10
            ellipsis: '...'
    legend: _.cloneDeep(ECHARTS_THEME_LEGEND)
    yAxis:
        type: 'value'
        animation: false
        axisPointer:
            show: false
        axisTick:
            show: true
            lineStyle:
                opacity: 0.5
        splitLine:
            lineStyle:
                opacity: 0.5
                width: 1
                type: 'dotted'
                color: ECHARTS_THEME.axisLabelColor
        axisLine:
            show: true
            lineStyle:
                width: 1
                opacity: 0.5
                type: 'solid'
                color: ECHARTS_THEME.axisLabelColor
    series: [{
        data: []
        type: 'bar'
        roundCap: true
        name: ''
    }]
    tooltip:
        trigger: 'axis'
        contain: false
        backgroundColor: "rgba(0, 0, 0, 0.9)"
        transitionDuration: 0
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
        axisPointer:
            show: true
            z: 1000
            type: 'shadow'
            shadowStyle:
                color: "rgba(0,0,0,0.05)"
            # lineStyle:
            #     type: 'solid'
            #     color: ECHARTS_THEME.axisLineColor


export EchartsBarChartModelFactory = -> [
    '$filter',
    ###* @param {import('angular').IFilterService} $filter ###
    ($filter) ->
        return class EchartsBarChartModel

            constructor: (metrics, data = [], options = {}) -> @chartOptions = do ->
                chartOptions = _.cloneDeep(ECHARTS_DEFAULT_BAR_OPTIONS)
                options =
                    maxNumberOfSlices         : options.maxNumberOfSlices,
                    groupSlicesThresholdValue : options.groupSlicesThresholdValue,

                chartOptions.customOptions = options
                chartOptions.tooltip.formatter = (params) ->
                    params = params[0] if Array.isArray(params)
                    return buildEchartTooltip($filter, params)

                chartOptions.yAxis.axisPointer ?= {}
                chartOptions.yAxis.axisPointer.label ?= {}
                chartOptions.yAxis.axisLabel ?= {}
                chartOptions.yAxis.axisLabel.formatter = (value) ->
                chartOptions.yAxis.axisPointer.label.formatter = chartOptions.tooltip.formatter

                chartOptions.series[0].data = data.flatMap (x, index) ->
                    value = x[metrics[0].field]
                    return [] if typeof value isnt 'number'
                    # NOTE: uncomment to test negatives...
                    # value = value * -1 if index is 2
                    return [{metric: metrics[0], value, name: x['property0'], isNegative: value < 0}]
                , []

                chartOptions.series[0].data.sort (a, b) ->
                    return  1 if a.isNegative and not b.isNegative
                    return -1 if not a.isNegative and b.isNegative
                    valueA = if a.isNegative then a.value * -1 else a.value
                    valueB = if b.isNegative then b.value * -1 else b.value
                    return valueB - valueA

                chartOptions.series[0].data.forEach (dataItem, idx) ->
                    dataItem.itemStyle = {...dataItem.itemStyle, borderRadius:[4,4,0,0]}
                    return if typeof dataItem.value isnt 'number'
                    color = ECHARTS_THEME.colors[idx % ECHARTS_THEME.colors.length]
                    dataItem.itemStyle = {...dataItem.itemStyle, ...(do ->
                        return {color} if dataItem.value >= 0
                        color: hexToRgba(color, 0.1),
                        borderType: 'dashed',
                        borderColor: color
                    )}

                chartOptions.xAxis.data = chartOptions.series[0].data.map (x) -> x.name
                chartOptions.yAxis.axisLabel.formatter = (value) -> $filter('metric')(value, metrics[0])
                return chartOptions
]
