import angular from 'angular';
import _ from 'lodash';
import { fetchOverviewPageFlags } from '../../lib/config-flags';
import type { IConfigObj } from '../../lib/types';
import type { IDashboardQuery } from '../main/dashboard-models';
import type { DashboardRootScope } from '../main-controller';
import type { ISalesView } from './overview-views';
import { SalesChartsSelectModel, SalesView, SalesViewFactory } from './overview-views';
import type { IOverviewTopItemsConfigService } from './components/card-top-items';
import OverviewTopItemsModule from './components/card-top-items';
import CardOverallStatsModule from './components/card-overall-stats';
import CardOverallStatsChartModule from './components/card-overall-stats-chart';
import CardChartGridDirectivesModule from './components/card-chart-grid-directives';
import { ISalesChartGridConfigService, SalesChartGridConfigFactory } from './components/card-chart-grid';

import { OverviewChartMetricsConfigServiceFactory, SalesChartMetricsConfigFactory } from './overview-metrics.service';
import { IOverviewOverallStats, OverviewOverallStats } from './overview-card-charts';

const module = angular.module('42.controllers.overview', [
    '42.filters',
    CardOverallStatsModule.name,
    CardOverallStatsChartModule.name,
    OverviewTopItemsModule.name,
    CardChartGridDirectivesModule.name,
    //
]);

module.config(($routeProvider: angular.route.IRouteProvider, ROUTES: Record<string, unknown>, CONFIG: IConfigObj) => {
    const override = _.pick(CONFIG.routes.overview ?? CONFIG.routes.sales ?? {}, 'label', 'url');
    const route: Record<string, unknown> =
        ROUTES.overview !== undefined ? { ...ROUTES.overview, ...override } : { ...override };
    if (typeof route.url === 'string') {
        if (Boolean(route.oldUrl) && typeof route.oldUrl === 'string') {
            $routeProvider.when(route.oldUrl, { redirectTo: route.url });
        }
        $routeProvider.when(route.url, route);
    }
});

module.service('OverviewChartMetricsConfigService', OverviewChartMetricsConfigServiceFactory());
module.service('SalesView', SalesViewFactory());
module.factory('SalesChartMetricsConfig', SalesChartMetricsConfigFactory());
module.factory('SalesChartGridConfig', SalesChartGridConfigFactory());
module.service('SaleCharts', OverviewOverallStats());

interface OverviewControllerFactoryScope extends angular.IScope {
    view?: ISalesView;
    itemsUrl: string | undefined;
}

const OverviewControllerFactory = () => [
    '$q',
    '$rootScope',
    '$scope',
    'SalesView',
    'SaleCharts',
    'DashboardQuery',
    'SalesChartGridConfig',
    'OverviewTopItemsConfigService',
    function OverviewController(
        $q: angular.IQService,
        $rootScope: DashboardRootScope,
        $scope: OverviewControllerFactoryScope,
        SalesView: SalesView,
        SaleCharts: IOverviewOverallStats,
        DashboardQuery: IDashboardQuery,
        SalesChartsGridConfigService: ISalesChartGridConfigService,
        OverviewTopItemsConfigService: IOverviewTopItemsConfigService,
    ) {
        $scope.itemsUrl = $rootScope.routes.grid?.url ?? $rootScope.routes.items?.url;

        const fetchOverviewTopItemsCardConfigs = async () => {
            const flags = await fetchOverviewPageFlags();
            if (!flags.showTopItems) return null;
            return OverviewTopItemsConfigService.fetch();
        };

        $scope.$on(
            '$destroy',
            $rootScope.$watch('initialized', (initialized: boolean) => {
                if (!initialized) return;
                const getSalesCharts = () =>
                    SaleCharts.fetch().then(available => SalesChartsSelectModel({ available }));

                void $q
                    .all([fetchOverviewTopItemsCardConfigs(), getSalesCharts(), SalesChartsGridConfigService.fetch()])
                    .then(([topItems, charts, chartGrid]) => {
                        const updateView = () => {
                            if ($scope.view?.charts) {
                                charts.select($scope.view.charts.getSelectedId());
                            }

                            const options = {
                                ...(topItems ? { topItems } : {}),
                                ...(chartGrid ? { chartGrid } : {}),
                                charts,
                            };

                            $scope.view = new SalesView(DashboardQuery.get(), options);
                        };
                        updateView();
                        $scope.$on('$destroy', $rootScope.$on('query.refresh', updateView));
                    });
            }),
        );
    },
];

module.controller('OverviewController', OverviewControllerFactory());

export default module;
