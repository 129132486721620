import _ from 'lodash'

export getDatepickerCalendarMonthModel = (calendar) ->

    # Like the calendar model, this is a little ugly but it works and it should be fast.
    # This links up the months and years such that you can

    flattened = []
    nested    = []

    calendar.forEach (year, yearIndex) ->
        yearNested = []

        year.views.monthly.forEach (month, monthIndex) ->
            return if _.every _.flatten(month), _.isNull
            id = "#{yearIndex}#{monthIndex}"
            descriptor = {id, year:year.year, yearIndex, monthIndex, month:month.month, weeks:month}
            flattened.push(descriptor)
            yearNested.push(descriptor)

        yearNested.index = yearIndex
        yearNested.year  = year.year
        nested.push(yearNested)

    flattened = flattened.map (x, index, array) ->
        x.prev = array[index-1]
        x.next = array[index+1]
        return x

    flattened: flattened
    nested: nested

    month: null
    months: null

    ranges:
        year: _.range(calendar.length)
        month: null

    selected:
        year:  0
        month: 0

    getYearLabel: (index) ->
        yearDescriptor = @nested[index]
        {index, year} = yearDescriptor
        label = year.key
        return "#{label}*" if (@date?.year.key is year.key) and (@selected.year isnt index)
        return label

    getMonthLabel: (index) ->
        monthDescriptor = @nested[@selected.year]?[index]
        return '' if not monthDescriptor
        {monthIndex, year, month, label} = monthDescriptor
        isYearSelected  = @date?.year.key is year.key
        isMonthSelected = isYearSelected and @date?.month.offset is month.offset
        label = "#{(month.offset+1).toString().padStart(2, '0')} - #{month.fullName}"
        return "#{label}*" if (isYearSelected and isMonthSelected) and (@selected.month isnt monthIndex)
        return label

    selectFromDate: (date) ->
        @date = date
        @selected.year = @nested.map((x) -> x.year.key).indexOf(date.year.key)
        if @selected.year is -1
            @selected.year  = 0
            @selected.month = 0
        else
            @selected.month = @nested[@selected.year].map((x) -> x.month.offset).indexOf(date.month.offset)
        @update(true)

    update: (nudged = false) ->
        prevMonths = @months
        @months = @nested[@selected.year]
        @ranges.month = _.range(@months.length)
        @selected.month = do =>
            return @selected.month if nudged
            return 0 if not prevMonths
            selected = @months.map((x) -> x.id).indexOf(prevMonths[@selected.month]?.id)
            return Math.max(0, selected)
        @month = @nested[@selected.year][@selected.month]
        @years = @nested

    next: ->
        return if not @month.next
        @selected.year = @month.next.yearIndex
        @selected.month = @month.next.monthIndex
        @update(true)

    prev: ->
        return if not @month.prev
        @selected.year  = @month.prev.yearIndex
        @selected.month = @month.prev.monthIndex
        @update(true)
