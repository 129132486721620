import _ from 'lodash';
import { ConfigExperimentsSchema, IConfigExperiments } from './types';

/**
 * This class is what we use to save/load the user's selected organization.
 * We cache the organizationId to make sure it doesn't change during the user's session.
 * We've had issues historically where having multiple tabs would cause some problems.
 */
export class UserExperimentsStorageAPI {
    protected key: string;
    protected storage: Storage | undefined;
    protected experiments: IConfigExperiments | null = null;

    constructor({ key, storage }: { key?: string; storage?: Storage } = {}) {
        this.key = key ?? '42-experiments';
        this.storage = storage ?? globalThis.localStorage;
    }

    get(): IConfigExperiments | null {
        if (this.experiments) return this.experiments;
        try {
            if (!this.storage) {
                console.error('[UserExperimentsStorageAPI] No localStorage available, cannot load experiments flags.');
                return null;
            } else {
                const experiments = (() => {
                    const storedExperiments = this.storage.getItem(this.key) ?? null;
                    console.log('[UserExperimentsStorageAPI] Loaded experiments from localStorage:', storedExperiments);
                    if (!storedExperiments) return {};
                    const parsedSchema = ConfigExperimentsSchema.safeParse(JSON.parse(storedExperiments));
                    return parsedSchema.success ? parsedSchema.data : {};
                })();
                this.experiments = experiments;
                return _.cloneDeep(experiments);
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async set(experiments: IConfigExperiments) {
        const parsedSchema = ConfigExperimentsSchema.safeParse(experiments);
        if (!parsedSchema.success) {
            console.error('[UserExperimentsStorageAPI] Invalid experiments object:', experiments);
            return;
        }
        console.log('[UserExperimentsStorageAPI] Setting experiments to:', experiments);
        this.experiments = experiments;
        try {
            if (!this.storage) {
                console.error('[UserExperimentsStorageAPI] No storage available, cannot save experiments flags.');
            } else {
                this.storage.setItem(this.key, JSON.stringify(experiments));
            }
        } catch (error) {
            if (isQuotaExceededError(error)) {
                console.error('LocalStorage length:', localStorage?.length);
            }
            console.error(error);
        }
    }
}

function isQuotaExceededError(error: unknown): error is DOMException {
    return error instanceof DOMException && error.name === 'QuotaExceededError';
}

export const UserExperimentsStorage = new UserExperimentsStorageAPI();

// This is probably not necessary, but it ensures we're caching the organizationId as soon as possible.
UserExperimentsStorage.get();
