import Utils from '../lib/utils';

const LegacyUtils = {
    uuid: Utils.Misc.uuid,
    copy: Utils.Misc.copy,
    clamp: Utils.Misc.clamp,
    ...Utils.Array,
    object: { hash: Utils.Object.hash },
} as const;
export type ILegacyUtils = typeof LegacyUtils;

const module = angular.module('42.modules.libs.utils', []);
module.service('Utils', [() => LegacyUtils]);
export default module;
