import { omit, isNil, isObject } from 'lodash';

// https://github.com/odynvolk/omit-deep-lodash/blob/master/src/index.js
export function omitDeep<T, K extends string>(
    value: T,
    key: K,
): T extends Array<any> ? DeepOmitArray<T, K> : DeepOmit<T, K> {
    return omitDeep_(value, key);
}

function omitDeep_(input: any, ...props: string[]): any {
    function omitDeepOnOwnProps(obj: any): any {
        if (isNil(obj)) {
            return input;
        }

        if (!Array.isArray(obj) && !isObject(obj)) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return omitDeep_(obj, ...props);
        }

        const o: any = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
                value = !isNil(value) ? omitDeep_(value, ...props) : value;
                return [key, value];
            }),
        );

        return omit(o, ...props);
    }

    if (Array.isArray(input)) {
        return input.map(omitDeepOnOwnProps);
    }

    return omitDeepOnOwnProps(input);
}

// https://gist.github.com/ahuggins-nhs/826906a58e4c1e59306bc0792e7826d1

/** Union of primitives to skip with deep omit utilities. */
type Primitive = string | CallableFunction | number | boolean | symbol | undefined | null;

/** Deeply omit members of an array of interface or array of type. */
export type DeepOmitArray<T extends any[], K> = {
    [P in keyof T]: DeepOmit<T[P], K>;
};

/** Deeply omit members of an interface or type. */
export type DeepOmit<T, K> = T extends Primitive
    ? T
    : {
          [P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorhic behavior // distribute over unions
              ? TP extends Primitive
                  ? TP // leave primitives and functions alone
                  : TP extends any[]
                  ? DeepOmitArray<TP, K> // Array special handling
                  : DeepOmit<TP, K>
              : never;
      };

/** Deeply omit members of an array of interface or array of type, making all members optional. */
export type PartialDeepOmitArray<T extends any[], K> = Partial<{
    [P in Partial<keyof T>]: Partial<PartialDeepOmit<T[P], K>>;
}>;

/** Deeply omit members of an interface or type, making all members optional. */
export type PartialDeepOmit<T, K> = T extends Primitive
    ? T
    : Partial<{
          [P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorhic behavior // distribute over unions
              ? TP extends Primitive
                  ? TP // leave primitives and functions alone
                  : TP extends any[]
                  ? PartialDeepOmitArray<TP, K> // Array special handling
                  : Partial<PartialDeepOmit<TP, K>>
              : never;
      }>;
