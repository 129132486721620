export class FontWidthCalculator {
    protected readonly textTransform: 'uppercase' | 'none';
    protected readonly font: string;
    protected letterSpacing: number;
    protected element: HTMLCanvasElement | null = null;
    protected context: CanvasRenderingContext2D | null = null;

    constructor({
        font,
        letterSpacing,
        textTransform,
    }: {
        font: string;
        letterSpacing?: number;
        textTransform?: 'uppercase' | 'none';
    }) {
        this.textTransform = textTransform ?? 'none';
        this.letterSpacing = Math.max(0, letterSpacing ?? 0);
        this.font = font;
        this.getOrCreateContext();
    }

    protected getOrCreateContext() {
        if (this.element && !this.context) return null;
        if (this.context) return this.context;
        this.element ??= document.createElement('canvas');
        this.context = this.element.getContext('2d');
        if (!this.context) return null;
        this.context.font = this.font;
        return this.context;
    }

    public setLetterSpacing(letterSpacing: number) {
        this.letterSpacing = Math.max(0, letterSpacing);
    }

    public getPixelWidth(text: string): number;
    public getPixelWidth(text: unknown): number {
        let str: string;
        if (typeof text === 'number') {
            str = String(text);
        } else if (typeof text === 'string') {
            str = this.textTransform === 'uppercase' ? text.toUpperCase() : text;
        } else {
            str = '';
        }
        const context = this.getOrCreateContext();
        const width = context?.measureText(str).width ?? 0; // TODO: better fallback
        return width + str.length * this.letterSpacing;
    }
}
