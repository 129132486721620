import './properties-items.scss';
import { IPropertyDefinition } from '../lib/config-hierarchy';
import { KeyboardObserverAngular } from '../lib/dom/keyboard-observer';
import { MouseObserverAngular } from '../lib/dom/mouse-observer';

export interface PropertiesItemsModel {
    label: string;
    available: IPropertyDefinition[];
    selected: IPropertyDefinition | IPropertyDefinition[];
    options?: {
        multipleSelection?: boolean;
    };
    onClick: (property: IPropertyDefinition[]) => void;
}

interface PropertiesItemsDirectiveScope extends angular.IScope {
    model: PropertiesItemsModel;
    selectedIds: Record<string, boolean>;
    multiPropertyModel: {
        mouseOnTopOfElement: boolean;
        enabled: boolean;
    };
    clickItem: ($event: MouseEvent, pebble: IPropertyDefinition) => void;
}
export const PropertiesItemsDirectiveFactory = () => [
    function PropertiesItemsDirective(): angular.IDirective<PropertiesItemsDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <div class="properties-items">
                <div class="properties-items-title">
                    {{ model.label || '' }}
                </div>
                <div class="items-list">
                    <ul class="ui-pellets">
                        <li class="ui-pellet available-property"
                            ng-repeat="property in model.available"
                            ng-click="clickItem($event, property)"
                            ng-class="{selected: selectedIds[property.id]}">
                            <span class="property-label">{{ property.label }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            `,
            link: function ExportButtonLink(scope, element) {
                scope.selectedIds = {};
                scope.multiPropertyModel = {
                    mouseOnTopOfElement: false,
                    enabled: false,
                };

                const fillSelectedIds = (properties: IPropertyDefinition | IPropertyDefinition[]) => {
                    properties = Array.isArray(properties) ? properties : [properties];
                    const values: Record<string, boolean> = {};
                    properties.forEach(property => (values[property.id] = true));
                    return values;
                };

                if (scope.model.options?.multipleSelection) {
                    const keyboardObserver = new KeyboardObserverAngular(scope, window);
                    keyboardObserver.onKeyPress((event: KeyboardEvent) => {
                        if ('key' in event && event.key !== 'Shift') {
                            scope.multiPropertyModel.enabled = false;
                            scope.selectedIds = fillSelectedIds(scope.model.selected ?? []);
                            return;
                        }
                        scope.multiPropertyModel.enabled = event.type === 'keydown';
                    });

                    const mouseElementObserver = new MouseObserverAngular(scope, element[0], { preventDefault: false });
                    mouseElementObserver.onMouseEnter(() => {
                        scope.multiPropertyModel.mouseOnTopOfElement = true;
                    });
                    mouseElementObserver.onMouseLeave(() => {
                        scope.multiPropertyModel.mouseOnTopOfElement = false;
                    });
                }

                const isMultipleSelectionEnabled = () => {
                    return (
                        scope.model.options?.multipleSelection &&
                        scope.multiPropertyModel.enabled &&
                        scope.multiPropertyModel.mouseOnTopOfElement
                    );
                };

                scope.clickItem = ($event, property) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();

                    if (Object.keys(scope.selectedIds).length === 1 && scope.selectedIds[property.id]) return;

                    if (isMultipleSelectionEnabled()) {
                        scope.selectedIds[property.id] = !scope.selectedIds[property.id];
                    } else {
                        scope.selectedIds = { [property.id]: true };
                    }

                    const properties = scope.model.available.filter(property => scope.selectedIds[property.id]);
                    scope.model.onClick(properties);
                };

                scope.$watch('model.selected', (properties?: IPropertyDefinition | IPropertyDefinition[]) => {
                    scope.selectedIds = fillSelectedIds(properties ?? []);
                });
            },
        };
    },
];

const PropertiesItemsModule = angular
    .module('42.components.propertiesItems', [])
    .directive('propertiesItems', PropertiesItemsDirectiveFactory());

export default PropertiesItemsModule;
