import SmartGroupFilterValues from './smart-groups-filter-values.service';
import {
    PropertyFilterLabelDirective,
    PropertyFilterListDirective,
} from './smart-group-filter-property-list.directive';
import {
    SmartGroupsItemDirective,
    SmartGroupsPanelDirective,
    SmartGroupsPanelCompactDirective,
    SmartGroupEditDropdownDirective,
} from './smart-group-panel.directive';
import {
    SegmentsDirective,
    SegmentsPropertiesServiceFactory,
    SegmentsViewFactory,
    SegmentsCompactDirective,
    SegmentsCompactViewFactory,
} from './smart-group-filter.directive';
import {
    PropertyViewFactory,
    SegmentPropertyFilterDirective,
    SelectionCheckboxDirective,
} from './smart-group-filter-property-item.directive';

const module = angular.module('42.modules.smart-groups', []);
SmartGroupFilterValues(module);
SmartGroupEditDropdownDirective(module);
SmartGroupsItemDirective(module);
SmartGroupsPanelDirective(module);
SmartGroupsPanelCompactDirective(module);

module.service('SegmentsPropertiesService', SegmentsPropertiesServiceFactory());
module.service('SegmentsView', SegmentsViewFactory());
module.service('SegmentsCompactView', SegmentsCompactViewFactory());
module.service('PropertyView', PropertyViewFactory());
module.directive('segmentPropertyFilter', SegmentPropertyFilterDirective());
module.directive('propertyFilterList', PropertyFilterListDirective());
module.directive('propertyFilterLabel', PropertyFilterLabelDirective());
module.directive('selectionCheckbox', SelectionCheckboxDirective());
SegmentsDirective(module);
SegmentsCompactDirective(module);
