import type { IConfigObj } from '../../lib/types';
import type { IQueryServiceAPI } from './query-service.types';
import type { ISchedulingServiceAPIFactory } from './scheduling.service';
import type { IDatabaseDescriptorsService } from '../../lib/config-database-descriptors';
import { DatabaseDescriptorsService } from '../../lib/config-database-descriptors';
import QueryServiceModule from './query-service-module.coffee';
import SchedulingServiceModule from './scheduling.service';

export type { IDatabaseDescriptorsService };
export type IDataDescriptorsService = IDatabaseDescriptorsService;
type IQueryServiceAPIFactory = (config: { host: string }) => Promise<IQueryServiceAPI>;

const module = angular.module('42.modules.services', [QueryServiceModule.name, SchedulingServiceModule.name]);
export default module;

module.service('DataDescriptors', [() => DatabaseDescriptorsService]);
module.service('DatabaseDescriptors', [() => DatabaseDescriptorsService]);

module.factory('QueryServiceAPI', [
    '42.services.query.api',
    'CONFIG',
    (QueryServiceAPIFactory: IQueryServiceAPIFactory, CONFIG: IConfigObj) => {
        const promise = QueryServiceAPIFactory(CONFIG.services.query);
        return () => promise;
    },
]);

module.factory('SchedulingServiceAPI', [
    '42.services.scheduling.api',
    function SchedulingServiceAPIFactory(SchedulingServiceAPIFactory: ISchedulingServiceAPIFactory) {
        const promise = SchedulingServiceAPIFactory();
        return () => promise;
    },
]);
