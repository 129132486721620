import { INoRowsOverlayComp } from '@ag-grid-community/all-modules';

const errorHTML = `
    <div class="error-message-container">
        <div class="message">
            <i class="message-icon icon-attention"></i>
            <span class="message-summary">Data could not be loaded</span>
        </div>
    </div>
`;

const noRowsHTML = `
    <div class="no-rows-message-container">
        <div class="message">
            <span class="message-summary">No Rows to display</span>
        </div>
    </div>
`;

export const GridOverlayMessageRendererFactory = () => {
    let isError = false;

    class GridOverlayMessageRenderer implements INoRowsOverlayComp {
        eGui: HTMLDivElement = document.createElement('div');

        init() {
            this.eGui = document.createElement('div');
            this.eGui.innerHTML = isError ? errorHTML : noRowsHTML;
        }

        getGui() {
            return this.eGui;
        }

        refresh() {
            return false;
        }
    }

    return {
        component: GridOverlayMessageRenderer,
        setError: (error: boolean) => {
            isError = error;
        },
    };
};
