import _ from 'lodash';
import { z } from 'zod';
import { IMetricsColumnDef, IMetricsFiltering } from './metrics-funnel';
import { isObject } from '../../lib/utils/utils-object';
import { IQueryColumnFilterValue, IQueryTableFilter, IQueryWhereTableFilter } from '../../lib/types';
import Decimal from 'decimal.js';

const AGGridFilteringTypesSchema = z.enum([
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
]);
type AG_GRID_FILTERING_TYPES = z.infer<typeof AGGridFilteringTypesSchema>;

const FilteringTypesSchema = z.enum(['$eq', '$ne', '$lt', '$lte', '$gt', '$gte']); // , '$between']);
type FILTERING_TYPES = z.infer<typeof FilteringTypesSchema>;

const isOfFilteringTypes = (keyInput: string): keyInput is FILTERING_TYPES => {
    const parsedSchema = FilteringTypesSchema.safeParse(keyInput);
    return parsedSchema.success;
};
const FILTERING_TYPES_DICTIONARY: Record<AG_GRID_FILTERING_TYPES, FILTERING_TYPES> = {
    equals: '$eq',
    notEqual: '$ne',
    lessThan: '$lt',
    lessThanOrEqual: '$lte',
    greaterThan: '$gt',
    greaterThanOrEqual: '$gte',
    // inRange: '$between',
};
const AG_GRID_FILTERING_TYPES_DICTIONARY: Record<FILTERING_TYPES, AG_GRID_FILTERING_TYPES> = {
    $eq: 'equals',
    $ne: 'notEqual',
    $lt: 'lessThan',
    $lte: 'lessThanOrEqual',
    $gt: 'greaterThan',
    $gte: 'greaterThanOrEqual',
    // inRange: '$between',
};

const AGGridPropertyFilteringTypesSchema = z.enum([
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
]);
type AG_GRID_PROPERTY_FILTERING_TYPES = z.infer<typeof AGGridPropertyFilteringTypesSchema>;

const PropertyFilteringTypesSchema = z.enum(['$eq', '$ne', '$contains', '$notcontains', '$startsWith', '$endsWith']); // , '$between']);
type PROPERTY_FILTERING_TYPES = z.infer<typeof PropertyFilteringTypesSchema>;

const isOfPropertyFilteringTypes = (keyInput: string): keyInput is PROPERTY_FILTERING_TYPES => {
    const parsedSchema = PropertyFilteringTypesSchema.safeParse(keyInput);
    return parsedSchema.success;
};
const PROPERTY_FILTERING_TYPES_DICTIONARY: Record<AG_GRID_PROPERTY_FILTERING_TYPES, PROPERTY_FILTERING_TYPES> = {
    equals: '$eq',
    notEqual: '$ne',
    contains: '$contains',
    notContains: '$notcontains',
    startsWith: '$startsWith',
    endsWith: '$endsWith',
    // inRange: '$between',
};
const AG_GRID_PROPERTY_FILTERING_TYPES_DICTIONARY: Record<PROPERTY_FILTERING_TYPES, AG_GRID_PROPERTY_FILTERING_TYPES> =
    {
        $eq: 'equals',
        $ne: 'notEqual',
        $contains: 'contains',
        $notcontains: 'notContains',
        $startsWith: 'startsWith',
        $endsWith: 'endsWith',
        // inRange: '$between',
    };

const FilteringOperatorsSchema = z.enum(['$and', '$or']);
type FILTERING_OPERATORS = z.infer<typeof FilteringOperatorsSchema>;
const AGGridFilteringOperatorsSchema = z.enum(['AND', 'OR']);
type AG_GRID_FILTERING_OPERATORS = z.infer<typeof AGGridFilteringOperatorsSchema>;

const FILTERING_OPERATORS_DICTIONARY: Record<AG_GRID_FILTERING_OPERATORS, FILTERING_OPERATORS> = {
    AND: '$and',
    OR: '$or',
};
const AG_GRID_FILTERING_OPERATORS_DICTIONARY: Record<FILTERING_OPERATORS, AG_GRID_FILTERING_OPERATORS> = {
    $and: 'AND',
    $or: 'OR',
};

const FILTERING_TYPES_KEYS: string[] = Object.keys(FILTERING_TYPES_DICTIONARY);

const MetricsFilteringSingleSchema = z.object({
    filter: z.number().or(z.string()),
    filterTo: z.number().or(z.string()).optional(),
    filterType: z.string(),
    type: z.enum([FILTERING_TYPES_KEYS[0] ?? '$eq', ...FILTERING_TYPES_KEYS.slice(0)]),
});
export type IMetricsFilteringSingle = z.infer<typeof MetricsFilteringSingleSchema>;
const MetricsFilteringMultipleSchema = z.object({
    condition1: MetricsFilteringSingleSchema,
    condition2: MetricsFilteringSingleSchema,
    filterType: z.string(),
    operator: AGGridFilteringOperatorsSchema,
});
export type IMetricsFilteringMultiple = z.infer<typeof MetricsFilteringMultipleSchema>;

export const AGGridFilteringSchema = z.record(MetricsFilteringSingleSchema.or(MetricsFilteringMultipleSchema));
export type IAGGridFiltering = z.infer<typeof AGGridFilteringSchema>;

const isOfQueryTableFilter = (obj: unknown): obj is IQueryTableFilter => {
    if (!isObject(obj)) return false;
    return ['$and', '$or'].find(key => key in obj) !== undefined;
};

const isProperty = (field: string) => /^property\d+/.test(field);

export const getPercentFilterValue = (value: number) => {
    return Decimal.mul(value, 100).toNumber();
};

export const convertToAGGridFiltering = (
    metricsFiltering: IMetricsFiltering,
    metrics: Record<string, IMetricsColumnDef> = {},
): IAGGridFiltering => {
    const agGridFiltering: IAGGridFiltering = {};
    for (const [metricKey, metricFilter] of Object.entries(metricsFiltering)) {
        if (isOfQueryTableFilter(metricFilter)) {
            for (const [operator, columnFilters] of Object.entries(metricFilter)) {
                if (isObject(columnFilters)) {
                    let index = 1;
                    let condition1: IMetricsFilteringSingle | undefined = undefined;
                    let condition2: IMetricsFilteringSingle | undefined = undefined;
                    for (const [columnFilterOperator, columnFilterValue] of Object.entries(columnFilters)) {
                        if (
                            (isOfFilteringTypes(columnFilterOperator) ||
                                isOfPropertyFilteringTypes(columnFilterOperator)) &&
                            (typeof columnFilterValue === 'string' || typeof columnFilterValue === 'number')
                        ) {
                            const type = (() => {
                                return isOfFilteringTypes(columnFilterOperator)
                                    ? AG_GRID_FILTERING_TYPES_DICTIONARY[columnFilterOperator]
                                    : AG_GRID_PROPERTY_FILTERING_TYPES_DICTIONARY[columnFilterOperator];
                            })();

                            const filter = (() => {
                                const metric = metrics[metricKey];
                                return metric?.cellFilter?.startsWith('percent')
                                    ? getPercentFilterValue(Number(columnFilterValue))
                                    : columnFilterValue;
                            })();
                            const filterType = 'number';

                            if (index === 1) {
                                condition1 = { filter, filterType, type };
                            } else {
                                condition2 = { filter, filterType, type };
                            }
                        }
                        index++;
                    }

                    if (condition1 && condition2) {
                        const parsedOperator = FilteringOperatorsSchema.safeParse(operator);
                        if (parsedOperator.success) {
                            const agGridFilteringOperator = AG_GRID_FILTERING_OPERATORS_DICTIONARY[parsedOperator.data];
                            const parsedSchema = AGGridFilteringOperatorsSchema.safeParse(agGridFilteringOperator);
                            if (parsedSchema.success) {
                                agGridFiltering[metricKey] = {
                                    condition1,
                                    condition2,
                                    filterType: condition1.filterType,
                                    operator: parsedSchema.data,
                                };
                            }
                        }
                    }
                }
            }
        } else {
            for (const [columnFilterOperator, columnFilterValue] of Object.entries(metricFilter)) {
                if (
                    (isOfFilteringTypes(columnFilterOperator) || isOfPropertyFilteringTypes(columnFilterOperator)) &&
                    (typeof columnFilterValue === 'string' || typeof columnFilterValue === 'number')
                ) {
                    const type = (() => {
                        return isOfFilteringTypes(columnFilterOperator)
                            ? AG_GRID_FILTERING_TYPES_DICTIONARY[columnFilterOperator]
                            : AG_GRID_PROPERTY_FILTERING_TYPES_DICTIONARY[columnFilterOperator];
                    })();
                    const filter = (() => {
                        const metric = metrics[metricKey];
                        return metric?.cellFilter?.startsWith('percent')
                            ? getPercentFilterValue(Number(columnFilterValue))
                            : columnFilterValue;
                    })();
                    const filterType = 'number';

                    agGridFiltering[metricKey] = {
                        filter,
                        filterType,
                        type,
                    };
                }
            }
        }
    }
    return agGridFiltering;
};

export const normalizedAGGridFiltering = (
    columnsFilter: undefined | IAGGridFiltering,
    metricsByField: Record<string, IMetricsColumnDef>,
): IMetricsFiltering => {
    if (!columnsFilter) return {};
    const metricsFiltering: IAGGridFiltering = {};
    for (const [columnId, columnFilterValue] of Object.entries(columnsFilter)) {
        if (metricsByField[columnId] || isProperty(columnId)) {
            metricsFiltering[columnId] = columnFilterValue;
        }
    }
    const where: IQueryWhereTableFilter = {};

    const getFilterConditionRange = (
        conditionType: FILTERING_TYPES | PROPERTY_FILTERING_TYPES | undefined,
        condition: IMetricsFilteringSingle,
    ): IQueryColumnFilterValue => (!conditionType ? {} : { [conditionType]: condition.filter });

    const buildQueryMetricFilterCondition = (
        operator: FILTERING_OPERATORS,
        condition1: [FILTERING_TYPES | PROPERTY_FILTERING_TYPES | undefined, IMetricsFilteringSingle],
        condition2?: [FILTERING_TYPES | PROPERTY_FILTERING_TYPES | undefined, IMetricsFilteringSingle],
    ) => {
        const filterCondition = _.merge(
            getFilterConditionRange(...condition1),
            condition2 ? getFilterConditionRange(...condition2) : {},
        );
        return { [operator]: filterCondition };
    };

    for (const [metricKey, metricFilter] of Object.entries(metricsFiltering)) {
        const metric = metricsByField[metricKey];
        const isPropertyField = isProperty(metricKey);
        if (!metric && !isPropertyField) throw new Error(`Metric ${metricKey} not found`);

        const transformConditionValue = !metric?.cellFilter?.startsWith('percent')
            ? (condition: IMetricsFilteringSingle) => condition
            : (condition: IMetricsFilteringSingle) => {
                  const filter = typeof condition.filter === 'number' ? condition.filter / 100 : condition.filter;
                  return { ...condition, filter };
              };
        if ('operator' in metricFilter) {
            const operator: FILTERING_OPERATORS = FILTERING_OPERATORS_DICTIONARY[metricFilter.operator];

            if (isPropertyField) {
                const conditionType1Parsed = AGGridPropertyFilteringTypesSchema.safeParse(metricFilter.condition1.type);
                const conditionType2Parsed = AGGridPropertyFilteringTypesSchema.safeParse(metricFilter.condition2.type);
                if (conditionType1Parsed.success && conditionType2Parsed.success) {
                    const condition1Type = PROPERTY_FILTERING_TYPES_DICTIONARY[conditionType1Parsed.data];
                    const condition2Type = PROPERTY_FILTERING_TYPES_DICTIONARY[conditionType2Parsed.data];
                    where[metricKey] = buildQueryMetricFilterCondition(
                        operator,
                        [condition1Type, transformConditionValue(metricFilter.condition1)],
                        [condition2Type, transformConditionValue(metricFilter.condition2)],
                    );
                }
            } else {
                const conditionType1Parsed = AGGridFilteringTypesSchema.safeParse(metricFilter.condition1.type);
                const conditionType2Parsed = AGGridFilteringTypesSchema.safeParse(metricFilter.condition2.type);
                if (conditionType1Parsed.success && conditionType2Parsed.success) {
                    const condition1Type = FILTERING_TYPES_DICTIONARY[conditionType1Parsed.data];
                    const condition2Type = FILTERING_TYPES_DICTIONARY[conditionType2Parsed.data];
                    where[metricKey] = buildQueryMetricFilterCondition(
                        operator,
                        [condition1Type, transformConditionValue(metricFilter.condition1)],
                        [condition2Type, transformConditionValue(metricFilter.condition2)],
                    );
                }
            }
        } else if ('type' in metricFilter) {
            if (isPropertyField) {
                const typeParsed = AGGridPropertyFilteringTypesSchema.safeParse(metricFilter.type);
                if (typeParsed.success) {
                    const type = PROPERTY_FILTERING_TYPES_DICTIONARY[typeParsed.data];
                    where[metricKey] = getFilterConditionRange(type, transformConditionValue(metricFilter));
                }
            } else {
                const typeParsed = AGGridFilteringTypesSchema.safeParse(metricFilter.type);
                if (typeParsed.success) {
                    const type = FILTERING_TYPES_DICTIONARY[typeParsed.data];
                    where[metricKey] = getFilterConditionRange(type, transformConditionValue(metricFilter));
                }
            }
        }
    }

    return where;
};
