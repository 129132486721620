import _ from 'lodash';
import * as AuthServiceAPI from '../../lib/auth';
import * as Analytics from '../../lib/analytics';
import { downloadFromJSON } from '../dom/download';

const getFileName = (name: string, namespace?: string) => {
    const filename = _.kebabCase(name.replace(/&/g, '-and-'));
    const fileNamespace = namespace ? `${namespace}-` : '';
    return `42-${fileNamespace}${filename}.json`;
};

const getData = (data: unknown, type: string, userId: string, organizationId: string) => {
    const meta = { type, version: 1, organizationId, createdAt: Date.now(), exportedByUserId: userId };
    return { data, meta };
};

export const downloadFile = (options: {
    data: Record<string, unknown>;
    name: string;
    namespace: string;
    type: string;
    analyticsEvent: string;
}): Promise<void> => {
    const { data, type, name, namespace, analyticsEvent } = options;
    return Promise.all([AuthServiceAPI.getOrganization(), AuthServiceAPI.getUser()]).then(([orgId, user]) => {
        if (!user.id) throw new Error('User not found');

        const payload = getData(data, type, user.id, orgId);
        const filename = getFileName(name, namespace);
        Analytics.track(analyticsEvent, {
            payload: payload.data,
            name,
        });
        return downloadFromJSON(filename, payload);
    });
};
