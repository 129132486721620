import { z } from 'zod';
/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type { ServicesConfig } from '@42technologies/config-client';
import type { ICalendarConfig } from '@42technologies/calendar';
import type { IHierarchy } from './config-hierarchy';
import type { ExactlyOneKey } from './utils';
import type { IDatepickerActionOverride } from '../modules/datepicker/lib/datepicker-actions';
import { IConfigRoute } from './config-routes';
export type * from './config-defaults';

export interface User {
    createdAt?: string;
    email: string;
    id?: string;
    name: string;
    organizationId?: string;
    role: string;
    status?: string;
    link?: string;
}

export interface ITablePropertyValues {
    count: number;
    value: string;
}

// prettier-ignore
export type IQueryColumnFilterValueOperatorIn =
    IQueryColumnFilterValueExclusiveOperator<'$in', (number|string)[]>
// prettier-ignore
export type IQueryColumnFilterValueOperatorNotIn =
    IQueryColumnFilterValueExclusiveOperator<'$nin', (number|string)[]>

// prettier-ignore
export type IQueryColumnFilterValueContains =
    | IQueryColumnFilterValueOperatorIn
    | IQueryColumnFilterValueOperatorNotIn;

export type IQueryColumnFilterValueInClosedRange = IQueryColumnFilterValueExclusiveOperator<'$gte' | '$gt'> &
    IQueryColumnFilterValueExclusiveOperator<'$lte' | '$lt'>;
export type IQueryColumnFilterValueRange =
    | IQueryColumnFilterValueExclusiveOperator<'$gte' | '$gt'>
    | IQueryColumnFilterValueExclusiveOperator<'$lte' | '$lt'>
    | IQueryColumnFilterValueInClosedRange;

export type IQueryColumnFilterValueOperator<K extends `$${string}` = `$${string}`, V = string | number> = Record<K, V>;
export type IQueryColumnFilterValueExclusiveOperator<
    K extends `$${string}` = `$${string}`,
    V = string | number,
> = ExactlyOneKey<K, V>;
export type IQueryColumnFilterValueImplicitEquals = number | string;
export type IQueryColumnFilterValueOperatorEqual = IQueryColumnFilterValueExclusiveOperator<'$equals'>;
export type IQueryColumnFilterValueOperatorNotEqual = IQueryColumnFilterValueExclusiveOperator<'$neq'>;
export type IQueryColumnFilterValueOperatorFuzzy = IQueryColumnFilterValueExclusiveOperator<'$fuzzy', string>;

export type IQueryColumnFilterValue =
    | IQueryColumnFilterValueImplicitEquals
    | IQueryColumnFilterValueRange
    | IQueryColumnFilterValueContains
    | IQueryColumnFilterValueOperatorEqual
    | IQueryColumnFilterValueOperatorNotEqual
    | IQueryColumnFilterValueOperatorFuzzy
    | { [x: string]: string | number | (string | number)[] };

export type IQueryTableFilterColumns = { [x: string]: IQueryColumnFilterValue };
export type IQueryTableFilterOperatorAnd = { $and: IQueryTableFilterColumns[] };
export type IQueryTableFilterOperatorOr = { $or: IQueryTableFilterColumns[] }; // not used in the dash currently
// export type IQueryTableFilterOperatorBetween = { $between: IQueryTableFilterColumns[] }; // not used in the dash currently
export type IQueryTableFilterImplicitAnd = {
    [column: string]: IQueryColumnFilterValue;
};
export type IQueryTableFilter = {
    $and?: IQueryTableFilterOperatorAnd['$and'];
    $or?: IQueryTableFilterOperatorOr['$or'];
    // $between?: IQueryTableFilterOperatorBetween['$between'];
    [x: string]: undefined | IQueryColumnFilterValue | IQueryTableFilterColumns[];
};

export interface IQueryWhereTableFilter {
    [x: string]: IQueryTableFilter | IQueryColumnFilterValue;
}

/** @deprecated use IQueryTableFilter */
export type IQueryFilterItem = IQueryTableFilter;

export interface IQueryTimestampFilter {
    $gte: string;
    $lt: string;
}

export interface IQueryComparison {
    timestamp?: IQueryTimestampFilter;
}

export interface IQueryModifiers {
    currency?: string;
    calendar?: string;
    pruneUnusedMetrics?: boolean;
    bypassLegacyDemandMetrics?: boolean;
    enableFoundationPruningFromFilters?: boolean;
    [key: string]: unknown;
}

export type IQueryFilterTransactions = IQueryTableFilter & {
    timestamp?: IQueryTimestampFilter;
};

export interface IQueryFilters {
    transactions?: IQueryFilterTransactions;
    stores?: IQueryTableFilter;
    customers?: IQueryTableFilter;
    items?: IQueryTableFilter;
    acquisitions?: IQueryTableFilter;
    transaction_items?: IQueryTableFilter;
    gift_cards?: IQueryTableFilter;
    accounts?: IQueryTableFilter;
    warehouses?: IQueryTableFilter;
    inventory?: IQueryTableFilter;
    [x: string]: undefined | IQueryTableFilter;
}

export interface IQueryExportReportingXLSX {
    columnStyle?: 'pivot' | 'compact' | 'tabular';
    columnDefs?: unknown;
    properties?: { id: string; label: string }[];
}

export type IQuerySortOrder = -1 | 1;
export type IQuerySortNulls = 'first' | 'last';
export type IQuerySort =
    | string
    | { [field: string]: IQuerySortOrder }
    | {
          field: string;
          order?: IQuerySortOrder;
          limit?: number;
          nulls?: IQuerySortNulls;
          type?: string;
      };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IQueryOptions = Record<string, any>;
export type IQueryDefinitions = {
    [key: string]: {
        fields: string[];
        query: string;
        headerGroup?: string;
        headerName?: string;
        cellClass?: string;
        cellFilter?: string;
    };
};

export interface IQueryMetricFilter {
    metrics: string[];
    timerange: {
        selection: IQueryTimestampFilter;
        comparison?: IQueryTimestampFilter;
    };
}
export type IQueryMetricFilters = Record<string, IQueryMetricFilter>;

export type IQueryType = 'json' | 'csv' | 'xlsx' | 'pdf' | 'html';
export type IQuery<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    QueryOptions extends undefined | Record<string, any> = IQueryOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    QueryDefinitions extends undefined | Record<string, any> = IQueryDefinitions,
    QueryFilters extends undefined | Record<string, undefined | IQueryTableFilter> = IQueryFilters,
> = {
    /** queryId specific options */
    options?: QueryOptions;

    /** customer metric definitions */
    definitions?: QueryDefinitions;

    /** filters to apply to the source tables */
    filters?: QueryFilters;

    /** think of this as global options... */
    modifiers?: IQueryModifiers;

    /** the comparison timerange */
    comparison?: {
        timestamp: IQueryTimestampFilter;
    };

    /** not sure if this is used, but we set it */
    timezone?: unknown;

    /** @deprecated use modifiers.currency instead */
    currency?: string;

    sort?: unknown;
    limit?: number;
    offset?: number;

    where?: IQueryWhereTableFilter;

    type?: IQueryType;
    export?: unknown;

    /** See moose knuckles config, or query service module */
    metricFilters?: IQueryMetricFilters;

    /** See config-access-control */
    restrictions?: IQueryFilters | IQueryFilters[];
};

export interface ICalendarDescriptor {
    /** Unique ID for the calendar descriptor */
    id: null | string;
    /** Calendar label, for display purposes */
    label: string;
}

export type ICalendarDatepickerActionOverride = IDatepickerActionOverride;
export type ICalendarDatepicker<T extends string = string> = ICalendarConfig<T>;
export type ICalendar<T extends string = string> = ICalendarDescriptor & {
    datepicker: ICalendarDatepicker<T> & {
        type: T;
        actions?: IDatepickerActionOverride[];
    };
};

// Experiments Object works as a Toggle to New Features, making it possible to disable/enable it without re-deploys
export const ConfigExperimentsSchema = z.object({
    // Enable Multiple Property Group selection in Metrics Page
    enableMultiplePropertyGroupBy: z.boolean().optional(),
    // Enable Exclude Property Filters feature
    enableSidebarFilterExcludes: z.boolean().optional(),
    // Enable Segments in Metrics Page
    enableSegmentsInMetricsPage: z.boolean().optional(),
    // Enable Calendar switching
    enableCalendarSwitching: z.boolean().optional(),
    // Enable Metrics Categorization in Metrics Jstree
    enableMetricsCategorization: z.boolean().optional(),
    // Enable Sidebar
    enableSidebar: z.boolean().optional(),
    // Enable Properties Group by in Sidebar
    enableGroupByPropertiesInSidebar: z.boolean().optional(),
    // Enable Metrics Selection in Sidebar
    enableMetricsInSidebar: z.boolean().optional(),
});
export type IConfigExperiments = z.infer<typeof ConfigExperimentsSchema>;

// Note: Page enabling is done at routing level.

export interface IKpiItem {
    cellFilter?: string;
    fields?: string[];
    headerGroup?: string;
    headerName?: string;
    query?: string;
}

export interface IKpiFoundationItem {
    aggregate?: string;
    category?: string;
    cellFilter?: string;
    fields?: string[];
    headerGroup?: string;
    headerName?: string;
    query?: string;
}
export interface IKpiFoundationFieldItem extends IKpiFoundationItem {
    field: string;
}
export type IKpiFoundation = Record<string, IKpiFoundationItem>;
export interface IKpisCategoryOverrides {
    [categoryLabel: string]: Partial<IMetricDefinition>;
}

export type IKpisOverride = Omit<Partial<IMetricDefinition>, 'field'>;
export type IKpisOverrides = Record<string, IKpisOverride>;

export interface IKpisDefinitions {
    [key: string]: IKpiItem;
}

export interface IKpisFilter {
    label: string;
    metrics: string[];
    timerange:
        | string
        | {
              selection: { start: string; end: string };
              comparison?: { start: string; end: string };
          };
}

export interface IKpisFilters {
    [prefix: string]: IKpisFilter;
}

export interface IKpis {
    filters?: IKpisFilters;
    categoryOverrides?: IKpisCategoryOverrides;
    foundations?: {
        [foundationId: string]: {
            [key: string]: IKpiFoundation;
        };
    };
    definitions: IKpisDefinitions;
    overrides: IKpisOverrides;
}

export interface IOrganization {
    // Org Id
    id: string;
    // Org Name label
    label: string;
    // Org Logo Url
    logo?: string;
    // Org Handbook URL
    handbook?: unknown;
    // Org Email - Example: Support emails.
    email?: string;
}

export interface IConfigViewSalesChartGridOptions {
    showPercentage?: boolean;
    multiline?: boolean;
    maxNumberOfSlices?: number;
    groupSlicesThresholdValue?: number;
}

// Chart Grid Config (Small Pie/Bar charts) in Overview page
export interface IConfigViewSalesChartGrid {
    metrics?: (
        | string
        | {
              [x: string]: unknown;
              field: string;
          }
    )[];
    properties?: (
        | string
        | {
              [x: string]: unknown;
              field: string;
          }
    )[];
    type?: string;
    options?: IConfigViewSalesChartGridOptions;
}

// Top Items Widget displayed KPIS
export interface IConfigViewSalesTopItems {
    property?: string[];
    properties?: string[];
    kpis?: string[];
    sort?: string[] | null;
}

export interface IConfigViewsChartObj {
    kpis: string[];
    groupBy?: string;
}

export interface IConfigViewsObj {
    ads?: {
        kpis: string[];
    };

    chart: IConfigViewsChartObj;

    customers: {
        // Properties to be shown for each Customer
        properties: string[];
    };

    metrics: {
        kpis: string[];
        properties: string[];
    };

    // Overview Page
    sales: {
        // GRID KPIS highlighted at the top of the page
        kpis: string[];
        // Small Pie/Bar charts
        chartGrid?: IConfigViewSalesChartGrid[];
        // Top Items Widget displayed KPIS
        topItems?: IConfigViewSalesTopItems | IConfigViewSalesTopItems[];
        // Charts group by config data
        chart?: Record<string, Record<'groupBy', string>>;
    };
}

// IMPORTANT: Although, it is not represented in the config, Almost every field is OPTIONAL.
export interface IConfigObj {
    calendars: ICalendar[];
    timespanOverride?: { min_timestamp: string; max_timestamp: string };

    /** @see IConfigDefaults */
    defaults?: {
        maxTimestamp?: unknown;
        [x: string]: unknown;
    };

    // Application New Features Toggles
    experiments?: IConfigExperiments;

    // This is for the hierarchy select definition.
    hierarchies?: unknown[];

    items: {
        label: string;
        hierarchy: IHierarchy;
        /** @deprecated */
        hierarchy2: IHierarchy;
    };

    stores: {
        label: string;
        hierarchy: IHierarchy;
        /** @deprecated */
        hierarchy2: IHierarchy;
    };

    // All Organizations Metrics
    kpis: IKpis;

    // Map Page configuration
    map: {
        // Map Id for Request
        id: string;
        view: {
            // Starting point
            latitude: string;
            longitude: string;
            zoom: number;
        };
    };

    // Organization Info
    organization: IOrganization;

    // resolved in config-api.ts
    routes: Record<string, undefined | IConfigRoute>;
    services: ServicesConfig;

    // Pages Starting States or Configs
    views?: IConfigViewsObj;

    /** Fetch these using: config-flags.ts */
    flags: never;

    /** Fetch these using: config-access-control.ts */
    accessControl: never;

    supportLink: string;
    supportEmail: string;
}

export interface IMetricDefinition {
    field: string;
    headerGroup: string;
    headerName?: string;

    category?: string;
    cellFilter?: null | string;
    cellClass?: string;

    /** Field dependencies for the query */
    fields?: string[];

    /** Query for the metric, used for definitions */
    query?: string;
}

export interface IPropertyDefinition {
    table: string;
    column: string;
    type: string;
}
