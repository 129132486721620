import { react2angular } from "react2angular";

interface ICloseButtonProps { onClose: () => void; }

const ButtonClose = (props: ICloseButtonProps) => {
    return (
        <div className="button-close" onClick={props.onClose}>
            <i className="icon-cancel"></i>
            <i className="icon-cancel-circled button-close"></i>
        </div>
    );
}

const buttonCloseModule = angular
    .module('42.components.button-close-react', [])
    .component('buttonClose', react2angular(ButtonClose, ["onClose"]));

export default buttonCloseModule;
