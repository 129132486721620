import _ from 'lodash';
import zod from 'zod';
import { ConfigAPI } from './config-api';
import { isObject } from './utils';

const CalendarPeriod = zod.enum(['dtd', 'wtd', 'mtd', 'qtd', 'std', 'ytd'] as const);
type CalendarPeriod = zod.infer<typeof CalendarPeriod>;

export interface IConfigQueryModifiers {
    unitPriceGrossColumn?: string;
    calendarPeriods?: CalendarPeriod[];
}

export function parse(config: unknown): IConfigQueryModifiers {
    if (!isObject(config)) return {};

    const getCalendarPeriods = () => {
        const value = config.calendarPeriods;
        if (_.isNil(value)) return {};
        let calendarPeriods = CalendarPeriod.or(zod.array(CalendarPeriod)).parse(value);
        if (typeof calendarPeriods === 'string') calendarPeriods = [calendarPeriods];
        return { calendarPeriods };
    };

    const getUnitPriceGrossColumn = (): { unitPriceGrossColumn?: string } => {
        const value = config.unitPriceGrossColumn;
        if (typeof value !== 'string') return {};
        const unitPriceGrossColumn = zod.enum(['unit_price', 'hardmark']).safeParse(value);
        if (unitPriceGrossColumn.success) return { unitPriceGrossColumn: unitPriceGrossColumn.data };
        console.error('[config-modifiers] Unknown unitPriceGrossColumn:', value);
        return { unitPriceGrossColumn: value };
    };

    return {
        ...config,
        ...getCalendarPeriods(),
        ...getUnitPriceGrossColumn(),
    };
}

export function resolve(configs: { organization: unknown; user: unknown }): IConfigQueryModifiers {
    const org = isObject(configs.organization) ? configs.organization.modifiers : {};
    const usr = isObject(configs.user) ? configs.user.modifiers : {};
    return {
        ...parse(org),
        // TODO: This needs to be implemented on the scheduling service...
        // ...parse(usr),
    };
}

export async function fetch(overrides?: { user?: unknown; organization?: unknown }) {
    const [user, organization] = await Promise.all([
        overrides?.user ?? ConfigAPI.get().then(api => api.user.getInternal()),
        overrides?.organization ?? ConfigAPI.get().then(api => api.organization.get()),
    ]);
    return resolve({ user, organization });
}
