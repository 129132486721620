import Sortable from 'sortablejs';

export { Sortable };
export const createSortable = (element: Element | HTMLElement, options: Sortable.Options = {}) => {
    // Doing this for convenience...
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Sortable.create(element as HTMLElement, {
        animation: 100,
        delay: 200,
        delayOnTouchOnly: true,
        ...options,
    });
};
