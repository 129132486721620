import { StorageAPI, IStorageSlice } from '../lib/storage-user-config';

const StorageAPIFactory = () => [
    '$q',
    ($q: angular.IQService) =>
        <Put = unknown, Key extends string = string>(key: Key): angular.IPromise<IStorageSlice<Put>> => {
            try {
                return $q.when(StorageAPI<Put, Key>(key)).then(api => ({
                    get() {
                        return $q.when(api.get());
                    },
                    put(data: Put) {
                        return $q.when(api.put(data));
                    },
                }));
            } catch (error) {
                return $q.reject(error);
            }
        },
];

const module = angular.module('42.modules.storage', []);
module.factory('StorageAPI', StorageAPIFactory());
