import _ from 'lodash'

module = angular.module '42.controllers.transactions', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'transactions'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.service 'Transactions', (QueryServiceAPI) ->
    fetch: (query) -> QueryServiceAPI().then (api) ->
        api.query.transactionsReport(query)


module.directive 'transactionsTable', ($rootScope, promiseTracker, Utils, Transactions) ->
    restrict: 'E'
    replace: false
    template: \
    """
    <article class="ui-table supertable transactions-table" supertable sortable-container>
        <main>
            <div class="loadable" promise-tracker="transactionsTablePromiseTracker"></div>
            <table class="table-normal" infinite-scroll="loadMore()">
                <tbody>
                    <tr ng-repeat="d in model.data">
                        <td class="column-timestamp">{{ d.timestamp | date:'medium' }}</td>
                        <td class="column-customer">{{ d.customer_name }}</td>
                        <td class="column-total">{{ d.total | money }}</td>
                        <td class="column-item-count">{{ d.item_count | number }}</td>
                    </tr>
                </tbody>
            </table>
        </main>
        <header>
            <table class="table-normal">
                <thead>
                    <tr class="table-titles">
                        <th class="column-timestamp" sortable-property="timestamp">TIMESTAMP</th>
                        <th class="column-customer" sortable-property="customer_name">CUSTOMER</th>
                        <th class="column-total" sortable-property="total">TOTAL</th>
                        <th class="column-item-count" sortable-property="item_count">ITEM COUNT</th>
                    </tr>
                </thead>
            </table>
        </header>
        <footer>
            <table class="table-normal">
                <tfoot>
                    <tr>
                        <td class="column-paginator" colspan="4">Showing <em>{{ model.data.length }}</em> transactions</td>
                    </tr>
                </tfoot>
            </table>
        </footer>
    </article>
    """
    link: (scope) ->
        scope.transactionsTablePromiseTracker = promiseTracker()

        scope.model = {}
        scope.model.data = []

        refresh = ->
            console.log("refresh transaction page...")
            scope.model.data = []
            query = Utils.copy($rootScope.query or {})
            query.limit = 250
            query.sort = scope.sortable?.model or {timestamp:-1}
            # FIXME: promise race condition
            promise = Transactions.fetch(query).then (result) ->
                scope.model.data = result
            scope.transactionsTablePromiseTracker.addPromise(promise)
            return promise

        scope.$on '$destroy', \
        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            scope.$watch('sortable.model', refresh, true)
            unsub = $rootScope.$on('query.refresh', refresh)
            scope.$on('$destroy', unsub)


module.controller 'TransactionsController', ($scope) ->
    $scope.sortable = {}
    $scope.sortable.model = {timestamp:-1}
