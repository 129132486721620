const module = angular.module('42.directives.toggle-sidebar', []);
export default module;

interface ToggleSidebarDirectiveScope extends angular.IScope {
    model: IToggleSidebarModel;
    onClick(): void;
}
export interface IToggleSidebarModel {
    isOpen: boolean;
    animating: boolean;
}
module.directive('toggleSidebar', [
    (): angular.IDirective<ToggleSidebarDirectiveScope> => ({
        restrict: 'E',
        scope: {
            model: '=',
        },
        replace: true,
        template: `
        <div class="toggle-sidebar" ng-click="onClick($event)" ng-class="{'showing-sidebar': model.isOpen}">
            <i class="icon-right-open" ng-hide="!model.isOpen" ></i>
            <i class="icon-left-open" ng-hide="model.isOpen" ></i>
        </div>
        `,
        link(scope) {
            scope.onClick = () => {
                scope.model.isOpen = !scope.model.isOpen;
                scope.model.animating = true;
                const viewEl = document.querySelector('#view');
                if (!viewEl) return;
                const onTransitionEnd = () => {
                    scope.model.animating = false;
                    viewEl?.removeEventListener('transitionend', onTransitionEnd);
                    scope.$apply();
                };
                return viewEl?.addEventListener('transitionend', onTransitionEnd);
            };
        },
    }),
]);
