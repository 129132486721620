import 'trix'
import 'angular-trix'
import 'angular-elastic'
import _ from 'lodash'
import SchedulingCommonLibModule from '../scheduling-controller-common-lib'

module = angular.module('42.controllers.scheduling.tabs.schedules', [
    SchedulingCommonLibModule.name,
    'angularTrix'
    'monospaced.elastic'
])
export default module


module.directive 'scheduleTargetEditor', () ->
    restrict: 'E'
    scope:
        model: "="
    template: \
    """
    <article class="schedule-target-editor" ng-if="model">
        <main>
            <label class="target-recipients" ng-class="{error:emailModels.recipients.invalid}">
                <span>Recipients</span>
                <span class="hint">(required, enter a newline or comma for multiple addresses)</span>
                <span class="error-message" ng-if="emailModels.recipients.value.length > 0">Invalid email address(es)</span>
                <span class="error-message" ng-if="!emailModels.recipients.value.length">(required)</span>
                <multiline-email-editor model="emailModels.recipients"></multiline-email-editor>
            </label>
            <label class="target-bcc" ng-class="{error:emailModels.bcc.invalid && emailModels.bcc.value.length > 0}">
                <span>BCC</span>
                <span class="error-message">Invalid email address(es)</span>
                <multiline-email-editor model="emailModels.bcc"></multiline-email-editor>
            </label>
            <!--
            <label class="target-subject" ng-class="{error:invalid.subject}">
                <span>Subject</span>
                <span class="hint">(required)</span>
                <span class="error-message">(required)</span>
                <input type="text" ng-model="model.subject"></input>
            </label>
            -->
            <section class="target-body" ng-class="{error:invalid.body}">
                <h1>Note</h1>
                <trix-editor angular-trix trix-initialize="initialized(e, editor)" trix-change="bodyChanged(e, editor)" ng-model="model.body" class="trix-content"></trix-editor>
            </section>
        </main>
    </article>
    """
    link: (scope) ->
        editor = null

        scope.invalid = {}

        changed = {subject:false, body:false}

        scope.$watch 'model', (model) ->
            changed = {subject:false, body:false}
            scope.invalid = {body:false, subject:false}
            scope.emailModels =
                recipients: {invalid:false, changed:false, value:model?.recipients or ''}
                bcc:        {invalid:false, changed:false, value:model?.bcc or ''}

        scope.$watch 'emailModels.recipients.value', (value) ->
            scope.model?.recipients = value
        scope.$watch 'model.recipients', (value) ->
            return if not scope.emailModels
            scope.emailModels.recipients.value = value
        scope.$watch 'emailModels.bcc.value', (value) ->
            scope.model?.bcc = value
        scope.$watch 'model.bcc', (value) ->
            return if not scope.emailModels
            scope.emailModels.bcc.value = value

        scope.invalid = {recipients:false, body:false}
        scope.initialized = (event, editor) ->
            editor = editor.loadHTML(scope.model.body)
        scope.$watch 'model', (model) ->
            editor?.loadHTML(scope.model.body)
        scope.bodyChanged = (event, editor) ->
            changed.body = true
            scope.model.body = $(editor.element).html()


module.directive 'multilineEmailEditor', (EmailAddressMultiFieldParser) ->
    restrict: 'E'
    scope:
        model: "="
    template: \
    """
    <article class="multiline-email-editor">
        <textarea rows="1" msd-elastic ng-model="view"></textarea>
    </article>
    """
    link: (scope) ->
        scope.view = ""
        scope.$watch 'model.value', (emails) ->
            scope.view = do ->
                return null if not emails
                return emails if _.isString(emails)
                return emails?.join('\n') or null
        scope.$watch 'view', (view) ->
            scope.model.changed = scope.model.changed or (view and view.length > 0)
            scope.model.value = view
            scope.model.invalid = do ->
                return true if scope.model.changed and not view
                return _.some(EmailAddressMultiFieldParser.parse(view) or [], (x) -> not x.isValid) or false
