import _ from 'lodash';
import moment from 'moment';
import { IQuery, IQueryFilters, IQueryTimestampFilter } from '../types';
import { isObject } from '../utils';

// Note:
// We're using static methods here to make it easy to define overloads
// It would be better if it was an object...
export type TimerangeFilterFromTimerangeInput<T extends Date | moment.Moment | string = Date | moment.Moment | string> =
    {
        start: T;
        end: T;
    };

export class TimerangeFilter {
    static normalize(filter: unknown, granularity: any = 'second'): IQueryTimestampFilter {
        if (!isObject(filter)) {
            throw new Error('Missing required argument: filter');
        }
        const strip = (x: unknown) => {
            return typeof x === 'string' ? x.trim() : null;
        };
        const parse = ([toNormalizeKey, toLeaveAloneKey]: string[]) => {
            const toNormalize = strip(toNormalizeKey ? filter[toNormalizeKey] : null);
            const toLeaveAlone = strip(toLeaveAloneKey ? filter[toLeaveAloneKey] : null);
            const timestamp = (() => {
                if (toNormalize) return moment.utc(toNormalize).startOf(granularity).add(1, granularity);
                if (toLeaveAlone) return moment.utc(toLeaveAlone);
                throw new Error(`Invalid timestamp filter: must have ${toNormalizeKey} or ${toLeaveAloneKey}`);
            })();
            if (timestamp.isValid()) return timestamp.format('YYYY-MM-DD HH:mm:ss');
            throw new Error('Invalid timestamp filter value.');
        };
        const $gte = parse(['$gt', '$gte']);
        const $lt = parse(['$lte', '$lt']);
        return { $gte, $lt };
    }

    static isValidTimerange(timerange: unknown): timerange is TimerangeFilterFromTimerangeInput {
        const isTs = (x: unknown) => typeof x === 'string' || x instanceof Date || moment.isMoment(x);
        if (!isObject(timerange)) return false;
        const { start, end } = timerange;
        return isTs(start) && isTs(end);
    }

    static fromTimerange(timerange: TimerangeFilterFromTimerangeInput, granularity?: any): IQueryTimestampFilter;
    static fromTimerange(timerange: unknown, granularity?: any): IQueryTimestampFilter;
    static fromTimerange(timerange: unknown, granularity: any = 'day') {
        if (!TimerangeFilter.isValidTimerange(timerange)) {
            throw new Error('Invalid timerange; cannot convert to filter.');
        }
        return {
            $gte: moment.utc(timerange.start).format('YYYY-MM-DD HH:mm:ss'),
            $lt: moment.utc(timerange.end).add(1, granularity).format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    static toTimerange(filter: IQueryTimestampFilter, granularity?: any): TimerangeFilterFromTimerangeInput<string>;
    static toTimerange(filter: unknown, granularity?: any): TimerangeFilterFromTimerangeInput<string>;
    static toTimerange(filter: unknown, granularity: any = 'day'): TimerangeFilterFromTimerangeInput<string> {
        const normalized = TimerangeFilter.normalize(filter);
        return {
            start: moment.utc(normalized.$gte).format('YYYY-MM-DD HH:mm:ss'),
            end: moment.utc(normalized.$lt).subtract(1, granularity).format('YYYY-MM-DD HH:mm:ss'),
        };
    }
}

export const QueryTimestampComparison = {
    get(query: IQuery) {
        if (!query?.comparison?.timestamp) return null;
        return TimerangeFilter.normalize(query.comparison.timestamp);
    },
    set(query: IQuery, filter: null | unknown) {
        const timestamp = _.isNil(filter) ? null : TimerangeFilter.normalize(filter);
        if (timestamp && _.isEqual(timestamp, this.get(query))) return query;
        const comparison = timestamp ? { ...query?.comparison, timestamp } : null;

        if (!comparison || _.isEmpty(comparison?.timestamp)) {
            query = { ...query };
            delete query.comparison;
        } else {
            query = { ...query, comparison };
        }

        return query;
    },
};

export const QueryTimestampSelection = {
    get(query: IQuery) {
        try {
            if (!query?.filters?.transactions?.timestamp) return null;
            return TimerangeFilter.normalize(query.filters?.transactions?.timestamp);
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    set(query: IQuery, filter: unknown) {
        const timestamp = TimerangeFilter.normalize(filter);
        if (_.isEqual(timestamp, this.get(query))) return query;
        const transactions = { ...query?.filters?.transactions, timestamp };
        const filters: IQueryFilters = { ...query?.filters, transactions };
        query = { ...query, filters };
        if (!query?.filters?.transactions?.timestamp) delete query?.filters?.transactions?.timestamp;
        if (_.isEmpty(query?.filters?.transactions)) delete query?.filters?.transactions;
        if (_.isEmpty(query.filters)) delete query.filters;
        return query;
    },
};

const QueryModifiersFactory = (key: string) => ({
    set(query: IQuery, value: unknown) {
        if (!_.isNil(value) && query?.modifiers?.[key] === value) return query;
        const modifiers = { ...query?.modifiers, [key]: value };
        if (typeof modifiers[key] !== 'string') delete modifiers[key];
        query = { ...query, modifiers };
        if (_.isEmpty(query.modifiers)) delete query.modifiers;
        return query;
    },
});

export const QueryModifiersCalendarId = QueryModifiersFactory('calendar');
export const QueryModifiersCurrencyId = QueryModifiersFactory('currency');
export const QueryModifiersMaxTimestamp = QueryModifiersFactory('maxTimestamp');
