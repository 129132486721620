import { z } from 'zod';

export const GridPageViewMetaSchema = z.object({
    createdAt: z.number(),
    organizationId: z.string(),
    exportedByUserId: z.string(),
    type: z.literal('tab-grid'),
    version: z.number(),
});

export const GridPageViewDataSchema = z.object({
    id: z.string(),
    name: z.string(),
    views: z
        .object({
            panel: z
                .object({
                    active: z.boolean().nullish(),
                })
                .or(z.boolean())
                .optional(),
            images: z.boolean(),
        })
        .optional(),
    selected: z.object({
        metrics: z.array(z.string()),
        groupBy: z.string(),
        itemsGroupBy: z.string(),
        itemsSortBy: z.string(),
        itemsLimitBy: z.number(),
        itemsSortOrder: z.number(),
        itemsThemeLarge: z.boolean().optional(),
    }),
});

export const GridPageExportViewConfigSchema = z.object({
    meta: GridPageViewMetaSchema,
    data: GridPageViewDataSchema,
});

export type IGridPageExportTabViewConfig = z.infer<typeof GridPageExportViewConfigSchema>;
