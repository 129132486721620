const LOADING_CLASS = 'loading';

export const PromiseTrackerInstance = () => [
    '$interval',
    function PromiseTracker($interval: angular.IIntervalService): angular.IDirective {
        return {
            restrict: 'A',
            link: ($scope: angular.IScope, $element: angular.IRootElementService, $attributes: angular.IAttributes) => {
                let tracker: angular.promisetracker.PromiseTracker;

                $scope.$watch(
                    $attributes.promiseTracker,
                    (promiseTrackerInstance: angular.promisetracker.PromiseTracker) => {
                        if (promiseTrackerInstance) {
                            tracker = promiseTrackerInstance;
                        }
                    },
                );

                const createIntervalTracker = () => {
                    let oldActive: boolean | undefined | null = null;

                    return () => {
                        const active = tracker && tracker.active && tracker.active();
                        const changed = active !== oldActive;
                        if (changed) {
                            active
                                ? $element[0]?.classList.add(LOADING_CLASS)
                                : $element[0]?.classList.remove(LOADING_CLASS);
                        }
                        oldActive = active;
                    };
                };

                const promise = $interval(createIntervalTracker(), 100);
                $scope.$on('$destroy', () => $interval.cancel(promise));
            },
        };
    },
];
