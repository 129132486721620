import _ from 'lodash';
import { AngularInjected } from '../../lib/angular';
import { IQuery, IMetricDefinition } from '../../lib/types';
import { IQueryServiceAPI } from '../../modules/services/query-service.types';
import { IMetricDefinitionExtended, remapData } from './ads.helper';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import { ConfigAPI } from '../../lib/config-api';
import { z } from 'zod';
import { IMetricsFunnelRowData } from '../metrics/metrics-grid.directive';

const AdsPlatformGridConfigSchema = z.object({
    sortBy: z.string(),
    gridLimit: z.number().optional(),
});
export type AdGridConfig = z.infer<typeof AdsPlatformGridConfigSchema>;

const AdsChartConfigSchema = z.object({ kpis: z.array(z.string()) });
export type AdsChartConfig = z.infer<typeof AdsChartConfigSchema>;

const AdsPlatformConfigSchema = z.object({
    property: z.string(),
    propertyFilter: z.string(),
    filterValue: z.string().optional(),
    groupBy: z.string(),
    chart: AdsChartConfigSchema,
    kpis: z.array(z.string()),
    grid: AdsPlatformGridConfigSchema,
});
export type AdsPlatformConfig = z.infer<typeof AdsPlatformConfigSchema>;

const AdsConfigSchema = z.object({
    platforms: z.record(AdsPlatformConfigSchema),
});
export type AdsConfig = z.infer<typeof AdsConfigSchema>;

const ViewsAdsConfigSchema = z.object({
    views: z.object({
        ads: z.record(z.any()),
    }),
});

const parseAdsConfig = (config: unknown): null | AdsConfig => {
    const parsedConfig = ViewsAdsConfigSchema.safeParse(config);
    if (parsedConfig.success) {
        const parsedAdsConfig = AdsConfigSchema.safeParse(parsedConfig.data.views.ads);
        if (parsedAdsConfig.success) return parsedAdsConfig.data;
    }
    return null;
};

export const fetchAdsConfig = (): Promise<AdsConfig | null> => {
    const configPromise = ConfigAPI.get();

    return Promise.all([
        configPromise.then(config => config.user.getInternal()),
        configPromise.then(config => config.organization.get()),
    ]).then(([user, organization]) => {
        return parseAdsConfig(user) ?? parseAdsConfig(organization);
    });
};

export const AdsServiceFactory = () => [
    '$filter',
    '$q',
    'QueryServiceAPI',
    function AdsService($filter: angular.IFilterService, $q: angular.IQService, QueryServiceAPI: IQueryServiceAPI) {
        const fetchStats = (
            availableMetrics: IMetricDefinition[],
            metrics: IMetricDefinitionExtended[],
            config: AdsPlatformConfig,
            property: IPropertyDefinition,
            rootQuery: IQuery,
        ) => {
            return QueryServiceAPI().then(api => {
                const query = _.cloneDeep(rootQuery);
                query.options ??= {};
                query.options.includeTotals = false;
                query.options.metrics = availableMetrics.map(metric => metric.field);
                query.filters ??= {};
                if (property.table && property.column) {
                    query.filters[property.table] = {
                        [property.column]: config.filterValue,
                    };
                }

                return api.query.metricsFunnel(query).then(data => {
                    data = (Array.isArray(data) ? data[0] : data) ?? {};

                    return remapData($filter, metrics, data);
                });
            });
        };

        const buildQuery = (
            rootQuery: IQuery,
            property: IPropertyDefinition,
            config: AdsPlatformConfig,
            availableMetrics: IMetricDefinition[],
        ) => {
            const GRID_ROWS_LIMIT = 5;
            const query = _.cloneDeep(rootQuery);
            const timestamp = query.filters?.transactions?.timestamp;
            const {
                filterValue,
                groupBy,
                grid: { sortBy, gridLimit },
            } = config;

            query.filters = query.filters ?? {};
            if (property.table && property.column) {
                query.filters[property.table] = {
                    [property.column]: filterValue,
                };
            }

            query.modifiers ??= {};
            query.options ??= {};
            query.options.property = groupBy;
            query.options.sort = [{ field: sortBy, order: -1 }];
            query.options.metrics = availableMetrics.map(metric => metric.field);
            query.limit = (gridLimit ?? GRID_ROWS_LIMIT) + 1;

            if (timestamp) {
                query.filters.transactions = { timestamp };
            }

            return query;
        };

        const fetchGrid = (
            availableMetrics: IMetricDefinition[],
            config: AdsPlatformConfig,
            property: IPropertyDefinition,
            rootQuery: IQuery,
        ) => {
            return QueryServiceAPI().then(api => {
                const query = buildQuery(rootQuery, property, config, availableMetrics);

                return api.query.metricsFunnel(query).then(data => {
                    if (!Array.isArray(data)) return null;
                    // Remove total line
                    data.shift();
                    const result = data.reduce<IMetricsFunnelRowData>(
                        (acc, row) => {
                            acc.rows.push(row);
                            return acc;
                        },
                        { rows: [], total: null },
                    );

                    if (property.type) {
                        result.rows.forEach(row => {
                            if (property.type === 'numeric') {
                                const parsed = Number(row.property0);
                                if (!Number.isNaN(parsed)) {
                                    row.property0 = parsed;
                                }
                            }
                        });
                    }

                    return result;
                });
            });
        };

        return {
            fetchStats,
            fetchGrid,
            fetch: (
                availableMetrics: IMetricDefinition[],
                metrics: IMetricDefinitionExtended[],
                config: AdsPlatformConfig,
                property: IPropertyDefinition,
                query: IQuery,
            ) => {
                return $q
                    .all([
                        fetchStats(availableMetrics, metrics, config, property, query),
                        fetchGrid(availableMetrics, config, property, query),
                    ])
                    .then(([stats, grid]) => ({
                        stats,
                        grid,
                    }));
            },
        };
    },
];
export type IAdsService = AngularInjected<typeof AdsServiceFactory>;
