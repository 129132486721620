import _ from 'lodash';
import GEO_JSON from './map-usa.geo.json';
export { GEO_JSON };

export const MAP_NAME = 'USA';

export const SPECIAL_AREAS: Record<string, Record<string, number>> = {
    'Alaska': {
        left: -131,
        top: 25,
        width: 15,
    },
    'Hawaii': {
        left: -110,
        top: 28,
        width: 5,
    },
    'Puerto Rico': {
        left: -76,
        top: 26,
        width: 2,
    },
} as const;

// prettier-ignore
export const STATES: Record<string, string> = {
    'Alabama'                  : 'AL',
    'Alaska'                   : 'AK',
    'Arizona'                  : 'AZ',
    'Arkansas'                 : 'AR',
    'California'               : 'CA',
    'Colorado'                 : 'CO',
    'Connecticut'              : 'CT',
    'Delaware'                 : 'DE',
    'Florida'                  : 'FL',
    'Georgia'                  : 'GA',
    'Hawaii'                   : 'HI',
    'Idaho'                    : 'ID',
    'Illinois'                 : 'IL',
    'Indiana'                  : 'IN',
    'Iowa'                     : 'IA',
    'Kansas'                   : 'KS',
    'Kentucky'                 : 'KY',
    'Louisiana'                : 'LA',
    'Maine'                    : 'ME',
    'Maryland'                 : 'MD',
    'Massachusetts'            : 'MA',
    'Michigan'                 : 'MI',
    'Minnesota'                : 'MN',
    'Mississippi'              : 'MS',
    'Missouri'                 : 'MO',
    'Montana'                  : 'MT',
    'Nebraska'                 : 'NE',
    'Nevada'                   : 'NV',
    'New Hampshire'            : 'NH',
    'New Jersey'               : 'NJ',
    'New Mexico'               : 'NM',
    'New York'                 : 'NY',
    'North Carolina'           : 'NC',
    'North Dakota'             : 'ND',
    'Ohio'                     : 'OH',
    'Oklahoma'                 : 'OK',
    'Oregon'                   : 'OR',
    'Pennsylvania'             : 'PA',
    'Rhode Island'             : 'RI',
    'South Carolina'           : 'SC',
    'South Dakota'             : 'SD',
    'Tennessee'                : 'TN',
    'Texas'                    : 'TX',
    'Utah'                     : 'UT',
    'Vermont'                  : 'VT',
    'Virginia'                 : 'VA',
    'Washington'               : 'WA',
    'West Virginia'            : 'WV',
    'Wisconsin'                : 'WI',
    'Wyoming'                  : 'WY',
    'American Samoa'           : 'AS',
    'District of Columbia'     : 'DC',
    'Guam'                     : 'GU',
    'Northern Mariana Islands' : 'MP',
    'Puerto Rico'              : 'PR',
    'Virgin Islands'           : 'VI',
} as const;

const STATES_SHORT = _.invert(STATES);

export const getStateFullName = (state: string): string | undefined => {
    if (state in STATES) return state;
    return STATES_SHORT[state];
};
