import _ from 'lodash'
import moment from 'moment'
import { isObject } from '../../lib/utils/utils-object'
import { deepStripAngularProperties } from '../../lib/angular'

module = angular.module('42.main.status-message-bar', [])
export default module

module.directive 'statusMessageBar', ['StatusMessageBarModel', (StatusMessageBarModel) ->
    restrict: "E"
    scope: true
    replace: true
    template: \
    """
    <div class="status-message-bar"
        ng-show="!model.state.isInvalid"
        ng-class="{'active': model.state.active}"
    >
        <div class="status status-{{ model.state.type }}" ng-click="onClick()">
            <i class="icon-status {{ model.state.icon }}"></i>
            <span class="message">{{ model.state.message }}</span>
            <i class="icon-close">
                <svg viewBox="0 0 32 32"><polygon points="28.374,5.747 26.253,3.626 16,13.879 5.747,3.626 3.626,5.747 13.879,16 3.626,26.253 5.747,28.374 16,18.121 26.253,28.374 28.374,26.253 18.121,16 "></polygon></svg>
            </i>
        </div>
    </div>
    """
    link: (scope) ->
        scope.model = StatusMessageBarModel
        scope.onClick = ->
            return if scope.model.state.isLoading
            scope.model.hide()
            return
]

module.directive 'statusMessageBarButton', ['$rootScope', '$location', 'ROUTES', 'StatusMessageBarModel', ($rootScope, $location, ROUTES, StatusMessageBarModel) ->
    restrict: "E"
    scope: true
    replace: true
    template: \
    """
    <div
        class="status-button status-{{ model.state.type }}"
        ng-show="!(model.state.isInvalid || model.state.isLoading)"
        ng-class="{'active':model.state.active, 'mobile': isMobile}"
        ng-click="onClick()"
    >
        <a href="#">
            <i class="{{ model.state.icon }}"></i>
            <span class="label">Status</span>
        </a>
    </div>
    """
    link: (scope) ->
        scope.model = StatusMessageBarModel
        scope.onClick = ->
            StatusMessageBarModel.toggle()
            return if not $rootScope.flags?.showStoreStatus
            $location.path(ROUTES.storestatus.url)
            return
]

module.service 'StatusMessageBarModel', [() ->

    STATE_VERSION = 0
    STORAGE_KEY = '42.views.status-message-bar.state'

    MESSAGE_TYPES = {'error', 'warning', 'success', 'notification'}

    MESSAGE_TYPES_TO_ICON = do ->
        result = {}
        result[MESSAGE_TYPES.error]        = "icon-thumbs-down"
        result[MESSAGE_TYPES.warning]      = "icon-alert"
        result[MESSAGE_TYPES.notification] = "icon-thumbs-up"
        result[MESSAGE_TYPES.success]      = "icon-thumbs-up"
        return result

    loadState = ->
        try
            savedState = localStorage.getItem(STORAGE_KEY)
            return null if not savedState
            state = try JSON.parse(savedState)
            return null if not isObject(state)
            state = _.pick(state, 'id', 'active', 'version')
            return null if not state.version is STATE_VERSION
            return deepStripAngularProperties(state)
        catch error
            console.error('failed to load status message state:', error)
            return null

    saveState = (state) ->
        try
            data = JSON.stringify(_.pick(state, 'id', 'active', 'version'))
            localStorage.setItem(STORAGE_KEY, data)
        catch error
            console.error('failed to save status message state:', error)
            return


    class StatusMessageBarModel

        constructor: (state) ->
            @state = {
                version   : STATE_VERSION
                id        : null
                message   : null
                type      : null
                timestamp : null
                icon      : null
                active    : false
                isLoading : false
                isInvalid : true
                ...state
            }

        show: ->
            @state.active = true
            return

        toggle: ->
            @state.active = not @state.active
            saveState(@state)
            return

        hide: ->
            @state.active = false
            return

        save: (state) ->
            @state = state
            saveState(state)
            return

        load: ->
            return loadState()

        set: (status) ->

            loadedState = {
                ...(loadState() ? {}),
                id     : @state.id     ? null
                active : @state.active ? null
            }

            statusMessage = status.message or do ->
                return if status.messageType isnt MESSAGE_TYPES.success
                if status.latestTransactionTimestamp
                    # FIXME: AllSaints-specific code / hack for realtime...
                    timestamp = moment(status.latestTransactionTimestamp).format('MMM Do [at] h:mm a')
                    return "The most recent transaction is from #{timestamp} BST"
                if status.load.end
                    timestamp = moment(status.load.end).format('MMM Do [at] h:mm a')
                    return "Data has been refreshed successfully on #{timestamp}"
                return

            isInvalid = do ->
                return not status.messageId

            return @save
                id        : status.messageId
                type      : status.messageType
                version   : STATE_VERSION
                icon      : MESSAGE_TYPES_TO_ICON[status.messageType]
                message   : statusMessage
                isLoading : status.isLoading
                isInvalid : isInvalid
                active    : do =>
                    return false if isInvalid
                    return false if not statusMessage
                    return true  if loadedState.active and not status.isLoading
                    return false if status.messageType is MESSAGE_TYPES.success
                    return false if status.messageType is MESSAGE_TYPES.notification
                    isNewMessage = loadedState.id isnt status.messageId
                    isNewMessage or= @state.message isnt statusMessage
                    isNewMessage or= @state.type isnt status.messageType
                    return isNewMessage or status.isLoading

    return new StatusMessageBarModel()
]
