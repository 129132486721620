import { createSortable } from '../../lib/dom/sortable';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import {
    ISmartGroupState,
    ISmartGroupsViewModel,
    SmartGroupFilterViewModel,
    SmartGroupViewModel,
} from './smart-groups.service';
import { isHTMLElement } from '../../lib/dom/html';
import * as Analytics from '../../lib/analytics';
import { ToggleModel } from '../../lib/model/model-toggle';
import { USER_IMPORT_SEGMENT, USER_IMPORT_SEGMENT_FAILED } from '../../lib/analytics/events';
import { IPromise } from 'angular';
import { DragAndDropExternalAPI } from '../../components/drag-and-drop';

export interface IGroupFilterViewModel {
    label: string;
    selected: boolean;
    isActive: boolean;
    select: () => void;
}

export interface IGroupViewModel {
    name?: string;
    selected?: boolean;
    select?: () => void;
    save?: (name: string) => void;
    delete?: () => void;
    filters?: IGroupFilterViewModel[];
}

interface SmartGroupsPanelDirectiveScope extends angular.IScope, DragAndDropExternalAPI {
    model: ISmartGroupsViewModel;
    openTabImportPopup: ($event: Event) => void;
    createTabToggleDropdown: ToggleModel;
    createTabDropdownItems: { title: string; onClick: (event: Event) => void }[];
    org: string;
}
export const SmartGroupsPanelDirective = (module: angular.IModule) =>
    module.directive('smartGroupsPanel', [
        'OutsideElementClick',
        (OutsideElementClick: IOutsideElementClick): angular.IDirective<SmartGroupsPanelDirectiveScope> => ({
            restrict: 'E',
            scope: {
                model: '=',
                org: '=',
                experiments: '=',
            },
            replace: true,
            template: `
            <div class="smart-groups-panel" drag-and-drop-zone>
                <div class="smart-group-actions">
                    <div class="ui-button smart-group-action-create" ng-click="model.create()">
                        <i class="icon-plus-circled"></i>
                        <span>Create New Segment</span>
                    </div>
                    <div class="ui-button smart-group-action-import"
                        ng-class="{'active': createTabToggleDropdown.isActive }"
                        ng-click="createTabToggleDropdown.toggle()">
                        <i class="icon-down-open"></i>
                        <div class="create-tab-dropdown-container">
                            <div class="create-tab-dropdown-item"
                                ng-repeat="item in createTabDropdownItems"
                                ng-click="item.onClick($event)">{{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="smart-groups sortable-ui">
                    <li class="smart-group-container" ng-repeat="group in model.groups.view.available track by group.id">
                        <smart-groups-panel-item model="group.model"></smart-groups-panel-item>
                    </li>
                </ul>
            </div>
            `,
            link: (scope, element: angular.IRootElementService) => {
                scope.createTabToggleDropdown = new ToggleModel();
                scope.createTabDropdownItems = [
                    {
                        title: 'Import',
                        onClick: (event: Event) => {
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            scope.openTabImportPopup(event);
                            scope.createTabToggleDropdown.close();
                        },
                    },
                    {
                        title: 'Export All',
                        onClick: (event: Event) => {
                            event.preventDefault();
                            event.stopImmediatePropagation();
                            scope.model.exportAll();
                            scope.createTabToggleDropdown.close();
                        },
                    },
                ];

                let unsubOutsideDropdownClick = () => {};
                scope.$watch('createTabToggleDropdown.isActive', (isActive: boolean) => {
                    unsubOutsideDropdownClick();
                    if (isActive) {
                        unsubOutsideDropdownClick = OutsideElementClick(
                            scope,
                            element.find('.ui-button.smart-group-action-import'),
                            () => scope.createTabToggleDropdown.close(),
                        );
                    }
                });

                const listElement = element[0]?.querySelector<HTMLElement>('.smart-groups');
                if (!listElement) throw new Error('Could not find list element');

                // We add a class when we add a new group to trigger a css transition...
                const observer = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        if (mutation.type !== 'childList') continue;
                        mutation.addedNodes.forEach(node => {
                            if (!isHTMLElement(node)) return;
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const el = node.querySelector<HTMLElement>('.smart-group');
                            if (node.attributes.getNamedItem('draggable')?.value === 'true') return;
                            // scroll to newly added node
                            el?.classList.add('new');
                            setTimeout(() => {
                                listElement.scrollTop = node.parentElement?.parentElement?.offsetTop ?? 0;
                                el?.classList.remove('new');
                            });
                        });
                    }
                });
                const observe = () => {
                    observer?.disconnect();
                    observer.observe(listElement, { childList: true });
                };
                observe();
                scope.$on('$destroy', () => observer.disconnect());

                const sortable = createSortable(listElement, {
                    ghostClass: 'placeholder',
                    draggable: '.smart-group-container',
                    filter: '.edit',
                    preventOnFilter: false,
                    onStart() {
                        observer.disconnect();
                    },
                    onEnd(event) {
                        setTimeout(observe, 0);
                        const prev = event.oldIndex;
                        const curr = event.newIndex;
                        if (typeof prev !== 'number' || typeof curr !== 'number') {
                            throw new Error(
                                `Cannot re-order groups: prev=${prev ?? 'not defined'}, curr=${curr ?? 'not defined'}`,
                            );
                        } else {
                            scope.model.reorder(prev, curr);
                            scope.$apply();
                        }
                    },
                });

                const dnd = scope.fillDragAndDropZoneExternalAPI({
                    onError: (error: unknown) => {
                        Analytics.track(USER_IMPORT_SEGMENT_FAILED, { error });
                    },
                    onFile: (file: unknown) => {
                        const data = scope.model.ValidateViewConfigFile(file, scope.org);
                        scope.model.import(data);
                        Analytics.track(USER_IMPORT_SEGMENT, { view: data });
                    },
                });

                scope.openTabImportPopup = ($event: Event) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    dnd.openUploadPopup();
                    return true;
                };

                scope.$on('$destroy', () => sortable.destroy());
            },
        }),
    ]);

interface SmartGroupsPanelCompactDirectiveScope extends angular.IScope {
    model: ISmartGroupsViewModel;
    onFunnelClick: ($event: MouseEvent) => void;
    addNewSegment: ($event: MouseEvent) => void;
    view: {
        propertyFiltersPopup: ToggleModel;
        editDropdown: ToggleModel;
    };
    toggleEditDropdown: () => void;
    isFunnelActive: () => void;
    actionClick: ($event: MouseEvent, item: ISmartGroupFilterDropdownActions) => void;
    onDescriptorClick: (segmentFilter: SmartGroupFilterViewModel) => void;
    groupModel: ISmartGroupState | undefined;
    segmentDropdownActions: {
        title: string;
        className?: string;
        action: () => void;
    }[];
}
export const SmartGroupsPanelCompactDirective = (module: angular.IModule) => {
    return module.directive('smartGroupsPanelCompact', [
        '$timeout',
        'OutsideElementClick',
        (
            $timeout: angular.ITimeoutService,
            OutsideElementClick: IOutsideElementClick,
        ): angular.IDirective<SmartGroupsPanelCompactDirectiveScope> => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="compact-group-select" ng-class="{'edit-mode': editMode.draft }">
                    <div class="compact-group-select-dropdown segments icon-selector"
                        ng-if="!editMode.draft">
                        <div class="icon-new-segment" ng-click="addNewSegment($event)">
                            <i class="icon-plus-circled"></i>
                        </div>
                    </div>
                    <div class="compact-group-select-segments"
                        ng-class="{'descriptor-popup-active': view.propertyFiltersPopup.isActive}"
                        ng-if="!editMode.draft">
                        <div class="compact-group-select-dropdown icon-selector funnel-action"
                            ng-class="{ active: isFunnelActive() }">
                            <div class="icon-selector-funnel"
                                ng-class="{'opened': view.propertyFiltersPopup.isActive}" }"
                                ng-click="onFunnelClick($event)">
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" id="entypo-funnel" width="16" height="16" fill="currentColor"><g><path d="M10 1C5.092 1 2 2.512 2 4.001v2c0 .918 6 6 6 6v6c-.001.684 1 1 2 1s2.001-.316 2-1v-6s6-5.082 6-6v-2C18 2.512 14.908 1 10 1zm0 5.123C6.409 6.122 3.862 4.79 3.862 4.292 3.86 3.797 6.41 2.461 10 2.463c3.59-.002 6.14 1.334 6.138 1.828 0 .499-2.547 1.831-6.138 1.832z"/></g></svg>
                            </div>
                        </div>
                        <article class="property-filters-descriptor-popup">
                            <div class="property-filters-descriptor-content"
                                ng-repeat="filter in groupModel.filters"
                                ng-class="{selected: !filter.isEmpty}"
                                ng-click="onDescriptorClick(filter)">
                                <div class="content-label">
                                    {{ filter.descriptor.label }}
                                </div>
                            </div>
                        </article>
                        <div class="compact-group-select-dropdown">
                            <select
                                ng-options="x as x.model.name for x in model.groups.view.available"
                                ng-model="model.groups.view.selected"
                            >
                            </select>
                        </div>
                        <div class="compact-group-select-dropdown icon-selector edit-button actions"
                            ng-class="{active: view.editDropdown.isActive}">
                            <div class="icon-selector-edit"
                                ng-click="toggleEditDropdown(model)">
                                <i class="icon-dot-3-vertical"></i>
                            </div>
                            <div ng-if="view.editDropdown.isActive" class="segments-edit-items-container">
                                <div class="segments-edit-item {{ item.className || '' }}"
                                    ng-repeat="item in segmentDropdownActions"
                                    ng-click="actionClick($event, item)">{{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                `,
            link: (scope, element) => {
                const highlightElement = (elementClass: string) => {
                    const elementToHighlight = element.find(elementClass);
                    if (elementToHighlight.hasClass('shadow')) return;
                    elementToHighlight.addClass('shadow');
                    void $timeout(() => {
                        elementToHighlight.removeClass('shadow');
                    }, 100);
                };

                scope.addNewSegment = $event => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    scope.model.create();

                    highlightElement('.compact-group-select-segments');
                };

                scope.groupModel = undefined;
                scope.view = {
                    propertyFiltersPopup: new ToggleModel(),
                    editDropdown: new ToggleModel(),
                };
                scope.toggleEditDropdown = () => {
                    scope.view.editDropdown.toggle();
                };

                scope.actionClick = ($event, item) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    item.action();
                    scope.view.editDropdown.close();
                };

                scope.segmentDropdownActions = [
                    {
                        title: 'RENAME',
                        action: () => {
                            const segmentName = scope.model.groups.view.selected.model.name;
                            const name = window.prompt('Please enter a new name', segmentName);

                            if (name === null || name === segmentName) return;
                            scope.model.groups.view.selected.model.update({ name });
                        },
                    },
                    {
                        title: 'DUPLICATE',
                        action: () => {
                            scope.model.groups.view.selected.model.duplicate();
                        },
                    },
                    {
                        title: 'DELETE',
                        className: 'delete',
                        action: () => {
                            const confirmed = window.confirm(
                                `Are you sure you want to delete ${scope.model.groups.view.selected.model.name} view?\nThis action cannot be un-done.`,
                            );
                            if (!confirmed) return;

                            void $timeout(() => {
                                scope.model.groups.view.selected.model.delete();
                                highlightElement('.compact-group-select-segments');
                            }, 333);
                        },
                    },
                ];

                let unsubEditDropdown: null | (() => void) = null;
                scope.$watch('view.editDropdown.isActive', (isActive: boolean) => {
                    if (unsubEditDropdown) unsubEditDropdown();
                    if (!isActive) return;
                    const dropdownElement = element.find('.actions');
                    unsubEditDropdown = OutsideElementClick(scope, dropdownElement, () => {
                        scope.view.editDropdown.close();
                    });
                });

                scope.isFunnelActive = () => {
                    if (scope.view.propertyFiltersPopup.isActive) return true;
                    const selected = scope.model.model.view?.getSelected();
                    if (!selected) return false;

                    return Boolean(selected.filters?.some(filter => !filter.isEmpty));
                };

                scope.$watch('model.groups.view.selected.id', (id: undefined | string) => {
                    if (typeof id !== 'string') return;
                    scope.model.selectGroup(id);
                });

                scope.onDescriptorClick = (segmentFilter: SmartGroupFilterViewModel) => {
                    scope.view.propertyFiltersPopup.close();
                    segmentFilter.select();
                };

                scope.onFunnelClick = $event => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();

                    if (!scope.view.propertyFiltersPopup.isActive) {
                        scope.groupModel = scope.model.model.view?.getSelected();
                    }

                    scope.view.propertyFiltersPopup.toggle();
                };

                let elementClick: () => void = () => {};
                scope.$watch('view.propertyFiltersPopup.isActive', (isActive: boolean) => {
                    elementClick();
                    if (!isActive) return;
                    const dropdownElement = element.find('.funnel-action, .property-filters-descriptor-popup');
                    elementClick = OutsideElementClick(scope, dropdownElement, () =>
                        scope.view.propertyFiltersPopup.close(),
                    );
                });
            },
        }),
    ]);
};

export interface ISmartGroupFilterDropdownActions {
    title: string;
    className?: string;
    action: () => void;
}

export class EditModeViewModel {
    draft: null | { name: string } = null;
    enable(model: SmartGroupViewModel) {
        this.draft = { name: model.name || '' };
    }
    disable() {
        this.draft = null;
    }
}

export const SmartGroupsItemDirective = (module: angular.IModule) => {
    interface Scope extends angular.IScope {
        isNew: boolean;
        isDeleting: boolean;
        isSelected: boolean;
        toggle: ToggleModel;
        model: SmartGroupViewModel;
        editMode: EditModeViewModel;
        select: () => void;
        save: () => void;
        smartGroupFilterDropdownActions: ISmartGroupFilterDropdownActions[];
    }
    return module.directive('smartGroupsPanelItem', [
        '$timeout',
        'OutsideElementClick',
        ($timeout: angular.ITimeoutService, OutsideElementClick: IOutsideElementClick): angular.IDirective<Scope> => ({
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div
                    class="smart-group"
                    ng-click="select()"
                    ng-class="{
                        remove: isDeleting,
                        selected: isSelected,
                        edit: editMode.draft
                    }"
                >
                    <header class="smart-group-info">
                        <section class="smart-group-info-normal animation-fade" ng-if="!editMode.draft">
                            <h1 class="smart-group-name" ng-dblclick="editMode.enable(model)">
                                <i class="icon-right-open-mini"></i>
                                <span>{{ model.name }}</span>
                            </h1>
                            <button-smart-group-edit-dropdown
                                model="smartGroupFilterDropdownActions"
                                toggle="toggle"
                                ng-if="isSelected"
                            ></button-smart-group-edit-dropdown>
                        </section>
                        <section class="smart-group-info-edit animation-fade" ng-if="editMode.draft">
                            <div class="smart-group-edit-actions animation-slide-down">
                                <button class="button-yellow smart-group-edit-save-button" ng-click="save($event)">rename</button>
                                <button class="smart-group-edit-cancel-button" ng-click="editMode.disable()">cancel</button>
                            </div>
                            <input
                                focus
                                type="text"
                                ng-model="editMode.draft.name"
                                placeholder="{{ model.name }}"
                                ng-enter="save()"
                                ng-esc="editMode.disable()"
                            />
                        </section>
                    </header>
                    <ul class="smart-group-filters">
                        <li
                            class="smart-group-filter"
                            ng-repeat="filter in model.filters track by filter.id"
                            ng-click="filter.select()"
                            ng-class="{selected: filter.isEditing, active: !filter.isEmpty}"
                        >
                            <span>{{ filter.descriptor.label }}</span>
                        </li>
                    </ul>
                </div>
            `,
            link: (scope, $element) => {
                scope.editMode = new EditModeViewModel();
                scope.toggle = new ToggleModel();

                scope.isSelected = false;
                scope.isDeleting = false;
                scope.$watch('model.parent.groups.view.selected.id', id => {
                    const selected = scope.model?.id === id;
                    void $timeout(() => (scope.isSelected = selected), 0);
                });

                let timeoutPromise: null | IPromise<JQLite> = null;
                const addHiddenClassForAnimation = () => {
                    $element.addClass('hidden');
                    if (timeoutPromise) $timeout.cancel(timeoutPromise);
                    timeoutPromise = $timeout(() => $element.removeClass('hidden'), 5000);
                };

                scope.$watch('toggle.isActive', (isOpened: boolean) => {
                    if (isOpened) {
                        if (timeoutPromise) $timeout.cancel(timeoutPromise);
                        $element.removeClass('hidden');
                    }
                });

                scope.select = () => {
                    if (scope.editMode.draft) return;
                    if (scope.isSelected) return;
                    if (scope.isDeleting) return;
                    addHiddenClassForAnimation();
                    scope.editMode.disable();
                    scope.model.select();
                };

                scope.save = () => {
                    if (scope.editMode.draft && scope.editMode.draft.name !== scope.model.name) {
                        const name = scope.editMode.draft.name;
                        scope.model.update({ name });
                    }
                    scope.editMode.disable();
                };

                scope.smartGroupFilterDropdownActions = [
                    {
                        title: 'RENAME',
                        action: () => {
                            scope.editMode.enable(scope.model);
                        },
                    },
                    {
                        title: 'DUPLICATE',
                        action: () => {
                            scope.editMode.disable();
                            scope.model.duplicate();
                        },
                    },
                    {
                        title: 'EXPORT',
                        action: () => {
                            scope.editMode.disable();
                            scope.model.export();
                        },
                    },
                    {
                        title: 'DELETE',
                        className: 'delete',
                        action: () => {
                            scope.isDeleting = true;
                            scope.editMode.disable();

                            const confirmed = window.confirm(
                                `Are you sure you want to delete this SEGMENT?\nThis action cannot be un-done.`,
                            );

                            if (!confirmed) {
                                scope.isDeleting = false;
                                return;
                            }

                            void $timeout(() => {
                                scope.isDeleting = false;
                                scope.model.delete();
                            }, 333);
                        },
                    },
                ];

                let unsub: null | (() => void);
                scope.$watch('editMode.draft', (draft: never) => {
                    if (unsub) unsub();
                    if (!draft) return;
                    unsub = OutsideElementClick(scope, $element.find('.smart-group-info .smart-group-info-edit'), () =>
                        scope.editMode.disable(),
                    );
                });
            },
        }),
    ]);
};

export const SmartGroupEditDropdownDirective = (module: angular.IModule) =>
    module.directive('buttonSmartGroupEditDropdown', [
        'OutsideElementClick',
        (OutsideElementClick: IOutsideElementClick) => ({
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
            <div class="button-smart-group-edit">
                <div
                    class="smart-group-edit-button"
                    ng-class="{ opened: toggle.isActive }"
                    ng-click="toggleDropdown()">
                    <i class="icon-dot-3-vertical"></i>
                </div>
                <div ng-if="toggle.isActive" class="smart-group-edit-items-container">
                    <div class="smart-group-edit-item {{ item.className || '' }}"
                        ng-repeat="item in model"
                        ng-click="actionClick($event, item)">{{ item.title }}</div>
                </div>
            </div>
            `,
            link: (
                $scope: angular.IScope & {
                    toggle: ToggleModel;
                    actionClick: ($event: MouseEvent, item: ISmartGroupFilterDropdownActions) => void;
                    toggleDropdown: () => void;
                },
                $element: angular.IRootElementService,
            ) => {
                $scope.actionClick = ($event, item) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    item.action();
                    $scope.toggle.close();
                };

                $scope.toggleDropdown = () => {
                    $scope.toggle.toggle();
                };

                let unsub: null | (() => void) = null;
                $scope.$watch('toggle.isActive', (isActive: boolean) => {
                    if (unsub) unsub();
                    if (!isActive) return;
                    unsub = OutsideElementClick($scope, $element, () => {
                        $scope.toggle.close();
                    });
                });
            },
        }),
    ]);
