import { StorageAPI } from '../../lib/storage-user-config'

export MetricsViewStorageAPI = () ->
    get: -> StorageAPI do ->
        prefix = "metrics.views.v1"
        return prefix

export MetricsViewsAPI = (storage) ->
    get: ->
        api = await storage.get()
        return await api.get()
    put: (data) ->
        api = await storage.get()
        await api.put(data)
        return
