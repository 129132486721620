import { v1 as ServiceV1 } from '@42technologies/service-client';
import { ConfigAPI } from '../config-api';
import { ServiceAPICall, ServiceAPIFactory } from './api';

// Used by the scheduling / query services, because we can override the service config using the organization config
export class ServiceAPIWithOrgConfigOverrideFactory<
    T extends ServiceV1.ServiceAPI = ServiceV1.ServiceAPI,
> extends ServiceAPIFactory<T> {
    // Overrides the base class such that we pull the api config from the org config
    // instead of the app config. The ConfigAPI class merges the AppConfig with the org config.
    // It may be better to do the merging in this file.
    protected override getApiConfig() {
        this.config ??= ConfigAPI.get()
            .then(api => api.organization.get())
            .then(orgConfig => {
                const config = orgConfig.services[this.serviceName];
                if (!config) throw new Error(`Could not find service config ${this.serviceName}`);
                return config;
            });
        return this.config;
    }
}

export type ServiceAPIWithOrgConfigOverrideCall<T extends Record<string, unknown>, V = any> = ServiceAPICall<
    Omit<T, 'organization'>,
    V
>;
