import _ from 'lodash';
import { isObject } from '../../../lib/utils';
import { normalizeOverviewConfigProperties, parseKpisArray } from '../config-views-sales';
import { DEFAULT_TOP_ITEMS_LIMIT } from './card-top-items';

export type ITopItemsConfigKpi = string | { field: string; [x: string]: unknown };
export interface ITopItemsConfig {
    properties: string[];
    kpis: ITopItemsConfigKpi[];
    sort: null | ITopItemsConfigKpi[];
    limit: number;
}

export interface ITopItemsConfigDefaults {
    kpis: string[];
    properties: string[];
}

export const normalizeTopItemsConfig = (
    config: unknown,
    defaults: ITopItemsConfigDefaults,
): ITopItemsConfig[] | null => {
    const unresolvedConfigs = castToArray(_.get(config, 'views.sales.topItems'));
    const configs = parse(unresolvedConfigs, defaults);
    return configs && configs.length > 0 ? configs : null;
};

const castToArray = <T>(configs: T | T[] | undefined | null): null | T[] => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!configs) return null;
    configs = isObject(configs) ? [configs] : configs;
    if (!Array.isArray(configs)) return null;
    return configs;
};

const parse = (configs: unknown[] | null, defaults: ITopItemsConfigDefaults): ITopItemsConfig[] | null => {
    if (!configs) return null;

    const topItemConfigs = configs.reduce((acc: ITopItemsConfig[], config: unknown) => {
        if (_.isEmpty(config)) return acc;
        if (!isObject(config)) return acc;

        const properties = (() => {
            const properties = normalizeOverviewConfigProperties(config);
            return Array.isArray(properties) && properties.length > 0 ? properties : defaults.properties;
        })();

        const kpis = (() => {
            const kpis = parseKpisArray(config.kpis);
            return Array.isArray(kpis) && kpis.length > 0 ? kpis : defaults.kpis;
        })();

        const sort = (() => {
            const values = config.sort;
            if (Array.isArray(values)) {
                return values.reduce((acc: (string | { field: string; [x: string]: unknown })[], value) => {
                    if (typeof value === 'string') {
                        acc.push(value);
                    } else {
                        if (isObject(value) && typeof value.field === 'string') {
                            acc.push({ ...value, ...{ field: value.field } });
                        }
                    }
                    return acc;
                }, []);
            }
            return null;
        })();

        const limit = (() => {
            const value = config.limit;
            if (typeof value === 'number') return Math.max(1, value);
            return DEFAULT_TOP_ITEMS_LIMIT;
        })();

        return [...acc, { properties, kpis, sort, limit }];
    }, []);

    if (topItemConfigs.length === 0) return null;
    return topItemConfigs;
};
