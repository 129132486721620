import type angular from 'angular';
import 'construct-style-sheets-polyfill';

// Loosely based on this: https://stackoverflow.com/questions/37491962/angularjs-and-shadow-dom
// Building block for the directive wrapper. You can use it directly if you need to.
export function ShadowDomWrapper({
    $compile,
    style,
}: {
    $compile: angular.ICompileService;
    style?: null | undefined | string | CSSStyleSheet;
}) {
    const stylesheet = normalizeToStylesheet(style);
    return <S extends angular.IScope>(params: { scope: S; container: HTMLElement; html: string }) => {
        // Angular directives often define custom elements, which we can't attach a shadow dom to.
        // We can only create a shadow dom over standard html elements like div or article.
        // So, we create a child container element and attach shadow to it.
        const element = document.createElement('div');
        element.classList.add('shadow-root__container');
        params.container.appendChild(element);
        const shadow = attachShadow(element, params.html, stylesheet);
        // The ShadowRoot element works fine with $compile, but typescript complains...
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        $compile(shadow as unknown as Element)(params.scope);
        return shadow;
    };
}

export type IShadowDirectiveLink<
    TScope extends angular.IScope = angular.IScope,
    TElement extends JQLite = JQLite,
    TAttributes extends angular.IAttributes = angular.IAttributes,
    TController extends angular.IDirectiveController = angular.IController,
> = (
    scope: TScope,
    instanceShadowRoot: ShadowRootCompatible,
    instanceElement: TElement,
    instanceAttributes: TAttributes,
    controller?: TController,
    transclude?: angular.ITranscludeFunction,
) => void;

export interface IShadowDirective<
    TScope extends angular.IScope = angular.IScope,
    TElement extends JQLite = JQLite,
    TAttributes extends angular.IAttributes = angular.IAttributes,
    TController extends angular.IDirectiveController = angular.IController,
> extends Omit<angular.IDirective<TScope, TElement, TAttributes, TController>, 'link'> {
    link: IShadowDirectiveLink<TScope, TElement, TAttributes, TController>;
    template: string;
    style?: string | CSSStyleSheet;
}

export function ShadowDomDirectiveWrapper(
    $compile: angular.ICompileService,
    directive: IShadowDirective,
): angular.IDirective {
    const { template, link, style, ...rest } = directive;
    const shadowDomWrapper = ShadowDomWrapper({ $compile, style });
    if (!template) throw new Error('missing required: template');
    return {
        ...rest,
        template: '',
        replace: false,
        link: function (this: IShadowDirective, scope, element, ...rest) {
            const container = element[0];
            if (!container) throw new Error('cannot find directive element');
            const shadowRoot = shadowDomWrapper({ scope, container, html: template });
            return link.call(this, scope, shadowRoot, element, ...rest);
        },
    };
}

function stylesheetToNode(stylesheet: CSSStyleSheet) {
    const node = document.createElement('style');
    node.innerHTML = Array.from(stylesheet.cssRules)
        .map(rule => rule.cssText)
        .join('\n');
    return node;
}

function normalizeToStylesheet(stylesheet: null | undefined | string | CSSStyleSheet) {
    if (typeof stylesheet !== 'string') return stylesheet ?? null;
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(stylesheet);
    return sheet;
}

// Some browsers don't have 'adoptedStyleSheets' yet, but the type says it always has it.
type ShadowRootCompatible = Omit<ShadowRoot, 'adoptedStyleSheets'> & Pick<Partial<ShadowRoot>, 'adoptedStyleSheets'>;

function attachShadow(element: HTMLElement, html: string, style?: null | CSSStyleSheet) {
    const root: ShadowRootCompatible = element.attachShadow({ mode: 'open' });
    root.innerHTML = html;
    if (style) {
        if ('adoptedStyleSheets' in root) {
            root.adoptedStyleSheets = [style];
        } else {
            console.warn('ShadowDOM.adoptedStyleSheets not supported.');
            root.appendChild(stylesheetToNode(style));
        }
    }
    return root;
}
