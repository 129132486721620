import { HTMLElementInputWatcher, InputEventListenerCallback } from './event-listeners';

export type KeyboardObserverState = Record<string, boolean>;

export class KeyboardObserver extends HTMLElementInputWatcher {
    protected override state: KeyboardObserverState = {};

    constructor(element: HTMLElement | Window) {
        super(element);
        this.onKeyDown(this._updateState.bind(this, true));
        this.onKeyUp(this._updateState.bind(this, false));
    }

    onKeyPress(callback: InputEventListenerCallback) {
        // the keypress event is now deprecated, so we use keydown instead
        // Alt, Shift and Ctrl are examples of keys that do not trigger keypress events
        this.onKeyDown(callback);
        this.onKeyUp(callback);
    }

    override getState() {
        return this.state;
    }

    protected onKeyDown(callback: InputEventListenerCallback) {
        this.listener?.add('keydown', callback);
    }

    protected onKeyUp(callback: InputEventListenerCallback) {
        this.listener?.add('keyup', callback);
    }

    private _updateState(value: boolean, event: KeyboardEvent) {
        this.state[event.code] = value;
        return true;
    }
}

export class KeyboardObserverAngular extends KeyboardObserver {
    constructor($scope: angular.IScope, element: HTMLElement | Window) {
        super(element);

        $scope.$on('$destroy', () => {
            this.detach();
        });
    }
}
