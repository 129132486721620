import { IHierarchyService } from '../../../modules/hierarchy';
import { ICustomerTransaction } from '../../../modules/services/query-service.types';
import { ICustomer, ICustomerAPI } from '../customer.types';
import { isObject } from '../../../lib/utils/utils';

export const CustomerDetailsControllerInstance = () => [
    '$q',
    '$scope',
    '$route',
    'promiseTracker',
    'Hierarchy',
    'CustomerAPI',
    function CustomerDetailsController(
        $q: angular.IQService,
        $scope: angular.IScope & {
            customer?: ICustomer;
            select: (index: number) => void;
            selectPreviousTransaction: () => void;
            selectNextTransaction: () => void;
            customerDetailsPromiseTracker: angular.promisetracker.PromiseTracker;
            transactionIndex: number;
            transaction?: ICustomerTransaction;
        },
        $route: angular.route.IRouteService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
        Hierarchy: IHierarchyService,
        CustomerAPI: ICustomerAPI,
    ) {
        $scope.customerDetailsPromiseTracker = promiseTracker();
        const customerId = (() => {
            if (!$route.current) {
                return undefined;
            }

            if (isObject($route.current.params) && 'customerId' in $route.current.params) {
                const customerId = (() => {
                    if (typeof $route.current.params.customerId === 'string') return $route.current.params.customerId;
                    if (typeof $route.current.params.customerId === 'number')
                        return $route.current.params.customerId.toString();
                    return undefined;
                })();

                return customerId;
            }
        })();

        $scope.selectPreviousTransaction = () => $scope.select($scope.transactionIndex - 1);
        $scope.selectNextTransaction = () => $scope.select($scope.transactionIndex + 1);

        $scope.select = (index: number) => {
            const newIndex = parseInt(index.toString(), 10);
            const transactionsList = $scope.customer?.transactions ?? [];
            const transaction = transactionsList[newIndex];

            if (!transaction) {
                return;
            }

            $scope.transaction = transaction;
            $scope.transactionIndex = newIndex;
        };

        const loadPage = () => {
            if (!customerId) throw new Error('No customer ID provided.');
            const customerAPIPromise = new CustomerAPI();

            return customerAPIPromise.then(api => {
                const refreshItemProperties = () => {
                    return Hierarchy.fetch().then(hierarchy => {
                        const { items } = hierarchy.segments;
                        const result = items.filter(f =>
                            f.column !== undefined
                                ? ['id', 'name', 'company', 'type', 'pattern'].indexOf(f.column) > -1
                                : false,
                        );

                        $scope.itemProperties = result.slice(0, 4);
                    });
                };

                return $q.all([refreshItemProperties(), api.getDetails(customerId)]).then(([_, customer]) => {
                    $scope.customer = customer;
                    $scope.select(0);
                });
            });
        };

        $scope.customerDetailsPromiseTracker.addPromise(loadPage());
    },
];
