export const USER_EXPORTED_PAGE_METRICS = 'User Exported Metrics Page';
export const USER_EXPORTED_PAGE_GRID = 'User Exported Grid Page';
export const USER_RUN_REPORT = 'User Ran Report Manually';
export const USER_RUN_REPORT_FAILED = 'User Manual Report Run Failed';
export const USER_RUN_SCHEDULE = 'User Ran Schedule Manually';
export const USER_CREATE_REPORT = 'User Created Report';
export const USER_CREATE_SCHEDULE = 'User Created Schedule';
export const USER_UPDATE_REPORT = 'User Updated Report';
export const USER_EXPORT_REPORT = 'User Exported Report';
export const USER_IMPORT_REPORT = 'User Imported Report';
export const USER_IMPORT_REPORT_FAILED = 'User Report Import Failed';
export const USER_UPDATE_SCHEDULE = 'User Updated Schedule';
export const USER_CREATE_VIEW_METRICS = 'User Created View Metrics Page';
export const USER_CREATE_VIEW_GRID = 'User Created View Grid Page';
export const USER_CREATE_VIEW_CHART = 'User Created View Chart Page';
export const USER_NAVIGATED_HANDBOOK = 'User Navigated to Handbook';
export const USER_EXPORT_METRICS_TAB = 'User Exported Metrics Tab';
export const USER_IMPORT_METRICS_TAB = 'User Imported Metrics Tab';
export const USER_IMPORT_METRICS_TAB_FAILED = 'User Metrics Tab Import Failed';
export const USER_EXPORT_CHART_TAB = 'User Exported Chart Tab';
export const USER_IMPORT_CHART_TAB = 'User Imported Chart Tab';
export const USER_IMPORT_CHART_TAB_FAILED = 'User Chart Tab Import Failed';
export const USER_EXPORT_GRID_TAB = 'User Exported Grid Tab';
export const USER_IMPORT_GRID_TAB = 'User Imported Grid Tab';
export const USER_IMPORT_GRID_TAB_FAILED = 'User Grid Tab Import Failed';
export const USER_EXPORT_SEGMENT = 'User Exported Segment';
export const USER_EXPORT_ALL_SEGMENTS = 'User Exported All Segments';
export const USER_IMPORT_SEGMENT = 'User Imported Segment';
export const USER_IMPORT_SEGMENTS = 'User Imported SegmentS';
export const USER_IMPORT_SEGMENT_FAILED = 'User Segment Import Failed';
export const USER_IMPORT_SEGMENTS_FAILED = 'User Segments Import Failed';
export const USER_SIGNED_UP = 'User Signed Up';
export const USER_SIGNED_IN = 'User Signed In';
export const USER_SIGNED_OUT = 'User Signed Out';
