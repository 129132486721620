import _ from 'lodash';
import angular from 'angular';
import type { IEchartsWithQueryViewModelFactory } from '../../../directives/charts/chart-echarts-with-query-view-model';
import type { ISalesChartGridConfig } from './card-chart-grid';
import type { IOverviewStat } from '../overview-card-charts';
import { DashboardRootScope } from '../../main-controller';

const module = angular.module('42.controllers.overview.card-chart-grid-directives', [
    '42.filters',
    '42.directives.charts',
]);
export default module;

interface echartsWithQueryScope extends angular.IScope {
    model: null | { switchTheme: (theme: unknown) => void; getType: () => string; setType: (type: unknown) => void };
    isMap: boolean;
    expandedTheme: boolean;
    toggleTheme: () => void;
    chart?: ISalesChartGridConfig;
}

module.directive('echartsWithQuery', [
    '$rootScope',
    'EchartsWithQueryViewModel',
    (
        $rootScope: DashboardRootScope,
        EchartsWithQueryViewModel: IEchartsWithQueryViewModelFactory,
    ): angular.IDirective<echartsWithQueryScope> => ({
        restrict: 'E',
        replace: true,
        scope: {
            chart: '=',
        },
        template: `
        <div class="card echart-with-query" ng-class="{loading: chart.isLoading || model.isLoading !== false }">
            <header>
                <chart-echarts-title-react model="model.title"></chart-echarts-title-react>
                <div class="chart-options-container">
                    <div
                        class="chart-expanded-theme"
                        ng-if="false && chart.type === 'pie'"
                        ng-class="{ 'active': expandedTheme }"
                        ng-click="toggleTheme()">
                        <div class="enable-legend-button">%</div>
                    </div>
                    <div class="separator" ng-if="false && chart.type === 'pie'"></div>
                    <chart-picker ng-if="!isMap" enabled="!model.isBlank" selected="chart.type"></chart-picker>
                </div>
            </header>
            <div class="blank" ng-if="model.isBlank">No Data</div>

            <div ng-if="model.isError && !model.isLoading" class="message-container">
                <div class="message">
                    <i class="message-icon icon-attention"></i>
                    <span class="message-summary">Data could not be loaded</span>
                </div>
            </div>

            <echarts-custom options="model.options"></echart-custom>
        </div>
        `,
        link: scope => {
            scope.model = null;
            scope.isMap = false;
            scope.expandedTheme = false;

            scope.toggleTheme = function () {
                scope.expandedTheme = !scope.expandedTheme;
                scope.model?.switchTheme(scope.expandedTheme);
            };

            const updateModel = _.debounce(function () {
                if (!scope.chart) {
                    scope.model = null;
                    scope.isMap = false;
                    return;
                }
                if (scope.chart.isLoading) {
                    return;
                }
                const chart = _.cloneDeep(scope.chart);
                scope.model = new EchartsWithQueryViewModel(chart, $rootScope.query);
                scope.isMap = scope.model.getType() === 'map';
            }, 100);

            const updateType = function (type: string) {
                if (!scope.model) return updateModel();
                scope.model.setType(type);
            };

            scope.$watch('chart.metrics', updateModel);
            return scope.$watch('chart.type', updateType);
        },
    }),
]);

interface echartsWithQueryGridScope extends angular.IScope {
    charts?: { metrics: string[] }[];
    stat?: IOverviewStat;
}

module.directive('echartsWithQueryGrid', [
    (): angular.IDirective<echartsWithQueryGridScope> => ({
        restrict: 'E',
        replace: true,
        scope: {
            charts: '=',
            stat: '=',
        },
        template: `
        <div class="echarts-grid">
            <echarts-with-query
                ng-repeat="chart in charts track by $index"
                class="echarts-item"
                chart="chart">
            </echarts-with-query>
        </div>
        `,
        link: scope => {
            const updateCharts = function () {
                if (!scope.charts) return;
                if (!scope.stat) return;
                const { field } = scope.stat.actual;
                scope.charts.forEach(c => (c.metrics = [field]));
            };
            scope.$watch('stat.actual.field', updateCharts);
            return scope.$watch('charts', updateCharts);
        },
    }),
]);

interface chartPickerScope extends angular.IScope {
    chartTypes: string[];
    isSelected: (type: string) => boolean;
    select: (type: string) => void;
    enabled?: boolean;
    selected?: string;
}

module.directive('chartPicker', [
    (): angular.IDirective<chartPickerScope> => ({
        restrict: 'E',
        scope: {
            selected: '=',
            enabled: '=',
        },
        replace: true,
        template: `
        <article class="chart-picker">
            <ul>
                <li ng-repeat="type in chartTypes"
                    ng-click="select(type)"
                    ng-class="{active:isSelected(type)}"
                ><i class='icon-chart-{{type}}'></i></li>
            </ul>
        </article>
        `,
        link: scope => {
            scope.chartTypes = ['bar', 'pie'];
            scope.isSelected = (type: string) => {
                if (!scope.enabled) return false;
                return scope.selected === type;
            };
            scope.select = type => {
                scope.selected = type;
            };
        },
    }),
]);
