import _ from 'lodash'
import moment from 'moment-timezone'
import * as RegularExpressions from '../../lib/parsers/regexp'

module = angular.module('42.controllers.scheduling.common.lib', [])
export default module


module.service 'EmailAddressMultiFieldParser', ->
    parse: (addresses) ->
        return null if not addresses
        tokens = do ->
            return addresses if _.isArray(addresses)
            return _.flatten (addresses or "").split('\n').map (x) -> x.split(',')
        return tokens.map((x) -> x.trim()).filter((x)-> x).map (x) ->
            value: x
            isValid: RegularExpressions.EMAIL.test(x)


module.service('TIMEZONES', [() ->
    WHITELIST = ['etc','america','europe']
    ###* @type {Set<string>} seen ###
    seen = new Set()
    ###* @type {{group: string, label: string, id: string}[]} result ###
    result = []
    for timezone from moment.tz.names()
        [group, label] = timezone.split('/')
        continue if typeof group isnt 'string'
        label = group if not label
        timezoneKey = "#{group}#{label}"
        continue if seen.has(timezoneKey)
        result.push({group, label:timezone, id:timezone})
        seen.add(timezoneKey)
    result = result.filter((x) -> WHITELIST.includes(x.group.toLowerCase()))
    result = _.orderBy(result, 'label')
    return result
])
