import _ from 'lodash'
import moment from 'moment-timezone'
import Utils from '../../lib/utils'
import * as AuthServiceAPI from '../../lib/auth'
import * as Analytics from '../../lib/analytics'
import { deepStripAngularProperties } from '../../lib/angular'
import { CurrenciesService } from '../../modules/currency/currency.service'
import SchedulingCommonModule from './scheduling-controller-common'
import { ReportModelMetaSchema } from './report-model-state-validation'

module = angular.module(SchedulingCommonModule.name)


PromiseGuard = ->
    lastPromiseId = null
    return (promise) ->
        lastPromiseId = promiseId = Utils.uuid()
        promise.then (x) ->
            throw new Error('Function was called before this promise has resolved.') if lastPromiseId isnt promiseId
            return x


class ListModel

    @MODES: {'SELECT', 'EDIT', 'CREATE'}

    constructor: (@tracker) ->
        throw new Error("Missing required `@_fetch` method in subclass.") if not @_fetch
        @viewState = {}

    init: ->
        @_fetch().then (x) => @setAvailable(x)

    refresh: ->
        @init()

    setAvailable: (available) ->
        @available = Utils.copy(available or [])

        if not @viewState or not @viewState.active
            selected = @available[0]?.id
            @selectById(selected)
            return

        @viewState.available = Utils.copy(@available)

        savedInAvailableIndex = do =>
            return -1 if not @viewState.saved
            return Utils.Array.indexOf @viewState.available, (x) => x.id is @viewState.saved.id

        savedInAvailable = @viewState.available[savedInAvailableIndex]

        savedChanged = do =>
            # If we don't have the saved item...
            not savedInAvailable or \
                # If we have the selected item but it has changed...
                Utils.Object.hash(@viewState.saved) is Utils.Object.hash(savedInAvailable)

        if savedChanged
            @viewState.selected = savedInAvailable
            @viewState.saved = Utils.copy(@viewState.selected)
        else
            @viewState.saved = Utils.copy(savedInAvailable)
            @viewState.available[savedInAvailableIndex] = @viewState.selected

        if @viewState.mode isnt ListModel.MODES.CREATE
            @viewState.active = @viewState.selected

        delete @viewState.saved?.$$hashKey
        return

    getActiveParams: ->
        return @viewState.active?.data

    reorder: (oldIndex, newIndex) ->
        return if oldIndex is newIndex

        @available = Utils.Array.move(@available, oldIndex, newIndex)
        @_saveOrder @available

    select: (item) ->
        return @selectById(item.id) if item isnt null
        @viewState ?= {}
        @viewState.mode = ListModel.MODES.SELECT
        @viewState.selected = null
        @viewState.active = null
        @viewState.saved = null
        return null

    selectById: (id) ->
        available = Utils.copy(@available)
        selected = _.find available, (x) -> x.id is id
        return null if not selected
        @viewState ?= {}
        @viewState.mode = ListModel.MODES.SELECT
        @viewState.available = available
        @viewState.selected = selected
        @viewState.active = @viewState.selected
        @viewState.saved = Utils.copy(@viewState.selected)
        delete @viewState.saved?.$$hashKey
        delete @viewState.postEdit
        return Utils.copy(@viewState.selected)

    hasUnsavedChanges: ->
        return true if @viewState.mode is ListModel.MODES.CREATE
        return false if (@viewState.available or []).length is 0
        [selected, saved] = [@viewState.selected, @viewState.saved]
        return true if _.some [selected, saved], (x) -> not x
        [selected, saved] = [selected, saved].map (x) ->
            result = Utils.copy(x)
            delete result.$$hashKey
            delete result.updatedAt
            return result
        return Utils.Object.hash(selected) isnt Utils.Object.hash(saved)

    # Reset the selected item to the saved value
    reset: ->
        @select(@viewState.saved)

    edit: ({postEdit} = {}) ->
        @select(@viewState.active)
        @viewState.postEdit = postEdit
        return @viewState.active

    copy: ({postEdit} = {}) ->
        return if not @_copy
        @viewState.mode = ListModel.MODES.CREATE
        @viewState.active = @_copy(Utils.copy @viewState.active)
        @viewState.available = Utils.copy(@available)
        @viewState.selected = _.find @viewState.available, (x) => x.id is @viewState.selected.id
        @viewState.saved = Utils.copy(@viewState.selected)
        delete @viewState.saved?.$$hashKey
        @viewState.postEdit = postEdit
        return @viewState.active

    delete: (item) ->
        item ?= @viewState.active
        index = Utils.indexOf @viewState.available, (x) -> x.id is item.id
        return if index is -1
        @_delete(item).then =>
            @viewState.available = Utils.Array.removeAt @viewState.available, index
            @available = Utils.copy(@viewState.available)
            @viewState.mode = ListModel.MODES.SELECT
            @viewState.selected = @viewState.available[0]
            @viewState.saved = Utils.copy(@viewState.selected)
            @viewState.active = @viewState.selected

    # Create a new item
    create: ({postEdit} = {}) ->
        return if not @_create
        @viewState.mode = ListModel.MODES.CREATE
        @viewState.postEdit = postEdit
        @viewState.active = @_create()
        @viewState.available = Utils.copy(@available)
        @viewState.selected = _.find(@viewState.available, (x) => x.id is @viewState.selected.id) or null
        @viewState.saved = (try Utils.copy(@viewState.selected)) or null
        delete @viewState.saved?.$$hashKey
        return @viewState.active

    import: (data) ->
        return if not @_import
        @viewState.mode = ListModel.MODES.CREATE
        @viewState.active = @_import(data)
        @viewState.available = Utils.copy(@available)
        @viewState.selected = _.find(@viewState.available, (x) => x.id is @viewState.selected.id) or null
        @viewState.saved = (try Utils.copy(@viewState.selected)) or null
        delete @viewState.saved?.$$hashKey
        return @viewState.active

    # Cancel create
    cancel: ->
        @_cancel?()
        @select(@viewState.selected)

    _savePreprocess: ->
        return if not @hasUnsavedChanges()
        @viewState.mode = ListModel.MODES.SELECT
        item = @viewState.active
        delete item.$$hashKey
        activeIndex = Utils.Array.indexOf @available, (x) -> x.id is item.id
        return [activeIndex, item]

    # Save changes to the selected, edited or created set
    save: ->
        [activeIndex, item] = @_savePreprocess()
        savePromise = do =>
            return @_save(item) if activeIndex is -1
            return @_update(item)
        savePromise
        .catch (error) ->
            console.error "Could not save item:", item
            throw error
        .then ([available, item]) =>
            @viewState.postEdit?(item)
            @setAvailable(available)
            @selectById(item.id)

    isInCreateMode: ->
        @viewState.mode is ListModel.MODES.CREATE


module.factory('ReportTemplatesModel', ($q, SchedulingServiceAPI, ReportsModel) ->
    return class ReportTemplatesModel

        @Create: ->
            model = new ReportTemplatesModel()
            model.init().then -> return model

        @Fetch: do ->
            guard = PromiseGuard()
            fetchJobs = -> (new SchedulingServiceAPI).then (api) -> api.jobs.list()
            return (reportsModelData) -> guard do ->
                $q.all([
                    fetchJobs()
                    CurrenciesService.fetch()
                ]).then ([jobs, currencies]) ->
                    jobs = jobs.map (job) ->
                        id:          job.id
                        label:       job.label or 'Untitled'
                        description: job.description or ""
                        params: (job.data?.params or []).filter (x) ->
                            return true if x isnt 'currency'
                            return true if currencies.length > 1
                            return true if currencies?[0].id isnt 'usd'
                            return false
                        reports: new ReportsModel(job.id)
                    $q.all((jobs.map (x) -> x.reports.init())).then -> return jobs

        constructor: ->
            @id = Utils.uuid()

        init: ->  ReportTemplatesModel .Fetch().then (available) =>
            @available = available or []
            @selected  = if @available.length is 1 then @available[0] else null

        refresh: -> ReportTemplatesModel.Fetch().then (available) =>
            @available = available
            @selected  = _.find(available, (x) => x.id is @selected.id) or null
            @selected  = available[0] if @available.length is 1 and not @selected

        hasReports: ->
            for template in @available
                return true if template.reports?.viewState?.available?.length > 0
            return false

        selectedReportIsInvalid: ->
            return not @selected?.reports.viewState.active and @selected?.reports.viewState.saved

        hasReportId: (reportId) ->
            report = @findReportById(reportId)
            return !!report

        findReportById: (reportId) ->
            for template in @available
                for report in template.reports.available
                    return report if report?.id is reportId
            return null

        select: (reportTemplate) ->
            @selected = reportTemplate

        getReportsLink: (report) ->
            id = do ->
                return '' if not report
                return "/#{report.id}"
            return "/reporting/reports#{id}"

        selectByReportId: (reportId) ->
            if not reportId
                @selected = null
                return null
            for template in @available
                report = template.reports.selectById(reportId)
                continue if not report
                return @select(template)
            @selected = null
            return null
)

module.factory('ReportsModel', ($q, CONFIG, StorageAPI, SchedulingServiceAPI) ->
    return class ReportsModel extends ListModel
        @STORAGE_KEY: 'reports'

        @Create: ->
            model = new ReportsModel(arguments...)
            model.init().then -> model

        @Fetch: (templateId) ->
            StorageAPI(ReportsModel.STORAGE_KEY)
            .then((api) -> api.get())
            .then (templates) ->
                templates = null if _.isArray(templates)
                templates ?= {}
                reports = templates[templateId] or []
                return reports

        @ValidateReportFile: (payload) ->
            try
                payload = JSON.parse(payload) if typeof payload is 'string'
            catch error
                console.error(error)
                throw new Error("Report import error: bad json.", {cause:error})
            if not Utils.isObject(payload)
                throw new Error("Report import error: must be a string or object")
            meta = ReportModelMetaSchema.safeParse(payload.meta)
            if not meta.success
                console.error(meta.error)
                throw new Error('Report import error: meta field missing or invalid.')
            { data } = payload
            if not Utils.isObject(data) or _.isEmpty(data or {}) or _.isEmpty(data.report or {})
                throw new Error("Invalid uploaded report data: #{JSON.stringify(payload)}")
            if CONFIG.organization.id isnt meta.data.organizationId
                throw new Error("Invalid uploaded report: organization mismatch.")
            return payload

        constructor: (templateId) ->
            super()
            throw new Error("Missing required `templateId` argument.") if not templateId
            @templateId = templateId

        run: ->
            serializedReport = try @_serialize(report) catch error then console.error(error)
            Analytics.track(Analytics.EVENTS.USER_RUN_REPORT, { report: serializedReport }) if serializedReport
            report = deepStripAngularProperties(@viewState.active)
            report: report
            promise: SchedulingServiceAPI().then((api) ->
                # We capture the user's current timezone on run for ad-hoc reports, as these do not have an associated schedule to capture the desired timezone.
                return api.jobs.run(report, moment.tz.guess(true))
            ).catch (error) ->
                Analytics.track(Analytics.EVENTS.USER_RUN_REPORT_FAILED, { report: serializedReport, error }) if serializedReport
                throw error

        _fetch: ->
            return ReportsModel.Fetch(@templateId)

        _update: (report) ->
            try
                throw new Error("Missing required `report` argument.") if not report
                report = Utils.copy(report)
                report.updatedAt = Date.now()
                # Do we want to update the assigned timezone any time the report is updated? Not sure about the UX here...

                try Analytics.track(Analytics.EVENTS.USER_UPDATE_REPORT, {report: @_serialize(report)})
                catch error then console.error(error)

                return @__updateStorage((reports) ->
                    index = Utils.Array.indexOf(reports, (x) -> x.id is report.id)
                    reports[index] = report
                    return reports
                ).then (reports) ->
                    return [reports, Utils.copy(report)]
            catch error
                return $q.reject(error)

        _delete: (report) ->
            return @__updateStorage((reports) ->
                index = Utils.Array.indexOf(reports, (x) -> x.id is report.id)
                reports = Utils.Array.removeAt(reports, index)
                return reports
            ).then (reports) ->
                return [reports, true]

        _save: (report) ->
            try
                report = Utils.copy(report)
                report.createdAt = Date.now()
                report.updatedAt = Date.now()

                try Analytics.track(Analytics.EVENTS.USER_CREATE_REPORT, {report: @_serialize(report)})
                catch error then console.error(error)

                return @__updateStorage((reports) ->
                    return [report, ...reports]
                ).then (reports) ->
                    return [reports, Utils.copy(report)]
            catch error
                return $q.reject(error)

        _serialize: (report = null) ->
            report ?= @viewState.active
            report = deepStripAngularProperties(report)
            report.data.metrics = report.data.metrics.map (metric) ->
                return metric if typeof metric is 'string'
                return metric.field
            return report

        export: (report) -> $q.when do =>
            getFilename = (report) ->
                filename = _.kebabCase(report.label.replace(/&/g,'-and-'))
                filename = "42-report-#{filename}.json"
                return filename
            getData = (report, userId, organizationId) =>
                report = @_serialize(report)
                meta = {type:'report', version:1, organizationId, createdAt: Date.now(), exportedByUserId:userId}
                data = {data:{report}, meta}
                return data
            return $q.all([
                AuthServiceAPI.getOrganization(),
                AuthServiceAPI.getUser()
            ]).then ([orgId, user]) ->
                payload = getData(report, user.id, orgId)
                Analytics.track(Analytics.EVENTS.USER_EXPORT_REPORT, {report: payload.data.report})
                filename = getFilename(report)
                return {filename, data:payload}

        _import: (payload) ->
            { data } = payload
            return {
                ...@_copy(data.report),
                label: data.report.label
            }

        _copy: (report) ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  report.templateId
            description: report.description
            label:       "#{report.label} (COPY)"
            data:        Utils.copy(report.data)

        _create: ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  @templateId
            description: ''
            label:       'Untitled'
            data:        {}

        _saveOrder: (reports) ->
            @__updateStorage -> return reports

        __updateStorage: (cb) ->
            StorageAPI(ReportsModel.STORAGE_KEY).then (api) =>
                api.get().then (templates) =>
                    templates = null if Array.isArray(templates)
                    templates ?= {}
                    reports = templates[@templateId] or []
                    $q.when(cb(reports)).then (reports) =>
                        templates[@templateId] = reports
                        return api.put(templates).then -> Utils.copy(reports)
)


module.factory('ReportingModels', ['ReportTemplatesModel', 'SchedulesModel', (ReportTemplatesModel, SchedulesModel) ->
    return class ReportingModel
        @Create: ->
            ReportTemplatesModel.Create().then (templates) ->
                SchedulesModel.Create(templates).then (schedules) ->
                    return new ReportingModel({templates, schedules})
        constructor: ({templates, schedules}) ->
            @templates = templates
            @schedules = schedules
])


module.service('ReportingState', ['ReportingModels', (ReportingModels) ->
    report = {
        flags: {}
        invalidFields: {}
        updateInvalidFields: (invalidFields) ->
            @invalidFields = _.extend({}, @invalidFields, invalidFields)
            @flags.isInvalid = Object.keys(@invalidFields).filter((k) => @invalidFields[k]).length > 0
            return
    }
    # This is used to optimize checks for `isInvalid` and `hasUnsavedChanges` across directives.
    report: report
    schedule: {flags:{}}
    models: null
    fetch: -> ReportingModels.Create().then((x) => (@models = x))
])


module.factory('SchedulesReportListModel', (ReportsModel, ReportTemplatesModel) ->
    return class SchedulesReportListModel

        constructor: (reportTemplateData, reportsData) ->
            @reportTemplateData = reportTemplateData
            @reportsData = reportsData
            @available = []

        add: (index) ->
            model = new ReportTemplatesModel()
            model.available = Utils.copy(@reportTemplateData)
            model.available.forEach (job) =>
                job.reports = new ReportsModel(job.id)
                job.reports.setAvailable(Utils.copy @reportsData[job.id])
            index = @available.length if not _.isNumber(index)
            @available = Utils.Array.insertAt(@available, index, model)
            return model

        push: ->
            @add()

        pop: ->
            @removeAt(@available.length-1)

        getReportDataById: (reportId) ->
            reports = _.flatten Object.keys(@reportsData).map (x) => @reportsData[x]
            reportId = [reportId] if not Array.isArray(reportId)
            return _.compact reportId.map (id) -> _.find reports, (report) -> report.id is id

        remove: (reportTemplates) ->
            index = Utils.Array.indexOf @available, (x) -> x.id is reportTemplates.id
            console.log "Removing `#{reportTemplates.id}` at index `#{index}`."
            if index is -1
                console.warn "Could not find report template `#{reportTemplates.id}`"
                return @
            return @removeAt(index)

        removeAt: (index) ->
            @available = Utils.Array.removeAt(@available, Math.max(0, index))
            return @

        reset: ->
            @available = []
            return @
)

module.factory('SchedulesModel', ($q, TIMEZONES, SchedulingServiceAPI, EmailAddressMultiFieldParser, SchedulesReportListModel, ReportsModel, ReportTemplatesModel) ->

    serializeEmailAddresses = (addresses) ->
        EmailAddressMultiFieldParser.parse(addresses)?.filter((x) -> x.isValid).map((x) -> x.value)

    return class SchedulesModel extends ListModel

        @Create: (reportTemplates) ->
            SchedulesModel.CreateReportTemplatesListModel(reportTemplates)
            .then (reportTemplatesList) ->
                model = new SchedulesModel(reportTemplatesList)
                model.init().then -> model

        @CreateReportTemplatesListModel: (reportTemplates) ->
            reportTemplatesDataPromise = do ->
                return $q.when(reportTemplates.available) if reportTemplates
                return ReportTemplatesModel.Fetch()
            reportTemplatesDataPromise
            .then (reportTemplateData) ->
                result = {}
                $q.all(reportTemplateData.map (template) ->
                    ReportsModel.Fetch(template.id).then (x) -> result[template.id] = x
                ).then -> [reportTemplateData, result]
            .then ([reportTemplateData, reportsData]) ->
                reportTemplatesListModel = new SchedulesReportListModel(reportTemplateData, reportsData)
                reportTemplatesListModel.add()
                return reportTemplatesListModel

        TIMEZONES: Utils.copy(TIMEZONES)

        constructor: (reportTemplatesListModel) ->
            super()
            @reportTemplatesListModel = reportTemplatesListModel

        getScheduleLink: (schedule) ->
            id = do ->
                return '' if not schedule or not schedule.id
                { id } = schedule
                return "/#{id.split('schedule|')[1]}" if id.startsWith('schedule|')
                return "/#{id}"

            return "/reporting/schedules#{id}"

        getInvalidFields: ->
            return [] if not @viewState.active
            result =
                'Timezone':         @viewState.active.timezone
                'Expression':       @viewState.active.expression
                'Report':           @viewState.active.data.reportId
                'Email Recipients': @viewState.active.data.target.recipients
                'Email BCC':        @viewState.active.data.target.bcc
            # 'Email Subject':    @viewState.active.data.target.subject
            # 'Email Body':       @viewState.active.data.target.body
            validKeys = Object.keys(result).filter (key) ->
                value = result[key]
                if key is 'Email Recipients'
                    value = serializeEmailAddresses(value)
                if key is 'Email BCC'
                    return _.every EmailAddressMultiFieldParser.parse(value), (x) -> x.isValid
                return value.length > 0 if _.isArray(value)
                return !!value if not _.isString(value)
                value = value.trim()
                return value.length > 0
            validKeys.forEach (key) -> delete result[key]
            return result

        isInvalid: ->
            Object.keys(@getInvalidFields()).length > 0

        hasUnsavedChanges: ->
            return true if @viewState.mode is ListModel.MODES.CREATE
            return false if (@viewState.available or []).length is 0
            [selected, saved] = [@viewState.selected, @viewState.saved].map (x) ->
                result = Utils.copy(x)
                ['recipients', 'cc', 'bcc'].forEach (key) ->
                    try
                        result.data.target[key] ?= ''
                        result.data.target[key] = result.data.target[key].join('\n') if _.isArray(result.data.target[key])
                        result.data.target[key] = result.data.target[key].trim()
                delete result.$$hashKey
                delete result.updatedAt
                delete result.active
                return result
            return Utils.Object.hash(selected) isnt Utils.Object.hash(saved)

        updateModelFromReportTemplates: ->
            @viewState.active.jobId = "metrics-breakdown"
            @viewState.active.data.reportId = @reportTemplatesListModel.available.map((template) ->
                return template.selected?.reports.viewState.selected.id
            ).filter((x) -> x)
            return

        updateReportTemplatesFromModel: (reportIds) ->
            reportIds = reportIds or @viewState.active?.data?.reportId
            reportIds = [reportIds] if not _.isArray(reportIds)
            @reportTemplatesListModel.reset()
            reportIds.forEach (reportId, index) =>
                @reportTemplatesListModel.add()
                reportTemplates = @reportTemplatesListModel.available[index]
                reportTemplates.selectByReportId(reportId)
            @reportTemplatesListModel.add() if reportIds.length is 0
            return

        run: ->
            return @_serialize(@viewState.active).then (schedule) ->
                Analytics.track(Analytics.EVENTS.USER_RUN_SCHEDULE, {schedule})
                SchedulingServiceAPI().then (api) ->
                    return api.schedules.run(schedule)

        _fetch: ->
            SchedulingServiceAPI().then (api) ->
                return $q.all([
                    api.schedules.list()
                    SchedulesModel.CreateReportTemplatesListModel()
                    AuthServiceAPI.getUser()
                ])
            .then ([schedules, templatesList, user]) =>
                schedules = schedules
                    .filter((x) -> not _.isNil(x.data?.userId))
                    .filter((x) -> x.data?.userId is user.id)
                    .map (x) =>
                        reportIds = x.data.reportId
                        reportIds = [reportIds] if not _.isArray(reportIds)
                        @reportTemplatesListModel = templatesList
                        @reportTemplatesListModel.reset()
                        x.data.reportId = reportIds.filter (reportId) =>
                            reportTemplates = @reportTemplatesListModel.push()
                            if reportTemplates.hasReportId(reportId)
                                reportTemplates.selectByReportId(reportId)
                                return true
                            # @reportTemplatesList.pop()
                            return false
                        @reportTemplatesListModel.push() if x.data.reportId.length is 0
                        return x
                return _.sortBy schedules, (x) -> -1 * moment.utc(x.createdAt).unix()

        _delete: (schedule) ->
            id = schedule.id
            return SchedulingServiceAPI().then (api) -> api.schedules.delete(id)

        _serialize: (schedule, userId, organizationId) ->
            try
                schedule ?= @viewState.active
                schedule = Utils.copy(schedule)
                schedule.jobId = "metrics-breakdown"
                schedule.data ?= {}
                schedule.data.target = try @_serializeTarget(schedule.data.target)
                return $q.all([
                    $q.when(userId         or AuthServiceAPI.getUser().then((x) -> x.id)),
                    $q.when(organizationId or AuthServiceAPI.getOrganization()),
                ]).then ([userId, organizationId]) =>
                    schedule.data.userId = userId
                    schedule.data.organizationId = organizationId
                    schedule.data.reportId = _.compact @reportTemplatesListModel.available.map (x) ->
                        x.selected?.reports?.viewState.selected.id
                    return schedule
            catch error
                return $q.reject(error)

        _serializeTarget: (target) ->
            recipients: serializeEmailAddresses(target.recipients)
            bcc:        serializeEmailAddresses(target.bcc)
            body:       target.body?.trim() or ""
            # subject:    target.subject?.replace(/\n/g, ' ').trim()
            filename:   target.filename?.replace(/\n/g, ' ').trim()

        _save: (schedule) ->
            return @_api(schedule, 'create')

        _update: (schedule) ->
            return @_api(schedule, 'update')

        _api: (schedule, method) -> @_serialize(schedule).then (schedule) =>
            throw new Error("Cannot #{method} schedule: Missing `schedule.data.reportId` value.") if not schedule.data.reportId
            throw new Error("Cannot #{method} schedule: Missing `schedule.jobId` value.")         if not schedule.jobId
            event = do ->
                return Analytics.EVENTS.USER_CREATE_SCHEDULE if method is 'create'
                return Analytics.EVENTS.USER_UPDATE_SCHEDULE if method is 'update'
            Analytics.track(event, { schedule }) if event
            SchedulingServiceAPI().then (api) ->
                return api.schedules[method](schedule)
            .then (schedule) =>
                return @_fetch().then (schedules) -> [schedules, schedule]
            .then ([schedules, schedule]) =>
                @updateReportTemplatesFromModel()
                return [schedules, schedule]

        _copy: (schedule) ->
            label:       schedule.label
            expression:  schedule.expression
            timezone:    schedule.timezone
            jobId:       schedule.jobId
            data:        Utils.copy(schedule.data)

        _create: ->
            @reportTemplatesListModel.reset()
            @reportTemplatesListModel.add()
            label:      "Untitled Schedule"
            expression: "30 8 * * *"
            timezone:   moment.tz.guess()
            jobId:      null
            active: 1
            data:
                userId: null
                reportId: []
                target: {}

        toggleActiveState: ->
            @viewState.active.active = do (schedule = @viewState.active) ->
                return 1 if schedule.active is 0
                return 0
            {active, saved} = @viewState
            delete active.$$hashKey
            saved = Utils.copy(saved)
            saved.active = active.active
            @_update(saved).then ([available, updated]) =>
                active.active = updated.active
                active.objectVersion = updated.objectVersion
                @viewState.postEdit?(active)
                @setAvailable(available)

        save: ->
            [activeIndex, item] = @_savePreprocess()
            active = null
            savePromise = do =>
                return @_save(item) if activeIndex is -1
                savedViewState = Utils.copy(@viewState.saved)
                active = @viewState.active
                @viewState.saved = Utils.copy(@viewState.active)
                return @_update(item).catch (error) =>
                    @viewState.saved = savedViewState
                    throw error
            savePromise
                .catch (error) ->
                    console.error "Could not save item:", item
                    throw error
                .then ([available, item]) =>
                    @viewState.postEdit?(item)
                    @setAvailable(available)
                    if active and active.id is item.id
                        active.objectVersion = item.objectVersion
                    else
                        @selectById(item.id)
)
