import LibsModule from './libs';
import LegacyUtilsModule from './legacy-utils-module';
import ServicesModule from './services';
import HierarchyModule from './hierarchy';

import './datepicker';
import './natural-language-filter-display/natural-language-filter-display.module';
import './smart-groups/smart-groups.module';
import './user-menu/user-menu.module';
import './currency/currency.module';
import './storage-module';

angular.module('42.modules', [
    LibsModule.name,
    LegacyUtilsModule.name,
    ServicesModule.name,
    HierarchyModule.name,
    '42.modules.datepicker',
    '42.modules.storage',
    '42.modules.natural-language',
    '42.modules.smart-groups',
    '42.modules.currency',
]);
