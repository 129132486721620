import type { AngularInjected } from '../../lib/angular';
import { deepStripAngularProperties } from '../../lib/angular';
import type { IMetricDefinition, IQueryTableFilter } from '../../lib/types';
import type { Schedule } from '../../lib/api/api-scheduling-service';
import { SchedulingServiceAPI } from '../../lib/api';

export interface IReportSort {
    field: string;
    limit: number;
    order: number;
    property: string;
}

export interface IReportData {
    columnStyle: string;
    filterItems: { items: IQueryTableFilter };
    filterStores: { stores: IQueryTableFilter };
    hierarchyStore: string[];
    metrics: IMetricDefinition[];
    sort: IReportSort[];
    timerange: string;
    timerangeComparisonMode: string;
    currency?: string;
}

export interface IReport {
    templateId: string;
    description: string;
    id: string;
    label: string;
    updatedAt: number;
    data: IReportData;
}

export type ISchedulingServiceAPIFactory = AngularInjected<typeof SchedulingServiceAPIFactory>;
export type ISchedulingServiceAPI = Awaited<ReturnType<ISchedulingServiceAPIFactory>>;

const SchedulingServiceAPIFactory = [
    '$q',
    function SchedulingAPIFactory($q: angular.IQService) {
        return () => {
            return $q.when(SchedulingServiceAPI.get()).then(api => {
                return {
                    jobs: {
                        get: (jobId: string) => {
                            return $q.when(api.organizations.GetJob({ jobId }));
                        },
                        list: () => {
                            return $q.when(api.organizations.ListJobs({}));
                        },
                        run: (report: IReport, timezone: string) => {
                            report = deepStripAngularProperties(report);
                            const jobId = report.templateId;
                            return $q.when(api.organizations.RunJob({ jobId, timezone, data: { report } }));
                        },
                    },
                    schedules: {
                        create: (schedule: Schedule) => {
                            schedule = deepStripAngularProperties(schedule);
                            return $q.when(api.organizations.CreateSchedule({ schedule }));
                        },
                        update: (schedule: Schedule) => {
                            const data = { scheduleId: schedule.id, schedule };
                            return $q.when(api.organizations.UpdateSchedule(data));
                        },
                        delete: (scheduleId: string | Schedule) => {
                            scheduleId = typeof scheduleId !== 'string' ? scheduleId.id : scheduleId;
                            return $q.when(api.organizations.DeleteSchedule({ scheduleId }));
                        },
                        list: () => {
                            return $q.when(api.organizations.ListSchedules({}));
                        },
                        get: () => {
                            return $q.when(api.organizations.GetSchedule({}));
                        },
                        run: (schedule: Schedule) => {
                            schedule = deepStripAngularProperties(schedule);
                            const scheduleId = schedule.templateId;
                            return $q.when(api.organizations.RunSchedule({ scheduleId, data: schedule }));
                        },
                    },
                };
            });
        };
    },
];

const module = angular.module('42.modules.services.scheduling', []);
module.factory('42.services.scheduling.api', SchedulingServiceAPIFactory);

export default module;
