export const requestAnimationFrame: typeof globalThis['requestAnimationFrame'] = ((window: any) =>
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    ((cb: any) => window.setTimeout(cb, 0)))(window);

export const cancelAnimationFrame: typeof globalThis['cancelAnimationFrame'] = ((window: any) =>
    window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.msCancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    ((handle: number) => window.clearTimeout(handle)))(window);
