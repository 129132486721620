import _ from 'lodash';

export class SimplePaginator {
    page = 1;
    pages: undefined | number;
    total: undefined | number;

    constructor(private size: number) {
        if (_.isNaN(this.size)) {
            throw new Error('`pageSize` argument must be of type `Number`.');
        }

        if (this.size < 1) {
            throw new Error('`pageSize` argument must be greater than 0.');
        }
    }

    prev() {
        this.page = Math.max(1, this.page - 1);
    }

    next() {
        if (this.pages === undefined) {
            this.page += 1;
            return this.page;
        }

        this.page = Math.min(this.pages, this.page + 1);
    }

    setPage(page: number) {
        this.page = page || 1;
    }

    setItemCount(count: number | string) {
        if (_.isNull(count)) {
            this.total = undefined;
            this.pages = undefined;
            return;
        }

        count = _.isNumber(count) ? count : parseInt(count);
        this.total = count;
        this.pages = Math.ceil(count / this.size);
    }

    toQuery() {
        return {
            offset: Math.max(0, this.page - 1) * this.size,
            limit: this.size,
        };
    }
}
