import { hash } from '../utils/utils-object';

export interface IModelStateTracker<T> {
    ref: T;
    get(): T;
    set(next: T): T;
}
export function stateTracker<T>(init: T) {
    const hasChanged = changeTracker(init);
    return {
        ref: init,
        get() {
            return this.ref;
        },
        set(next: T) {
            if (hasChanged(next)) this.ref = next;
            return this.get();
        },
    };
}

export function changeTracker(init?: any) {
    const h = (x: any) => (x ? hash(x) : null);
    let curr = h(init);
    return (next: any) => {
        const prev = curr;
        curr = h(next);
        return [curr, prev === curr];
    };
}
