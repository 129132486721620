export interface TooltipDirectiveScope extends angular.IScope {
    messages: string[];
}

const TooltipDirective = () => [
    function MultiCheckDropdownDirective(): angular.IDirective<TooltipDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                messages: '=',
            },
            replace: true,
            template: `
                <div class="tooltip-container">
                    <div class="triangle"></div>
                    <div class="messages">
                        <div class="message" ng-repeat="message in messages">
                            {{ message }}
                        </div>
                    </div>
                </div>
            `,
        };
    },
];

const TooltipModule = angular.module('42.components.tooltip', []).directive('tooltip', TooltipDirective());

export default TooltipModule;
