import Utils from '../utils';

export class ToggleModel {
    static Parse(state: unknown): boolean | null {
        if (typeof state === 'boolean') return state;
        if (!Utils.isObject(state)) return null;
        const values = Utils.Object.pickValues(state, ['isOpen', 'isActive', 'active']);
        return values.find((x): x is boolean => x === true) ?? null;
    }

    static Deserialize(state: unknown, defaultState = false) {
        const value = ToggleModel.Parse(state);
        return new ToggleModel(value ?? defaultState);
    }

    public state: { isOpen: boolean };

    constructor(isOpen = false) {
        this.state = { isOpen };
    }

    public get isOpen() {
        return this.state.isOpen;
    }

    public get isActive() {
        return this.state.isOpen;
    }

    public toggle(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.update(!this.state.isOpen);
        return false;
    }

    public open(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.update(true);
        return false;
    }

    public close(event?: Event) {
        event?.preventDefault();
        event?.stopImmediatePropagation();
        this.update(false);
        return false;
    }

    protected update(state: boolean) {
        this.state = { isOpen: state };
    }

    public serialize() {
        return { isOpen: this.state.isOpen };
    }
}
