import _ from 'lodash';
import { AbortController, AbortSignal } from '../dom/abort-controller';
import { isRequestAbortedError, QueryServiceAPI, ServiceRequestCancelledError } from '../api';
import type { DatabaseStatus } from '../api/api-query-service';
import { CustomError, delay, seconds } from '../utils';
import { stateTracker } from '../model/model-state';
import { hash } from '../utils/utils-object';

class DatabaseStatusAPI {
    async getStatus(options: { timeout?: number; signal?: AbortSignal }) {
        try {
            const api = await QueryServiceAPI.get(options);
            const status = await api.organizations.getStatus({}, options);
            return status;
        } catch (error) {
            if (!(error instanceof ServiceRequestCancelledError)) throw error;
            throw new DatabaseFetchStatusTimeoutError(error);
        }
    }
}

export class DatabaseFetchStatusTimeoutError extends CustomError {
    constructor(cause?: unknown) {
        super('Timed out during fetch for database status', { cause });
    }
}

const statusIsEqual = (current: null | undefined | DatabaseStatus, prev: null | undefined | DatabaseStatus) => {
    if ((!current && prev) || (current && !prev)) return false;
    return hash(_.omit(current, 'timestamp')) === hash(_.omit(prev, 'timestamp'));
};

export class DatabaseStatusMonitorService extends EventTarget {
    protected started: boolean;
    protected interval: number;
    protected timeoutPoll: number;
    protected timeoutInit: number;
    protected api: DatabaseStatusAPI;
    protected promise: null | Promise<DatabaseStatus>;
    protected updatedAt: null | number;
    protected readonly state = stateTracker<{ status?: DatabaseStatus }>({});

    constructor() {
        super();
        this.interval = seconds(10);
        this.timeoutPoll = seconds(30);
        this.timeoutInit = seconds(30);
        this.api = new DatabaseStatusAPI();
        this.promise = null;
        this.updatedAt = null;
        this.started = false;
    }

    public async start(timeout?: number) {
        if (this.started) throw new Error('[DatabaseStatusMonitor] already started');
        console.debug('[DatabaseStatusMonitor] Starting...');
        this.started = true;
        const result = await this.updateStatus(timeout ?? this.timeoutInit);
        void this.poll();
        return result;
    }

    public stop() {
        console.debug('[DatabaseStatusMonitor] Stopping...');
        this.started = false;
    }

    public getStatusSync(): null | DatabaseStatus {
        const { status } = this.state.get();
        return status ?? null;
    }

    public async getStatus() {
        return this.getStatusSync() ?? (await this.updateStatus());
    }

    protected async fetchStatus(timeout?: null | number): Promise<DatabaseStatus> {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const abortTimeout = (() => {
            if (timeout === null) return null;
            return delay(timeout ?? this.timeoutPoll).then(() => {
                abortController.abort();
                throw new DatabaseFetchStatusTimeoutError();
            });
        })();
        const status = this.api.getStatus({ signal });
        return await Promise.race([
            status,
            ...(abortTimeout ? [abortTimeout] : []),
            //
        ]);
    }

    protected async updateStatus(timeout?: null | number) {
        this.promise ??= (async () => {
            try {
                const status = await this.fetchStatus(timeout);
                this.setStatus(status);
                return status;
            } finally {
                this.promise = null;
            }
        })();
        return this.promise;
    }

    protected setStatus(status: DatabaseStatus) {
        const previous = this.state.get().status;
        if (statusIsEqual(status, previous)) return;
        console.info('[DatabaseStatusMonitor]', 'status changed:', status);
        const current = this.state.set({ status });
        const detail = current;
        const event = new CustomEvent('databaseStatusChanged', { detail });
        this.dispatchEvent(event);
    }

    protected async update() {
        try {
            return await this.updateStatus();
        } catch (error) {
            if (error instanceof DatabaseFetchStatusTimeoutError) {
                console.error('[DatabaseStatusMonitor] status fetch timed out...');
            } else {
                if (isRequestAbortedError(error)) return;
                console.error('[DatabaseStatusMonitor] Could not get data status from query service:', error);
            }
        }
    }

    protected async tick() {
        await this.update();
        this.updatedAt = Date.now();
        await delay(this.pollDelay());
    }

    protected async poll() {
        this.updatedAt = Date.now();
        while (this.started) await this.tick();
    }

    protected pollDelay() {
        const timeSinceLastUpdate = (() => {
            if (_.isNil(this.updatedAt)) return Infinity;
            return Date.now() - this.updatedAt;
        })();
        let timeout = Math.max(0, this.interval - timeSinceLastUpdate);
        timeout = timeout + Math.floor(Math.random() * 1000);
        return timeout;
    }
}
