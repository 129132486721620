import { AuthUserModel } from '@42technologies/client-lib-auth';
import { isRequestAbortedError } from './lib/api';
import { getOrganization, getUser, isAllsaintsOrganizationId } from './lib/auth';

import { ConfigAPI } from './lib/config-api';
import { LoadingStatus, Browser } from './lib/services';
import type { IConfigObj } from './lib/types';

const FRASERS_HOST_OLD = 'dash-staging.42technologies.com';
const FRASERS_HOST = 'frasersgroup.42technologies.com';

const isLocalhost = ['localhost', '127.0.0.1'].includes(document.location.hostname);
const isProduction = ['dash.42technologies.com', 'app.42technologies.com'].includes(document.location.hostname);

function identifyUser(user: AuthUserModel, organizationId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    if (!window.analytics) return;
    if (user.isInternalRobot()) return;

    const userId = user.id;
    if (!userId) return;
    if (!organizationId) return;

    const analyticsIdentifyDisabledDestinations: Record<string, boolean> = {};

    window.analytics.ready(() => {
        // REMOVE INTERNAL STAFF
        // if (!user.isInternalStaff()) {
        //     window.posthog?.init('phc_oqZ7ErwMnVjIIMrez2bHgB9122hFlH0Zkqcn1ApOxgz', {
        //         api_host: 'https://app.posthog.com',
        //         segment: window.analytics,
        //         capture_pageview: false,
        //     });
        // } else {
        //     analyticsIdentifyDisabledDestinations['PostHog'] = false;
        // }

        if (!isAllsaintsOrganizationId(organizationId) || Math.random() <= 0.2) {
            window.posthog?.init('phc_oqZ7ErwMnVjIIMrez2bHgB9122hFlH0Zkqcn1ApOxgz', {
                api_host: 'https://app.posthog.com',
                segment: window.analytics,
                capture_pageview: false,
            });
        }

        window.analytics.identify(
            `${organizationId}/${userId}`,
            {
                name: user.name,
                role: user.role,
                email: user.email,
                organization: organizationId,
                createdAt: user.createdAt,
                updatedAt: user.updatedAt,
                isLocalhost,
            },
            analyticsIdentifyDisabledDestinations,
        );

        window.analytics.group(organizationId, {
            name: organizationId,
        });
        window.analytics.page();
    });
}

function getOrganizationConfig(organizationId: string) {
    LoadingStatus.loading('Configuring Dashboard');
    return ConfigAPI.get()
        .then(api => api.organization.get())
        .catch((error: unknown) => {
            if (isRequestAbortedError(error)) throw error;
            const message = 'Could not fetch organization config.';
            console.error('[bootstrap]', message, error);
            LoadingStatus.error(LoadingStatus.Messages.ConfigurationError(organizationId, 'org-config'));
            analytics.track('error/bootstrap/org-config-fetch-error', { message, error });
            throw error;
        });
}

function bootstrapAngular(orgConfig: IConfigObj) {
    patchJQuery($);
    require('./app.ts');
    angular.module('42.config', []).constant('CONFIG', orgConfig);
    angular.bootstrap('#app', ['42.dashboard', '42.modules.user-menu', '42.config']);
}

const loadOrganizationId = () =>
    getOrganization().then((organizationId: string) => {
        if (!organizationId) throw new Error('missing required organizationId');
        const isSportsdirect = ['sportsdirect'].some(x => organizationId.startsWith(x));
        if (!(isProduction && isSportsdirect)) return organizationId;
        window.location.replace(`https://${FRASERS_HOST}`);
        return new Promise<never>(() => {});
    });

// function loadAppConfig() {
//     LoadingStatus.loading('Fetching application config');
//     return AppConfigService.get().catch((error: any) => {
//         LoadingStatus.error(LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-app-config'));
//         throw error;
//     });
// }

function loadAppUser() {
    return getUser().catch(error => {
        if (isRequestAbortedError(error)) throw error;
        LoadingStatus.error(LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-user'));
        throw error;
    });
}

function start() {
    if (Browser.isNotSupported()) {
        LoadingStatus.error(LoadingStatus.Messages.BrowserCompatibilityError('unknown'));
        return Promise.reject(new Error('Browser is not supported'));
    }
    return Promise.all([
        loadAppUser().catch(error => {
            if (isRequestAbortedError(error)) throw error;
            const message = LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-user');
            LoadingStatus.error(message);
            throw error;
        }),
        loadOrganizationId().catch(error => {
            if (isRequestAbortedError(error)) throw error;
            const message = LoadingStatus.Messages.ConfigurationError('unknown', 'bootstrap-get-organization');
            LoadingStatus.error(message);
            throw error;
        }),
    ])
        .then(([user, organizationId]) => {
            identifyUser(user, organizationId);
            return getOrganizationConfig(organizationId);
        })
        .then(config => {
            try {
                return bootstrapAngular(config);
            } catch (error) {
                LoadingStatus.error(LoadingStatus.Messages.ConfigurationError(config.organization.id, 'bootstrap'));
                throw error;
            }
        });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function patchJQuery($: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const originalFn = $.fn.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    $.fn.data = function () {
        // eslint-disable-next-line prefer-rest-params
        if (arguments[0] === '$binding') return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, prefer-rest-params, @typescript-eslint/no-unsafe-call
        return originalFn.apply(this, arguments);
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return $;
}

function redirectOnLegacyHashUrl(window: Window) {
    const hasPath = window.location.pathname !== '/';
    const hasHash = window.location.hash !== '';
    const isOldUrl = !hasPath && hasHash;
    if (!isOldUrl) return;
    console.log('Redirecting to non-hash based url...');
    const newPath = window.location.hash.replace(/^#\//, '');
    const newUrl = new URL(newPath, window.location.origin);
    window.location.replace(newUrl.toString());
}

function redirectOnOldFrasersUrl(window: Window) {
    const isFrasersOldHost = window.location.host === FRASERS_HOST_OLD;
    if (!isFrasersOldHost) return;
    window.location.replace(`https://${FRASERS_HOST}`);
}

export function main() {
    redirectOnLegacyHashUrl(window);
    redirectOnOldFrasersUrl(window);
    return start().catch(error => {
        if (!isRequestAbortedError(error)) throw error;
        console.error(error);
        return;
    });
}
