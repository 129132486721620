import _ from 'lodash';
import type { IQuery } from '../../lib/types';
import type { AngularInjected } from '../../lib/angular';
import type {
    IOverviewTopItemsConfigService,
    ITopItemsResolvedConfig,
    ITopItemsView,
    TopItemsView,
} from './components/card-top-items';
import type { IOverviewStatsView, IOverviewStatsViewFactory } from './components/card-overall-stats';
import type { IOverviewOverallStats, IOverviewStat } from './overview-card-charts';
import { CardGridCharts, ISalesChartGridConfig, ISalesChartGridConfigService } from './components/card-chart-grid';
import { ISelectModel, SelectModel } from '../../lib/model';

export interface ISalesViewOptions {
    charts: ISalesChartsSelectModel;
    topItems?: ITopItemsResolvedConfig[];
    chartGrid?: ISalesChartGridConfig[];
}

export type ISalesChartsSelectModel = ISelectModel<IOverviewStat, string>;
export const SalesChartsSelectModel = ({
    available,
    selected,
}: {
    available: IOverviewStat[];
    selected?: IOverviewStat | string;
}): ISalesChartsSelectModel => {
    const selectedChart =
        selected !== undefined ? (typeof selected === 'string' ? selected : selected.actual.field) : undefined;
    return new SelectModel({
        state: { available, ...(selectedChart ? { selected: selectedChart } : {}) },
        getId: (x: IOverviewStat): string => x.actual.field,
    });
};
export type SalesView = AngularInjected<typeof SalesViewFactory>;
export type ISalesView = InstanceType<SalesView>;

export const SalesViewFactory = () => [
    '$q',
    'SaleCharts',
    'SalesChartGridConfig',
    'OverviewTopItemsConfigService',
    'OverviewStatsView',
    'TopItemsView',
    function SalesViewModel(
        $q: angular.IQService,
        SaleChartsService: IOverviewOverallStats,
        SalesChartsGridConfigService: ISalesChartGridConfigService,
        OverviewTopItemsConfigService: IOverviewTopItemsConfigService,
        OverviewStatsView: IOverviewStatsViewFactory,
        TopItemsView: TopItemsView,
    ) {
        const fetchOverviewViewData = async (options: { topItems?: ITopItemsResolvedConfig[] }) => {
            const [chartGrid, charts, topItems] = await Promise.all([
                SalesChartsGridConfigService.fetch(),
                SaleChartsService.fetch(),
                'topItems' in options ? OverviewTopItemsConfigService.fetch() : null,
            ]);
            return { chartGrid, charts, ...(topItems ? { topItems } : {}) };
        };

        return class SalesView {
            charts: ISalesChartsSelectModel;
            statsView: IOverviewStatsView;
            topItems?: ITopItemsView;
            chartGrid?: CardGridCharts;
            protected readonly query: IQuery;

            constructor(query: IQuery, private readonly options: ISalesViewOptions) {
                this.query = _.cloneDeep(query);
                this.charts = options.charts;

                this.statsView = new OverviewStatsView({
                    query: query,
                    charts: this.charts,
                    selectChart: this.selectChart.bind(this),
                });

                if ('chartGrid' in options) {
                    const chartGrid = options.chartGrid;
                    this.chartGrid = new CardGridCharts({ chartGrid });
                }

                if ('topItems' in options) {
                    const topItems = options.topItems;
                    this.topItems = new TopItemsView({
                        query: this.query,
                        chart: this.charts.getSelected(),
                        config: topItems,
                    });
                }

                void this.init(query);
            }

            protected init(query: IQuery) {
                return $q.when(fetchOverviewViewData(this.options)).then(result => {
                    const { chartGrid, charts, topItems } = result;
                    this.charts = (() => {
                        const selected = this.charts.getSelectedId();
                        return SalesChartsSelectModel({ available: charts, selected });
                    })();
                    this.statsView = (() => {
                        return new OverviewStatsView({ ...this.statsView, query, charts: this.charts });
                    })();
                    if (topItems) {
                        this.refreshTopItemsWidgets(topItems);
                    }
                    if (chartGrid) {
                        this.chartGrid = new CardGridCharts({ chartGrid });
                    }
                });
            }

            protected selectChart(chart: IOverviewStat) {
                const prev = this.charts.getSelectedId();
                if (prev === chart.actual.field) return;
                this.charts.select(chart.actual.field);
                this.refreshTopItemsWidgets();
            }

            refreshTopItemsWidgets(config?: ITopItemsResolvedConfig[]) {
                config ??= this.topItems?.config;
                if (!config) return;
                this.topItems = new TopItemsView({
                    query: this.query,
                    chart: this.charts.getSelected(),
                    config,
                });
            }
        };
    },
];
