import _ from 'lodash'
import { requestAnimationFrame, cancelAnimationFrame } from '../../lib/dom/request-animation-frame'


module = angular.module '42.controllers.counter', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'counter'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.controller 'CounterController', ($scope, CounterMetricsViewModel) ->
    $scope.model = new CounterMetricsViewModel()
    $scope.$watch 'model.fullscreen', (isFullscreen) ->
        $('body:not(.fullscreen)').addClass('fullscreen') if isFullscreen
        $('body.fullscreen').removeClass('fullscreen')    if not isFullscreen
    $scope.$on '$destroy', ->
        $('body.fullscreen').removeClass('fullscreen')


module.directive 'viewCounter', ($rootScope) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article promise-tracker="model.tracker" ng-class="{'fullscreen':model.fullscreen}" class="view view-counter">
        <div class="logo"></div>
        <div class="organization-logo"></div>
        <div class="background-image" style="background-image: url({{ model.image }})"></div>
        <icon class="button-fullscreen"
            ng-click="model.toggleFullscreen()"
            ng-class="{'icon-resize-full':!model.fullscreen, 'icon-resize-small':model.fullscreen}">
        </icon>
        <article class="counter-metric" ng-if="initialized">
            <main>
            <h1>{{ getGroupName() }} <span>| {{ model.selected.metric.headerGroup }}</span></h1>
            <div class="counter-metric-ty" fit-text>
                <counter-metric-money value="model.selected.ty"></counter-metric-money>
            </div>
            </main>
            <footer>
            <div class="counter-metric-ly">
                <label>LY</label>
                <counter-metric-money value="model.selected.ly"></counter-metric-money>
            </div>
            <div class="counter-metric-growth" ng-if="model.selected.growth">
                <label>% Change</label>
                <counter-metric-percentage value="model.selected.growth"></counter-metric-percentage>
            </div>
            </footer>
        </article>
    </article>
    """
    link: (scope) ->

        refresh = ->
            scope.initialized = true
            scope.model.refresh()

        scope.getGroupName = ->
            $rootScope.smartGroupsModel?.selected?.getModel().name or 'loading'

        scope.initialized = false
        scope.$on '$destroy', \
        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            scope.$on '$destroy', \
            $rootScope.$on 'query.refresh', refresh
            refresh()


module.directive 'counterMetricMoney', ($rootScope, $timeout, $filter) ->
    restrict: 'E'
    scope:
        value: '='
    replace: true
    template: \
    """
    <div class="counter-metric-money" ng-class="{'changing':view.changing}">
        <span class="counter-metric-money-currency">{{ view.currency }}</span>
        <span class="counter-metric-money-value">{{ view.display }}</span>
    </div>
    """
    link: (scope, element) ->

        scope.view =
            timer:    null
            previous: null
            current:  null
            target:   null
            display:  null

        timer = null
        bucketSize = 100

        updateScroller = -> $timeout ->
            cancelAnimationFrame(timer) if timer
            hasChanged = (scope.view.current isnt scope.view.target)
            scope.view.changing = hasChanged
            if hasChanged or not scope.view.display
                hasChanged = true
                tick = Math.ceil((scope.view.target - scope.view.previous) / bucketSize)
                scope.view.current = do ->
                    isLastBucket = Math.abs((scope.view.target - scope.view.current) / bucketSize) < 1
                    return scope.view.target if isLastBucket
                    return scope.view.current + tick
                scope.view.display = format(scope.view.current)
            timer = requestAnimationFrame(updateScroller)

        format = (value) ->
            value = $filter('money')(value, 0, false)
            return value.replace(scope.view.currency, '')

        updateValue = (nextValue, oldValue) ->
            return if _.isNull(nextValue)
            nextValue = Math.floor(nextValue)
            cancelAnimationFrame(timer) if timer
            scope.view.currency = ($rootScope.currencyModel.selected?.symbol or '$')
            scope.view.target   = nextValue
            scope.view.current  = scope.view.current || nextValue
            scope.view.previous = do ->
                return scope.view.target if _.isNull(oldValue)
                return scope.view.current
            updateScroller()

        scope.$watch('value', updateValue)



module.directive 'counterMetricPercentage', ->
    restrict: 'E'
    scope:
        value: '='
    replace: true
    template: \
    """
    <div class="counter-metric-percentage">
        <span percent="{{ value }}">{{ value | percent:false:false:1 }}</span>
    </div>
    """



module.factory 'CounterMetricsViewModel', (CONFIG, promiseTracker, CounterMetrics) ->
    DEFAULT_METRIC_ID = CONFIG.defaults?.metrics?.sortBy or 'net_sales_without_taxes'

    class CounterMetricsViewModel

        constructor: (tracker) ->
            @tracker   = tracker or promiseTracker()
            @selected  = null
            @available = null
            @fullscreen = false
            @image = @_getImage()

        toggleFullscreen: ->
            @fullscreen = !@fullscreen

        refresh: -> @tracker.addPromise do =>
            CounterMetrics.fetch().then (metrics) =>
                metricId = @_getMetric()
                @selected = _.find metrics, (x) -> x.id is metricId
                @available = metrics

        _getMetric: ->
            return CONFIG.views?.counter?.metric or DEFAULT_METRIC_ID

        _getImage: ->
            return CONFIG.views?.counter?.image or null




module.service 'CounterMetrics', ($rootScope, Utils, QueryServiceAPI, QueryMetrics, CounterMetric) ->

    fetch: (query) ->
        return QueryMetrics.fetch().then (metricsData) =>
            metrics = metricsData.map (metric) -> metric.field
            query = @_getQuery(query or $rootScope.query, metrics)
            return @_getData(query).then (data) => @_getAvailableMetrics(data, metricsData)

    _getAvailableMetrics: (data, metricsData) ->
        metrics = metricsData.filter (x) -> CounterMetric.IsAvailable(x, data)
        return metrics.map (x) -> new CounterMetric(x, data)

    _getQuery: (query, metrics) ->
        query = Utils.copy(query)
        query.options = {}
        query.options.property = 'stores.aggregate'
        query.options.metrics  = metrics
        return query

    _getData: (query) ->
        promise = QueryServiceAPI().then((api) -> api.query.metricsFunnel(query))
        @latestPromise = promise
        @latestPromise.then (data) =>
            throw new Error('A newer request has been made. Cancelling promise callback.') if @latestPromise isnt promise
            data = data?[0] or {}
            return data



module.factory 'CounterMetric', ->

    class CounterMetric

        @IsAvailable: (metric, data) ->
            [ty, ly, growth] = [metric.field, "growth_#{metric.field}_prev", "growth_#{metric.field}"]
            keys = Object.keys(data)
            for metric in [ty, ly, growth]
                return false if not (metric in keys)
            return true

        constructor: (metric, data) ->
            [ty, ly, growth] = [metric.field, "growth_#{metric.field}_prev", "growth_#{metric.field}"]
            @id = metric.field
            @ty = data[ty]
            @ly = data[ly]
            @growth = data[growth]
            @metric = metric
