import _ from 'lodash';
import type { IMetricDefinition } from '../../lib/types';
import type { IPromise } from 'angular';
import type { IQueryMetrics } from '../main-controller';
import type { AngularInjected } from '../../lib/angular';
import { ConfigAPI } from '../../lib/config-api';
import { normalizeOverviewConfigKpis } from './config-views-sales';

const DEFAULT_METRICS = [
    'net_sales',
    'net_sales_units',
    'average_net_sales',
    'gross_dollar_per_transaction',
    'transaction_count',
    'gross_sales_units_per_transaction',
    'returned_sales_as_of_gross_sales',
    'on_hands_units',
] as const;

export interface ISalesMetricDefinition extends IMetricDefinition {
    inverted: boolean;
}

const logMetricNotFoundError = _.memoize((field: string) => {
    console.error('[overview] Metric definition not found for:', field);
});
export const joinMetricIdsWithMetricDescriptors = (
    available: IMetricDefinition[],
    selected: (string | { field: string })[],
): ISalesMetricDefinition[] => {
    const definitions = _.keyBy(available, metric => metric.field);

    return selected.flatMap(metric => {
        if (!metric) return [];

        const [field, overrides] = (() => {
            if (typeof metric === 'string') return [metric, {}];
            const { field, ...overrides } = metric;
            return [field, overrides];
        })();

        if (!field) return [];
        let definition = definitions[field];
        if (!definition) {
            logMetricNotFoundError(field);
            return [];
        } else {
            definition = { ...definition, ...overrides, field };
            const cellClass = (definitions[`growth_${field}`] ?? definition).cellClass;
            const inverted = cellClass === 'percent-inverted';
            return [{ ...definition, field, inverted }];
        }
    });
};

const fetchSelectedMetrics = async () => {
    const config = await ConfigAPI.get();
    const [userConfig, orgConfig] = await Promise.all([config.user.getInternal(), config.organization.get()]);
    return (
        normalizeOverviewConfigKpis(userConfig) ??
        normalizeOverviewConfigKpis(orgConfig) ??
        DEFAULT_METRICS.map(field => ({ field }))
    );
};

export type IOverviewChartMetricsConfigService = AngularInjected<typeof OverviewChartMetricsConfigServiceFactory>;
export const OverviewChartMetricsConfigServiceFactory = () => [
    '$q',
    'QueryMetrics',
    function OverviewChartMetricsConfigService($q: angular.IQService, QueryMetrics: IQueryMetrics) {
        const fetch = async () => {
            const [available, selected] = await Promise.all([QueryMetrics.fetch(), fetchSelectedMetrics()]);
            return joinMetricIdsWithMetricDescriptors(available, selected);
        };
        let promise: null | IPromise<ISalesMetricDefinition[]> = null;
        return {
            fetch: () => {
                promise ??= fetch();
                return $q.when(promise).then(salesMetrics => {
                    const metrics = _.cloneDeep(salesMetrics);
                    if (metrics.length === 0) {
                        console.warn('[overview][chart][metrics] No metrics found in config for sales chart');
                        return null;
                    }
                    return QueryMetrics.applyCurrencyToMetrics(metrics);
                });
            },
        };
    },
];

export const SalesChartMetricsConfigFactory = () => [
    'OverviewChartMetricsConfigService',
    function OverviewChartMetricsConfigService(OverviewChartMetricsConfigService: IOverviewChartMetricsConfigService) {
        return {
            fetch: () => OverviewChartMetricsConfigService.fetch(),
        };
    },
];
