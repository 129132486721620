import _ from 'lodash'

class ReportParamsColumnStyleModel

    constructor: (@params) ->
        @selected = null
        @available = [
            {
                id:          "compact"
                label:       "Compact"
                description: "In one column"
            }
            {
                id:          "tabular"
                label:       "Tabular"
                description: "In separate columns"
            }
            {
                id:          "pivot"
                label:       "Pivot"
                description: "Best for pivot table"
            }
            {
                id:          "csv"
                label:       "CSV"
                description: "Best for lots of rows"
            }
        ]

    updateModelFromParams: ->
        id = @params.columnStyle
        @selected = _.find(@available, (x) -> x.id is id) or @available[0]

    updateParamsFromModel: ->
        @params.columnStyle = @selected.id


module = angular.module('42.controllers.scheduling.common')
module.directive 'reportParamsColumnStyle', [()->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-column-style">
        <header>
            <h1>What's the report format?</h1>
        </header>
        <main>
            <section class="layouts">
                <ul>
                    <li class="layout layout-{{ layout.id }}" ng-repeat="layout in model.available"
                        ng-click="view.select(layout)"
                        ng-class="{selected:view.isSelected(layout)}">
                        <span class="label">{{ layout.label }}</span>
                        <section class="example">
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                        </section>
                        <span class="description">{{ layout.description }}</span>
                    </li>
                </ul>
            </section>
        </main>
    </article>
    """
    link: (scope) ->
        scope.view =
            select: (item) ->
                scope.model.selected = item
            isSelected: (item) ->
                scope.model.selected is item

        watchers = []
        scope.$watch 'params', (params) ->
            return if not params
            scope.model = new ReportParamsColumnStyleModel(params)
            scope.model.updateModelFromParams()
            watchers.forEach (x) -> x()
            watchers.push scope.$watch 'model.selected', ->
                scope.model.updateParamsFromModel()
]
