import _ from 'lodash';

export const IncludesFilter = {
    parse: (text: unknown, value: string, caseSensitive?: boolean) => {
        if (_.isNil(text)) return [];
        const data = Array.isArray(text) ? text : [text];
        if (_.isNil(value) || value === '') return data;
        value = caseSensitive ? value : value.toLowerCase();
        return data.filter(x => (caseSensitive ? String(x) : String(x).toLowerCase()).includes(value));
    },
};

export const IncludesFilterFactory = () => [
    function IncludesFilterFn() {
        return IncludesFilter.parse;
    },
];

export default angular.module('42.filters.includes', []).filter('includes', IncludesFilterFactory());
