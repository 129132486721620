type Awaitable<T> = Awaited<T> | Promise<Awaited<T>>;

export interface ICachedService<T> {
    get(): T;
    init(): Promise<T>;
    fetch(): Promise<T>;
}

export function CachedService<T>(fetch: () => Awaitable<T>): ICachedService<Awaited<T>> {
    let instance: null | Awaited<T>;
    let promise: null | Promise<Awaited<T>>;
    const init = () => {
        promise ??= Promise.resolve()
            .then(fetch)
            .then(x => (instance = x));
        return promise;
    };
    const get = () => {
        if (!instance) throw new Error('Service not initialized');
        return instance;
    };
    return { get, init, fetch: init };
}
