import { IPropertyDefinition } from '../config-hierarchy';

export interface IPropertyGroup {
    key: string;
    properties: IPropertyDefinition[];
}

export const buildPropertyGroups = (properties: IPropertyDefinition[]): IPropertyGroup[] => {
    const groups = properties.reduce((acc: Record<string, IPropertyDefinition[]>, property) => {
        const groupName = property.category?.label ?? property.label ?? property.table ?? '';
        acc[groupName] = acc[groupName] ?? [];
        acc[groupName]?.push(property);

        return acc;
    }, {});

    const result = [];
    for (const [key, properties] of Object.entries(groups)) {
        result.push({ key, properties });
    }

    return result;
};
