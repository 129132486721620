import * as Highcharts from 'highcharts';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsData from 'highcharts/modules/data';
import HighchartsFunnel from 'highcharts/modules/funnel';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsPatchTick from './highcharts.patch.tick-positioner';
import HighchartsPatchFormatter from './highcharts.patch.label-formatter';
import HighchartsRoundedCorners from 'highcharts-rounded-corners';

HighchartsNoDataToDisplay(Highcharts);
HighchartsData(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsDrilldown(Highcharts);

HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

HighchartsPatchTick(Highcharts);
HighchartsPatchFormatter(Highcharts);
HighchartsRoundedCorners(Highcharts);

export default Highcharts;
