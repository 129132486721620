export const createBrowserService = (
    window: {
        navigator: Pick<Window['navigator'], 'userAgent'>;
        document: Pick<Document, 'location' | 'addEventListener' | 'removeEventListener' | 'visibilityState'>;
    } = globalThis.window,
) => {
    const document = window.document;

    const visibilityState = { isVisible: true };
    const updateVisibility = () => {
        visibilityState.isVisible = document.visibilityState !== 'hidden';
    };
    updateVisibility();
    document.addEventListener('visibilitychange', updateVisibility, false);

    return {
        Visibility: visibilityState,
        isLocalhost: () => {
            return document.location.hostname === 'localhost';
        },
        isNotSupported: () => {
            const userAgent = window.navigator.userAgent;
            return userAgent.indexOf('Trident/') >= 0 || userAgent.indexOf('Edge/') >= 0;
        },
        destroy: () => {
            document.removeEventListener('visibilitychange', updateVisibility);
        },
    };
};
export const Browser = createBrowserService();
