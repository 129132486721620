import _ from 'lodash';
import type { IPropertyView } from './smart-group-filter-property-item.directive';
import type { ISegmentPropertyFilter, ISegmentsView } from './smart-group-filter.directive';

export const PropertyFilterLabelDirective = () => [
    function PropertyFilterLabelDirectiveInstance(): angular.IDirective<
        angular.IScope & {
            propertyClick: ($event: Event, value: ISegmentPropertyFilter) => void;
            model: IPropertyView;
            values: ISegmentPropertyFilter[];
        }
    > {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <ul class="selected-filters-property-values">
                    <li class="property-value" ng-repeat="value in values" ng-class="{'excluded': model.propertyValues.excludeMode }">
                        <div class="property-value-label">{{ value.ogLabel }}</div>
                        <i class="property-value-icon icon-cancel-circled" ng-click="propertyClick($event, value)"></i>
                    </li>
                </ul>
            `,
            link: function SmartGroupsFilterDirectiveLink($scope) {
                $scope.values = [];

                $scope.$watch(
                    'model.propertyValues.selectedFilters',
                    (selectedPropertyFilters: Record<string, boolean>) => {
                        $scope.values =
                            $scope.model?.propertyValues.values?.filter(value => selectedPropertyFilters[value.id]) ||
                            [];
                    },
                );

                $scope.propertyClick = ($event, value) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    $scope.model.unSelectPropertyFilter(value);
                };
            },
        };
    },
];

export const PropertyFilterListDirective = () => [
    function PropertyFilterListDirectiveInstance(): angular.IDirective<
        angular.IScope & {
            model: ISegmentsView;
            isEmptyPropertyFilters: (view: IPropertyView) => void;
        }
    > {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <main>
                    <section class="selected">
                        <h1 class="selected-filters-title">Selected Filters</h1>
                        <div class="selected-filters-properties">
                            <div class="selected-filters-property"
                                ng-repeat="propertyView in model"
                                ng-if="!isEmptyPropertyFilters(propertyView)">
                                <div class="selected-filters-property-title">{{ propertyView.property.label }}</div>
                                <property-filter-label model="propertyView"></property-filter-label>
                            </div>
                        </div>
                    </section>
                </main>
            `,
            link: function SmartGroupsFilterDirectiveLink($scope) {
                $scope.isEmptyPropertyFilters = (view: IPropertyView) => _.isEmpty(view.propertyValues.selectedFilters);
            },
        };
    },
];
