import _ from 'lodash';
import { IConfigObj } from '../../lib/types';
import { AdsCardHeaderDirectiveFactory } from './components/ads-card-header.directive';
import { AdsChartDirectiveFactory, AdsSectionChartDirectiveFactory } from './components/ads-chart.directive';
import {
    AdsGridDirectiveFactory,
    AdsGridFactory,
    AdsSectionGridDirectiveFactory,
} from './components/ads-grid.directive';
import {
    AdsSectionStatsDirectiveFactory,
    AdsStatsDirectiveFactory,
    AdsStatsFactory,
} from './components/ads-stats.directive';
import { AdViewFactory, AdsControllerFactory, AdsViewFactory } from './ads.controller';
import { AdsServiceFactory } from './ads.service';

const module = angular.module('42.controllers.ads', []);
module.config(function AdsConfig(
    $routeProvider: angular.route.IRouteProvider,
    ROUTES: Record<string, angular.route.IRoute>,
    CONFIG: IConfigObj,
) {
    const routes = _.get(CONFIG, ['routes', 'ads'], {});
    const route = _.extend({}, ROUTES.ads, _.pick(routes, 'label', 'url'));
    if ('url' in route) {
        $routeProvider.when(route.url, route).otherwise({ redirectTo: '/' });
    }
});
module.controller('AdsController', AdsControllerFactory());
module.directive('adsStats', AdsStatsDirectiveFactory());
module.service('AdsStats', AdsStatsFactory());
module.service('AdsGrid', AdsGridFactory());
module.directive('adsCardHeader', AdsCardHeaderDirectiveFactory());
module.directive('adsSectionStats', AdsSectionStatsDirectiveFactory());
module.directive('adsGrid', AdsGridDirectiveFactory());
module.directive('adsSectionChart', AdsSectionChartDirectiveFactory());
module.directive('adsSectionGrid', AdsSectionGridDirectiveFactory());
module.directive('adsChart', AdsChartDirectiveFactory());
module.service('AdsView', AdsViewFactory());
module.service('AdView', AdViewFactory());
module.service('AdsService', AdsServiceFactory());
