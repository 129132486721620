import { downloadFromURL } from '../../lib/dom/download';

export class QueryServiceExport {
    // `host` the query service host
    // `data` is whatever the server returned.
    static downloadAs(filename: string) {
        return (props: { host: string; data: { id: string; type: string } }) => {
            const dataType = encodeURIComponent(props.data.type);
            filename = encodeURIComponent(filename);
            const id = encodeURIComponent(props.data.id);
            const url = `${props.host}/exports/${dataType}/${filename}?id=${id}`;
            return downloadFromURL({ url });
        };
    }
}
