import _ from 'lodash';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import { IQueryTableFilter } from '../../lib/types';
import {
    SmartGroupsPopupModel,
    ISmartGroupFilterDescriptor,
    IFiltersKey,
} from '../../modules/smart-groups/smart-groups.service';
import { AngularInjected } from '../../lib/angular';
import { ICalendarPropertiesService, IHourPropertyService } from '../main-controller';
import { ISegmentsPropertiesService } from '../../modules/smart-groups/smart-group-filter.directive';
import { IHierarchyService } from '../../modules/hierarchy';

export const SchedulingHierarchyFactory = () => [
    '$q',
    'Hierarchy',
    'SegmentsPropertiesService',
    'HourProperty',
    'CalendarProperties',
    function SchedulingHierarchyFn(
        $q: angular.IQService,
        Hierarchy: IHierarchyService,
        SegmentsPropertiesService: ISegmentsPropertiesService,
        HourProperty: IHourPropertyService,
        CalendarProperties: ICalendarPropertiesService,
    ) {
        const _handleFetch = (result: [IPropertyDefinition[], IPropertyDefinition[], IPropertyDefinition[]]) => {
            const [hierarchy, calendar, hour] = result;
            const configured = new Set(hierarchy.map(x => x.id));
            return [
                ...hierarchy,
                ...calendar.filter(x => !configured.has(x.id)),
                ...hour.filter(x => !configured.has(x.id)),
            ];
        };

        return {
            fetch: () =>
                $q
                    .all([
                        Hierarchy.fetch().then(x => x.groupBy),
                        CalendarProperties.fetch().then(x => x ?? []),
                        HourProperty.fetch().then(x => (x ? [x] : [])),
                    ])
                    .then(_handleFetch),

            fetchSegmentsProperties: (descriptorId: string) =>
                $q
                    .all([
                        SegmentsPropertiesService.fetchProperties(descriptorId).then(x => x.properties),
                        CalendarProperties.fetch().then(x => x ?? []),
                        HourProperty.fetch().then(x => (x ? [x] : [])),
                    ])
                    .then(_handleFetch),
        };
    },
];

export type ISchedulingHierarchy = AngularInjected<typeof SchedulingHierarchyFactory>;

export const ReportSmartGroupsPopupFactory = () => [
    function ReportSmartGroupsPopup() {
        return {
            popup: new SmartGroupsPopupModel(),
        };
    },
];

type IReportSmartGroupsPopup = AngularInjected<typeof ReportSmartGroupsPopupFactory>;

export const ReportParamsFilterModelFactory = () => [
    'ReportSmartGroupsPopup',
    'SegmentsPropertiesService',
    function ReportParamsFilterModelFn(
        ReportSmartGroups: IReportSmartGroupsPopup,
        SegmentsPropertiesService: ISegmentsPropertiesService,
    ) {
        return class ReportParamsFilterModel {
            descriptor: undefined | ISmartGroupFilterDescriptor;
            descriptorId: IFiltersKey;
            properties: IPropertyDefinition[] = [];
            filters: Record<string, IQueryTableFilter> = {};
            title: string | undefined;

            constructor(filters: Record<IFiltersKey, IQueryTableFilter>, descriptorId: IFiltersKey) {
                if (descriptorId !== 'stores' && descriptorId !== 'items')
                    throw new Error('Invalid `descriptor` argument.');
                this.descriptorId = descriptorId;
                this.filters = filters;
                this.init();
            }

            init() {
                this.refresh();
            }

            refresh() {
                void SegmentsPropertiesService.fetchDescriptors().then(descriptors => {
                    this.descriptor = descriptors[this.descriptorId];
                    this.properties = this.descriptor.hierarchy ?? [];
                    this.title = this.descriptor.label;
                    this._updateFromProperties(this.descriptor.hierarchy ?? []);
                });
            }

            reset() {
                for (const key of Object.keys(this.filters)) {
                    delete this.filters[key];
                }
            }

            editFilter() {
                if (!this.descriptor) return;
                const popupState = _.cloneDeep({
                    descriptor: this.descriptor,
                    filters: this.filters,
                });

                ReportSmartGroups.popup.open(_.cloneDeep(popupState), update => {
                    this.reset();
                    Object.keys(update).forEach(key => {
                        if (_.isEmpty(update[key])) delete update[key];
                    });
                    Object.assign(this.filters, update);
                });
            }

            _updateFromProperties(propertiesDefinitions: IPropertyDefinition[]) {
                const properties = propertiesDefinitions.map(p => p.id);
                const availableTables = _.uniq(properties.map(id => id.split('.')[0]));
                const unsupportedTables = _.compact(_.difference(Object.keys(this.filters), availableTables));
                unsupportedTables.forEach(t => delete this.filters[t]);

                for (const [key, value] of Object.entries(this.filters)) {
                    value.$and = (value.$and ?? []).filter(property => {
                        const field = Object.keys(property)[0];
                        if (!field) return false;
                        const propertyId = `${key}.${field}`;
                        return properties.includes(propertyId);
                    });
                    if (value.$and.length === 0) delete value.$and;
                }
            }
        };
    },
];

interface XAA {
    descriptor: ISmartGroupFilterDescriptor | undefined;
    filters: Record<string, IQueryTableFilter>;
}
