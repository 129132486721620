import _ from 'lodash';

import { Map } from './map-models';
import { MapDirective } from './map-directive';

const module = angular.module('42.controllers.map', []);

module.config([
    '$routeProvider',
    'ROUTES',
    'CONFIG',
    function ($routeProvider: any, ROUTES: Record<string, any>, CONFIG: any) {
        const routeOverrides = _.pick((CONFIG.routes ?? {}).map, 'label', 'url');
        const route = _.extend({}, ROUTES.map, routeOverrides);
        return $routeProvider.when(route.url, route);
    },
]);

module.directive('customerMapbox', MapDirective());
module.directive('mapView', () => ({
    restrict: 'E',
    scope: true,
    replace: true,
    template: `
    <div class="view view-map">
        <section class="map-container" ng-if="map.data">
            <customer-mapbox map="map" />
        </section>
    </div>
    `,
}));

module.controller('MapController', [
    '$rootScope',
    '$q',
    '$scope',
    'CONFIG',
    function (
        $rootScope: angular.IRootScopeService,
        $q: angular.IQService,
        $scope: angular.IScope & { map: null | Map },
        CONFIG: any,
    ) {
        $scope.map = null;
        const refresh = () => {
            $scope.map = new Map();
            $q.when($scope.map.init(CONFIG)).catch(error => {
                console.error(error);
            });
        };
        $rootScope.$watch('initialized', initialized => {
            if (!initialized) return;
            // $scope.$on('$destroy', $rootScope.$on('query.refresh', refresh));
            refresh();
        });
    },
]);
