import _ from 'lodash';
import Utils from '../../lib/utils';

export function isTotalRow<T extends { property0?: unknown }>(row: undefined | null | T, labels?: string[]) {
    row ??= {};
    let index = 0;
    labels ??= ['$total', '$loadingTotal'];
    while (`property${index}` in row) index++;
    return Array.from({ length: index }, (_, i) => i).some(i => {
        // return row?.[`property${i}`] === '$total' || row?.[`property${i}`] === '$loadingTotal';
        return labels.includes(row?.[`property${i}`]); // === '$total' || row?.[`property${i}`] === '$loadingTotal';
    });
}

export function isLoadingTotalRow<T extends { property0?: string | number | null }>(row: undefined | null | T) {
    return isTotalRow(row, ['$loadingTotal']);
}

export type IMetricsGridConfigViewsColumns = Record<string, number | undefined>;
export interface IMetricsGridConfigViews {
    columns?: IMetricsGridConfigViewsColumns;
}

export function deserializeViews(views: unknown): { columns?: Record<string, number> } {
    if (!Utils.isObject(views)) return {};
    if (!Utils.isObject(views.columns) && !Utils.isObject(views.filters)) return {};
    const columns: Record<string, number> = {};
    for (const [columnName, value] of Object.entries(views.columns ?? {})) {
        if (typeof value === 'number') columns[columnName] = value;
    }

    return {
        columns: _.isEmpty(columns) ? {} : columns,
    };
}
