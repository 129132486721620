export type InputEventWatcherOptions = { [key: string]: any };
export type InputEventWatcherState = any;

export abstract class InputEventWatcher<T extends InputEventListenerTarget> {
    protected listener: null | InputEventListener<T> = null;
    protected target: null | T = null;
    protected state: InputEventWatcherState;
    protected options: InputEventWatcherOptions;

    constructor(target: T, options?: InputEventWatcherOptions) {
        this.options = options ?? {};
        this.attach(target);
    }

    protected attach(target: T) {
        this.detach();
        this.target = target;
        this.listener = this.createListener(target);
    }

    protected createListener(target: T) {
        return new InputEventListener(target);
    }

    public detach() {
        this.listener?.detach();
        this.listener = null;
        this.target = null;
        this.state = null;
    }

    public getState(): InputEventWatcherState {
        return this.state;
    }

    public updateOptions(options: InputEventWatcherOptions) {
        this.options = Object.assign({}, this.options, options);
    }
}

export class InputEventListener<T extends InputEventListenerTarget> {
    protected target: T | null;
    private registered: (() => void)[] = [];

    constructor(target: T) {
        this.attach(target);
        this.target = target;
    }

    add(eventName: string, callback: InputEventListenerCallback, ...args: any[]) {
        const target = this.target;
        if (!target) return;
        target.addEventListener(eventName, callback, ...args);
        this.registered.push(() => {
            target.removeEventListener(eventName, callback);
        });
    }

    detach() {
        this.registered.forEach(this.remove.bind(this));
        this.target = null;
    }

    protected attach(target: T) {
        this.detach();
        this.target = target;
    }

    private remove(fn: () => void) {
        try {
            fn();
        } catch (error) {
            console.error(error);
        }
    }
}

export abstract class HTMLElementInputWatcher extends InputEventWatcher<HTMLElement | Window> {}

export type InputEventListenerCallback = (...args: any[]) => void;

export interface InputEventListenerTarget {
    addEventListener(type: string, listener: InputEventListenerCallback, ...args: any): void;
    removeEventListener(type: string, listener: InputEventListenerCallback): void;
    destroy?: () => void;
}
