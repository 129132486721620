export function parseString(value: unknown): null | string {
    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') return null;
    const trimmed = value.toString().trim();
    return trimmed.length > 0 ? trimmed : null;
}

const NumericParser =
    <T>(fn: (value: string) => T) =>
    (value: unknown): null | T => {
        if (typeof value !== 'string' && typeof value !== 'number') return null;
        const parsed = fn(value.toString());
        if (Number.isNaN(parsed)) return null;
        return parsed;
    };

// Mirrors the API of the orchestrator's DataFrameUtils
export const Parse = {
    String: parseString,
    Double: NumericParser(globalThis.parseFloat),
    Integer: NumericParser(globalThis.parseInt),
};
