import _ from 'lodash'
import Utils from '../../lib/utils'
import * as Analytics from '../../lib/analytics'
import { ToggleModel } from '../../lib/model/model-toggle'
import { createDuplicateLabel } from '../../lib/model/model-utils'
import { downloadFile } from '../../lib/services/download-file'
import { MetricsPageTabViewModel } from './metrics-tab-view-model'
import { isObject, CustomError } from '../../lib/utils'
import { MetricsPageViewDataSchema, MetricsPageViewMetaSchema } from './metrics-tabs-view-model-state-validator'
import { MetricsViewStorageAPI, MetricsViewsAPI } from './metrics-tabs-storage'

###*
@typedef {import('./metrics-tabs-view-model').IMetricsPageTabsViewModel} IMetricsPageTabsViewModel
###
export class MetricsPageTabsViewModel

    ###*
    @argument {import('./metrics-tabs-view-model').IMetricsPageTabsViewModelStorage} storage
    @argument {import('./metrics-tabs-view-model').IMetricsPageTabsViewModelParams} params
    ###
    constructor: (storage, {properties, metrics, available, selected, views}) ->
        @storage    = storage
        @properties = _.cloneDeep(properties)
        @metrics    = _.cloneDeep(metrics)
        @available  = available ? []
        @selected   = selected ? null
        @panel      = new ToggleModel(views?.panel?.isOpen ? true)

    reset: =>
        @available = []
        @addNewTab(false)
        return

    ###* @type {import('./metrics-tabs-view-model').MetricsPageTabsViewModel['resetTab']} ###
    resetTab: (tab) =>
        tab = @available.find((x) -> x.id is tab.id)
        return console.warn('tab not found') if not tab
        {id, name } = tab
        @selected = new MetricsPageTabViewModel(@, {id, name, metrics: tab.instance?.metrics?.selected})
        @available = @available.map (tab) =>
            return tab if tab.id isnt id
            return @selected
        return @saveState()

    addNewTab: (analytics = true) =>
        (try Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_METRICS)) if analytics
        return @addTab()

    addTab: =>
        @selected = new MetricsPageTabViewModel(@)
        @available.push(@selected)
        return @saveState()

    duplicated: =>
        name  = createDuplicateLabel(@selected.name)
        state = @selected.funnel.serialize()
        tabObj = new MetricsPageTabViewModel(@, {...state, name})
        index  = @available.indexOf(@selected)
        index ?= @available.length - 1
        @available = Utils.Array.insertAt(@available, (index+1), tabObj)
        @selected = tabObj
        return @saveState()

    removeTab: (id) =>
        return if not id
        index = @available.findIndex((x) -> x.id is id)
        @available = @available.filter((x) -> x.id isnt id)
        @selected = @available[Math.min(@available.length-1, index)]
        return @saveState()

    reorderTabs: (oldIndex, newIndex) =>
        @available = Utils.Array.move(@available, oldIndex, newIndex)
        @selected  = @available[newIndex]
        return @saveState()

    createTabFromJSON: (payload) =>
        try
            { funnelState: state, name } = payload
            tabObj = new MetricsPageTabViewModel(@, {...state, name: "#{name} (shared)"})
            @available.push(tabObj)
            @selected = tabObj
            return @saveState()
        catch error
            throw new CustomError("[metrics] Tab config import error", {cause:error})

    @ValidateViewConfigFile: (payload, orgId) ->
        try
            payload = JSON.parse(payload) if typeof payload is 'string'
        catch error
            console.error(error)
            throw new CustomError("View config import error: bad json.", {cause:error})
        if not isObject(payload)
            throw new CustomError("View config import error: must be a string or object")

        meta = MetricsPageViewMetaSchema.safeParse(payload.meta)
        if not meta.success
            console.error(meta.error)
            throw new CustomError('View config import error: meta field missing or invalid.')

        if meta.data.organizationId isnt orgId
            throw new CustomError('View config import error: incorrect organization.')

        parsedData = MetricsPageViewDataSchema.safeParse(payload.data)
        if not parsedData.success
            console.error(parsedData.error)
            throw new CustomError('Metrics Page View config import error: data field missing or invalid.')

        return parsedData.data

    exportTab: =>
        funnelState = @selected.funnel.serialize()
        name = @selected.name
        data = {funnelState, name}
        return downloadFile({
            data
            name
            type: 'tab-metrics'
            namespace: 'metrics'
            analyticsEvent: Analytics.EVENTS.USER_EXPORT_METRICS_TAB
        })

    ###* @type {import('./metrics-tabs-view-model').MetricsPageTabsViewModel['saveState']} ###
    saveState: =>
        @_saveStateDebounced ?= _.debounce((() => @_saveState()), 500)
        await @_saveStateDebounced()

    _saveState: =>
        state =
            views: {panel:@panel.serialize()}
            selected: @selected.id
            available: @available.map (x) ->
                {properties, metrics, views} = x.funnel.serialize()
                return {id:x.id, name:x.name, properties, metrics, views}
        data = await @storage.get()
        data ?= {}
        data.views ?= {}
        data.views.panel = state.views.panel
        data.available = state.available
        data.selected = state.selected
        return @storage.put(data)

    ###* @type {(typeof import('./metrics-tabs-view-model').MetricsPageTabsViewModel)['deserialize']} ###
    @deserialize: (storage, {metrics, properties, state}) ->
        state ?= {}
        views = do ->
            isOpen = (try state.views.panel.isOpen)
            isOpen = true if typeof isOpen isnt 'boolean'
            return {...(state.views ? {}), panel:{isOpen}}
        model = do ->
            return new MetricsPageTabsViewModel(storage, {properties, metrics, views})
        model.available = do ->
            available = (try state.available) ? []
            available = [{}] if not Array.isArray(available) or available.length is 0
            available = available.map (state) -> new MetricsPageTabViewModel(model, state)
            return available
        model.selected = do ->
            return _.find(model.available, (x) -> x.id is state.selected) ? model.available[0]
        return model


    ###* @type {(typeof import('./metrics-tabs-view-model').MetricsPageTabsViewModel)['Storage']} ###
    @Storage: () ->
        return MetricsViewsAPI(MetricsViewStorageAPI())
