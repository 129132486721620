import { MouseObserverAngular } from '../lib/dom/mouse-observer';
import { ToggleModel } from '../lib/model/model-toggle';
import './list-custom-header.scss';

export interface IListSearchBoxDirectiveScope extends angular.IScope {
    search: { id: string };
    onSearch: (value: string) => void;
}

export const ListSearchBoxDirective = () => [
    function ListSearchBox(): angular.IDirective<IListSearchBoxDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                onSearch: '=',
            },
            replace: true,
            template: `
                <div class="list-menu-search-box filter-input-search"
                    ng-class="{'filtered': search.id !== ''}">
                    <i class="icon-clear-filter icon-cancel-circled"
                        ng-click="search.id = ''">
                    </i>
                    <input
                        type="text"
                        spellcheck="false"
                        placeholder="search"
                        ng-model="search.id"
                    >
                    <i class="icon-search"></i>
                </div>
            `,
            link: function ListSearchBoxLink($scope) {
                $scope.search = {
                    id: '',
                };

                $scope.$watch('search.id', (value: string) => {
                    $scope.onSearch(value);
                });
            },
        };
    },
];

export interface IListCustomHeaderOptions {
    onSearch: (value: string) => void;
    reset: () => void;
    selectAll: () => void;
    itemLabel?: string;
    back?: () => void;
    actions: { messages: string[]; toggle: ToggleModel }[];
}
interface IListCustomHeaderDirectiveScope<T> extends angular.IScope {
    model: { available: T[]; selected: T[] };
    message: string;
    options: IListCustomHeaderOptions;
    onModeClick: (modeOn: boolean) => void;
}

interface IListCustomHeaderAdvancedDirectiveScope<T> extends angular.IScope {
    model: { available: T[]; selected: T[] };
    message: string;
    options: IListCustomHeaderOptions;
}

const groupingSVG = `
    <svg viewBox="0 0 24 24">
        <g>
            <path style="stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 8 2 L 8 7 L 17 7 L 17 2 Z M 8 1 L 17 1 C 17.550781 1 18 1.449219 18 2 L 18 7 C 18 7.550781 17.550781 8 17 8 L 8 8 C 7.449219 8 7 7.550781 7 7 L 7 2 C 7 1.449219 7.449219 1 8 1 Z M 1 16 L 1 21 L 9 21 L 9 16 Z M 1 15 L 9 15 C 9.550781 15 10 15.449219 10 16 L 10 21 C 10 21.550781 9.550781 22 9 22 L 1 22 C 0.449219 22 0 21.550781 0 21 L 0 16 C 0 15.449219 0.449219 15 1 15 Z M 15 16 L 15 21 L 23 21 L 23 16 Z M 15 15 L 23 15 C 23.550781 15 24 15.449219 24 16 L 24 21 C 24 21.550781 23.550781 22 23 22 L 15 22 C 14.449219 22 14 21.550781 14 21 L 14 16 C 14 15.449219 14.449219 15 15 15 Z M 15 15 " />
            <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 4.5 11 L 19.5 11 C 19.832031 11 20 11.167969 20 11.5 C 20 11.832031 19.832031 12 19.5 12 L 4.5 12 C 4.167969 12 4 11.832031 4 11.5 C 4 11.167969 4.167969 11 4.5 11 Z M 4.5 11 " />
            <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 4.5 11 C 4.832031 11 5 11.167969 5 11.5 L 5 15.5 C 5 15.832031 4.832031 16 4.5 16 C 4.167969 16 4 15.832031 4 15.5 L 4 11.5 C 4 11.167969 4.167969 11 4.5 11 Z M 4.5 11 " />
            <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 19.5 11 C 19.832031 11 20 11.167969 20 11.5 L 20 15.5 C 20 15.832031 19.832031 16 19.5 16 C 19.167969 16 19 15.832031 19 15.5 L 19 11.5 C 19 11.167969 19.167969 11 19.5 11 Z M 19.5 11 " />
            <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 12.5 7 C 12.832031 7 13 7.167969 13 7.5 L 13 11.5 C 13 11.832031 12.832031 12 12.5 12 C 12.167969 12 12 11.832031 12 11.5 L 12 7.5 C 12 7.167969 12.167969 7 12.5 7 Z M 12.5 7 " />
        </g>
    </svg>
`;

// This needs to be generic, and it will with the new react icons.
export interface IListCustomHeaderAdvancedIconParams {
    model: {
        messages: string[];
        toggle: ToggleModel;
        icon?: string;
    };
}

interface IListCustomHeaderAdvancedIconDirectiveScope extends IListCustomHeaderAdvancedIconParams, angular.IScope {
    onIconClick: () => void;
    hideTooltip: boolean;
}

export const ListCustomHeaderAdvancedGroupingIconDirective = () => [
    function ListCustomHeaderAdvancedGroupingIcon(): angular.IDirective<IListCustomHeaderAdvancedIconDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div
                    class="list-header-action-icon"
                    ng-class="{
                        active: model.toggle.isActive,
                    }"
                    ng-click="onIconClick()"
                >
                    ${groupingSVG}
                    <tooltip ng-if="model.messages" messages="model.messages"></tooltip>
                </div>,
            `,
            link: function ListCustomHeaderAdvancedLink($scope, $element) {
                const HIDE_TOOLTIP_CLASS = 'hide-tooltip';
                const mouseElementObserver = new MouseObserverAngular($scope, $element[0]);
                mouseElementObserver.onMouseLeave(() => {
                    if ($element.hasClass(HIDE_TOOLTIP_CLASS)) $element.removeClass(HIDE_TOOLTIP_CLASS);
                });

                $scope.onIconClick = () => {
                    $scope.model.toggle.toggle();

                    if (!$element.hasClass(HIDE_TOOLTIP_CLASS)) $element.addClass(HIDE_TOOLTIP_CLASS);
                };
            },
        };
    },
];

export const ListCustomHeaderAdvancedDirective = () => [
    function ListCustomHeaderAdvanced<T>(): angular.IDirective<IListCustomHeaderAdvancedDirectiveScope<T>> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
                back: '=',
                options: '=',
            },
            replace: true,
            template: `
                <div class="list-custom-header-advanced">
                    <div class="list-header-content">
                        <div class="list-header-top header-title">
                            <div class="action-button" ng-if="back" ng-click="back()">
                                <i class="icon-left-open"></i>
                            </div>
                            <div class="list-header-title">
                                {{ options.itemLabel }}
                            </div>
                        </div>
                        <div class="list-header-top">
                            <list-search-box
                                ng-if="model.available.length > 0 && toggle.isActive"
                                on-search="options.onSearch">
                            </list-search-box>
                        </div>
                        <div class="list-header-top spaced">
                            <div class="list-header-selection-actions" ng-if="toggle.isActive">
                                <div class="list-header-selection-actions-left">
                                    <div class="list-header-selection-action-select-all"
                                        ng-class="{'with-reset': model.available.length > 0 && model.selected.length !== model.available.length && model.selected.length > 0}">
                                        <div
                                            class="list-header-selection-action select-all"
                                            ng-if="model.available.length > 0 && model.selected.length !== model.available.length"
                                            ng-click="options.selectAll()">
                                            select all
                                        </div>
                                    </div>
                                    <div
                                        class="list-header-selection-action"
                                        ng-if="model.selected.length > 0"
                                        ng-click="options.reset()">
                                        reset
                                    </div>
                                </div>
                            </div>
                            <div class="list-header-actions">
                                <list-custom-header-advanced-icon
                                    ng-repeat="action in options.actions"
                                    model="action"
                                >
                                </list-custom-header-advanced-icon>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: function ListCustomHeaderAdvancedLink($scope) {
                $scope.$watch('model.selected', (selected: T[] | undefined): void => {
                    if (selected === undefined || selected.length === 0) {
                        $scope.message = '';
                        return;
                    }

                    const numberOfAvailable = $scope.model.available.length;
                    $scope.message =
                        numberOfAvailable === selected.length
                            ? `selected all ${selected.length} ${$scope.options.itemLabel ?? 'items'}`
                            : `selected ${selected.length} of ${numberOfAvailable} ${
                                  $scope.options.itemLabel ?? 'items'
                              }`;
                });
            },
        };
    },
];

export interface IListCustomHeaderParamsOptions {
    label: string;
    onSearch: (value: string) => void;
    back?: () => void;
}

export interface IListCustomHeaderAdvancedCompactParams<T> {
    model: { available: T[]; selected: T[] };
    toggle: ToggleModel;
    options: IListCustomHeaderParamsOptions;
}

interface IListCustomHeaderAdvancedCompactDirectiveScope<T>
    extends IListCustomHeaderAdvancedCompactParams<T>,
        angular.IScope {
    message: string;
}

export const ListCustomHeaderAdvancedCompactDirective = () => [
    function ListCustomHeaderAdvancedCompact<T>(): angular.IDirective<
        IListCustomHeaderAdvancedCompactDirectiveScope<T>
    > {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
                options: '=',
            },
            replace: true,
            template: `
                <div class="list-custom-header-advanced">
                    <div class="list-header-content">
                        <div class="list-header-top header-title" ng-if="options.back">
                            <div class="action-button" ng-click="options.back()">
                                <i class="icon-left-open"></i>
                            </div>
                            <div class="list-header-title">
                                {{ options.label }}
                            </div>
                        </div>
                        <div class="list-header-top">
                            <list-search-box
                                ng-if="model.available.length > 0 && toggle.isActive"
                                on-search="options.onSearch">
                            </list-search-box>
                        </div>
                    </div>
                </div>
            `,
            link: function ListCustomHeaderAdvancedLink($scope) {
                $scope.$watch('model.selected', (selected: T[] | undefined): void => {
                    if (selected === undefined || selected.length === 0) {
                        $scope.message = '';
                        return;
                    }

                    const numberOfAvailable = $scope.model.available.length;
                    $scope.message =
                        numberOfAvailable === selected.length
                            ? `selected all ${selected.length} ${$scope.options.label}`
                            : `selected ${selected.length} of ${numberOfAvailable} ${$scope.options.label}`;
                });
            },
        };
    },
];

export const ListCustomHeaderDirective = () => [
    function ListCustomHeader<T>(): angular.IDirective<IListCustomHeaderDirectiveScope<T>> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
                itemLabel: '@',
                back: '=',
                options: '=',
            },
            replace: true,
            template: `
                <div class="list-custom-header">
                    <div class="list-header-top">
                        <div class="action-button" ng-if="back" ng-click="back()">
                            <i class="icon-left-open"></i>
                        </div>
                        <list-search-box
                            ng-if="model.available.length > 0 && toggle.isActive"
                            on-search="options.onSearch">
                        </list-search-box>
                    </div>
                    <div class="list-header-selection-actions" ng-if="toggle.isActive">
                        <div class="list-header-selection-actions-left">
                            <div class="list-header-selection-action-select-all"
                                ng-class="{'with-reset': model.available.length > 0 && model.selected.length !== model.available.length && model.selected.length > 0}">
                                <div
                                    class="list-header-selection-action select-all"
                                    ng-if="model.available.length > 0 && model.selected.length !== model.available.length"
                                    ng-click="options.selectAll()">
                                    select all
                                </div>
                            </div>
                            <div
                                class="list-header-selection-action"
                                ng-if="model.selected.length > 0"
                                ng-click="options.reset()">
                                reset
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: function ListCustomHeaderLink($scope) {
                $scope.$watch('model.selected', (selected: T[] | undefined): void => {
                    if (selected === undefined || selected.length === 0) {
                        $scope.message = '';
                        return;
                    }

                    const numberOfAvailable = $scope.model.available.length;
                    $scope.message =
                        numberOfAvailable === selected.length
                            ? `selected all ${selected.length} ${$scope.options.itemLabel ?? 'items'}`
                            : `selected ${selected.length} of ${numberOfAvailable} ${
                                  $scope.options.itemLabel ?? 'items'
                              }`;
                });
            },
        };
    },
];

const listCustomHeaderModule = angular
    .module('42.components.list-search-box', [])
    .directive('listSearchBox', ListSearchBoxDirective())
    .directive('listCustomHeader', ListCustomHeaderDirective())
    .directive('listCustomHeaderAdvanced', ListCustomHeaderAdvancedDirective())
    .directive('listCustomHeaderAdvancedIcon', ListCustomHeaderAdvancedGroupingIconDirective())
    .directive('listCustomHeaderAdvancedCompact', ListCustomHeaderAdvancedCompactDirective());

export default listCustomHeaderModule;
