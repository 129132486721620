import { isObject } from '../../lib/utils';
import { IGridActionsModel } from './components/grid-cell-renderer';
import { IItemGridQuery } from './items-controller';

export class ItemGridDataViewModel {
    loading: boolean | null;
    error = false;
    model: IGridActionsModel;
    data: null | { items: Record<string, unknown>[] }[];
    hasImages: boolean | null;
    ItemGridQuery: IItemGridQuery;

    constructor(model: IGridActionsModel, ItemGridQuery: IItemGridQuery) {
        this.loading = true;
        this.model = model;
        this.data = null;
        this.hasImages = null;
        this.ItemGridQuery = ItemGridQuery;
        void this.init();
    }

    fetch() {
        return this.ItemGridQuery.fetch(this.model.toQuery());
    }

    init() {
        return this.fetch()
            .then(data => {
                this.setData(data);
            })
            .catch(err => {
                this.error = true;
                this.data = [];
                throw err;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    private setData(data: { items: Record<string, unknown>[] }[]) {
        this.data = data;
        this.hasImages = this._hasImages(data);
        this.loading = false;
    }

    private _hasImages(data: { items: Record<string, unknown>[] }[]) {
        const row = data?.[0];
        if (!isObject(row)) return false;
        if ('item_image' in row) return true;
        const cell = row.items[0];
        if (!isObject(cell)) return false;
        return 'item_image' in cell;
    }
}
