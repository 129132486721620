import _ from 'lodash'
import moment from 'moment'
import { TimerangeFilter } from '../../../lib/query/query-builder'
import { getComparisonModes } from '../../../modules/datepicker/lib/datepicker-comparison-mode.model'
import { logError } from '../../../lib/analytics'

module = angular.module('42.controllers.scheduling.common')


module.directive 'reportParamsViewerTimerange', (ReportParamsTimerangeModel) ->
    restrict: "E"
    scope:
        params: "="
    template: \
    """
    <article class="report-params-viewer report-params-timerange">
        <header>
            <h1>What's the time range?</h1>
        </header>
        <span class="ui-pellet active disabled">
            <span ng-if="view.label">{{ view.label }}</span>
            <span ng-if="view.calendar">{{ view.calendar }}</span>
            <span class="ui-pellet-value" ng-if="view.timerange">
                <span class="timerange-start">{{ view.timerange.start }}</span>
                <span>to</span>
                <span class="timerange-end">{{ view.timerange.end }}</span>
            </span>
            <span class="ui-pellet-detail" ng-if="model.mode && model.mode.id != 'year'">{{ model.mode.label }} Comparison</span>
        </span>
    </article>
    """
    link: (scope) ->
        watchers = []
        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            scope.model = new ReportParamsTimerangeModel(params)
            watchers.forEach (x) -> x()
            watchers = []
            watchers.push scope.$watch 'params.timerange', ->
                scope.model.updateModelFromParams()
            watchers.push scope.$watch 'model.timerange', ->
                scope.model.updateParamsFromModel()
                scope.view = {}
                scope.view.label = do ->
                    return scope.model.timerange.label if scope.model.timerange.id isnt 'custom'
                    return "Custom Timerange"
                scope.view.calendar = try do ->
                    return scope.model.calendarModel.getDatepicker().label
                scope.view.timerange = do ->
                    return if scope.model.timerange.id isnt 'custom'
                    {start, end} = scope.model.calendarModel.getDatepicker().getSelection()
                    [start, end] = [start, end].map (x) -> x.toDate().format('MMM DD, YYYY')
                    start: start
                    end: end


module.directive 'reportParamsTimerangeSelectXFromNow', (ReportingState, ReportParamsTimerangeXFromNowDisplay) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-params-timerange-select-x-from-now">
        <header>
            <article class="start-date">
                How many
                <select ng-options="x.label for x in model.start.offset.unit.available" ng-model="model.start.offset.unit.selected"></select>
                from <em>{{ display.now }}</em>?
                <article class="input-offset-count-container">
                    <input class="input-offset-count" type="number" ng-model="model.start.offset.count"></input>
                    <span>{{ model.start.offset.unit.selected.label.toLowerCase() }}</span>
                </article>
            </article>
        </header>
        <article class="display" ng-if="display.isValid()">
            <h1>
                Time Range Preview
                <span class="hint">(when used in a schedule, this time range will update automatically)</span>
            </h1>
            <article ng-if="display.isValid()" class="display-content display-timerange">
                <span class="start">{{ display.start }}</span>
                to
                <span class="end">{{ display.end }}</span>
            </article>
            <article ng-if="!display.isValid()" class="display-content display-error">
                Enter the number of {{ model.start.offset.unit.selected.label.toLowerCase() }} to get a preview
            </article>
        </article>
    </article>
    """
    link: (scope) ->
        scope.display = null

        updateDisplay = _.throttle (->
            scope.display = new ReportParamsTimerangeXFromNowDisplay(scope.model)
        ), 50

        updateModel = ->
            scope.model.updateSelectorModels()
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

        update = ->
            return if not scope.model
            updateModel()
            scope.model.updateParamsFromModel()
            updateDisplay()

        scope.$watch 'model.start.offset', update, true
        scope.$on '$destroy', ->
            fields = scope.model.getInvalidFields()
            Object.keys(fields).forEach (k) -> fields[k] = false
            ReportingState.report.updateInvalidFields(fields)


module.directive 'reportParamsTimerangeDatepickerSelect', ->
    restrict: 'E'
    scope:
        model: '=' # calendar model
    template: \
    """
    <div class="report-params-calendar-select" ng-if="view.datepickers.available.length > 1">
        <span class="calendar-type-title">Calendar</span>
        <select
            ng-options="d as d.model.label for d in view.datepickers.available"
            ng-model="view.datepickers.selected">
        </select>
    </div>
    """
    link: (scope) ->
        scope.view = {}
        scope.$watch 'model.state.ref', (state) ->
            return if not state
            scope.view.datepickers = scope.model.getDraft().datepickers?.view
        scope.$watch 'view.datepickers.selected.id', ->
            selected = try scope.view.datepickers.selected
            return if not selected?.model.calendarId
            scope.model.setState do ->
                draft = scope.model.getDraft()
                draft.setCalendar(selected.model.calendarId)
                return draft


module.directive 'reportParamsTimerange', (CONFIG, Utils, ReportParamsTimerangeModel) ->
    restrict: "E"
    scope:
        params: "="
    replace: true
    template: \
    """
    <article class="report-params report-params-timerange">
        <header>
            <h1>What's the time range?</h1>
        </header>

        <section class="report-params-timerange-select">
            <select
                ng-options="x as (x.label) group by (x.group) for x in model.timeranges"
                ng-model="model.timerange"
            ></select>
            <div class="report-params-calendar" ng-if="model.timerange.id !== 'custom'">
                <report-params-timerange-datepicker-select model="model.calendarModel"></report-params-timerange-datepicker-select>
            </div>
            <div class="report-params-timerange-custom" ng-if="model.timerange.id == 'custom'">
                <smart-groups-filter-time model="model.calendarModel"></smart-groups-filter-time>
            </div>
        </section>

        <section class="report-params-timerange-select-x-from-now-container" ng-if="model.timerange.id == 'x-from-now'">
            <report-params-timerange-select-x-from-now model="model.xFromNowModel"></report-params-timerange-select-x-from-now>
        </section>

        <section class="report-params-timerange-options-container" ng-class="{active:view.showOptions}" ng-if="model.timerange.id != 'custom'">
            <div class="report-params-timerange-options-toggle" ng-click="view.showOptions = !view.showOptions">
                <i class="{{ view.showOptions && 'icon-down-open-mini' || 'icon-right-open-mini' }}">Additional Options...</i>
            </div>
            <ul class="report-params-timerange-options" ng-if="view.showOptions">
                <li class="report-params-timerange-option report-params-timerange-option-mode">
                    <label>
                        <span class="option-title">Comparison Mode</span>
                        <span class="option-help-text">How should we compare against the current timerange?</span>
                        <select ng-model="model.mode" ng-options="x.label for x in model.modes"></select>
                    </label>
                </li>
            </ul>
        </section>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.paramTimerangeHash = ->
            return null if not scope.params.timerange
            return Utils.object.hash(scope.params.timerange)

        scope.view = {showOptions:false}

        initModel = (params) ->
            watchers.forEach (x) -> x()
            watchers = []
            scope.model = new ReportParamsTimerangeModel(params)

            watchers.push scope.$watch 'model.timerange.id', ->
                scope.model.updateParamsFromModel()
            watchers.push scope.$watch 'model.mode.id', ->
                scope.model.updateParamsFromModel()

            watchers.push scope.$watch 'model.calendarModel.state.ref', ->
                scope.model.updateParamsFromModel()

            watchers.push scope.$watch 'paramTimerangeHash()', ->
                scope.model.updateModelFromParams()

            watchers.push scope.$watch 'params.timerangeComparisonMode', ->
                scope.model.updateModelFromParams()

            watchers.push scope.$watch 'params.timerangeCalendarId', ->
                scope.model.updateModelFromParams()

        scope.$watch 'params', (params) ->
            initModel(params) if not _.isNil(params)


module.service 'ReportParamsTimerangeTimeranges', () -> get: -> [
    {id:'custom',             group:'Frequently Used', label:'Custom Timerange'}

    {id:'last-complete-week', group:'Frequently Used', label:'Last Complete Week'}
    {id:'last-complete-mtd',  group:'Frequently Used', label:'MTD – Complete Weeks'}
    {id:'last-complete-qtd',  group:'Frequently Used', label:'QTD – Complete Weeks'}
    {id:'last-complete-std',  group:'Frequently Used', label:'STD – Complete Weeks'}
    {id:'last-complete-ytd',  group:'Frequently Used', label:'YTD – Complete Weeks'}

    {id:'complete-months-qtd',  group:'Frequently Used', label:'QTD – Complete Months'}
    {id:'complete-months-std',  group:'Frequently Used', label:'STD – Complete Months'}
    {id:'complete-months-ytd',  group:'Frequently Used', label:'YTD – Complete Months'}

    {id:'x-from-now',         group:'Frequently Used', label:'X From Now'}

    {id:'yesterday',        group:'To Yesterday', label:'Yesterday'}
    {id:'wtd-complete-day', group:'To Yesterday', label:'WTY – Week To Yesterday'}
    {id:'mtd-complete-day', group:'To Yesterday', label:'MTY – Month To Yesterday'}
    {id:'qtd-complete-day', group:'To Yesterday', label:'QTY – Quarter To Yesterday'}
    {id:'std-complete-day', group:'To Yesterday', label:'STY – Season To Yesterday'}
    {id:'ytd-complete-day', group:'To Yesterday', label:'YTY – Year To Yesterday'}

    {id:'wtd',              group:'To Date', label:'WTD – Week To Date'}
    {id:'mtd',              group:'To Date', label:'MTD – Month To Date'}
    {id:'qtd',              group:'To Date', label:'QTD – Quarter To Date'}
    {id:'std',              group:'To Date', label:'STD – Season To Date'}
    {id:'ytd',              group:'To Date', label:'YTD – Year to Date'}
    {id:'dtd',              group:'To Date', label:'DTD – Day To Date'}

    {id:'last-complete-week',    group:'Last Complete', label:'Last Complete Week'}
    {id:'last-complete-month',   group:'Last Complete', label:'Last Complete Month'}
    {id:'last-complete-quarter', group:'Last Complete', label:'Last Complete Quarter'}
    {id:'last-complete-season',  group:'Last Complete', label:'Last Complete Season'}
    {id:'last-complete-year',    group:'Last Complete', label:'Last Complete Year'}

    {id:'rolling-complete-2-week',   group:'Last Complete (Rolling)', label:'Last Complete 2 Weeks'}
    {id:'rolling-complete-3-week',   group:'Last Complete (Rolling)', label:'Last Complete 3 Weeks'}
    {id:'rolling-complete-4-week',   group:'Last Complete (Rolling)', label:'Last Complete 4 Weeks'}
    {id:'rolling-complete-6-week',   group:'Last Complete (Rolling)', label:'Last Complete 6 Weeks'}
    {id:'rolling-complete-8-week',   group:'Last Complete (Rolling)', label:'Last Complete 8 Weeks'}
    {id:'rolling-complete-13-week',  group:'Last Complete (Rolling)', label:'Last Complete 13 Weeks'}
    {id:'rolling-complete-26-week',  group:'Last Complete (Rolling)', label:'Last Complete 26 Weeks'}
    {id:'rolling-complete-52-week',  group:'Last Complete (Rolling)', label:'Last Complete 52 Weeks'}

    {id:'last-complete-2-week',   group:'Last Complete (Ago)', label:'2 Weeks Ago'}
    {id:'last-complete-3-week',   group:'Last Complete (Ago)', label:'3 Weeks Ago'}

    # {id:'week-from-now',    group:'From Now', label:'Week from Now'}
    # {id:'month-from-now',   group:'From Now', label:'Month from Now'}
    # {id:'quarter-from-now', group:'From Now', label:'Quarter from Now'}
    # {id:'season-from-now',  group:'From Now', label:'Season from Now'}
    # {id:'year-from-now',    group:'From Now', label:'Year from Now'}
    {id:'all', group:'All Time', label:'All Time'}
]

module.factory 'ReportParamsTimerangeXFromNowDisplay', () -> class ReportParamsTimerangeXFromNowDisplay

    constructor: (@model) ->
        @update()

    isValid: ->
        return @start and @end

    update: ->
        return if not @model.calendarModel
        baseFormat = "ddd, MMM Do"
        now = @model.calendarModel.getDatepicker().getBounds().end
        @now ?= now.format(baseFormat)
        result =
            start: @_applyOffset(now, @model?.start?.offset)
            end:   @_applyOffset(now, @model?.end?.offset)
        if result.start and result.end
            yearFormat = if result.start.format('YYYY') isnt result.end.format('YYYY') then 'YYYY' else ''
            for k in Object.keys(result)
                format = "#{baseFormat} #{yearFormat}".trim()
                result[k] = result[k].format(format)
        else
            result = {start:null, end:null}
        @start = result.start
        @end   = result.end
        return

    _applyOffset: (date, offset) ->
        return null if not date
        return null if not (_.isNumber(offset?.count) and offset?.unit?.selected?.id)
        return date if offset.count is 0
        return date.subtract(offset.count, offset.unit.selected.id)


module.factory 'ReportParamsTimerangeXFromNowModel', (Utils) ->

    # TODO: Compute max based on time range / data bounds
    AVAILABLE_UNITS = [
        {id:"day",  label:"Days", max:365}
        {id:"week", label:"Weeks", max:52}
    ]

    class ReportParamsTimerangeXFromNowSelectorModel

        constructor: (data = {}) ->
            @reference = data.reference or "yesterday"
            @offset = @_parseOffsetFromData(data)

        isValid: ->
            count = parseInt(@offset.count)
            count = null if _.isNaN(count)
            count = null if count < 0
            return _.isNumber(count)

        serialize: ->
            reference: @reference
            offset:
                count: @offset.count,
                unit:  @offset.unit.selected?.id or null

        update: ->
            @offset = @_parseOffsetFromData(@serialize())

        _parseOffsetFromData: (data) ->
            unit = @_parseUnitFromData(data)
            count = @_parseCountFromData(data, unit)
            return {unit, count}

        _parseCountFromData: (data, unit) ->
            result = parseInt(data.offset?.count)
            return null if _.isNaN(result)
            result = Math.min(result, unit.selected.max) if unit?.selected
            result = Math.max(0, result)
            return result

        _parseUnitFromData: (data) ->
            available = Utils.copy(AVAILABLE_UNITS)
            available: available
            selected: do ->
                selectedId = do ->
                    result = data.offset?.unit
                    return null if not result
                    return result if _.isString(result)
                    return result.id
                selected = _.find available, (x) -> x.id is selectedId
                return selected or available[0]

    class ReportParamsTimerangeXFromNowModel

        constructor: (@params, @calendarModel) ->
            @updateModelFromParams()

        isValid: ->
            return @start.isValid() and @end.isValid()

        getInvalidFields: ->
            result = {}
            result['Timerange'] = not @isValid()
            return result

        updateModelFromParams: ->
            @start  = new ReportParamsTimerangeXFromNowSelectorModel(@params.timerange?.start)
            @end   ?= new ReportParamsTimerangeXFromNowSelectorModel(@params.timerange?.end)
            @end.offset.count = 0

        updateSelectorModels: ->
            @start.update()

        updateParamsFromModel: ->
            start = @start.serialize()
            end = @end?.serialize() ? null
            @params.timerange = {id:'x-from-now', start, end}


    return ReportParamsTimerangeXFromNowModel


module.factory 'ReportParamsTimerangeModel', (ReportingCalendarModel, ReportParamsTimerangeTimeranges, ReportParamsTimerangeXFromNowModel) ->
    return class ReportParamsTimerangeModel

        constructor: (@params) ->
            ``###* @type {import('../../../modules/datepicker/datepicker').ICalendarModel} calendarModel ###
            @calendarModel = ReportingCalendarModel.get()
            @timeranges = ReportParamsTimerangeTimeranges.get()
            @xFromNowModel = new ReportParamsTimerangeXFromNowModel(@params, @calendarModel)
            @modes = getComparisonModes()
            @mode = do =>
                return @modes[0] if not @params.timerangeComparisonMode
                modeId = @params.timerangeComparisonMode
                return @modes.find((m) -> m.id is modeId) ? @modes[0]
            @updateModelFromParams()

        updateParamsFromModel: ->
            @updateParamsFromModelCalendar()
            @updateParamsFromModelComparisonMode()
            @updateParamsFromModelTimerange()
            return

        updateParamsFromModelCalendar: ->
            delete @params.timerangeCalendarId
            calendarId = @calendarModel.getDraft().getCalendarId()
            @params.timerangeCalendarId = calendarId if calendarId
            return

        updateParamsFromModelComparisonMode: ->
            @mode = @modes[0] if @timerange?.id is 'custom'
            @params.timerangeComparisonMode = @mode.id

        updateParamsFromModelTimerange: ->
            return @updateParamsFromModelTimerangeXFromNow() if @timerange?.id is 'x-from-now'
            return @updateParamsFromModelTimerangeCustom() if @timerange?.id is 'custom'
            return @updateParamsFromModelTimerangePredefined()

        updateParamsFromModelTimerangeXFromNow: ->
            @xFromNowModel.updateParamsFromModel(@params)

        updateParamsFromModelTimerangePredefined: ->
            @params.timerange = @timerange?.id ? null

        updateParamsFromModelTimerangeCustom: ->
            {selection, comparison} = @calendarModel.getDatepicker().serialize()
            throw new Error("Cannot update params; Datepicker selection is invalid.") if not selection
            selection = TimerangeFilter.fromTimerange(selection)
            comparison = TimerangeFilter.fromTimerange(comparison) if comparison
            @params.timerange = {
                selection,
                ...(if comparison then {comparison} else {})
            }

        updateModelFromParams: ->
            @updateModelFromParamsTimerange()
            @updateModelFromParamsDatepicker()
            @xFromNowModel.updateModelFromParams() if @timerange.id is 'x-from-now'

        updateModelFromParamsTimerange: ->
            timerangeId = do =>
                return 'custom' if _.isPlainObject(@params.timerange) and @params.timerange.selection
                return 'x-from-now' if _.isPlainObject(@params.timerange) and @params.timerange.start
                return @params.timerange if typeof @params.timerange is 'string'
            @timerange = do =>
                return if not timerangeId
                return @timeranges.find((x) -> x.id is timerangeId)
            @timerange ?= do =>
                action = @calendarModel.getDraft().datepicker.getAction()
                return if not action
                return @timeranges.find((x) => x.id is action.id)
            @timerange ?= do =>
                return @timeranges.find((x) => x.id is 'last-complete-week')
            @timerange ?= @timeranges[0]
            return

        updateModelFromParamsDatepicker: ->
            @calendarModel.setState do =>
                draft = @calendarModel.getDraft()
                if @params.timerangeCalendarId
                    try draft.setCalendar(@params.timerangeCalendarId)
                    catch error then logError(error)
                if typeof @params.timerangeComparisonMode is 'string'
                    draft.datepicker.setComparisonMode(@params.timerangeComparisonMode)
                if _.isPlainObject(@params.timerange)
                    if @params.timerange.selection
                        selection = TimerangeFilter.toTimerange(@params.timerange.selection)
                        draft.datepicker.setSelection(selection)
                    if @params.timerange.comparison
                        comparison = TimerangeFilter.toTimerange(@params.timerange.comparison)
                        draft.datepicker.setComparison(comparison)

                return draft
            return
