### eslint-disable coffee/no-invalid-this ###

# Better tick positioner
# The default tick positioner doesn't align the zeros on the axis
# https://github.com/chriskmnds/highcharts-zero-align-y-axes doesn't use nice numbers for the axes
# This does both.

export default (Highcharts) ->
    # polyfill for
    Math.log10 = Math.log10 or ((x) -> Math.log(x) / Math.LN10)

    positionTicks = (chart, index, nTicks) ->
        # takes an array of objects with properties dataMax and dataMin, e.g. [{dataMin: 1, dataMax: 3}, {dataMin: -5, dataMax: 14}]
        axes = chart.axes.filter (axis) -> axis.coll is 'yAxis'

        return ->
            # normalize all axes and compute global min/ max
            norms = axes.filter (x) ->
                # eslint-disable-next-line coffee/english-operators
                return x.dataMin != null and x.dataMax != null

            divZeroGuard = (x) ->
                return 0 if Number.isNaN(x)
                return x

            norms = norms.map ({dataMin, dataMax}) ->
                range = Math.max(dataMax, dataMax - dataMin, -dataMin)
                min: divZeroGuard(dataMin / range)
                max: divZeroGuard(dataMax / range)

            normMin = Math.min (norms.map ({min, max}) -> min) ...
            normMax = Math.max (norms.map ({min, max}) -> max) ...

            # figure out which tick should be zero
            zeroTick = Math.ceil Math.abs ((nTicks - 2) * normMin / (normMax - normMin))  # the -2 leaves space required because we use ceil
            zeroTick = 0 if normMin >= 0
            zeroTick = nTicks - 1 if normMax <= 0

            # determine tick sizes as the next pretty number that will fit
            tickSizes = axes.map ({dataMin, dataMax}) ->
                return roundUpPretty Math.abs dataMin / zeroTick if dataMax <= 0
                return roundUpPretty Math.abs dataMax / (nTicks - zeroTick - 1) if dataMin >= 0
                return roundUpPretty Math.max Math.abs(dataMin / zeroTick), Math.abs(dataMax / (nTicks - zeroTick - 1))

            # return an array of arrays that contain the tick markings
            markings = tickSizes.map (tickSize) ->
                return ((i - zeroTick) * tickSize for i in [0 ... nTicks])

            # eslint-disable-next-line coffee/english-operators
            return [null] if axes[index].dataMin == null
            return markings[index]

    roundUpPretty = (n) ->
        return 0 if n is 0
        base = Math.floor(Math.log10(n))
        mantissa = n / Math.pow(10, base)

        if mantissa > 5
            return 10 * Math.pow(10, base)
        else if mantissa > 2
            return 5 * Math.pow(10, base)
        else if mantissa > 1
            return 2 * Math.pow(10, base)
        else # mantissa == 1
            return 1 * Math.pow(10, base)

    Highcharts.wrap Highcharts.Series.prototype, 'setVisible', (proceed) ->
        # Redraw the axes when the legend is clicked
        proceed.apply(this, Array.prototype.slice.call(arguments, 1))
        for axis in @chart.axes
            axis.isDirty = true
        @chart.redraw()

    Highcharts.Chart.prototype.callbacks.push (chart) ->
        ## CODE FOR FINDING THE BEST INTERVALS FOR X number OF TICKS
        # nTicks = 8
        # yAxes = (axis for axis in chart.axes when axis.coll == 'yAxis')
        # yAxes.filter((axis) -> axis.coll == 'yAxis').forEach (axis, index) ->
        #     axis.options.tickPositioner = positionTicks(chart, index, nTicks)
        for axis in chart.axes
            axis.isDirty = true
        chart.redraw()

    return Highcharts
