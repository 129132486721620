import { IConfigObj, IOrganization } from '../../lib/types';

export const DEFAULT_SUPPORT_EMAIL = 'support@42technologies.com';

export const SUPPORT_EMAIL_BODY_MESSAGE = `
    Hi there! Thanks a lot for reaching out. We're here to help.

    To make sure that we can help you, please attach the following to your email:

    1. Full page screenshot
    2. Your primary store or region
    3. A detailed explanation of your request or issue

    Thank you!
    - 42 team
`;

const getSupportEmail = (email: string | undefined, id: string, CONFIG: IConfigObj): string => {
    if (email) {
        return email;
    }

    const prefix = CONFIG.supportLink || CONFIG.supportEmail || DEFAULT_SUPPORT_EMAIL;
    const [name, domain] = prefix.split('@');

    return `${name}%2B${id}@${domain}`;
};

const encodeEmailSearchParams = (params: Record<string, string | number | boolean>) =>
    Object.entries(params)
        .map(([key, value]) => [key, encodeURIComponent(value)].join('='))
        .join('&');

const buildDefaultSupportLink = (supportEmail: string, subject: string) => {
    const url = new URL(`mailto:${supportEmail}`);

    const searchParams = encodeEmailSearchParams({
        subject,
        body: SUPPORT_EMAIL_BODY_MESSAGE,
    });

    return `${url.toString()}?${searchParams}`;
};

const buildAllSaintsSupportLink = (supportEmail: string, subject: string) => {
    const url = new URL('https://mail.google.com/mail/?view=cm&fs=1&tf=1');

    const searchParams = encodeEmailSearchParams({
        su: subject,
        body: SUPPORT_EMAIL_BODY_MESSAGE,
    });

    return `${url.toString()}?&to=${supportEmail}&${searchParams}`;
};

export const buildSupportLink = (CONFIG: IConfigObj, organization: IOrganization | undefined) => {
    if (!organization) return '';
    const { id, email, label } = organization;
    const supportEmail = getSupportEmail(email, id, CONFIG);
    const subject = `Support request for ${label}`;
    const isAllsaints = ['allsaints-new', 'allsaints_dev'].includes(id);

    return isAllsaints
        ? buildAllSaintsSupportLink(supportEmail, subject)
        : buildDefaultSupportLink(supportEmail, subject);
};
