export interface ICardHeaderModel {
    icon?: string;
    title?: string;
}

interface CardHeaderDirectiveScope extends angular.IScope {
    model: ICardHeaderModel;
}

export const AdsCardHeaderDirectiveFactory = () => [
    function AdsCardHeaderDirective(): angular.IDirective<CardHeaderDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: `
                <div class="marketing-card-header-container" ng-class="{ 'no-icon': !model.icon }">
                    <div class="ads-card-header-icon" ng-if="model.icon"></div>
                    <div class="title-container">
                        {{ model.title }}
                    </div>
                </div>
            `,
        };
    },
];
