export interface ISelectModel<T, ID extends Primitive = IDFromModel<T>> {
    view: _ISelectModelView<T, ID>;
    getId(x: T): ID;
    getState(): ISelectModelState<T, ID>;
    getSelectedId(): ID;
    getSelected(): T;
    getAvailable(): T[];
    find(id: ID): undefined | T;
    select(id: ID): T;
}

// The complexity with the type is because we want to auto-create the getId method
// if the underlying T has an 'id' property.
export class SelectModel<
    T,
    G extends (x: T) => any = T extends { id: Primitive } ? (x: T) => T['id'] : never,
    ID extends Primitive = IDFromGetter<G>,
> implements ISelectModel<T, ID>
{
    protected readonly _getId: (x: T) => ID;
    protected state: ISelectModelState<T, ID>;
    public view: _ISelectModelView<T, ID>;
    public ids: ID[];

    constructor(params: SelectModelParams<T, G, ID>) {
        const getId = !Array.isArray(params) && 'state' in params ? params.getId : null;
        this._getId =
            getId ??
            ((x: any) => {
                if (x && x.id) return x.id;
                throw new Error('Cannot get ID from select model element; not an object.');
            });

        const state = Array.isArray(params)
            ? params
            : 'state' in params
            ? params.state
            : { available: params.available, selected: params.selected };

        const available = [...(Array.isArray(state) ? state : state.available ?? [])];
        let selected = Array.isArray(state) ? undefined : state?.selected;
        let selectedModel: undefined | T;
        selectedModel ??= selected ? available.find(x => this.getId(x) === selected) : undefined;
        selectedModel ??= available[0] ?? undefined;
        selected = selectedModel ? this.getId(selectedModel) : undefined;
        if (!selected) throw new Error('Could not initialize select model. Nothing to select');
        this.ids = available.map(x => this.getId(x));

        this.state = { available, selected };
        this.view = new SelectModelView(this);
    }

    getId(model: T) {
        return this._getId(model);
    }

    getState() {
        return { ...this.state };
    }

    getAvailable() {
        return [...this.state.available];
    }

    getSelectedId() {
        return this.state.selected;
    }

    getSelected() {
        const found = this.find(this.getSelectedId());
        if (!found) throw new Error('Selection not found; SelectModel is in a bad state.');
        return found;
    }

    find(id: ID) {
        return this.state.available.find(x => this.getId(x) === id);
    }

    select(id: ID): T {
        if (!id) throw new Error('Missing required argument: id');
        const currentId = this.getSelectedId();
        const next = this.find(id);
        if (!next) throw new Error(`Could not find item with id: ${id}`);
        const nextId = this.getId(next);
        if (currentId !== nextId) this.setState({ ...this.state, selected: nextId });
        return next;
    }

    protected setState(state: ISelectModelState<T, ID>) {
        this.state = { ...state };
        this.view = new SelectModelView(this);
        return this;
    }
}

export type ISelectModelView<T, ID extends Primitive = IDFromModel<T>> = T extends ISelectModel<infer _T, infer _ID>
    ? ISelectModel<_T, _ID>['view']
    : _ISelectModelView<T, ID>;

interface _ISelectModelView<T, ID> {
    available: { id: ID; model: T }[];
    selected: { id: ID; model: T };
}

export class SelectModelView<T, ID extends Primitive, S extends ISelectModel<T, ID>>
    implements _ISelectModelView<T, ID>
{
    public readonly available: { id: ID; model: T }[];
    public readonly selected: { id: ID; model: T };

    constructor(selectModel: S) {
        const state = selectModel.getState();
        const getView = (x: T) => ({ id: selectModel.getId(x), model: x });
        const available = state.available.map(getView);
        const selectedId = selectModel.getSelectedId();
        const selected = selectedId ? available.find(x => x.id === selectedId) : null;
        if (!selected) throw new Error('Could not create SelectModelView; Selection not found.');
        this.available = available;
        this.selected = selected;
    }
}

// What the IDs can be
type Primitive<T = string | number | symbol> = T extends string | number | symbol ? T : never;

interface ISelectModelState<T, ID extends Primitive = Primitive> {
    available: T[];
    selected: ID;
}

type IDFromModel<T> = T extends { id: infer U } ? (U extends Primitive ? U : never) : never;
type IDFromGetter<G extends (x: any) => Primitive> = G extends (x: any) => infer U ? Primitive<U> : never;

type SelectModelInitialState<T, ID extends Primitive | undefined> = T[] | { available: T[]; selected?: undefined | ID };

type SelectModelParams<T, G extends (x: T) => ID, ID extends Primitive | undefined> = T extends { id: Primitive }
    ? SelectModelParamsWithOptionalGetter<T, G, ID>
    : SelectModelParamsWithRequiredGetter<T, ID>;

type SelectModelParamsWithOptionalGetter<T, G extends (x: T) => ID, ID extends Primitive | undefined> =
    | SelectModelInitialState<T, ID>
    | { state: SelectModelInitialState<T, ID>; getId?: undefined | G };

type SelectModelParamsWithRequiredGetter<T, ID extends Primitive | undefined> = {
    state: SelectModelInitialState<T, ID>;
    getId: (x: T) => ID;
};
