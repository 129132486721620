import SchedulingTabSchedulesModule from './tab-schedules';
import SchedulingTabReportsModule from './tab-reports';
import SelectionPebbleModule from './components/selection-pebble';
import SchedulingPageModule from './scheduling-controller.module';
import './components/schedule-expression-editor';
import './components/report-params';

const module = angular.module('42.controllers.scheduling', [
    SchedulingTabReportsModule.name,
    SchedulingTabSchedulesModule.name,
    SchedulingPageModule.name,
    SelectionPebbleModule.name,
]);

export default module;
