export const OverlayDirectiveFactory = () => [
    '$log',
    function OverlayDirectiveFn($log: angular.ILogService) {
        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
            },
            template: `<div id="overlay" ng-click="clicked()" class="overlay overlay-{{ enabled && 'enabled' || 'disabled' }}"></div>`,
            replace: true,
            link: function OverlayDirectiveLink(
                $scope: angular.IScope & {
                    clicked: () => void;
                    enabled: boolean;
                    ngModel: {
                        enable: (targetEl: HTMLElement, clickHandler: () => void) => void;
                        disable: () => void;
                        toggle: (targetEl: HTMLElement, clickHandler: () => void) => void;
                    };
                },
                element: angular.IRootElementService,
            ) {
                const ZINDEX_TIMEOUT = 600;

                let zIndexTimer: null | number = null;
                let onClick: null | (() => void) = null;

                const $element = $(element);

                $scope.clicked = () => onClick?.();

                $scope.enabled = false;
                $scope.ngModel = (() => {
                    let $target: null | JQuery = null;

                    // options:
                    // - targetEl     | the element to focus on
                    // - clickHandler | what happens when the overlay is clicked
                    const enable = (targetEl: HTMLElement, clickHandler: () => void) => {
                        $log.info(`Enabling overlay, focus on \`${targetEl}\`.`);
                        if (zIndexTimer !== null) clearTimeout(zIndexTimer);
                        $target = $(targetEl);
                        onClick = clickHandler;
                        $element.css('zIndex', 1000);
                        $target.css('zIndex', 1001);
                        $scope.enabled = true;
                    };

                    const disable = () => {
                        $log.info('Disabling overlay.');
                        $target = null;
                        $scope.enabled = false;
                        zIndexTimer = setTimeout(resetZIndex, ZINDEX_TIMEOUT);
                    };

                    const toggle = (targetEl: HTMLElement, clickHandler: () => void) => {
                        if ($scope.enabled) {
                            disable();
                        } else {
                            enable(targetEl, clickHandler);
                        }
                    };

                    const resetZIndex = () => {
                        $target?.css('zIndex', '');
                        $element.css('zIndex', -1);
                    };

                    return { enable, disable, toggle };
                })();
            },
        };
    },
];
