import { ResizeObserver } from '@juggle/resize-observer';
import { debounce, values } from 'lodash';
import { StorePopup } from './mapbox-store-popup';
import { Mapbox, IClusterLayerConfig } from './mapbox';
import { IMapStore } from './map-models';
import { IMapCustomer } from './map-models';

export interface ILayerTab {
    selected: boolean;
    layers: string[];
}

const MAPBOX_CONFIG: Record<string, IClusterLayerConfig> = {
    stores: {
        source: 'stores',
        layers: {
            cluster: 'stores-cluster',
            point: 'stores-unclustered-point',
            count: 'stores-count',
        },
        clusterPaint: {
            'circle-color': ['step', ['get', 'point_count'], '#AE8CD1', 100, '#7954AD', 750, '#4E2B81'],
        },
        pointPaint: {
            'circle-color': '#AE8CD1',
        },
        popup: StorePopup,
    },
    customers: {
        source: 'customers',
        layers: {
            cluster: 'customers-cluster',
            point: 'customers-unclustered-point',
            count: 'customers-count',
        },
        clusterPaint: {
            'circle-color': ['step', ['get', 'point_count'], '#FBE47C', 100, '#F1B24F', 750, '#EB8B35'],
        },
        pointPaint: {
            'circle-color': '#FBE47C',
        },
    },
};

function buildCustomersFeatures(customers: IMapCustomer[]): GeoJSON.Feature<GeoJSON.Geometry>[] {
    return customers.map(customer => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [customer.longitude, customer.latitude],
        },
        properties: {},
    }));
}

const buildStoreFeatures = (stores: IMapStore[]): GeoJSON.Feature<GeoJSON.Geometry>[] => {
    const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];

    return stores.reduce((acc, store) => {
        if (!store.location) {
            return acc;
        }

        const { id, name, location, address, type, phone } = store;

        acc.push({
            type: 'Feature',
            properties: {
                id,
                name,
                address,
                type,
                phone,
            },
            geometry: {
                type: 'Point',
                coordinates: [location.longitude, location.latitude],
            },
        });

        return acc;
    }, features);
};

interface MapDirectiveScope extends angular.IScope {
    [x: string]: any;
}
export const MapDirective = () => [
    function (): angular.IDirective<MapDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                map: '=',
            },
            replace: true,
            template: `
            <article class="customer-mapbox">
                <div class="new-map"></div>
                <div class="layer-selector">
                    <div class="selector-item stores"
                        ng-click="toggleLayer(layers.stores)"
                        ng-class="{selected: layers.stores.selected}">
                        Stores
                    </div>
                    <div class="selector-item customers"
                        ng-click="toggleLayer(layers.customers)"
                        ng-class="{selected: layers.customers.selected}">
                        Customers
                    </div>
                </div>
            </article>
            `,
            link: (scope, $element) => {
                scope.layers = {
                    stores: {
                        layers: values(MAPBOX_CONFIG.stores.layers),
                        selected: true,
                    },
                    customers: {
                        layers: values(MAPBOX_CONFIG.customers.layers),
                        selected: true,
                    },
                };

                const {
                    token,
                    style,
                    view: { latitude, longitude },
                } = scope.map.config;

                const mapbox = new Mapbox(token);
                const mapboxEl: HTMLElement = $element[0].getElementsByClassName('new-map').item(0);
                if (!mapboxEl) throw new Error('Element not found: .new-map');

                mapbox
                    .initMap({
                        container: mapboxEl,
                        style: style,
                        center: [Number(latitude), Number(longitude)],
                    })
                    .then(() => {
                        const { customers, stores } = MAPBOX_CONFIG;
                        const data = {
                            customers: buildCustomersFeatures(scope.map.data.customers),
                            stores: buildStoreFeatures(scope.map.data.stores),
                        };
                        mapbox.addClusterLayer(customers, data.customers);
                        mapbox.addClusterLayer(stores, data.stores);
                    });

                scope.toggleLayer = (layerTab: ILayerTab) => {
                    layerTab.selected = !layerTab.selected;
                    layerTab.layers.forEach(layerId => mapbox.setLayerVisibility(layerId, layerTab.selected));
                };

                const resizeObserver = new ResizeObserver(debounce(() => mapbox.resize(), 200));
                resizeObserver.observe($element[0]);
                scope.$on('$destroy', () => {
                    resizeObserver.disconnect();
                    mapbox.map.remove();
                });
            },
        };
    },
];
