import { HTMLElementInputWatcher, InputEventListenerCallback } from './event-listeners';

export abstract class DropObserverOptions {
    timeout?: number = 30 * 10000;
    type?: string;
    allowedFileTypes?: string[];
}

export class DropObserver extends HTMLElementInputWatcher {
    onErrorCallback: undefined | ((err: Error) => void);

    constructor(target: HTMLElement, options?: DropObserverOptions) {
        super(target, options);
    }

    onDragEnter(callback?: InputEventListenerCallback) {
        this.listener?.add('dragenter', this._preventDefault(callback), false);
        return this;
    }

    onDragOver(callback?: InputEventListenerCallback) {
        this.listener?.add('dragover', this._preventDefault(callback), false);
        return this;
    }

    onDragExit(callback?: InputEventListenerCallback) {
        this.listener?.add('dragexit', this._preventDefault(callback), false);
        return this;
    }

    onDragLeave(callback?: InputEventListenerCallback) {
        this.listener?.add('dragleave', this._preventDefault(callback), false);
        return this;
    }

    onFileDrop(callback: InputEventListenerCallback) {
        this.listener?.add(
            'drop',
            this._preventDefault(async (e: DragEvent) => {
                const file = e.dataTransfer?.files[0];
                if (!file) {
                    this.onErrorCallback && this.onErrorCallback(new Error('File not found.'));
                    return;
                }

                if (this.options.allowedFileTypes) {
                    const allowed = this.options.allowedFileTypes.some(type => file.type.includes(type));
                    if (!allowed) {
                        this.onErrorCallback && this.onErrorCallback(new Error('File type not allowed.'));
                        return;
                    }
                }

                const data = await this.readFile(file);
                callback(data);
            }),
            false,
        );
        return this;
    }

    onError(callback: (err: Error) => void) {
        this.onErrorCallback = callback;
    }

    onUrlDrop(callback: InputEventListenerCallback) {
        this.listener?.add(
            'drop',
            this._preventDefault(async (e: DragEvent) => {
                const item = e.dataTransfer?.items[0];
                if (item) item.getAsString(callback);
            }),
            false,
        );
        return this;
    }

    private readFile(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let rejected = false;
            const timer = setTimeout(() => {
                reject(new Error('Read file timeout.'));
                rejected = true;
            }, this.options?.timeout ?? 10000);
            reader.onload = event => {
                if (rejected) return;
                clearTimeout(timer);
                return resolve(event.target?.result as string);
            };
            try {
                switch (this.options.type) {
                    case 'text': {
                        reader.readAsText(file);
                        break;
                    }
                    case 'buffer':
                        reader.readAsArrayBuffer(file);
                        break;
                    default:
                        reader.readAsDataURL(file);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    private _preventDefault(callback?: InputEventListenerCallback) {
        return (event: Event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            if (callback) callback(event);
            return false;
        };
    }
}

export class ImageDropObserver extends DropObserver {
    constructor(target: HTMLElement, options?: DropObserverOptions) {
        super(target, options);
    }

    onDrop(callback: InputEventListenerCallback) {
        return super.onFileDrop((data: string) => {
            const image = this.readImage(data);
            setTimeout(callback, 0, image);
        });
    }

    private readImage(data: string): null | HTMLElement {
        if (!this.target) return null;
        const image = this.target?.ownerDocument.createElement('img');
        image.src = data;
        return image;
    }
}
