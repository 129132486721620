import { HTMLElementInputWatcher, InputEventListenerCallback } from './event-listeners';

export interface MouseObserverOptions {
    preventDefault?: boolean;
}

export class MouseObserver extends HTMLElementInputWatcher {
    observerOptions: MouseObserverOptions;
    constructor(target: HTMLElement | Window, options?: MouseObserverOptions) {
        super(target);
        this.observerOptions = {
            preventDefault: options?.preventDefault ?? true,
        };
    }

    onMouseMove(callback?: InputEventListenerCallback) {
        this.listener?.add('mousemove', this._listenerEventHandler(callback), false);
        return this;
    }

    onMouseEnter(callback?: InputEventListenerCallback) {
        this.listener?.add('mouseenter', this._listenerEventHandler(callback), false);
        return this;
    }

    onMouseLeave(callback?: InputEventListenerCallback) {
        this.listener?.add('mouseleave', this._listenerEventHandler(callback), false);
        return this;
    }

    onMouseClick(callback?: InputEventListenerCallback) {
        this.listener?.add('click', this._listenerEventHandler(callback), false);
        return this;
    }

    private _listenerEventHandler(callback?: InputEventListenerCallback) {
        return (event: Event) => {
            if (this.observerOptions.preventDefault) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }

            if (callback) callback(event);
            return false;
        };
    }
}

export class MouseObserverAngular extends MouseObserver {
    constructor($scope: angular.IScope, target: HTMLElement | Window, options: MouseObserverOptions = {}) {
        super(target, options);

        $scope.$on('$destroy', () => this.detach());
    }
}
