import _ from 'lodash';
import moment from 'moment';
import type { IQuery } from '../../lib/types';
import { isObject } from '../../lib/utils';
import { IPropertyDefinition, normalizePropertyDefinition } from '../../lib/config-hierarchy';
import { QueryTimestampSelection } from '../../lib/query/query-builder';

export interface INaturalLanguagePropertyFilterModel {
    id: string;
    property: IPropertyDefinition;
    included: null | string[];
    excluded: null | string[];
}

export function getTimerangeFilterFromQuery(query: IQuery) {
    const timerange = QueryTimestampSelection.get(query);
    if (!timerange) return null;
    const { $gte: start, $lt: end } = timerange;
    return {
        start: moment.utc(start).format('MMM DD yyyy'),
        end: moment.utc(end).subtract(1, 'minute').format('MMM DD yyyy'),
    };
}

// TODO: move this to a lib (without the properties argument)
export function* getPropertyFiltersFromQuery(
    query: IQuery,
    properties: IPropertyDefinition[],
): Generator<INaturalLanguagePropertyFilterModel> {
    if (!isObject(query.filters)) return [];

    const propertiesById = _.keyBy(properties, 'id');

    for (const [tableName, tableFilters] of Object.entries(query.filters)) {
        if (tableName === 'transactions') continue;
        if (!isObject(tableFilters)) continue;

        const columnFilters = tableFilters.$and;
        if (!Array.isArray(columnFilters)) continue;

        for (const columnFilter of columnFilters) {
            if (!isObject(columnFilter)) continue;
            const entry = Object.entries(columnFilter)[0];
            const [columnName, columnFilterValue] = Array.isArray(entry) ? entry : [];

            if (typeof columnName !== 'string') continue;
            if (!isObject(columnFilterValue)) continue;

            const propertyId = `${tableName}.${columnName}`;
            const property: IPropertyDefinition =
                propertiesById[propertyId] ??
                normalizePropertyDefinition({
                    id: propertyId,
                });

            const normalizeStringContainsOp = (x: unknown) => {
                if (!Array.isArray(x)) return null;
                const values = x.filter(x => typeof x === 'string' || typeof x === 'number').map(x => x.toString());
                return values.length === 0 ? null : values;
            };

            yield {
                id: propertyId,
                property,
                included: normalizeStringContainsOp('$in' in columnFilterValue ? columnFilterValue.$in : []),
                excluded: normalizeStringContainsOp('$nin' in columnFilterValue ? columnFilterValue.$nin : []),
            };
        }
    }
}

export class RenderedQueryFilterListModel {
    filters: RenderedQueryFilterModel[];
    size: number;

    constructor(filters: INaturalLanguagePropertyFilterModel[]) {
        this.filters = filters.map(x => new RenderedQueryFilterModel(x));
        this.size = this.getSize();
    }

    decreaseSize() {
        let filter = this.filters.filter(x => x.showValues).pop();
        filter ??= this.filters.filter(x => x.showLength).pop();
        if (!filter) return false;
        filter.collapse();
        this.size = this.getSize();
    }

    increaseSize() {
        let filter = _.minBy(
            this.filters.filter(x => !x.showLength),
            x => x.getSize({ showLength: true }),
        );
        filter ??= _.minBy(
            this.filters.filter(x => !x.showValues),
            x => x.getSize({ showValues: true }),
        );
        if (!filter) return false;
        filter.expand();
        this.size = this.getSize();
    }

    getSize() {
        return _.sum(this.filters.map(x => x.size));
    }
}

class RenderedQueryFilterModel {
    id: string;
    size: number;
    showValues: boolean;
    showLength: boolean;
    values: string[];
    label: string;
    included: boolean;
    model: INaturalLanguagePropertyFilterModel;

    constructor(model: INaturalLanguagePropertyFilterModel) {
        this.id = model.id;
        this.label = model.property.label;
        this.values = model.included ?? model.excluded ?? [];
        this.included = Boolean(model.included);
        this.model = model;
        this.size = this.getSize();
        this.showValues = true;
        this.showLength = true;
    }

    expand() {
        if (!this.showLength) {
            this.showValues = false;
            this.showLength = true;
        } else {
            this.showValues = true;
            this.showLength = true;
        }
        this.size = this.getSize();
    }

    collapse() {
        if (this.showValues) {
            this.showValues = false;
            this.showLength = true;
        } else {
            this.showValues = false;
            this.showLength = false;
        }
        this.size = this.getSize();
    }

    getSize(overrides: { showLength?: boolean; showValues?: boolean } = {}) {
        const { showLength, showValues } = {
            showLength: overrides.showLength ?? this.showLength,
            showValues: overrides.showValues ?? this.showValues,
        };
        if (!showLength) return this.label.length;
        if (!showValues) return `${this.label.length}|${this.values.length} filters`.length;
        return `${this.label.length}|${this.values.join(',')}`.length;
    }
}
