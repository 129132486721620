export const ECHARTS_THEME = {
    fontFamily: 'Open Sans',
    // axisLineColor: 'rgba(161,174,178,0.7)',
    axisLineColor: '#e6eaef',
    axisLabelColor: '#888',
    colors: [
        '#7b68ee',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
        '#FB85D0',
        '#CBACF6',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
        '#FB85D0',
        '#CBACF6',
    ],
};

export const ECHARTS_THEME_LEGEND = {
    inactiveColor: ECHARTS_THEME.axisLineColor,
    pageIcons: {
        horizontal: [
            // http://www.entypo.com/images/chevron-small-left.svg
            'M12.141,13.418c0.268,0.271,0.268,0.709,0,0.978c-0.268,0.27-0.701,0.272-0.969,0l-3.83-3.908  c-0.268-0.27-0.268-0.707,0-0.979l3.83-3.908c0.267-0.27,0.701-0.27,0.969,0c0.268,0.271,0.268,0.709,0,0.978L9,10L12.141,13.418z',
            // http://www.entypo.com/images/chevron-small-right.svg
            'M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908  c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z',
        ],
    },
    pageIconSize: 11,
    pageIconInactiveColor: ECHARTS_THEME.axisLineColor,
    pageIconColor: '#444',
    pageTextStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 12,
    },
    textStyle: {
        color: ECHARTS_THEME.axisLabelColor,
        fontSize: 11,
    },
};

export const ECHARTS_GROUPED_SERIES_COLOR = '#777';
