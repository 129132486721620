import _ from 'lodash';
import { titleize } from 'inflected';
import { isObject } from '../../lib/utils';
import type { AngularInjected } from '../../lib/angular';
import type { IMetricDefinition, IQuery } from '../../lib/types';
import type { IPropertyDefinition } from '../../lib/config-hierarchy';
import type { IHierarchyService } from '../../modules/hierarchy';
import type { IQueryServiceAPI } from '../../modules/services/query-service.types';
import type { ICalendarPropertiesService, IQueryMetrics } from '../../controllers/main-controller';
import { CustomError } from '../../lib/utils';

export interface EchartsWithQueryViewModelOptionsOverridesLineChart {
    nameFn: (m: IMetricDefinition) => string;
    areaStyle: null;
    lineStyle: { width: number };
    multipleYAxis: boolean;
    timeRangeAsHeader: boolean;
    displayTitle: boolean;
    colorsByMetric: Record<string, string | undefined>;
    bucket: string;
}
export interface EchartsWithQueryViewModelOptionsOverrides {
    lineChart?: EchartsWithQueryViewModelOptionsOverridesLineChart;
}

export interface EchartsWithQueryViewModelOptions {
    title?: string;
    type: string;
    properties: string[];
    metrics: string[];
    options?: Record<string, unknown>;
    overrides?: EchartsWithQueryViewModelOptionsOverrides;
}

type EchartStandardChart = new (
    metrics: IMetricDefinition[],
    data: Record<string, unknown>[],
    displayOptions: Record<string, unknown> & { expandedTheme?: boolean },
) => { chartOptions: Record<string, unknown> & { expandedTheme?: boolean } };

type EchartsLineChart = new (
    metrics: IMetricDefinition[],
    data: Record<string, unknown>[],
    overrides: EchartsWithQueryViewModelOptionsOverridesLineChart | undefined,
) => {
    chartOptions: Record<string, unknown> & { expandedTheme?: boolean };
};

type EchartsMapChart = new (metrics: IMetricDefinition[], data: Record<string, unknown>[]) => {
    chartOptions: Record<string, unknown> & { expandedTheme?: boolean };
};

export const EchartsWithQueryViewModelFactory = () => [
    '$q',
    'QueryServiceAPI',
    'QueryMetrics',
    'Hierarchy',
    'CalendarProperties',
    'EchartsBarChartModel',
    'EchartsPieChartModel',
    'EchartsLineChartModel',
    'EchartsMapChartModel',
    function EchartsWithQueryViewModelFactoryFn(
        $q: angular.IQService,
        QueryServiceAPI: IQueryServiceAPI,
        QueryMetrics: IQueryMetrics,
        Hierarchy: IHierarchyService,
        CalendarProperties: ICalendarPropertiesService,
        EchartsBarChartModel: EchartStandardChart,
        EchartsPieChartModel: EchartStandardChart,
        EchartsLineChartModel: EchartsLineChart,
        EchartsMapChartModel: EchartsMapChart,
    ) {
        return class EchartsWithQueryViewModel {
            displayOptions: Record<string, unknown> & { expandedTheme?: boolean };
            overrides: EchartsWithQueryViewModelOptionsOverrides;
            type: string;
            properties: string[] | IPropertyDefinition[] = [];
            metrics: IMetricDefinition[] = [];
            isLoading: boolean;
            isBlank: boolean | undefined;
            isError: boolean | undefined;
            options: Record<string, unknown> = {};
            title:
                | undefined
                | string
                | {
                      properties: {
                          id: string;
                          label: string;
                      }[];
                      metrics: {
                          id: string;
                          label: string;
                      }[];
                  };
            data: null | Record<string, unknown> | Record<string, unknown>[] = null;

            constructor(model: EchartsWithQueryViewModelOptions, rootQuery: IQuery) {
                const { properties, metrics, type, options = {}, overrides = {} } = _.cloneDeep(model);

                this.displayOptions = {
                    expandedTheme: false,
                    ...options,
                };
                this.overrides = overrides;
                this.isLoading = true;
                this.type = type;

                this.fetch(model, properties, metrics, type, this.displayOptions, rootQuery)
                    .then(({ properties, metrics, options, data }) => {
                        this.properties = properties;
                        this.metrics = metrics;
                        this.data = data;
                        this.isBlank = this.isBlankModel(data, metrics, options);
                        this.options = this.isBlank ? {} : options;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isBlank = false;
                        this.isLoading = false;
                        this.isError = true;
                        throw error;
                    });
            }

            fetch(
                model: EchartsWithQueryViewModelOptions,
                properties: string[],
                metrics: string[],
                type: string,
                displayOptions: Record<string, unknown> & { expandedTheme?: boolean },
                rootQuery: IQuery,
            ) {
                const propertiesPromise = this.fetchProperties(properties);
                const metricsPromise = this.fetchMetrics(metrics);

                return $q
                    .all([propertiesPromise, metricsPromise, this.fetchData(type, properties, metrics, rootQuery)])
                    .then(([properties, metrics, data]) => {
                        this.title = model.title ? model.title : this.getTitle(properties, metrics);
                        if (!Array.isArray(data)) throw new Error('Data is not an array.');

                        metrics = metrics.filter(m => {
                            const dataByMetric = data.map(x => x[m.field]);
                            return !_.every(dataByMetric, value => _.isUndefined(value));
                        });
                        const options =
                            metrics.length !== 0 ? this.getChartOptions(metrics, type, displayOptions, data) : {};
                        return { properties, metrics, title: this.title, options, data };
                    });
            }

            isBlankModel(
                data: Record<string, unknown>[] = [],
                metrics: IMetricDefinition[] = [],
                options: Record<string, unknown> = {},
            ) {
                if (data.length === 0 || metrics.length === 0) return true;
                if (Array.isArray(options.series)) {
                    return options.series.every(serie => {
                        if (isObject(serie)) {
                            return !Array.isArray(serie.data) || serie.data.length === 0;
                        }
                    });
                }
                if (isObject(options.series)) {
                    const data = options.series.data ?? [];
                    return !Array.isArray(data) || data.length === 0;
                }
                return false;
            }

            getTitle(properties?: (string | IPropertyDefinition)[], metrics?: IMetricDefinition[]) {
                if (!properties || !metrics) return { metrics: [], properties: [] };

                const metricsInfo = _.uniqBy(metrics, x => x.headerGroup).map(x => ({
                    id: x.field,
                    label: x.headerGroup,
                }));
                const propertiesInfo = (() => {
                    let mappedProperties = _.compact(
                        properties.map(x => {
                            if (typeof x !== 'string') return { id: x.id, label: x.label };
                            let label = x.trim().split('.').join(' ').replace('calendar', '').trim();
                            label = titleize(label);
                            return { id: x, label };
                        }),
                    );

                    mappedProperties = _.uniqBy(mappedProperties, x => x.label);

                    // Hack: for the overview page chart, we don't want to show the "Year" property, but we need to group by it
                    if (mappedProperties.length !== 2) return mappedProperties;
                    return mappedProperties.filter(x => x.label?.toLowerCase() !== 'year');
                })();

                return {
                    properties: propertiesInfo,
                    metrics: metricsInfo,
                };
            }

            getChartOptions(
                metrics: IMetricDefinition[],
                type: string,
                displayOptions: Record<string, unknown> & { expandedTheme?: boolean },
                data: Record<string, unknown>[],
            ) {
                switch (type) {
                    case 'pie':
                        return new EchartsPieChartModel(metrics, data, displayOptions).chartOptions;
                    case 'line':
                        return new EchartsLineChartModel(metrics, data, this.overrides.lineChart).chartOptions;
                    case 'bar':
                        return new EchartsBarChartModel(metrics, data, displayOptions).chartOptions;
                    case 'map':
                        return new EchartsMapChartModel(metrics, data).chartOptions;
                    default:
                        throw new UnknownChartTypeError(type);
                }
            }

            fetchMetrics(metrics: string[]) {
                return QueryMetrics.fetch().then(available => {
                    const metricsByField = _.keyBy(available, x => x.field);
                    return _.compact(metrics.map(x => metricsByField[x]));
                });
            }

            fetchProperties(properties: string[]) {
                return $q.all([CalendarProperties.fetch(), Hierarchy.fetch()]).then(([calendar, hierarchy]) => {
                    if (!calendar) return properties;
                    // This is a workaround so that we have a descriptor for queries that are requesting 'calendar.month'
                    // It's used by the getTitle method, and by the overview page.
                    const calendarInfo = [
                        ...calendar,
                        ...calendar
                            .filter(x => x.id === 'calendar.month_label')
                            .map(x => ({ ...x, id: 'calendar.month' })),
                    ];
                    const allProperties = [...hierarchy.all, ...calendarInfo];
                    const allPropertiesById = _.keyBy(allProperties, x => x.id);
                    const selectedProperties = _.compact(properties.map(x => allPropertiesById[x]));
                    return selectedProperties.length === 0 ? properties : selectedProperties;
                });
            }

            getBaseQueryOptions(rootQuery: IQuery) {
                const query = _.cloneDeep(rootQuery);
                query.options ??= {};
                query.options.includeItemInformation = false;
                return query;
            }

            getGenericQueryOptions(properties: string[], metrics: string[], baseQuery: IQuery) {
                const query = _.cloneDeep(baseQuery);
                query.options ??= {};
                query.options.includeTotals = false;
                query.options.metrics = metrics;
                query.options.properties = properties;
                query.options.sort = properties.map(prop => ({
                    property: prop,
                    field: metrics[0],
                    order: -1,
                    limit: null,
                }));
                return query;
            }

            getLineQueryOptions(properties: string[], metrics: string[], baseQuery: IQuery) {
                const query = _.cloneDeep(baseQuery);
                query.options ??= {};
                query.options.includeTotals = false;
                query.options.fillCalendarGaps = true;
                query.options.metrics = metrics;
                query.options.properties = properties;
                query.options.sort = properties.map(prop => ({ property: prop, field: prop, order: 1, limit: null }));
                return query;
            }

            fetchData(type: string, properties: string[], metrics: string[], rootQuery: IQuery) {
                const baseQuery = this.getBaseQueryOptions(rootQuery);
                const query = (() => {
                    switch (type) {
                        case 'pie':
                        case 'bar':
                        case 'map':
                            return this.getGenericQueryOptions(properties, metrics, baseQuery);
                        case 'line':
                            return this.getLineQueryOptions(properties, metrics, baseQuery);
                        default:
                            throw new UnknownChartTypeError(type);
                    }
                })();

                return QueryServiceAPI().then(api => api.query.metricsFunnel(query));
            }

            switchTheme(enableExpandedTheme: boolean) {
                this.displayOptions.expandedTheme = enableExpandedTheme;
                const data = (() => {
                    if (!this.data) return [];
                    return Array.isArray(this.data) ? this.data : [this.data];
                })();
                this.options = this.getChartOptions(this.metrics, this.type, this.displayOptions, data);
            }

            setType(type: string) {
                this.type = type;
                const data = (() => {
                    if (!this.data) return [];
                    return Array.isArray(this.data) ? this.data : [this.data];
                })();
                this.options = this.getChartOptions(this.metrics, type, this.displayOptions, data);
                this.isBlank = this.isBlankModel(data, this.metrics, this.options);
            }

            getType() {
                return this.type;
            }
        };
    },
];

class UnknownChartTypeError extends CustomError {
    readonly type: string;
    constructor(type: string) {
        super(`Unknown chart type: ${type}`);
        this.type = type;
    }
}

export type IEchartsWithQueryViewModelFactory = AngularInjected<typeof EchartsWithQueryViewModelFactory>;
export type IEchartsWithQueryViewModel = InstanceType<IEchartsWithQueryViewModelFactory>;
