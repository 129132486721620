import { z } from 'zod';

const HandbookItemSchema = z.object({
    url: z.string(),
    label: z.string().nullish().optional(),
    id: z.string().nullish().optional(),
});
const HandbookConfigSchema = z.string().or(HandbookItemSchema).or(z.array(HandbookItemSchema));

export type IHandbookItem = z.infer<typeof HandbookItemSchema>;
export type IHandbook = undefined | IHandbookItem[];

export const normalizeHandbook = (config: unknown): IHandbook => {
    const parsedConfig = HandbookConfigSchema.safeParse(config);
    if (parsedConfig.success) {
        if (typeof parsedConfig.data === 'string') {
            return [{ url: parsedConfig.data }];
        } else if (Array.isArray(parsedConfig.data)) {
            return parsedConfig.data;
        } else {
            return [parsedConfig.data];
        }
    }

    return undefined;
};
