import _ from 'lodash';
import hexToRgba from 'hex-to-rgba';
import { ResizeObserver } from '@juggle/resize-observer';
import type { SeriesOption, EChartsOption } from 'echarts';
import { init as echartsInit } from 'echarts';
import type { IMetricDefinition } from '../../lib/types';
import { hasGroupSeries, shouldGroupSeries, normalizeGroupedSeriesDisplayOptions } from './chart-echarts.helper';

export type I42CustomSeriesOption = SeriesOption & { data?: ISeriesData[] };
export type I42CustomEchartsOptions = EChartsOption & {
    series: I42CustomSeriesOption[];
    customOptions?: EChartsCustomOptions;
};

export interface ISeriesData {
    metric: IMetricDefinition;
    percent?: number;
    name: string;
    value: number;
    isNegative?: boolean;
    groupedSeries?: ISeriesData[];
    itemStyle: {
        color: string;
        [x: string]: unknown;
    };
}

export interface EChartsCustomOptions {
    groupSlicesThresholdValue?: number;
    maxNumberOfSlices?: number;
    multiline?: boolean;
    showPercentage?: boolean;
}

export interface EchartsCustomFactoryScope extends angular.IScope {
    options: I42CustomEchartsOptions;
}

export const EchartsCustomFactory = () => [
    function EChartsCustom(): angular.IDirective<EchartsCustomFactoryScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                options: '=',
            },
            template: `
            <div class="echarts-container">
                <div class="echarts-main"></div>
            </div>
            `,
            link: function EchartsCustomLink(scope, $element) {
                const containerEl = $element[0];
                if (!containerEl) throw new Error('EChartsCustom container element not found');

                const chartEl: null | HTMLElement = containerEl.querySelector('.echarts-main');
                if (!chartEl) throw new Error('EChartsCustom .echarts-main not found');

                const eChartsInstance = echartsInit(chartEl);

                const observerCleanup = (() => {
                    const resizeChart = () => eChartsInstance.resize({ silent: true, animation: { duration: 0 } });
                    let resizing = false;
                    const onResizeStart = () => {
                        containerEl.style.setProperty('overflow', 'hidden');
                        containerEl.style.setProperty('pointer-events', 'none');
                        resizing = true;
                    };
                    const onResizeEnd = _.debounce(() => {
                        containerEl.style.removeProperty('pointer-events');
                        containerEl.style.removeProperty('overflow');
                        resizing = false;
                        resizeChart();
                    }, 500);
                    const onResize = () => {
                        if (!resizing) onResizeStart();
                        onResizeEnd();
                        resizeChart();
                    };
                    const observer = new ResizeObserver(onResize);
                    observer.observe(containerEl);
                    return () => {
                        observer.disconnect();
                        onResizeEnd.cancel();
                    };
                })();

                scope.$on('$destroy', () => {
                    observerCleanup();
                    eChartsInstance.dispose();
                });

                scope.$watch(
                    'options',
                    (options: undefined | I42CustomEchartsOptions) => {
                        if (!options) return;
                        options = onChartOptionsChange(options);
                        eChartsInstance.clear();
                        eChartsInstance.setOption(options);
                    },
                    true,
                );
            },
        };
    },
];

// TODO: revisit this...
const getReferenceSerieFromOptions = <O extends I42CustomEchartsOptions = I42CustomEchartsOptions>(
    options: undefined | O,
): undefined | I42CustomEchartsOptions['series'][number] => {
    const series = options?.series;
    if (!Array.isArray(series)) return;
    return series[0];
};

const getChartTypeFromOptions = (options: undefined | I42CustomEchartsOptions): undefined | string => {
    const serie = getReferenceSerieFromOptions(options);
    const type = serie?.type;
    if (typeof type !== 'string' || type.length === 0) return;
    return type;
};

const shouldGroupSlices = (
    chartOptions: undefined | I42CustomEchartsOptions,
    displayOptions: { maxNumberOfSlices: number; groupSlicesThresholdValue: number },
): boolean => {
    const serie = getReferenceSerieFromOptions(chartOptions);
    if (!serie) return false;
    const type = getChartTypeFromOptions(chartOptions);
    if (!type) return false;
    if (!['pie', 'bar'].includes(type)) return false;
    return !hasGroupSeries(serie) && shouldGroupSeries(serie, displayOptions);
};

// FIXME: To create the "other" grouping, this function assumes that the metrics can be added together, which isn't always the case (e.g. averages)
const onChartOptionsChange = <O extends I42CustomEchartsOptions = I42CustomEchartsOptions>(chartOptions: O) => {
    const serie = getReferenceSerieFromOptions(chartOptions);
    if (!serie) return chartOptions;

    const data = serie.data ?? [];
    if (!Array.isArray(data) || data.length === 0) return chartOptions;

    const displayOptions = normalizeGroupedSeriesDisplayOptions(chartOptions.customOptions);
    if (!shouldGroupSlices(chartOptions, displayOptions)) return chartOptions;

    chartOptions = _.cloneDeep({ ...chartOptions, series: [] });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const referenceMetric = data[0]!.metric;

    // FIXME: remove manual sums, use data instead
    // https://42technologies.atlassian.net/browse/DEV-5394
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    const slicesBelowThreshold: ISeriesData[] = [];
    const slicesAboveThreshold: ISeriesData[] = [];

    let slicesBelowThresholdTotalSum = 0;
    data.forEach((item, index) => {
        const isBelowValueThreshold = Math.abs(item.value) / totalValue < displayOptions.groupSlicesThresholdValue;
        const isBelowSliceNumberThreshold =
            displayOptions.maxNumberOfSlices !== 0 && index >= displayOptions.maxNumberOfSlices;
        if (isBelowSliceNumberThreshold && isBelowValueThreshold) {
            const percentValue = Math.abs(item.value) / totalValue;
            slicesBelowThreshold.push({ ...item, percent: percentValue });
            slicesBelowThresholdTotalSum += item.value;
            return;
        }
        slicesAboveThreshold.push(item);
    });

    if (slicesBelowThreshold.length > 0) {
        const firstNegativeIndex = slicesAboveThreshold.findIndex(item => item.isNegative);
        const groupedItem: ISeriesData = {
            metric: referenceMetric,
            value: slicesBelowThresholdTotalSum,
            name: 'Other',
            groupedSeries: slicesBelowThreshold,
            itemStyle: {
                color: '#BABCC8',
                borderRadius: [4, 4, 0, 0],
            },
            isNegative: slicesBelowThresholdTotalSum < 0,
        };

        if (groupedItem.isNegative) {
            groupedItem.itemStyle.borderRadius = [0, 0, 4, 4];
            groupedItem.itemStyle.borderColor = groupedItem.itemStyle.color;
            groupedItem.itemStyle.color = hexToRgba(groupedItem.itemStyle.color, 0.1);
            groupedItem.itemStyle.borderType = 'dashed';
        }

        if (firstNegativeIndex < 0) {
            slicesAboveThreshold.push(groupedItem);
        } else {
            slicesAboveThreshold.splice(firstNegativeIndex, 0, groupedItem);
        }
    }

    const slicesNames = slicesAboveThreshold.map(s => s.name);

    if (serie.type !== 'bar' && chartOptions.legend && !Array.isArray(chartOptions.legend)) {
        chartOptions.legend.data = [...slicesNames];
    }

    if (serie.type === 'bar') {
        if (Array.isArray(chartOptions.xAxis)) throw new Error('array xAxis for bar type is not implemented');
        chartOptions.xAxis = { ...(chartOptions.xAxis ?? {}), type: 'category', data: [...slicesNames] };
    }

    return {
        ...chartOptions,
        series: [{ ...serie, data: slicesAboveThreshold }],
    };
};
