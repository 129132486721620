import _ from 'lodash'
import angular from 'angular'
import cronstrue from 'cronstrue'
import * as RegularExpressions from '../../../lib/parsers/regexp'

module = angular.module('42.controllers.scheduling.common')

ExpressionLevels = do ->
    DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    SPECIFIC_DAY = (day) -> "Every #{day}"
    EVERY_DAY          : 'Every Day'
    FIRST_DAY_OF_MONTH : 'Every First Day of Month (Gregorian)'
    CRON               : 'Cron'
    DAYS               : [...DAYS]
    SPECIFIC_DAYS      : DAYS.map(SPECIFIC_DAY)
    SPECIFIC_DAY       : SPECIFIC_DAY
    AT_TIME            : 'At Time'
    EVERY_HOUR         : 'Every Hour'


module.service 'CronExpressionUtils', ->

    # FIXME: refactor this thing
    EVERY_DAY          : ExpressionLevels.EVERY_DAY
    FIRST_DAY_OF_MONTH : ExpressionLevels.FIRST_DAY_OF_MONTH
    CRON               : ExpressionLevels.CRON
    DAYS               : ExpressionLevels.DAYS
    SPECIFIC_DAYS      : ExpressionLevels.SPECIFIC_DAYS
    SPECIFIC_DAY       : ExpressionLevels.SPECIFIC_DAY
    AT_TIME            : ExpressionLevels.AT_TIME
    EVERY_HOUR         : ExpressionLevels.EVERY_HOUR

    normalizeCron: (cron) ->
        cron = _.cloneDeep(cron or {})
        cron.minute = 0 if not cron.minute or cron.minute is '*'
        cron.hour       ?= '*'
        cron.dayOfMonth ?= '*'
        cron.month      ?= '*'
        cron.dayOfWeek  ?= '*'
        return cron

    timeToCron: (time) ->
        return null if not time
        matches = time.match(RegularExpressions.TIME)?[1..]
        return null if not matches
        tokens = matches.map (x) -> (x?.replace?(/\s/g, '')) or x
        [hour, minute, amPm] = [parseInt(tokens[2]), tokens[3]?.replace(':', ''), tokens[4].toLowerCase()]
        hour = do ->
            return 0         if hour is 12 and amPm is 'am'
            return hour + 12 if hour isnt 12 and amPm is 'pm'
            return hour
        minute = if minute then parseInt(minute) else 0
        return {minute, hour, dayOfMonth:'*', month:'*', dayOfWeek:'*'}

    cronToTime: (cron) ->
        return null if not cron
        return null if cron.hour is '*'
        {minute, hour} = cron
        [m, h, amPm] = do ->
            [m, h] = [minute, hour].map (x) -> Math.max(0, parseInt(x))
            [m, h] = [Math.min(59, m), Math.min(23, h)]
            m = do ->
                return "" if m is 0
                return ":0#{m}" if m <= 9
                return ":#{m}"
            return [m, h-12, 'pm'] if h > 12
            return [m, h, 'pm']    if h is 12
            return [m, 12, 'am']   if h is 0
            return [m, h, 'am']
        return "#{h}#{m}#{amPm}"

    cronToExpression: (cron) ->
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return "#{minute} #{hour} #{dayOfMonth} #{month} #{dayOfWeek}"

    expressionToCron: (expression) ->
        return null if not expression
        expression = expression.replace(/s+/g, ' ').trim()
        matches = expression.match(RegularExpressions.CRON)?[1..]
        return null if not matches
        [minute, hour, dayOfMonth, month, dayOfWeek] = matches.map (x) ->
            return x if x is '*'
            return parseInt(x)
        return {minute, hour, dayOfMonth, month, dayOfWeek}

    cronToLevels: (cron) ->
        return [@CRON] if not cron
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return [ExpressionLevels.FIRST_DAY_OF_MONTH, ExpressionLevels.AT_TIME] if dayOfMonth is 1 and month is '*'
        return [ExpressionLevels.CRON]                                         if dayOfMonth isnt '*' or month isnt '*'
        return [ExpressionLevels.EVERY_DAY, ExpressionLevels.AT_TIME]          if minute isnt '*' and hour isnt '*' and dayOfWeek is '*'
        return [ExpressionLevels.EVERY_DAY, ExpressionLevels.EVERY_HOUR]       if minute isnt '*' and hour is '*' and dayOfWeek is '*'
        dayLabel = ExpressionLevels.DAYS[parseInt(dayOfWeek)]
        if dayLabel
            return [ExpressionLevels.SPECIFIC_DAY(dayLabel), ExpressionLevels.AT_TIME]    if minute isnt '*' and hour isnt '*' and dayOfWeek isnt '*'
            return [ExpressionLevels.SPECIFIC_DAY(dayLabel), ExpressionLevels.EVERY_HOUR] if minute isnt '*' and hour is '*' and dayOfWeek isnt '*'
        return [ExpressionLevels.CRON]

    expressionToNaturalLanguage: (expression) ->
        cron = @expressionToCron(expression)
        return @cronToNaturalLanguage(cron)

    cronToNaturalLanguage: (cron) ->
        return null if not cron
        return try cronstrue.toString(cron, { verbose: true })


module.filter 'humanizeCron', (CronExpressionUtils) -> (expression) ->
    return CronExpressionUtils.expressionToNaturalLanguage(expression)


module.directive 'scheduleExpressionEditor', (CronExpressionUtils) ->
    restrict: "E"
    scope:
        model: "="
        reports: "="
        errorMessage: "="
    # replace: true
    template: \
    """
    <article class="schedule-expression-editor" ng-class="{invalid:isInvalid}">

        <select ng-options="x for x in options" ng-model="levels[0]"></select>

        <div class="level-every-day" ng-if="levels[0] == ExpressionLevels.EVERY_DAY">
            <select ng-options="x for x in [ExpressionLevels.AT_TIME, ExpressionLevels.EVERY_HOUR]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time"
                ng-if="levels[1] == ExpressionLevels.AT_TIME"
                placeholder="ex: 8:05am"
                type="text"
                ng-model="view.time"
                ng-pattern="regex.TIME">
            </input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == ExpressionLevels.EVERY_HOUR">
                <span>at minute</span>
                <input class="input-minute" data-tooltip="{{ errorMessage }}" type="number" ng-model="view.minute" min="0" max="59" integer></input>
            </div>
        </div>

        <div class="level-every-day" ng-if="levels[0] == ExpressionLevels.FIRST_DAY_OF_MONTH">
            <select ng-options="x for x in [ExpressionLevels.AT_TIME]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" placeholder="ex: 8:05am" type="text" ng-model="view.time" ng-pattern="regex.TIME"></input>
        </div>

        <!-- <minute> <hour> <day> * * -->
        <div class="level-day" ng-if="dayOfWeekSelected()">

            <select ng-options="x for x in [ExpressionLevels.AT_TIME, ExpressionLevels.EVERY_HOUR]" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" type="text" ng-if="levels[1] == ExpressionLevels.AT_TIME" ng-model="view.time" ng-pattern="regex.TIME"></input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == ExpressionLevels.EVERY_HOUR">
                <span>at minute</span>
                <input class="input-minute" type="number" ng-model="view.minute" min="0" max="59" placeholder="0" integer></input>
            </div>
        </div>

        <!-- catchall -->
        <div class="level-cron" ng-if="levels[0] == ExpressionLevels.CRON">
            <input class="input-cron" type="text" ng-model="model.expression"></input>
        </div>
    </article>
    """
    link: (scope) ->
        scope.regex =
            TIME:  RegularExpressions.TIME
            CRON:  RegularExpressions.CRON
            EMAIL: RegularExpressions.EMAIL

        scope.options = [
            ExpressionLevels.EVERY_DAY,
            ...ExpressionLevels.SPECIFIC_DAYS,
            ExpressionLevels.FIRST_DAY_OF_MONTH,
            ExpressionLevels.CRON,
        ]
        scope.ExpressionLevels = _.cloneDeep(ExpressionLevels)

        getCronSelectedDayIndex = ->
            index = ExpressionLevels.SPECIFIC_DAYS.indexOf(scope.levels[0])
            return "*" if index is -1
            return index

        getCronSelectedDayOfMonth = (cron) ->
            # if scope.levels and scope.levels[0] and scope.levels[0] is 'Every First Day of Month'
            #     return '1'
            return cron?.dayOfMonth ? '*'

        scope.$watch 'model', (model) ->
            return if not model
            scope.view ?= {}
            scope.view.cron = CronExpressionUtils.expressionToCron(model.expression)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)

        scope.dayOfWeekSelected = ->
            return scope.levels?[0] and ExpressionLevels.SPECIFIC_DAYS.includes(scope.levels[0])

        scope.$watch 'levels[0]', (level0) ->
            return if not level0
            if level0 is ExpressionLevels.CRON
                delete scope.levels[1]
                return
            scope.view.cron ?= {}
            scope.view.cron.month = '*'
            scope.view.cron.dayOfMonth = do ->
                return 1 if level0 is ExpressionLevels.FIRST_DAY_OF_MONTH
                return '*'
            scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron or {})
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)
            scope.view.minute = scope.view.cron?.minute

        scope.$watch 'levels[1]', (level1) ->
            return if not level1
            if level1 is ExpressionLevels.AT_TIME
                scope.view.cron ?= {}
                scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron)
                scope.view.cron.hour = 8 if scope.view.cron.hour is '*'
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            if level1 is ExpressionLevels.EVERY_HOUR
                minute = scope.view.cron?.minute
                dayOfWeek = getCronSelectedDayIndex()
                scope.view.cron = CronExpressionUtils.normalizeCron({minute, dayOfWeek})
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = 0

        scope.$watch 'view.cron', ((cron) ->
            return if not scope.model
            isInvalid = not cron or _.some _.values(cron), (x) -> _.isNull(x) or _.isUndefined(x)
            scope.view.cron = CronExpressionUtils.normalizeCron(cron) if not isInvalid
            scope.model.expression = do ->
                return scope.model.expression if scope.levels?[0] is ExpressionLevels.CRON
                return null if isInvalid
                return CronExpressionUtils.cronToExpression(cron)
            return if isInvalid or scope.levels[0] is ExpressionLevels.CRON
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)
            scope.view.time = do ->
                return null if scope.levels[1] isnt ExpressionLevels.AT_TIME
                return CronExpressionUtils.cronToTime(cron)
            scope.view.minute = scope.view.cron?.minute
            return if scope.levels[1] is ExpressionLevels.AT_TIME
            scope.levels = CronExpressionUtils.cronToLevels(cron)
        ), true

        scope.$watch 'model.expression', (expression) ->
            scope.isInvalid = not expression
            return if not scope.model
            scope.view.cron = (try CronExpressionUtils.expressionToCron(expression)) or null
            if scope.view.cron
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = scope.view.cron.minute

        scope.$watch 'view.minute', (minute, prevMinute) ->
            return if not scope.model
            return if scope.levels[1] isnt ExpressionLevels.EVERY_HOUR
            before = minute
            minute = do ->
                return null if _.isUndefined(minute) or _.isNull(minute)
                result = parseInt(minute)
                return null if _.isNaN(result)
                return result
            scope.view.cron = CronExpressionUtils.normalizeCron({minute}) if not scope.view.cron
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.minute = minute

        scope.$watch 'view.time', (time) ->
            return if not scope.model
            return if scope.levels[1] isnt ExpressionLevels.AT_TIME
            cronTime = CronExpressionUtils.timeToCron(time)
            return scope.view.cron = null if not cronTime
            {minute, hour} = cronTime
            dayOfWeek = getCronSelectedDayIndex()

            cronToNormalize = {hour, minute, dayOfWeek}
            cronToNormalize.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)

            scope.view.cron = CronExpressionUtils.normalizeCron(cronToNormalize)

        scope.$watch 'isInvalid', (isInvalid) ->
            scope.errorMessage = do ->
                return "" if not isInvalid or not scope.levels
                return "Invalid minute, must be a number between 0 and 59" if scope.levels[1] is ExpressionLevels.EVERY_HOUR
                return "Invalid time, must be formatted like '9am' or '6:45pm'" if scope.levels[1] is ExpressionLevels.AT_TIME
