//prettier-ignore
export const EMAIL = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i)
export const TIME = new RegExp(/^\s*(((0?[1-9]|1[0-2])(:[0-5][0-9])?\s*(am|pm)))\s*$/i);
//prettier-ignore
export const CRON = new RegExp(/^(0?[0-9]|[1-5][0-9]|\*) ([0-1]?[0-9]|2[0-3]|\*) ([0-1]?[0-9]|2[0-9]|3[0-1]|\*) (0?[0-9]|1[0-2]|\*) (0?[0-6]|\*)$/)

export const SPECIAL_CHARACTER = new RegExp(/[`~!@#$%^&*()\-_+{}[\]\\|,.//?;':"]/);

export function createRegex(searchText: string, flags = 'gi'): RegExp {
    searchText = searchText.trim().replace(new RegExp(SPECIAL_CHARACTER, 'g'), '\\$&');
    return new RegExp(searchText, flags);
}
