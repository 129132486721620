export const StickyDirective = () => [
    function StickyDirective(): angular.IDirective<angular.IScope> {
        return {
            restrict: 'A',
            link: function StickyLink($scope, $element) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const element = $element.get()[0];
                if (!element) throw new Error("Element doesn't exist!");

                element.style.position = 'sticky';
                element.style.top = '-1px';

                const observer = new IntersectionObserver(
                    ([e]) => e?.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
                    { threshold: [1] },
                );

                observer.observe(element);
                $scope.$on('$destroy', () => observer.disconnect());
            },
        };
    },
];

const StickyModule = angular.module('42.components.stuck', []).directive('sticky', StickyDirective());

export default StickyModule;
