import type { v1 as ServiceV1 } from '@42technologies/service-client';
import type { IQuery, IQueryType } from '../types';
import { ServiceAPIWithOrgConfigOverrideFactory, ServiceAPIWithOrgConfigOverrideCall } from './api-with-override';

export const QueryServiceAPI = new ServiceAPIWithOrgConfigOverrideFactory<QueryServiceAPI>('query', false);

export interface QueryServiceAPI extends ServiceV1.ServiceAPI {
    cache: {
        deleteCachedOrganizationQueries: QueryServiceAPICall<{ organization: string }, void>;
    };
    exports: {
        getExport: QueryServiceAPICall<{ type: string } & ({ filename: string } | { id: string })>;
    };
    organizations: {
        getQueryIds: QueryServiceAuthenticatedAPICall<{ organization: string }, string[]>;
        getDescriptors: QueryServiceAuthenticatedAPICall<{ organization: string }, DatabaseDescriptors>;
        getMetrics: QueryServiceAuthenticatedAPICall<{ organization: string }, QueryServiceMetric[]>;
        getStatus: QueryServiceAuthenticatedAPICall<{ organization: string }, DatabaseStatus>;
        doQuery: <QueryId extends string, Query extends QueryFrom<QueryId>>(
            params: {
                // organization: string
                queryId: QueryId;
                query: Query;
            },
            httpOptions?: any,
        ) => Promise<QueryResultFrom<QueryId, Query>>;
    };
}

export interface DatabaseColumnDescriptor {
    type_name: string;
    type_id: number;
    name: string;
    table: string;
}
export type DatabaseDescriptors = Record<string, DatabaseColumnDescriptor[]>;

export interface DatabaseStatus {
    messageId: null | string;
    message: null | string;
    messageType: null | string;
    timestamp: string;
    latestTransactionTimestamp: null | string;
    isLoading: boolean;
    load: { start: string; end: string };
}

export interface QueryServiceMetric<Field extends string = string> {
    field: Field;
    cellFilter?: null | string;
    headerGroup?: string;
    headerName?: string;
    category?: string;
}

type Queries = Readonly<{
    /** The currency ID */
    findCurrencies: QueryDeclaration<string[] | { id: string }[]>;
    findCalendarTypes: QueryDeclaration<{ id: null | string }[]>;
    getFullTimeSpan: QueryDeclaration<
        {
            max_timestamp: string;
            min_timestamp: string;
        }[]
    >;
    customerCount: QueryDeclaration<{ count: number }[]>;
    customerStats: QueryDeclaration<
        {
            avg_item_count: number;
            avg_total_spent: number;
            avg_transaction_count: number;
            customer_count: number;
            item_count: number;
            total_spent: number;
            transaction_count: number;
        }[]
    >;
}>;

interface QueryDeclaration<QueryOutput = Record<string, unknown>[], QueryInput extends IQuery = IQuery> {
    readonly query: QueryInput;
    readonly json: QueryOutput;
}

type QueryDeclarationFrom<QueryId extends string> = QueryId extends keyof Queries ? Queries[QueryId] : QueryDeclaration;

export type QueryFrom<QueryId extends string> = QueryDeclarationFrom<QueryId>['query'];
export type QueryResultFrom<QueryId extends string, Query extends IQuery = IQuery> = Query extends {
    type: Exclude<IQueryType, null | undefined | 'json'>;
}
    ? ExportedQueryResult
    : QueryDeclarationFrom<QueryId>['json'];

export interface ExportedQueryResult {
    host: string;
    data: {
        id: string;
        type: string;
    };
}

type QueryServiceAPICall<
    T extends Record<string, unknown> = Record<string, unknown>,
    V = unknown,
> = ServiceAPIWithOrgConfigOverrideCall<T, V>;

type QueryServiceAuthenticatedAPICall<
    T extends { organization: string } = { organization: string },
    V = unknown,
> = ServiceAPIWithOrgConfigOverrideCall<T, V>;
