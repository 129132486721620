import { ICellRendererComp, ICellRendererParams } from '@ag-grid-community/core';
import type { IPropertyDefinition } from '../../../lib/config-hierarchy';
import type { IMetricDefinition, IQuery } from '../../../lib/types';
import {
    InfoItemCellRenderer,
    InfoItemCellRendererInitParams,
    ItemCellRenderer,
    ItemCellRendererInitParams,
} from '../../../components/item-cell-renderer';
import { ToggleModel } from '../../../lib/model/model-toggle';

export interface IItemsSortBy {
    id: string;
    group: string;
    label: string;
}

export interface GridActionsModel {
    new (save: () => Promise<void>, state: unknown): IGridActionsModel;
}
export interface IGridActionsModel {
    dropdown: boolean;
    editMode: boolean;
    fillNameIfNeeded: () => void;
    id: string;
    name: string;
    parentSave: () => void;
    save: () => void;
    toQuery: () => IQuery;
    toExportQuery: () => IQuery;
    setSelectedMetrics: (metrics: (IMetricDefinition | string)[]) => void;
    updateExtraItemInfo: () => void;
    selected: {
        groupBy: IPropertyDefinition;
        itemsExtraInfo: IPropertyDefinition[];
        itemsGroupBy: IPropertyDefinition;
        itemsLimitBy: number;
        itemsSortBy: IItemsSortBy;
        itemsSortOrder: number;
        metrics: string[];
        itemsThemeLarge?: undefined | boolean;
    };
    values: {
        groupBy: IPropertyDefinition[];
        hierarchyIndex: {
            groupBy: Record<string, IPropertyDefinition>;
            indexGroupBy: Record<string, IPropertyDefinition>;
        };
        itemsGroupBy: IPropertyDefinition[];
        itemsLimitBy: number[];
        itemsSortBy: IItemsSortBy[];
        itemsSortOrder: { id: number; label: number }[];
        metrics: IMetricDefinition[];
        metricsByField: Record<string, IMetricDefinition>;
    };
    views: {
        images: ToggleModel;
        metrics: ToggleModel;
        panel: ToggleModel;
    };
}

type ICustomDataCellRendererParams = Record<string, string | number> & {
    items: Record<string, string | number>[];
};
export type IGridPageCellRenderer = ICellRendererComp;
export function GridItemCellRendererFactory($filter: angular.IFilterService, actionsModel: IGridActionsModel) {
    type GridItemCellRendererInitParams = ItemCellRendererInitParams &
        Omit<ICellRendererParams, 'data'> & { data: ICustomDataCellRendererParams };

    return class GridItemCellRenderer extends ItemCellRenderer implements ICellRendererComp {
        readonly actionsModel: IGridActionsModel;

        constructor() {
            super($filter);
            this.actionsModel = actionsModel;
        }

        public override init(params: GridItemCellRendererInitParams) {
            const columnId = params.column?.getColId();
            const colIndex = params.columnApi.getAllColumns()?.findIndex(col => col.getColId() === columnId);
            if (typeof colIndex !== 'number' || colIndex === -1) {
                throw new Error('[Grid Cell Renderer] Column index not found!');
            }
            const data = params.data;
            const item = data.items[colIndex - 1];

            super.init({
                item,
                label: item?.property1,
                imagesEnabled: actionsModel.views.images.state.isOpen,
                itemsThemeLarge: actionsModel.selected.itemsThemeLarge,
                availableMetrics: actionsModel.values.metricsByField,
                selectedMetrics: actionsModel.selected.metrics,
                selectedExtraProperties: actionsModel.selected.itemsExtraInfo,
            });
        }

        public refresh(params: GridItemCellRendererInitParams): boolean {
            return true;
        }
    };
}

export function GridInfoCellRendererFactory($filter: angular.IFilterService, actionsModel: IGridActionsModel) {
    type GridInfoItemCellRendererInitParams = InfoItemCellRendererInitParams &
        Omit<ICellRendererParams, 'data'> & { data: ICustomDataCellRendererParams };

    return class GridInfoItemCellRenderer extends InfoItemCellRenderer implements ICellRendererComp {
        constructor() {
            super($filter);
        }

        override init(params: GridInfoItemCellRendererInitParams) {
            const item = params.data;
            super.init({
                item,
                label: item.property0,
                imagesEnabled: actionsModel.views.images.state.isOpen,
                itemsThemeLarge: actionsModel.selected.itemsThemeLarge,
                availableMetrics: actionsModel.values.metricsByField,
                selectedMetrics: actionsModel.selected.metrics,
                selectedExtraProperties: actionsModel.selected.itemsExtraInfo,
            });
            this.eGuiElement?.classList.remove('empty');
        }

        public refresh(params: GridInfoItemCellRendererInitParams): boolean {
            return true;
        }
    };
}
