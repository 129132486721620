import _ from 'lodash';
import { IMetricDefinition } from '../../lib/types';

export interface IMetricDefinitionExtended extends IMetricDefinition {
    title: string;
    property: string;
    color: string | undefined;
    value?: number | null;
    growth?: number | null;
    growth_value?: number | null;
    growth_value_prefix?: string;
    inverted?: boolean;
}

export interface IAdStatsItem {
    chart?: IMetricDefinitionExtended;
    value?: null | number | string;
    growth?: number | null;
    growth_value?: null | number | string;
    growth_value_prefix?: undefined | string;
    title: string;
    inverted: boolean | undefined;
}

export const processMetrics = (
    availableMetrics: IMetricDefinition[],
    metrics: string[],
    metricByColor: Record<string, string>,
): { availableMetrics: IMetricDefinitionExtended[]; selectedMetrics: IMetricDefinitionExtended[] } => {
    const allMetrics = _.keyBy(availableMetrics, metric => metric.field);
    const selectedMetrics = metrics.reduce<IMetricDefinitionExtended[]>((acc, metricName) => {
        const metric = allMetrics[metricName];
        if (!metric) {
            console.error(`Sales page metric '${metricName}' not found.`);
            return acc;
        }

        const metricToExtend = _.cloneDeep(metric);
        const extendedMetric = _.extend(metric, {
            inverted: (allMetrics[`growth_${metricName}`] ?? metric).cellClass === 'percent-inverted',
            field: metricName,
            title: metricToExtend.headerGroup,
            property: metricToExtend.field,
            cellFilter: metricToExtend.cellFilter ?? null,
            color: metricByColor[metricName],
        });

        acc.push(extendedMetric);

        return acc;
    }, []);

    return {
        availableMetrics,
        selectedMetrics,
    };
};

const getMetricValue = (
    $filter: angular.IFilterService,
    metric: IMetricDefinitionExtended,
    data: undefined | Record<string, unknown>,
    property?: string,
): null | string => {
    if (!data) return null;
    const field = property ?? metric.property;
    const value = data[field];
    return $filter('metric')(value, metric);
};

export const remapData = (
    $filter: angular.IFilterService,
    metrics: IMetricDefinitionExtended[],
    data?: Record<string, unknown>,
): IAdStatsItem[] => {
    return metrics.map(metric => {
        const { property, inverted, growth_value_prefix, title } = metric;
        const growth = (() => {
            if (!data) return null;
            const growthPropertyValue = data[`growth_${property}`] ?? null;
            if (typeof growthPropertyValue !== 'string' && typeof growthPropertyValue !== 'number') return null;
            return $filter('number')(growthPropertyValue);
        })();

        return {
            chart: metric,
            title,
            value: getMetricValue($filter, metric, data),
            growth: growth !== null ? Number(growth) : growth,
            growth_value_prefix,
            inverted: Boolean(inverted),
            growth_value: getMetricValue($filter, metric, data, `growth_${property}_prev`),
        };
    });
};
