import _ from 'lodash';
import type { ICalendarDate, ICalendarDateStatic } from '@42technologies/calendar';
import { isObject } from '../../../lib/utils';

export interface IDateSelectModelTimeRange<T = ICalendarDate> {
    start: T;
    end: T;
}
export interface IDateSelectModelFilter {
    $gte?: string;
    $lt?: string;
}
export type IDateSelectModelBounds = {
    start: ICalendarDate;
    end: ICalendarDate;
};

export type IDateSelectModelParams = {
    selected?: null | IDateSelectModelTimeRange<unknown>;
    bounds: {
        start: string | ICalendarDate;
        end: string | ICalendarDate;
    };
    shouldClamp?: boolean;
};

export type DateSelectModel = ReturnType<typeof DateSelectModelFactory>;
export type IDateSelectModel = InstanceType<DateSelectModel>;

export function DateSelectModelFactory(CalendarDate: ICalendarDateStatic) {
    return class DateSelectModel {
        static IsRange(range: unknown): range is IDateSelectModelTimeRange<unknown> {
            return isObject(range) && ('start' in range || 'end' in range);
        }

        static ParseRange(range: unknown): null | IDateSelectModelTimeRange {
            if (!isObject(range)) return null;
            const start = !_.isNil(range.start) ? CalendarDate.CreateFromDate(range.start) : null;
            const end = !_.isNil(range.end) ? CalendarDate.CreateFromDate(range.end) : null;
            if (!start || !end) return null;
            return { start, end };
        }

        readonly bounds: IDateSelectModelBounds;
        selected: null | IDateSelectModelTimeRange = null;
        shouldClamp: boolean;

        constructor(params: IDateSelectModelParams) {
            // NOTE:
            // We also use ParseRange to convert whatever date object we get from the bounds
            // to the date class that this model uses.
            const { start, end } = DateSelectModel.ParseRange(params.bounds) ?? {};
            if (!start) throw new Error('Bounds not set; missing required bounds.start');
            if (!end) throw new Error('Bounds not set; missing required bounds.end');
            this.bounds = { start, end };

            const range = DateSelectModel.IsRange(params.selected)
                ? DateSelectModel.ParseRange(params.selected)
                : this.bounds;

            this.select(range);
            this.shouldClamp = params.shouldClamp ?? true;
        }

        serialize() {
            if (!this.selected) return null;
            return {
                start: this.selected.start.format('YYYY-MM-DD'),
                end: this.selected.end.format('YYYY-MM-DD'),
            };
        }

        getSelected() {
            if (!this.selected) return null;
            return { ...this.selected };
        }

        selectStart(start: IDateSelectModelTimeRange['start']) {
            return this.select({ start, end: this.selected?.end });
        }

        selectEnd(end: IDateSelectModelTimeRange['end']) {
            return this.select({ end, start: this.selected?.start });
        }

        select(range: null | undefined | Partial<IDateSelectModelTimeRange<unknown>>) {
            let { start, end } = DateSelectModel.ParseRange(range) ?? {};
            if (!start && !end) {
                this.selected = null;
            } else {
                start ??= this.selected?.start;
                end ??= this.selected?.end;
                if (this.shouldClamp) {
                    start = start?.clamp(this.bounds.start, this.bounds.end);
                    end = end?.clamp(this.bounds.start, this.bounds.end);
                }
                if (start && end && start.gt(end)) end = start.clone();
                if (start && end && end.lt(start)) start = end.clone();
                this.selected = !start || !end ? null : { start, end };
            }
            return this;
        }
    };
}
