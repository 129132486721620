import _ from 'lodash';

import { CustomerStatsDirective } from './customer-stats.directive';
import { CustomerListDirective } from './customer-list.directive';
import { CustomerControllerInstance } from './customer.controller';
import {
    CustomerStatsServiceInstance,
    CustomerCountServiceInstance,
    CustomersReportServiceInstance,
} from '../customer.service';

const module = angular.module('42.controllers.customer.main', []);
module.config(function CustomersMainConfig($routeProvider: angular.route.IRouteProvider, ROUTES: any, CONFIG: any) {
    const routes = CONFIG.routes ? CONFIG.routes?.customers : {};
    const route = _.extend({}, ROUTES.customers, _.pick(routes, 'label', 'url'));
    $routeProvider.when(route.url, route).otherwise({ redirectTo: '/' });
});

module.service('CustomerStats', CustomerStatsServiceInstance());
module.service('CustomerCount', CustomerCountServiceInstance());
module.service('CustomersReport', CustomersReportServiceInstance());

module.controller('CustomerController', CustomerControllerInstance());

module.directive('customerStats', CustomerStatsDirective());
module.directive('customerList', CustomerListDirective());
