/**
 * This class is what we use to save/load the user's selected organization.
 * We cache the organizationId to make sure it doesn't change during the user's session.
 * We've had issues historically where having multiple tabs would cause some problems.
 */
export class UserOrganizationStorageAPI {
    protected key: string;
    protected storage: Storage;
    protected organizationId: null | string = null;

    constructor({ key, storage }: { key?: string; storage?: Storage } = {}) {
        this.key = key ?? '42-organization';
        this.storage = storage ?? globalThis.localStorage;
    }

    get(): string | null {
        if (this.organizationId) return this.organizationId;
        try {
            if (!this.storage) {
                console.error('[UserOrganizationStorageAPI] No localStorage available, cannot load organization.');
                return null;
            } else {
                const organizationId = this.storage.getItem(this.key) ?? null;
                console.log('[UserOrganizationStorageAPI] Loaded organizationId from localStorage:', organizationId);
                this.organizationId = organizationId;
                return organizationId;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async set(organizationId: string) {
        console.log('[UserOrganizationStorageAPI] Setting organizationId to:', organizationId);
        this.organizationId = organizationId;
        try {
            if (!this.storage) {
                console.error('[UserOrganizationStorageAPI] No storage available, cannot save organization.');
            } else {
                this.storage.setItem(this.key, organizationId);
            }
        } catch (error) {
            if (isQuotaExceededError(error)) {
                console.error('LocalStorage length:', localStorage?.length);
            }
            console.error(error);
        }
    }
}

function isQuotaExceededError(error: unknown): error is DOMException {
    return error instanceof DOMException && error.name === 'QuotaExceededError';
}

export const UserOrganizationStorage = new UserOrganizationStorageAPI();

// This is probably not necessary, but it ensures we're caching the organizationId as soon as possible.
UserOrganizationStorage.get();
