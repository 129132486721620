import _ from 'lodash';
import { omitDeep } from '../utils/utils-object-omit-deep';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AngularInjected<T> = T extends any[]
    ? ReturnType<Exclude<T[number], string>>
    : T extends (...args: unknown[]) => infer R
    ? AngularInjected<R>
    : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepStripAngularProperties<T extends Record<string, any> | Record<string, any>[]>(obj: T): T {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return omitDeep(_.cloneDeep(obj), '$$hashKey') as T;
}
