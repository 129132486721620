import _ from 'lodash';
import angular from 'angular';
import { IConfigObj, IMetricDefinition } from '../../lib/types';
import { DashboardRootScope, IQueryMetrics } from '../main-controller';

const module = angular.module('42.controllers.debug.metrics', []);
export default module;

module.config([
    '$routeProvider',
    'ROUTES',
    'CONFIG',
    function (
        $routeProvider: angular.route.IRouteProvider,
        ROUTES: Record<string, angular.route.IRoute>,
        CONFIG: IConfigObj,
    ) {
        const override = (CONFIG.routes ?? {}).debugMetrics ?? {};
        const route = { ...ROUTES.debugMetrics, ..._.pick(override, 'label', 'url') };
        if (!route.url) {
            alert('route not found');
            throw new Error('route not found');
        }
        return $routeProvider.when(route.url, route);
    },
]);

type ControllerScope = angular.IScope & {
    view: {
        loading: boolean;
        metrics: null | IMetricDefinition[];
    };
};

module.controller('DebugMetricsController', [
    '$scope',
    '$rootScope',
    'QueryMetrics',
    ($scope: ControllerScope, $rootScope: DashboardRootScope, QueryMetrics: IQueryMetrics) => {
        $scope.view = { loading: true, metrics: null };
        $scope.$on(
            '$destroy',
            $rootScope.$watch('initialized', (initialized: undefined | boolean) => {
                if (!initialized) return;
                void QueryMetrics.fetch()
                    .then(metrics => {
                        $scope.view.metrics = metrics;
                    })
                    .finally(() => {
                        $scope.view.loading = false;
                    });
            }),
        );
    },
]);

module.directive('debugPageMetricsView', [
    '$q',
    '$rootScope',
    'QueryMetrics',
    () => ({
        restrict: 'E',
        scope: {
            view: '=',
        },
        replace: true,
        template: `
        <main style="font-family: monospace;">
            <div class="metrics">
                <div class="metric-group" ng-repeat="category in grouped.categories">
                    <header style="position: sticky; top: 0; background: white; border: 1px solid black; padding: 10px;">
                        <h1>{{ category.label }}</h1>
                    </header>
                    <section>
                        <div ng-repeat="headerGroup in grouped.metrics[category.label].headerGroups"
                        style="display: block; width: fit-content; margin: 10px; background: white; padding: 10px; border: 1px solid black; ">
                            <h2 style="margin-bottom: 5px;">{{ headerGroup }}</h2>
                            <debug-page-metrics-list-item ng-repeat="metric in grouped.metrics[category.label].metrics[headerGroup]" metric="metric"></debug-page-metrics-list-item>
                        </div>
                    </section>
                </div>
            </div>
        </main>
        `,
        link: (
            scope: angular.IScope & {
                grouped?: {
                    categories: { label: string }[];
                    metrics: Record<string, { headerGroups: string[]; metrics: Record<string, IMetricDefinition[]> }>;
                };
            },
        ) => {
            scope.grouped = { categories: [], metrics: {} };
            scope.$watch('view.metrics', (metrics: undefined | IMetricDefinition[]) => {
                metrics ??= [];
                scope.grouped = {
                    categories: _.uniq(metrics.map(x => x.category ?? 'No Category')).map(x => ({ label: x })),
                    metrics: _.mapValues(
                        _.groupBy(metrics, x => x.category),
                        x => ({
                            headerGroups: _.uniq(x.map(m => m.headerGroup)),
                            metrics: _.groupBy(x, 'headerGroup'),
                        }),
                    ),
                };
            });
        },
    }),
]);

module.directive('debugPageMetricsListItem', [
    () => ({
        restrict: 'E',
        scope: {
            metric: '=',
        },
        replace: true,
        template: `
        <span class="metric" style="background: #F4F4F4; font-size: 12px; display: inline-block; padding: 4px 6px; border-radius: 4px; margin: 4px;" title="{{ metric.field }}">
            <span>{{:: metric.headerName }}</span>
        </div>
        `,
    }),
]);
