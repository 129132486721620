import 'construct-style-sheets-polyfill';

import '../../styles/libs.scss';
import '../../styles/default.scss';

import angular from 'angular';
window.angular = angular;

import '../libs/angular/angular.ui.js';
import '../libs/angular/angular.infinitescroll.js';
import '../libs/angular/angular.ui.bootstrap.js';

import { main } from './bootstrap';
void main();
