import React from 'react';
import { react2angular } from 'react2angular';

const ChartEchartsTitle = ({ model }: { model: { metrics: { label: string; id: string; }[]; properties: { id: string; label: string}[] } }) => {

    const getTemplate = (m:{ metrics: { label: string; id: string; }[]; properties: { id: string; label: string}[] } | string | undefined ) => {
        m ??= '';
        if (typeof m === 'string') return <div><span>{m}</span></div>;

        return (
            <div>
                { m.metrics.map(x => <span key={x.id} className="metric">{x.label}</span>) }
                {' '}
                { m.metrics.length > 0 && m.properties.length > 0 && <span>by</span> }
                {' '}
                { m.properties.map(x => <span key={x.id} className="metric">{x.label}</span>) }
            </div>
        )
    }

    return (
        <h1 className="echarts-title card-title">
            {getTemplate(model)}
        </h1>
    )
}


const EchartsTitleReactModule = angular.module('42.directives.charts.echarts.title.react', []);
EchartsTitleReactModule.component('chartEchartsTitleReact', react2angular(ChartEchartsTitle, ['model']));

export default EchartsTitleReactModule;
