import * as _MiscUtils from './utils-misc';
import * as _ArrayUtils from './utils-array';
import * as _ObjectUtils from './utils-object';
export * from './utils-types';
export const MiscUtils = _MiscUtils;
export const ArrayUtils = _ArrayUtils;
export const ObjectUtils = _ObjectUtils;

export const isObject = _ObjectUtils.isObject;
export const uuid = _MiscUtils.uuid;

export default {
    // eslint-disable-next-line import/no-deprecated
    indexOf: _ArrayUtils.indexOf,
    copy: MiscUtils.copy,
    uuid: MiscUtils.uuid,
    clamp: MiscUtils.clamp,
    isObject: ObjectUtils.isObject,
    Misc: _MiscUtils,
    Array: _ArrayUtils,
    Object: _ObjectUtils,
};

export function seconds(n = 0) {
    return n * 1000;
}

// Minutes in milliseconds
export function minutes(n = 0) {
    return seconds(n * 60);
}

// Hours in milliseconds
export function hours(n = 0) {
    return minutes(n * 60);
}

export const delay = (ms: number) => {
    return new Promise(r => setTimeout(r, Math.max(0, Math.floor(ms))));
};

// Custom error class that works with ES5
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CustomError<Cause = any> extends Error {
    override readonly cause!: Cause;
    constructor(message?: string, options?: { cause?: Cause }) {
        // 'Error' breaks prototype chain here
        super(message, options);

        // restore prototype chain
        const actualProto = new.target.prototype;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            // @ts-expect-error - this exists...
            this.__proto__ = actualProto;
        }
    }
}
