export const FUNNEL_SVG = `
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g>
            <path
                style=" stroke:none;fill-rule:nonzero;fill-opacity:1;"
                d="M 19.476562 3.96875 C 19 3.648438 18.335938 3.363281 17.503906 3.125 C 15.863281 2.65625 13.699219 2.398438 11.398438 2.398438 C 9.101562 2.398438 6.933594 2.65625 5.296875 3.125 C 4.460938 3.363281 3.800781 3.648438 3.324219 3.96875 C 2.558594 4.484375 2.398438 5.03125 2.398438 5.398438 L 2.398438 6 C 2.398438 6.511719 2.785156 7.359375 3.167969 7.929688 L 9.035156 16.734375 C 9.335938 17.183594 9.601562 18.058594 9.601562 18.601562 L 9.601562 22.199219 C 9.601562 22.40625 9.710938 22.601562 9.886719 22.710938 C 9.980469 22.769531 10.089844 22.800781 10.203125 22.800781 C 10.292969 22.800781 10.386719 22.777344 10.46875 22.738281 L 12.871094 21.535156 C 13.074219 21.433594 13.203125 21.226562 13.203125 21 L 13.203125 18.601562 C 13.203125 18.058594 13.464844 17.183594 13.765625 16.734375 L 19.636719 7.929688 C 20.015625 7.359375 20.402344 6.511719 20.402344 6 L 20.402344 5.398438 C 20.402344 5.03125 20.242188 4.484375 19.476562 3.96875 Z M 5.625 4.28125 C 7.160156 3.84375 9.210938 3.601562 11.398438 3.601562 C 13.589844 3.601562 15.640625 3.839844 17.175781 4.28125 C 18.828125 4.753906 19.199219 5.257812 19.199219 5.398438 C 19.199219 5.539062 18.828125 6.046875 17.175781 6.519531 C 15.640625 6.957031 13.589844 7.199219 11.398438 7.199219 C 9.210938 7.199219 7.160156 6.957031 5.625 6.519531 C 3.972656 6.046875 3.601562 5.539062 3.601562 5.398438 C 3.601562 5.257812 3.972656 4.753906 5.625 4.28125 Z M 12.765625 16.070312 C 12.335938 16.714844 12 17.824219 12 18.601562 L 12 20.628906 L 10.800781 21.230469 L 10.800781 18.601562 C 10.800781 17.824219 10.460938 16.714844 10.03125 16.070312 L 4.175781 7.285156 C 4.507812 7.425781 4.878906 7.554688 5.296875 7.675781 C 6.933594 8.140625 9.101562 8.398438 11.398438 8.398438 C 13.699219 8.398438 15.867188 8.140625 17.503906 7.675781 C 17.917969 7.554688 18.292969 7.425781 18.621094 7.285156 L 12.765625 16.066406 Z M 12.765625 16.070312 "
            />
        </g>
    </svg>
`;
