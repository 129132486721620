export class TextareaAutoSize {
    protected element: null | Element | HTMLElement = null;
    protected listener: null | (() => void) = null;
    protected verticalBorderSize: number;
    constructor(element: Element | HTMLElement) {
        this.element = element;
        this.listener = () => this.update();
        // prettier-ignore
        this.verticalBorderSize =
            this.getNumericStyleProp('borderTopWidth') +
            this.getNumericStyleProp('borderBottomWidth');
        element.addEventListener('input', this.listener);
        this.update();
    }

    destroy() {
        if (!this.element || !this.listener) return;
        this.element.removeEventListener('input', this.listener);
        this.listener = null;
        this.element = null;
    }

    update() {
        if (!this.element) return;
        if (!('style' in this.element)) return;
        const smallestHeight = this.getNumericStyleProp('fontSize');
        this.element.style.height = `${smallestHeight}px`;
        // Firefox still triggers a vertical scrollbar but as long as we add the
        // top/bottom padding to the scroll height, it's not shown. Other browsers
        // do the same regardless of whether this value is added or not.
        const newHeight = this.element.scrollHeight + this.verticalBorderSize;
        this.element.style.height = `${newHeight}px`;
    }

    protected getNumericStyleProp(name: keyof CSSStyleDeclaration) {
        if (!this.element) throw new Error('This component is not initialized');
        const computedStyle = getComputedStyle(this.element, null);
        return parseFloat(String(computedStyle[name] ?? 0));
    }
}
