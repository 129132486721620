import _ from 'lodash';
import { ConfigAPI } from './config-api';
import { UnionToIntersection, isObject } from './utils';

export type IConfigFlags = UnionToIntersection<IConfigFlagsSlice>;

// Intentionally not including the deprecated flags to trigger a TS compile Error but it will still work at runtime.
type IConfigFlagsSlice =
    | IConfigFlagsProperties
    | IConfigFlagsPages
    | IConfigFlagsPageOverview
    | IConfigFlagsMetrics
    | IConfigFlagsOptions;

export interface IConfigFlagsOptions {
    /** Query service modifiers */
    bypassLegacyDemandMetrics?: boolean;
    enableFoundationPruningFromFilters?: boolean;
    bypassTransfersInSellThruMetric?: boolean;
}

export interface IConfigFlagsProperties {
    /** Adds the Hour Property to the groupBy list */
    showHourDimension: boolean;
}

export interface IConfigFlagsPageOverview {
    /** Enable Top Items Component in Overview Page */
    showTopItems: boolean;
    /** Show the 'month' bucket in the overview page charts */
    showMonthBucket: boolean;
}

export interface IConfigFlagsMetrics {
    /** @deprecated remove this from codebase if possible */
    showBudget: boolean;
}
interface IConfigFlagsMetricsDeprecatedAliases {
    /** @deprecated use showBudget */
    showBudgets: boolean;
}

/** Flags to control show/hide of pages */
export interface IConfigFlagsPages {
    /** Enable Customers page */
    showCustomers: boolean;
    /** Enable Map page - Only for Demo */
    showMap: boolean;
    /** Enable Metrics page */
    showMetrics: boolean;
    /** Enable Transactions page  */
    showTransactions: boolean;
    /** Enable Ads page  */
    showAds: boolean;
    /** Enable Chart page */
    showChart: boolean;
    /** Enable Reporting/Scheduling pages */
    showReporting: boolean;
    /** Enable Status page */
    showStoreStatus: boolean;
    /** Enable Kiosk Page - Only supported for AllSaints - Can be navigated to on user-menu */
    showKiosk: boolean;
}

export interface IConfigFlagsPagesDeprecatedAliases {
    /** @deprecated */
    showStores: boolean;
    /** @deprecated */
    ads: boolean;
    /** @deprecated */
    chart: boolean;
    /** @deprecated */
    customers: boolean;
    /** @deprecated */
    kiosk: boolean;
    /** @deprecated */
    map: boolean;
    /** @deprecated */
    metrics: boolean;
    /** @deprecated */
    reporting: boolean;
    /** @deprecated */
    storestatus: boolean;
    /** @deprecated */
    transactions: boolean;
}

export function resolve<T extends IConfigFlagsSlice = IConfigFlags>(configs: {
    organization: unknown;
    user: unknown;
}): T {
    const flagsConf = (() => {
        const Flags = (x: unknown) => (isObject(x) && isObject(x.flags) ? x.flags : {});
        const user = Flags(configs.user);
        const org = Flags(configs.organization);
        const merged = { ...user, ...org };
        return { user, org, merged };
    })();

    const get = (key: string, from: Record<string, unknown> = flagsConf.merged): undefined | boolean => {
        const value = _.get(from, key);
        return typeof value === 'boolean' ? value : undefined;
    };

    const showAds = get('showAds') ?? false;
    const showChart = get('showChart') ?? get('showStores') ?? true;
    const showCustomers = get('showCustomers') ?? true;
    const showKiosk = get('showKiosk') ?? false;
    const showMap = get('showMap') ?? false;
    const showMetrics = get('showMetrics') ?? true;
    const showReporting = get('showReporting') ?? false;
    const showStoreStatus = get('showStoreStatus') ?? false;
    const showTransactions = get('showTransactions') ?? false;

    const showMonthBucket = get('showMonthBucket') ?? false;
    const showTopItems = get('showTopItems') ?? true;

    const showHourDimension = get('showHourDimension') ?? false;

    const showBudget = get('showBudget') ?? get('showBudgets') ?? true;

    const pageFlags: IConfigFlagsPages & IConfigFlagsPagesDeprecatedAliases = {
        showAds,
        showChart,
        showCustomers,
        showKiosk,
        showMap,
        showMetrics,
        showReporting,
        showStoreStatus,
        showTransactions,

        showStores: showChart,

        ads: showAds,
        chart: showChart,
        customers: showCustomers,
        kiosk: showKiosk,
        map: showMap,
        metrics: showMetrics,
        reporting: showReporting,
        storestatus: showStoreStatus,
        transactions: showTransactions,
    };

    const metricFlags: IConfigFlagsMetrics & IConfigFlagsMetricsDeprecatedAliases = {
        showBudget,
        showBudgets: showBudget,
    };

    const propertyFlags: IConfigFlagsProperties = {
        showHourDimension,
    };

    const overviewFlags: IConfigFlagsPageOverview = {
        showTopItems,
        showMonthBucket,
    };

    // query service modifiers
    const bypassLegacyDemandMetrics = get('bypassLegacyDemandMetrics', flagsConf.org);
    const bypassTransfersInSellThruMetric = get('bypassTransfersInSellThruMetric', flagsConf.org);
    const enableFoundationPruningFromFilters = get('enableFoundationPruningFromFilters', flagsConf.org);

    const optionFlags: IConfigFlagsOptions = {
        ...(typeof bypassLegacyDemandMetrics === 'boolean' ? { bypassLegacyDemandMetrics } : {}),
        ...(typeof bypassTransfersInSellThruMetric === 'boolean' ? { bypassTransfersInSellThruMetric } : {}),
        ...(typeof enableFoundationPruningFromFilters === 'boolean' ? { enableFoundationPruningFromFilters } : {}),
    };

    const result: IConfigFlags = {
        ...pageFlags,
        ...metricFlags,
        ...propertyFlags,
        ...overviewFlags,
        ...optionFlags,
    };

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as T;
}

// Use the type to narrow the set of flags you want to use...
export async function fetch<T extends IConfigFlagsSlice = IConfigFlags>(overrides?: {
    user?: unknown;
    organization?: unknown;
}): Promise<T> {
    const [user, organization] = await Promise.all([
        overrides?.user ?? ConfigAPI.get().then(api => api.user.getInternal()),
        overrides?.organization ?? ConfigAPI.get().then(api => api.organization.get()),
    ]);
    return resolve<T>({ user, organization });
}

export const fetchPageFlags = (overrides?: { user?: unknown; organization?: unknown }) =>
    fetch<IConfigFlagsPages>(overrides);

export const fetchOverviewPageFlags = (overrides?: { user?: unknown; organization?: unknown }) =>
    fetch<IConfigFlagsPageOverview>(overrides);
