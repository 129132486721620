import _ from 'lodash';
import { ConfigAPI } from './config-api';

export interface UserExternalConfig {
    'currency'?: unknown;
    'groups'?: unknown;
    'metrics.views.v1'?: unknown;
    'items.action-model-state-v2'?: unknown;
    'items.action-model-state-v3'?: unknown;
    'reports'?: {
        'metrics-breakdown'?: unknown;
        [x: string]: unknown;
    };
    'ads'?: {
        [x: string]: {
            chart?: string[] | undefined;
        };
    };
    [x: string]: unknown;
}

export type IStorageSlice<Put = unknown> = {
    get(): PromiseLike<unknown>;
    put(data: Put): PromiseLike<void>;
};

export class StorageAPISlice<Put = unknown, Key extends keyof UserExternalConfig = string>
    implements IStorageSlice<Put>
{
    constructor(
        readonly key: Key,
        private storage: Promise<StorageBackend<UserExternalConfig>> | StorageBackend<UserExternalConfig>,
    ) {}
    async get(): Promise<unknown> {
        return (await this.storage).get(this.key);
    }
    async put(data: Put): Promise<void> {
        return (await this.storage).put(this.key, data);
    }
}

interface StorageBackend<T extends Record<string, unknown>> {
    get<K extends keyof T>(key: K): Promise<T[K]>;
    put<K extends keyof T>(key: K, data: T[K]): Promise<void>;
}

class UserExternalConfigStorageBackend implements StorageBackend<UserExternalConfig> {
    static async Create() {
        const api = await ConfigAPI.get();
        const state = await api.user.get();
        return new UserExternalConfigStorageBackend(state ?? {});
    }

    constructor(private state: UserExternalConfig) {}

    async get(key: keyof UserExternalConfig) {
        const value = key in this.state && this.state[key] !== undefined ? _.cloneDeep(this.state[key]) : undefined;
        return Promise.resolve(value);
    }

    async put<K extends keyof UserExternalConfig>(key: K, data: UserExternalConfig[K]): Promise<void> {
        if (_.isPlainObject(data)) data = _.cloneDeep(data);
        this.state[key] = data;
        return ConfigAPI.get().then(api => api.user.set(this.state));
    }
}

export type IStorageAPI<Put = unknown, Key extends string = string> = (key: Key) => Promise<IStorageSlice<Put>>;
export const StorageAPI = (() => {
    const storage = UserExternalConfigStorageBackend.Create();
    return async <Put = unknown, Key extends string = string>(key: Key) => new StorageAPISlice<Put, Key>(key, storage);
})();
