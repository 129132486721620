import { DropObserver } from '../lib/dom/drop-observer';
import { CustomError } from '../lib/utils';

class DragAndDropNotInitializedError extends CustomError {}

export interface DragAndDropZoneState {
    isHoverDropbox: boolean;
    enabledFileDrop: boolean;
}

export interface DragAndDropZoneExternalAPI {
    onError: (error?: unknown) => void;
    onFile: (file: unknown) => void;
}

export interface DragAndDropExternalAPI {
    fillDragAndDropZoneExternalAPI: (methods: Partial<DragAndDropZoneExternalAPI>) => {
        openUploadPopup: () => void;
        enableDragAndDropZone: () => void;
        disableDragAndDropZone: () => void;
    };
}

interface DragAndDropZoneDirectiveScope extends angular.IScope, DragAndDropExternalAPI {}

export const DragAndDropZoneDirective = () => [
    function DragAndDropZoneDirective(): angular.IDirective<DragAndDropZoneDirectiveScope> {
        return {
            restrict: 'A',
            link: function DragAndDropZoneLink($scope, $element) {
                const element = $element.get()[0];
                if (!element) throw new Error("Element doesn't exist!");

                const dragAndDrop = {
                    isHoverDropbox: false,
                    enabledFileDrop: true,
                };

                const externalAPI: DragAndDropZoneExternalAPI = {
                    onFile: (file: unknown) => {
                        throw new DragAndDropNotInitializedError();
                    },
                    onError: error => {},
                };

                const reset = () => {
                    dragAndDrop.isHoverDropbox = false;
                    dragAndDrop.enabledFileDrop = true;
                    updateDropMode(dragAndDrop.isHoverDropbox);
                };

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { dragAndDropElement, inputElement } = (() => {
                    const dragAndDropElement = document.createElement('div');
                    dragAndDropElement.classList.add('drag-and-drop-zone');

                    const inputElement = document.createElement('input');
                    inputElement.id = 'report-file';
                    inputElement.style.display = 'none';
                    inputElement.classList.add('file-input');
                    inputElement.setAttribute('type', 'file');
                    inputElement.setAttribute('accept', '.json, .txt');
                    inputElement.setAttribute('name', 'report-file');

                    element.insertBefore(dragAndDropElement, element.firstChild);
                    element.insertBefore(inputElement, null);
                    return { dragAndDropElement, inputElement };
                })();

                const onViewImported = (file: unknown) => {
                    $scope.$applyAsync(() => {
                        try {
                            externalAPI.onFile(file);
                        } catch (err) {
                            if (!(err instanceof DragAndDropNotInitializedError)) {
                                alert('Sorry, but the file is invalid and cannot be imported.');
                                externalAPI.onError(err);
                            }
                            throw err;
                        } finally {
                            reset();
                        }
                    });
                };

                const updateDropMode = (dropMode: boolean) => {
                    const hasDropModeClass = element.classList.contains('drop-mode');
                    if (!dropMode && hasDropModeClass) {
                        element.classList.remove('drop-mode');
                    }
                    if (dropMode && !hasDropModeClass) {
                        element.classList.add('drop-mode');
                    }
                };

                const cleanupDragSection = ((element: HTMLElement) => {
                    const onDragStart = () => {
                        console.log('[drag-and-drop-zone] onDragStart');
                        dragAndDrop.enabledFileDrop = false;
                    };
                    const onDragEnter = (e: DragEvent) => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        console.log('[drag-and-drop-zone] onDragEnter');
                        if (!dragAndDrop.enabledFileDrop) return;
                        if (!e.dataTransfer?.types.includes('Files')) return;
                        dragAndDrop.isHoverDropbox = true;
                        updateDropMode(dragAndDrop.isHoverDropbox);
                        return;
                    };
                    const onDragEnd = (e: DragEvent) => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        console.log('[drag-and-drop-zone] onDragEnd');
                        reset();
                        return;
                    };

                    element.addEventListener('dragstart', onDragStart, false);
                    element.addEventListener('dragenter', onDragEnter, false);
                    element.addEventListener('dragend', onDragEnd, false);

                    return () => {
                        element.removeEventListener('dragstart', onDragStart);
                        element.removeEventListener('dragenter', onDragEnter);
                        element.removeEventListener('dragend', onDragEnd);
                    };
                })(element);

                const cleanupFileDropzone = (() => {
                    const dropzoneEl = element.querySelector<HTMLElement>('.drag-and-drop-zone');
                    if (!dropzoneEl) return () => {};
                    const observer = new DropObserver(dropzoneEl, {
                        type: 'text',
                        allowedFileTypes: ['application/json', 'text/plain'],
                    });
                    observer.onDragLeave(() => {
                        dragAndDrop.isHoverDropbox = false;
                        updateDropMode(dragAndDrop.isHoverDropbox);
                    });
                    observer.onDragOver(() => {});
                    observer.onError(err => {
                        externalAPI.onError?.(err);
                        reset();
                        throw err;
                    });
                    observer.onFileDrop(file => {
                        reset();
                        onViewImported(file);
                    });
                    return () => {
                        observer.detach();
                    };
                })();

                const { openUploadPopup, cleanupUploadPopup } = ((inputElement: HTMLInputElement) => {
                    const reader = new FileReader();
                    const onFileLoaded = (event: ProgressEvent<FileReader>) => {
                        // This method should be defined in the component scope that uses this directive.
                        onViewImported(event.target?.result);
                    };
                    const onFileInputChanged = (event: Event) => {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const target = event.target as null | HTMLInputElement;
                        if ((target?.files?.length ?? 0) === 0) return;
                        const file = inputElement.files?.[0];
                        if (!file) return;
                        reader.readAsText(file);
                    };

                    reader.addEventListener('load', onFileLoaded);
                    inputElement.addEventListener('change', onFileInputChanged);

                    return {
                        openUploadPopup: () => setTimeout(() => inputElement.click(), 0),
                        cleanupUploadPopup: () => {
                            reader.removeEventListener('load', onFileLoaded);
                            inputElement.removeEventListener('change', onFileInputChanged);
                        },
                    };
                })(inputElement);

                const disableDragAndDropZone = () => {
                    dragAndDrop.isHoverDropbox = false;
                    dragAndDrop.enabledFileDrop = false;
                    updateDropMode(dragAndDrop.isHoverDropbox);
                };
                const enableDragAndDropZone = () => {
                    reset();
                };

                $scope.fillDragAndDropZoneExternalAPI = methods => {
                    externalAPI.onError = methods.onError ? methods.onError : externalAPI.onError;
                    externalAPI.onFile = methods.onFile ? methods.onFile : externalAPI.onFile;

                    return {
                        openUploadPopup,
                        disableDragAndDropZone,
                        enableDragAndDropZone,
                    };
                };

                $scope.$on('$destroy', () => {
                    cleanupDragSection();
                    cleanupFileDropzone();
                    cleanupUploadPopup();
                });
            },
        };
    },
];

const DragAndDropZoneModule = angular
    .module('42.components.drag-and-drop-zone', [])
    .directive('dragAndDropZone', DragAndDropZoneDirective());

export default DragAndDropZoneModule;
