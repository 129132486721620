import {
    UserMenuDirective,
    UserMenuMobileDirective,
    UserMenuActionsDirective,
    UserMenuOrganizationListDirective,
} from './user-menu.directive';
import { UserMenuRunBlock } from './user-menu.service';

const module = angular.module('42.modules.user-menu', [
    'ui',
    '42.routes',
    '42.directives',
    '42.modules.libs.utils',
    '42.modules.libs.ui',
]);

module.run(UserMenuRunBlock());
module.directive('userMenu', UserMenuDirective());
module.directive('userMenuMobile', UserMenuMobileDirective());
module.directive('userMenuOrganizationList', UserMenuOrganizationListDirective());
module.directive('userMenuActions', UserMenuActionsDirective());
