const DUPLICATE_NAME_REGEX = /\(copy( \d+)?\)$/;
export function createDuplicateLabel(label: string): string {
    const matches = label.match(DUPLICATE_NAME_REGEX);

    if (!matches) return `${label} (copy)`;

    const name = label.replace(DUPLICATE_NAME_REGEX, '');
    let copyNumber = 1;
    if (matches[1]) copyNumber = parseInt(matches[1]);

    return `${name} (copy ${copyNumber + 1})`;
}
