import type { ServiceAPI } from './api';
import { ServiceAPIFactory } from './api';

type UserServiceAPICall<T = any, V = any> = (params?: T, httpOptions?: any) => Promise<V>;

export interface UserServiceAPI extends ServiceAPI {
    auth: {
        Auth42_Activate: UserServiceAPICall<{ token: string; password: string; confirm: string }, { loginUrl: string }>;
        Auth42_Callback: UserServiceAPICall;
        Auth42_GenerateActivationToken: UserServiceAPICall;
        Auth42_GetUserFromToken: UserServiceAPICall;
        Auth42_ValidateActivationToken: UserServiceAPICall;
    };
    organizations: {
        CreateOrganization: UserServiceAPICall;
        CreateUser: UserServiceAPICall;
        DeleteOrganization: UserServiceAPICall;
        DeleteUser: UserServiceAPICall;
        GetOrganization: UserServiceAPICall;
        GetOrganizationExternalConfig: UserServiceAPICall;
        GetOrganizationInternalConfig: UserServiceAPICall;
        GetOrganizations: UserServiceAPICall<any, any[]>;
        GetUser: UserServiceAPICall;
        GetUserExternalConfig: UserServiceAPICall;
        GetUserInternalConfig: UserServiceAPICall;
        GetUsers: UserServiceAPICall<{ organizationId: string }, any[]>;
        SetOrganizationExternalConfig: UserServiceAPICall;
        SetOrganizationInternalConfig: UserServiceAPICall<{ organizationId: string }>;
        SetUserExternalConfig: UserServiceAPICall;
        SetUserInternalConfig: UserServiceAPICall;
        UpdateOrganization: UserServiceAPICall;
    };
}

export const UserServiceAPI = new ServiceAPIFactory<UserServiceAPI>('user', false);
